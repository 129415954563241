import { combineReducers, configureStore, TypedStartListening } from '@reduxjs/toolkit';

import { cmsApi, serverApi, tenantApi } from 'api/baseServerApi';
import { listenerMiddleware } from 'api/listeners/initAppState';
import { dashboardsSlice, listenerMiddlewareDiscovery } from 'api/slices/dashboardsSlice';
import { discoveryPagesSlice } from 'api/slices/discoveryPagesSlice';
import { discoveryDashboardControlsSlice } from 'api/slices/discoveryDashboardControlsSlice';
import { appInfoSlice } from 'api/slices/appInfoSlice';
import { apitSlice } from 'api/slices/apitSlice';

const rootReducer = combineReducers({
    [serverApi.reducerPath]: serverApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [appInfoSlice.name]: appInfoSlice.reducer,
    [dashboardsSlice.name]: dashboardsSlice.reducer,
    [discoveryPagesSlice.name]: discoveryPagesSlice.reducer,
    [discoveryDashboardControlsSlice.name]: discoveryDashboardControlsSlice.reducer,
    [apitSlice.name]: apitSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend([listenerMiddleware.middleware, listenerMiddlewareDiscovery.middleware])
            .concat([serverApi.middleware, tenantApi.middleware, cmsApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const rootState = (state: RootState) => state;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

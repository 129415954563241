import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import './BreadCrumb.scss';
import { useLocation } from 'react-router';
import { UiIcon } from '../icon/UiIcon';
import { UiTooltip } from '../UiTooltip/UiTooltip';

export interface INavigationLinks {
    url: string;
    text: string;
    tooltip?: string | JSX.Element;
}

export interface IBreadcrumbProps {
    breadcrumbList: INavigationLinks[];
    selectedRoute: string;
    isPassParams?: boolean;
    showLastItem?: boolean;
}

export const BreadCrumb = (props: IBreadcrumbProps) => {
    const { breadcrumbList, selectedRoute, isPassParams, showLastItem = true } = props;
    const history = useHistory();
    const location = useLocation();

    return (
        <Breadcrumb className="breadcrumb-container" separator={<UiIcon name={'breadcrumbArrow'} />}>
            {breadcrumbList.map(({ text, tooltip, url }: INavigationLinks, index) => {
                const urlItem = url + (isPassParams ? location.search : '');
                const content = tooltip ? (
                    <UiTooltip
                        key={text + index}
                        title={tooltip}
                        overlayClassName="breadcrumb-tooltip"
                        placement="bottom"
                        disableCopyButton
                    >
                        <span className="breadcrumb-item" onClick={() => history.push(urlItem)}>
                            {text}
                        </span>
                    </UiTooltip>
                ) : selectedRoute !== text ? (
                    <span className="breadcrumb-item" onClick={() => history.push(urlItem)}>
                        {text}
                    </span>
                ) : showLastItem ? (
                    <span className="breadcrumb-item-current">{text}</span>
                ) : null;
                return <Breadcrumb.Item key={index}>{content}</Breadcrumb.Item>;
            })}
        </Breadcrumb>
    );
};

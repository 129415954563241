import { apiTags, IServerQueryResponse, tenantApi } from './baseServerApi';
import { fetchAll } from './apiUtils';
import { createSelector } from '@reduxjs/toolkit';

export enum ExclusionRuleOption {
    PATH = 'path',
    ATTRIBUTE = 'attribute',
}

export enum ExclusionRuleOperator {
    MATCH = 'match',
    NOTMATCH = 'notmatch',
}
export interface IFilterableAttribute {
    name: string;
    in: string;
    part_of: string;
}

export interface IExclusionRuleForm {
    filter_option: ExclusionRuleOption;
    attribute_name?: string;
    attribute_part_of?: string;
    attribute_in?: string;
    regex: string;
    operator: ExclusionRuleOperator;
    method?: string[];
    description?: string;
    service_pattern?: string; // regex or service name
    active?: boolean;
    id?: string;
}

export interface IExclusionRule extends IExclusionRuleForm {
    tenant_key?: string;
    creating_user?: string;
    created_at?: string;
    last_updated_user?: string;
    updated_at?: string;
}
export interface IExclusionRulePreviewItem {
    service_name: string;
    method?: string;
    methods?: string[];
    path: string;
}

export interface IExclusionRulePreviewItemQueryResponse extends IServerQueryResponse<IExclusionRulePreviewItem> {
    totalFilteredCalls: number;
}

export interface IEndpointDeletionRequest {
    endpoints_ids?: string[];
    patterns?: string[];
}

export const exclusionRuleApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getExclusionRuleAttributes: builder.query<IFilterableAttribute[], void>({
            query: () => ({
                url: 'discovery/exclusion_rules/filterable_attributes',
            }),
        }),
        getExclusionRules: builder.query<IServerQueryResponse<IExclusionRule>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IExclusionRule>(`discovery/exclusion_rules`, 1000, baseQuery),
            providesTags: [apiTags.endpointExclusionRules],
        }),
        previewExclusionRule: builder.query<IExclusionRulePreviewItemQueryResponse, IExclusionRuleForm>({
            query: (exclusionRule) => ({
                url: `discovery/exclusion_rules/preview`,
                method: 'POST',
                body: exclusionRule,
            }),
        }),
        createExclusionRule: builder.mutation<void, IExclusionRuleForm>({
            query: (exclusionRule) => ({
                url: `discovery/exclusion_rules`,
                method: 'POST',
                body: exclusionRule,
            }),
            invalidatesTags: [apiTags.endpointExclusionRules],
        }),
        updateExclusionRule: builder.mutation<void, Partial<IExclusionRuleForm>>({
            query: ({ id, ...body }) => ({
                url: `discovery/exclusion_rules/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: [apiTags.endpointExclusionRules],
        }),
        deleteExclusionRule: builder.mutation<void, string>({
            query: (exclusionRuleId) => ({
                url: `discovery/exclusion_rules/${exclusionRuleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [apiTags.endpointExclusionRules],
        }),
    }),
});

export const {
    useGetExclusionRuleAttributesQuery,
    useGetExclusionRulesQuery,
    useLazyPreviewExclusionRuleQuery,
    useCreateExclusionRuleMutation,
    useUpdateExclusionRuleMutation,
    useDeleteExclusionRuleMutation,
} = exclusionRuleApi;

export const selectExclusionRulesResponse = exclusionRuleApi.endpoints.getExclusionRules.select();
export const selectExclusionRuleAttributes = exclusionRuleApi.endpoints.getExclusionRuleAttributes.select();
export const selectExclusionRules = createSelector(selectExclusionRulesResponse, ({ data }) => data?.items);
export const selectExclusionRuleById = (exclusionRuleId: IExclusionRuleForm['id']) =>
    createSelector(selectExclusionRules, (exclusionRules) =>
        exclusionRules?.find((rule) => rule.id === exclusionRuleId)
    );

export const selectExclusionRuleAttributeOptions = createSelector(
    selectExclusionRuleAttributes,
    ({ data: attributeList }) => {
        if (!attributeList) {
            return [];
        }

        const groups: Record<string, IFilterableAttribute[]> = attributeList.reduce(
            (attributesGroupBy: Record<string, IFilterableAttribute[]>, item: IFilterableAttribute) => ({
                ...attributesGroupBy,
                [`${item.part_of} ${item.in}`]: [...(attributesGroupBy[`${item.part_of} ${item.in}`] || []), item],
            }),
            {}
        );

        const attributesOptionsType = Object.keys(groups).map((sectionKey: string) => {
            const options = groups[sectionKey].map((item) => ({
                key: item.name,
                value: item.name,
            }));
            return { title: sectionKey, options };
        });
        return attributesOptionsType;
    }
);

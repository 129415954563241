import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { API_TESTING_PATH, IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { UiTabsNav } from 'sharedComponents/UiTabsNav/UiTabsNav';
import { ScanList } from 'features/apiTesting/scans/scanList/ScanList/ScanList';
import { BreadCrumb } from 'sharedComponents/breadcrumb/BreadCrumb';
import { useGetApplicationQuery } from 'api/apiTestingApi';
import { ApplicationSummary } from './ApplicationSummary/ApplicationSummary';
import { ReactComponent as NoConfigsImage } from 'features/settings/shared/assets/no_documents.svg';
import { EmptyStateList } from 'sharedComponents/EmptyStateList/EmptyStateList';
import { UiButton } from 'sharedComponents/button/Button';
import { AddScanFormModal } from 'features/apiTesting/scans/scanList/AddScanFormModal/AddScanFormModal';
import Spinner from 'sharedComponents/spinner/Spinner';
import { FindingList } from 'features/apiTesting/findings/FindingList/FindingList';
import { ExportButton } from 'features/apiTesting/shared/ExportButton/ExportButton';

import './ApplicationDetails.scss';

enum ScansOrFindingsTabsEnum {
    SCANS = 'scans',
    FINDINGS = 'findings',
}

export const ApplicationDetails = () => {
    const { scansOrFindings, appId } = useParams<IApitRouteParams>();
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const { data: applicationData, isLoading } = useGetApplicationQuery({ appId }, { pollingInterval: 6000 });

    const isFindingTab = scansOrFindings === ScansOrFindingsTabsEnum.FINDINGS;

    const invalidPathParam =
        scansOrFindings !== ScansOrFindingsTabsEnum.FINDINGS && scansOrFindings !== ScansOrFindingsTabsEnum.SCANS;
    const findingTabIsDisabled = applicationData && applicationData?.totalFindings < 1 && isFindingTab;

    useEffect(() => {
        if (invalidPathParam || findingTabIsDisabled) {
            history.push(`/${currentTenantKey}/${API_TESTING_PATH}/applications/${appId}/scans`);
        }
    }, [applicationData, scansOrFindings]);

    const handleTabSelected = useCallback(
        (key: string | number) => {
            history.push(`/${currentTenantKey}/${API_TESTING_PATH}/applications/${appId}/${key}`);
        },
        [history, currentTenantKey]
    );

    if (isLoading) {
        return <Spinner show />;
    }

    return (
        <div className="ApplicationDetails">
            <div className="app-details-header">
                <div>
                    <BreadCrumb
                        isPassParams
                        breadcrumbList={[
                            { url: `/${currentTenantKey}/${API_TESTING_PATH}/applications`, text: 'API Testing' },
                            {
                                url: `/${currentTenantKey}/${API_TESTING_PATH}/applications/${appId}/scans`,
                                text: applicationData?.name || '',
                            },
                        ]}
                        selectedRoute={applicationData?.name || ''}
                    />
                    <span className="app-details-title">{applicationData?.name}</span>
                </div>
                {isFindingTab ? (
                    <ExportButton />
                ) : (
                    <UiButton type="primary" text="New Scan" onClick={() => setIsAddModalOpen(true)} />
                )}
                {isAddModalOpen && <AddScanFormModal onClose={() => setIsAddModalOpen(false)} />}
            </div>
            {!applicationData || (applicationData?.totalScans < 1 && applicationData?.totalFindings < 1) ? (
                <EmptyStateList text={<>ADD A NEW SCAN TO GET STARTED</>} img={<NoConfigsImage />} />
            ) : (
                <div className="app-details-content">
                    <ApplicationSummary application={applicationData} />
                    <div className="subsections-command-bar">
                        <UiTabsNav
                            className="tabs-nav"
                            items={[
                                {
                                    key: ScansOrFindingsTabsEnum.FINDINGS,
                                    label: 'Findings',
                                    disabled: applicationData?.totalFindings < 1,
                                },
                                { key: ScansOrFindingsTabsEnum.SCANS, label: 'Scans' },
                            ]}
                            onTabSelected={handleTabSelected}
                            activeKey={scansOrFindings}
                        />
                    </div>
                    {isFindingTab ? <FindingList /> : <ScanList />}
                </div>
            )}
        </div>
    );
};

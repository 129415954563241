import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './Severity.scss';

export type TSeverity = 'Info' | 'Low' | 'Medium' | 'High' | 'Critical';

interface ISeverityProps {
    severity: TSeverity;
}

export const Severity = ({ severity }: ISeverityProps) => {
    const formattedSeverity = severity.charAt(0).toUpperCase() + severity.substr(1).toLowerCase();
    return (
        <div className="Severity">
            <UiIcon name={`alert${formattedSeverity}`} />
            <span>{formattedSeverity}</span>
        </div>
    );
};

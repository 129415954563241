import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Select, Radio, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { formFieldName, formFieldRequired, isDuplicatedNameValidator, urlRule } from 'general/forms';
import { ICollector } from '../shared/collectorApis';
import { DataSourceDetailsContext } from '../../dataSources/DataSourceDetails/DataSourceDetails';
import { IDropDownOptionsProps } from '../CollectorList/CollectorList';
import { RadioChangeEvent } from 'antd/lib/radio';

export interface INeosecNodeAddFormStep1Props {
    form: FormInstance;
    collectors?: ICollector[];
    collectorConfigsOptions: IDropDownOptionsProps[];
}

export enum ConfigRadiosOptions {
    NOW = 'now',
    LATER = 'later',
}

export const NeosecNodeAddFormStep1 = ({ form, collectors, collectorConfigsOptions }: INeosecNodeAddFormStep1Props) => {
    const { currentDataSource } = useContext(DataSourceDetailsContext);
    const [configDropdownDisabled, setConfigDropdownDisabled] = useState<boolean>(true);

    useEffect(() => {
        const currentConfigState = form.getFieldValue('configDropdownEnabler') || ConfigRadiosOptions.LATER;
        setConfigDropdownDisabled(currentConfigState === ConfigRadiosOptions.LATER);
    }, [form]);

    const handleConfigStateChange = (e: RadioChangeEvent) => {
        if (e.target.value === ConfigRadiosOptions.LATER) {
            setConfigDropdownDisabled(true);
            form.setFieldsValue({ currentConfigId: undefined });
        } else {
            setConfigDropdownDisabled(false);
        }
    };

    return (
        <Form form={form} colon={false} layout="vertical" requiredMark={false} title="General">
            <div className="node-general-form">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            validateFirst
                            rules={[
                                {
                                    required: true,
                                    message: formFieldRequired.errorMessage,
                                },
                                {
                                    pattern: formFieldName.pattern,
                                    message: formFieldName.errorMessage,
                                },
                                {
                                    validator: (_, value) => {
                                        return isDuplicatedNameValidator(collectors || [], value, 'Node');
                                    },
                                    message: 'Name already exists',
                                },
                            ]}
                        >
                            <Input placeholder="My new Neosec Node" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="sourceId" label="Source Name">
                            <div className="readonly-text">{currentDataSource?.name}</div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="proxy" label="Outbound Proxy" rules={[urlRule('Invalid proxy URL')]}>
                            <Input placeholder="https://proxy.intranet.example.com:8443" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className="configuration-radio-toggle-container">
                    <Col>
                        <Form.Item name="configDropdownEnabler" label="Configuration">
                            <Radio.Group onChange={handleConfigStateChange} className="config-state-chooser">
                                <Radio value={ConfigRadiosOptions.LATER}>I'll do that later</Radio>
                                <Radio value={ConfigRadiosOptions.NOW}>Use an existing Configuration</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Configuration"
                            name="currentConfigId"
                            rules={[{ required: !configDropdownDisabled, message: formFieldRequired.errorMessage }]}
                        >
                            <Select
                                options={collectorConfigsOptions}
                                placeholder="Select a value"
                                disabled={configDropdownDisabled}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
    );
};

import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { ReactNode } from 'react';
import './UiPopover.scss';

interface IPopoverProps {
    placement?: PopoverProps['placement'];
    children?: ReactNode;
    content: ReactNode;
    title: string | ReactNode;
    open: boolean;
    closeAble: boolean;
    onOpenChange: (value: boolean) => void;
    onClose?: () => void;
}

export const UiPopover = ({
    placement = 'bottomRight',
    children,
    title,
    content,
    open,
    closeAble,
    onOpenChange,
    onClose,
}: IPopoverProps) => {
    const handleOpenChange = (value: boolean) => {
        if (!closeAble && !value) return;
        if (!value) onClose?.();
        onOpenChange(value);
    };
    return (
        <Popover
            placement={placement}
            title={title}
            content={content}
            trigger="click"
            className="UiPopover"
            overlayClassName="UiPopoverOverlay"
            open={open}
            onOpenChange={handleOpenChange}
        >
            {children}
        </Popover>
    );
};

import { IServerQueryResponse, tenantApi } from './baseServerApi';

export interface IComment {
    id: string;
    created_by: string;
    created_at: string;
    content: string;
}

export interface ICommentsResponse {
    data?: Pick<IServerQueryResponse<IComment>, 'items' | 'total'>;
    isLoading: boolean;
    isFetching: boolean;
}

export const alertApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getAlert: builder.query<{ comments: ICommentsResponse[] }, { alertId: string }>({
            query: ({ alertId }) => `alerts/${alertId}`,
        }),
        getComments: builder.query<
            Pick<IServerQueryResponse<IComment>, 'items' | 'total'>,
            { alertId: string; limit?: number; offset?: number }
        >({
            query: ({ alertId, limit = 30, offset = 0 }) =>
                `alerts/${alertId}/comments?limit=${limit}&offset=${offset}`,
            providesTags: ['alertComments'],
        }),
        addComment: builder.mutation<IComment, { alertId: string; content: String }>({
            query: ({ alertId, content }) => ({
                url: `alerts/${alertId}/comments`,
                method: 'POST',
                body: { content },
            }),
            invalidatesTags: ['alertComments'],
        }),
        deleteComment: builder.mutation<unknown, { alertId: string; commentId: String }>({
            query: ({ alertId, commentId }) => ({
                url: `alerts/${alertId}/comments/${commentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['alertComments'],
        }),
    }),
    overrideExisting: true,
});

export const useGetAlertQuery = alertApi.endpoints.getAlert.useQuery;
export const useGetAlertCommentsQuery = alertApi.endpoints.getComments.useQuery;
export const useAddAlertCommentMutation = alertApi.endpoints.addComment.useMutation;
export const useDeleteAlertCommentMutation = alertApi.endpoints.deleteComment.useMutation;

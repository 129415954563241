import { useSelector } from 'react-redux';

import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';

export const useRedirectToServicePage = (dateRange?: TDatetimeRange) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    if (!dateRange) {
        return;
    }
    const queryParamDateRange = `${dateRange![0].format('X')}&to_timestamp=${dateRange![1].format('X')}`;

    return ({ name }: any) => {
        const encodeName = encodeURIComponent(name);
        const pathname = `/${currentTenantKey}/discovery/services/${encodeName}/?from_timestamp=${queryParamDateRange}`;
        const win = window.open(pathname, '_blank', 'noopener,noreferrer');
        if (win != null) {
            win.focus();
        }
    };
};

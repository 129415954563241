import { ColDef } from '@ag-grid-community/core';

import { BASIC_COLUMN_DEFINITION, DISABLE_FILTER_AND_SORT } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import {
    CellRenderLink,
    CellRenderScanStatus,
    CellRenderTimeStamp,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { IApplication } from 'api/apiTestingApi';

const customLastScanRenderer = (props: any) => {
    const { status, ended_at } = props?.data?.lastScan || {};

    if (status) {
        if (status === 'Completed' && ended_at) {
            return CellRenderTimeStamp({ value: ended_at });
        }
        return CellRenderScanStatus({ value: status });
    } else {
        return '-';
    }
};

export const applicationTableColumnDefinitions = (applicationTableMenu: any, applicationsBaseUrl: string): ColDef[] => [
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'NAME',
        field: 'name',
        sort: 'asc',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear', 'apply'],
            filterOptions: ['contains', 'notContains'],
            closeOnApply: true,
        },
        cellRenderer: CellRenderLink,
        cellRendererParams: ({ data }: any) => ({
            path: `${applicationsBaseUrl}/${data.id}/findings`,
            text: data.name,
        }),
        flex: 2,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'ENDPOINTS',
        field: 'totalEndpoints',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'FINDINGS',
        field: 'totalFindings',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'HIGH',
        headerComponentParams: { prefixIconName: 'alertHigh' },
        field: 'highFindings',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'MEDIUM',
        headerComponentParams: { prefixIconName: 'alertMedium' },
        field: 'mediumFindings',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'LOW',
        headerComponentParams: { prefixIconName: 'alertLowOpaque' },
        field: 'lowFindings',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'INFO',
        headerComponentParams: { prefixIconName: 'alertInfo' },
        field: 'infoFindings',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'TOTAL SCANS',
        field: 'totalScans',
        suppressMenu: true,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        type: 'agSetFilter',
        headerName: 'Last Scan',
        field: 'lastScan',
        cellRenderer: customLastScanRenderer,
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: '',
        field: 'more',
        width: 64,
        flex: 0,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: (params: { data: IApplication }) => ({
            menuItems: applicationTableMenu(params.data),
        }),
        suppressMenu: true,
        suppressMovable: true,
        filter: false,
        filterParams: null,
        sortable: false,
        resizable: false,
    },
];

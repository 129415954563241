import './DiscoveryDashboardEmptyState.scss';

export interface DiscoveryDashboardEmptyStateProps {
    title: string;
    widgetSvg: string;
}
export const DiscoveryDashboardEmptyState = ({ title, widgetSvg }: DiscoveryDashboardEmptyStateProps) => (
    <div className="EmptyState">
        <div className="widget-header">
            <div className="widget-title">{title}</div>
            <div className="no-data">
                <span>No Data</span>
            </div>
        </div>
        <div className="widget-empty-state-svg">
            <img src={widgetSvg} alt="empty state image" />
        </div>
    </div>
);

import { ContentType } from 'recharts/types/component/DefaultLegendContent';

import './UiChartLegend.scss';

export const UiChartLegend: ContentType = ({ payload, align, verticalAlign, layout, onClick, formatter }) => {
    return (
        <ul className={`UiChartLegend align-${align} verticalAlign-${verticalAlign} layout-${layout}`}>
            {payload?.map((entry, index) => (
                <li key={`item-${index}`} className="legend-item">
                    <div className="legend-item-color" style={{ backgroundColor: entry.color || 'black' }} />
                    {formatter?.(entry.value, entry, index) || entry.value}
                </li>
            ))}
        </ul>
    );
};

import { Switch } from 'antd';

import { LabelValue } from 'interfaces/labels.interface';

import './UiToggle.scss';
import { useState } from 'react';

export interface IUiToggleProps {
    firstOption: LabelValue;
    secondOption: LabelValue;
    initialOptionValue?: LabelValue['value'];
    onChange: (value: LabelValue['value']) => void;
}

export const UiToggle = ({ firstOption, secondOption, initialOptionValue, onChange }: IUiToggleProps) => {
    const [selectedOptionValue, setSelectedOptionValue] = useState<LabelValue['value']>(
        initialOptionValue || firstOption.value
    );

    const handleOnChange = (switchValue: boolean) => {
        const newValue = switchValue ? secondOption.value : firstOption.value;
        setSelectedOptionValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="UiToggle">
            <span className={`uit-label ${selectedOptionValue === firstOption.value ? '' : 'uit-label--inactive'}`}>
                {firstOption.label}
            </span>
            <Switch
                className="uit-switch"
                size="small"
                defaultChecked={initialOptionValue === secondOption.value}
                onChange={handleOnChange}
            />
            <span className={`uit-label ${selectedOptionValue === secondOption.value ? '' : 'uit-label--inactive'}`}>
                {secondOption.label}
            </span>
        </div>
    );
};

import React, { useReducer, createContext, Dispatch } from 'react';

export interface IAppState {
    tableApi: { gridApi: any; columnApi: any } | null;
}

interface IProps {
    children: JSX.Element;
}

interface Actions {
    SET_TABLE_API: string;
}

export const actions: Actions = {
    SET_TABLE_API: 'SET_TABLE_API',
};

function action(type: string) {
    return { type };
}

export type ActionType = {
    type: string;
    payload:
        | { id: string; key: string; name: string }
        | { id: string; key: string; name: string }[]
        | { gridApi: any; columnApi: any }
        | undefined
        | null;
};

function actionPayload(type: string, payload: string | Object) {
    return { type, payload };
}

export const dispatches = {
    setUser: actionPayload,
    setTableApi: actionPayload,
};

const initialState = {
    currOrg: null,
    orgList: null,
    tableApi: null,
};

export const AppStateContext = createContext<{
    state: IAppState;
    dispatch: Dispatch<ActionType>; //action type here
}>({
    state: initialState,
    dispatch: () => null,
});
AppStateContext.displayName = 'App_State_Context';

const reducer = (state: IAppState, action: ActionType) => {
    switch (action.type) {
        case actions.SET_TABLE_API:
            return { ...state, tableApi: action.payload };
        default:
            return state;
    }
};

export const AppStateProvider = ({ children }: IProps) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>;
};

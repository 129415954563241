import { Divider } from 'antd';

import { IApplication } from 'api/apiTestingApi';
import { ValueWithLabel, ValueWithIcon } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { fullDateStringFormat } from 'general/utils';

import './ApplicationSummary.scss';

interface IApplicationProps {
    application?: IApplication;
}

export const ApplicationSummary = ({ application }: IApplicationProps) => {
    const {
        totalEndpoints,
        totalFindings,
        mediumFindings,
        lowFindings,
        highFindings,
        infoFindings,
        totalScans,
        lastScan,
    } = application || {};

    return (
        <div className="ApplicationSummary">
            <div className="dividerCell">
                <ValueWithLabel label="endpoints" value={totalEndpoints} />
                <Divider type="vertical" style={{ height: '100%' }} />
            </div>
            <ValueWithLabel label="findings" value={totalFindings} />
            <ValueWithLabel label="high" value={<ValueWithIcon value={highFindings} iconName="alertHigh" />} />
            <ValueWithLabel label="medium" value={<ValueWithIcon value={mediumFindings} iconName="alertMedium" />} />
            <ValueWithLabel label="low" value={<ValueWithIcon value={lowFindings} iconName="alertLowOpaque" />} />
            <div className="dividerCell">
                <ValueWithLabel label="info" value={<ValueWithIcon value={infoFindings} iconName="alertInfo" />} />
                <Divider type="vertical" style={{ height: '100%' }} />
            </div>
            <ValueWithLabel label="total scans" value={totalScans} />
            <ValueWithLabel
                label="last scanned"
                value={lastScan?.created_at && fullDateStringFormat(lastScan?.created_at, false)}
            />
        </div>
    );
};

import React from 'react';
import { Col, Row } from 'antd';
import { IDiscoveryEndpointResponse } from 'interfaces/endpoint.interface';
import { isObjectNotEmpty } from 'general/utils';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { colDefs, dummyFunctions } from 'features/discovery/service/ServiceSummary/ServiceSummary';

interface IProps {
    endpoint: IDiscoveryEndpointResponse | undefined;
    isClosed?: boolean;
}

export const EndpointSummary = ({ endpoint, isClosed }: IProps) => {
    if (endpoint) {
        return (
            <div className={`service-summary-container${isClosed ? ' closed' : ''}`}>
                {isObjectNotEmpty(endpoint) && (
                    <Row className={'service-row'}>
                        {Object.entries(endpoint).map(function ([key, value], index) {
                            return (
                                colDefs.hasOwnProperty(key) && (
                                    <Col key={index} order={colDefs[key].order}>
                                        <UiTitle title={colDefs[key].displayName} type="medium" />
                                        <div className="value alerts-value">
                                            <div className="text">
                                                <span>
                                                    {(colDefs[key].renderer || dummyFunctions.renderer)(
                                                        (colDefs[key].formatter || dummyFunctions.formatter)(value)
                                                    )}
                                                </span>{' '}
                                                {colDefs[key].getIcon && (
                                                    <span className={'icon'}>
                                                        {(colDefs[key].getIcon || dummyFunctions.getIcon)(value)}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                )
                            );
                        })}
                    </Row>
                )}
            </div>
        );
    } else return null;
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { goToAlertsInNewTab } from 'general/utils';
import { AlertSeverityEnum } from 'enums/alertSeverity.enum';
import { UiLabel } from 'sharedComponents/label/UiLabel';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

import './AlertsOverview.scss';

const severities = Object.keys(AlertSeverityEnum);

interface IAlertsOverviewProps {
    alertsData: {
        heatMap: IAlertGridRow[];
    };
}

export interface IAlertGridRow {
    label: string;
    values: number[];
    totalAlertsForLabel: number; // not displayed - for sorting purposes
}

export const AlertsOverview = ({ alertsData }: IAlertsOverviewProps) => {
    const [heatMap, setHeatMap] = useState<IAlertGridRow[]>([]);
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    useEffect(() => {
        // // sort alert heatmap rows and add the unlabeled row at the end
        // let rows = alertsData.heatMap.reduce <{ labeledRows: IAlertGridRow[], unlabeledRow?: IAlertGridRow }>(
        //     (acc, alertRow) => {
        //         const newRow = {
        //             label: alertRow.label,
        //             values: alertRow.values,
        //             totalAlertsForLabel: alertRow.totalAlertsForLabel
        //         }
        //         if (alertRow.label) { // for labeled rows - add the row to the heatmap as is
        //             acc.labeledRows.push(newRow)
        //         } else {
        //             acc.unlabeledRow = { // for unlabeled rows - sum up the alerts in each severity. Unlabled rows are not displayed
        //                 label: {
        //                     text: 'unlabeled',
        //                     background_color: '#000',
        //                     font_color: '#000',
        //                 },
        //                 values: alertRow.values,
        //                 totalAlertsForLabel: (acc.unlabeledRow?.totalAlertsForLabel || 0) + alertRow.totalAlertsForLabel
        //             }
        //         }
        //
        //         return acc
        //     }, {labeledRows: []});

        const rows = { labeledRows: [...alertsData.heatMap] };
        rows.labeledRows.sort((a: IAlertGridRow, b: IAlertGridRow) => {
            // sort alerts grid by higher severity -> lower severity count (i.e 5 critical alerts is higher than 4 critical and 12 High), then by total no of Alerts
            return (
                b.values[0] - a.values[0] ||
                b.values[1] - a.values[1] ||
                b.values[2] - a.values[2] ||
                b.values[3] - a.values[3] ||
                b.values[4] - a.values[4] ||
                b.totalAlertsForLabel - a.totalAlertsForLabel
            );
        });

        // if (rows.unlabeledRow) {
        //     rows.labeledRows.push(rows.unlabeledRow)
        // }
        setHeatMap(rows.labeledRows);
    }, [alertsData]);

    const getHeaderRow = () => {
        return (
            <div className="alerts-header-row">
                <div className="spacer" />
                <div className="headers">
                    {severities.map((severity) => (
                        <div
                            className="header"
                            key={severity}
                            onClick={() => goToAlertsInNewTab(currentTenantKey, Object(AlertSeverityEnum)[severity])}
                        >
                            <div className="top">
                                <UiIcon name={`alert${severity}`} />
                            </div>
                            <div className="bottom">
                                <span className="alert-severity">{severity === 'Medium' ? 'Med.' : severity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const bubble = (val: number, colNumber: number, label: string) =>
        val ? (
            <div
                className={`bubble ${severities[colNumber].toLowerCase()}`}
                onClick={() =>
                    goToAlertsInNewTab(currentTenantKey, Object(AlertSeverityEnum)[severities[colNumber]], label)
                }
                key={`key-${colNumber}`}
            >
                {val}
            </div>
        ) : (
            <div className="empty" key={`empty-${colNumber}`} />
        ); // not displaying zeros

    const getRow = (row: IAlertGridRow, index: number) => {
        if (!row.label || row.label === 'unlabeled') {
            return;
        }

        return (
            <div className="alerts-row" key={index}>
                <div className="tag-label">
                    <UiLabel text={row.label} />
                </div>
                <div className="alerts-values">{row.values.map((item, index) => bubble(item, index, row.label))}</div>
            </div>
        );
    };

    return (
        <div className="alerts-overview-container">
            {getHeaderRow()}
            <div className="custom-grid">{heatMap.map((item, index) => getRow(item, index))}</div>
        </div>
    );
};

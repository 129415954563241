import { Timeline } from 'antd';

import { TimeDisplayResEnum, timeStringFormat } from 'general/utils';
import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { ICall, IGroupedFinding } from 'api/apiTestingApi';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './FindingDetailsTimeline.scss';

interface IFindingDetailsTimeline {
    calls: ICall[];
    offendingCallSeverity: IGroupedFinding['severity'];
    onItemClick: (index: number) => void;
}

export const FindingDetailsTimeline = ({ calls, offendingCallSeverity, onItemClick }: IFindingDetailsTimeline) => {
    return (
        <div className="FindingDetailsTimeline">
            <div className="timeline-title">Timeline</div>
            <Timeline mode="left">
                {calls.map((call, index) => (
                    <Timeline.Item
                        label={timeStringFormat(call.timestamp, TimeDisplayResEnum.SEC)}
                        key={call.timestamp}
                    >
                        <div className="fdt-timeline-item" onClick={() => onItemClick(index)}>
                            <div className="fdt-icon">
                                {call.is_offending && <UiIcon name={`alert${offendingCallSeverity}`} />}
                            </div>
                            <div className="fdt-endpoint">
                                <ApiLabel apiType={[call.method]} place="top" apiName={call.url} />
                            </div>
                            <div className="fdt-status-code">{call.response_status_code}</div>
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    );
};

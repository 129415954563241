import { IServerQueryResponse, tenantApi } from './baseServerApi';
import { urlEncode } from 'general/utils';

export type TEntityFamily = 'actor' | 'business';

export interface IEntity {
    index: number;
    source_attribute: string;
    name: string;
    pretty_name: string;
    class: string;
    family: TEntityFamily;
    value_type: string;
    column_name: string;
    active: boolean;
    hidden: boolean;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    value?: string;
}

export type INewEntityDefinition = Pick<IEntity, 'name' | 'class' | 'family' | 'value_type' | 'source_attribute'>;

export interface IEntityClass {
    class_name: string;
    family: TEntityFamily;
}

export const entityManagementApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getEntities: builder.query<IServerQueryResponse<IEntity>, { includeHidden?: boolean } | void>({
            query: (params: { includeHidden?: boolean }) => `entities?include_hidden=${!!params?.includeHidden}`,
            providesTags: ['entities'],
        }),
        postEntity: builder.mutation<IEntity, { body: INewEntityDefinition }>({
            query: ({ body }) => ({
                url: `entities`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['entities'],
        }),
        patchEntity: builder.mutation<
            null,
            { entityName: IEntity['name']; body: Pick<IEntity, 'active'> | Pick<IEntity, 'hidden'> }
        >({
            query: ({ entityName, body }) => ({
                url: `entities/${urlEncode(entityName)}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['entities'],
        }),
        getClasses: builder.query<IServerQueryResponse<IEntityClass>, { family: TEntityFamily }>({
            query: ({ family }) => `entity_classes?family_name=${family}`,
        }),
    }),
    overrideExisting: false,
});

export const useGetEntitiesQuery = entityManagementApi.endpoints.getEntities.useQuery;
export const usePostEntityMutation = entityManagementApi.endpoints.postEntity.useMutation;
export const usePatchEntityMutation = entityManagementApi.endpoints.patchEntity.useMutation;
export const useGetClassesQuery = entityManagementApi.endpoints.getClasses.useQuery;

import { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ISettingsPathParams } from '../../../Settings';
import { deleteDataSource, IDataSource } from '../shared/dataSourceApis';
import { useFetchAllDataSources } from '../shared/useFetchAllDataSources';
import { SettingsSectionHeader } from '../../SettingsSectionHeader/SettingsSectionHeader';
import { ReactComponent as NoDataSourceImage } from '../../../shared/assets/no_servers.svg';
import { DataSourceCard } from '../DataSourceCard/DataSourceCard';
import { ApiResponse } from 'interfaces/apiResponse.interface';
import { errorMessage, successMessage } from 'general/toast-service';

import './DataSourceList.scss';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { removeTrailingSlashes } from 'general/utils';
import { UiOverlayMenu } from 'sharedComponents/UiOverlayMenu/UiOverlayMenu';
import Spinner from 'sharedComponents/spinner/Spinner';
import { EmptyStateList } from 'sharedComponents/EmptyStateList/EmptyStateList';
import { DataSourceAddEditModal } from 'features/settings/components/dataSources/DataSourceAddEditModal/DataSourceAddEditModal';
import { UiModal } from 'sharedComponents/UiModal/UiModal';

export interface IDataSourceListProps {
    addMode?: boolean;
    editMode?: boolean;
}

export const DataSourceList = (props: IDataSourceListProps) => {
    const { addMode, editMode } = props;
    const { activeOrg } = useParams<ISettingsPathParams>();
    const history = useHistory();

    const [dataSources, setDataSources] = useState<IDataSource[]>();
    const [dataSourceToDelete, setDataSourceToDelete] = useState<IDataSource>();

    const refreshDataSourceList = useFetchAllDataSources(setDataSources);

    const deleteDS = async () => {
        try {
            const deleteRes: ApiResponse = await deleteDataSource(activeOrg, dataSourceToDelete?.id as string).then(
                (res) => ({
                    status: res.status,
                    message: res.data,
                })
            );
            setDataSourceToDelete(undefined);
            deleteRes.status === 204 ? successMessage('Deleted successfully') : errorMessage(deleteRes.message);
            await refreshDataSourceList();
        } catch (e) {
            errorMessage('Error: cannot delete Data Source');
        }
    };

    const getContextMenuItems = useCallback(
        (ds: IDataSource) => {
            const menuItems = [
                {
                    label: 'Edit',
                    onClick: () =>
                        history.push({ pathname: `${removeTrailingSlashes(history.location.pathname)}/${ds.id}/edit` }),
                    disabled: false,
                    icon: <UiIcon name="pencil" />,
                },
                {
                    label: 'Delete',
                    onClick: () => setDataSourceToDelete(ds),
                    disabled: ds.numberOfCollectors > 0 || ds.numberOfSensors > 0,
                    icon: <UiIcon name="trash" />,
                    tooltip: 'Data Source Not Empty',
                },
            ];

            return <UiOverlayMenu menuItems={menuItems} />;
        },
        [history, activeOrg]
    );

    const handleClosingAddEditModal = (refreshNeeded?: boolean) => {
        history.push({ pathname: `/${activeOrg}/settings/data-sources` });
        refreshNeeded && refreshDataSourceList();
    };

    return (
        <div className="DataSourceList">
            <SettingsSectionHeader
                title="Data Sources"
                buttonText="Add Data Source"
                urlPath="settings/data-sources/add"
            />
            <div className="subtitle">{`${dataSources?.length || 0} Sources`}</div>
            <div className="content">
                <Spinner show={!dataSources} />
                {dataSources?.length === 0 && (
                    <EmptyStateList
                        text={
                            <>
                                Start analyzing your API activity by adding <br /> a new Data Source
                            </>
                        }
                        img={<NoDataSourceImage />}
                    />
                )}
                <div className="data-sources-grid card-grid">
                    {dataSources?.map((ds) => (
                        <DataSourceCard key={ds?.id} menu={getContextMenuItems(ds)} ds={ds} />
                    ))}
                </div>
            </div>
            {addMode && <DataSourceAddEditModal onClose={handleClosingAddEditModal} />}
            {editMode && <DataSourceAddEditModal onClose={handleClosingAddEditModal} />}
            {dataSourceToDelete && (
                <UiModal
                    onCancel={() => setDataSourceToDelete(undefined)}
                    acceptButton={{ text: 'Delete', onClick: deleteDS }}
                    rejectButton={{ text: 'Cancel', onClick: () => setDataSourceToDelete(undefined) }}
                    title={'Delete Data Source?'}
                    icon={'trash2'}
                >{`Delete the Data Source '${dataSourceToDelete.name}'?`}</UiModal>
            )}
        </div>
    );
};

import { tenantApi } from './baseServerApi';
import { createSelector } from '@reduxjs/toolkit';

export interface IConfigResponse {
    default_interval: string;
    labels: string[];
    timeframe: {
        start_timestamp: number; // epoch in milliseconds
        end_timestamp: number; // epoch in milliseconds
    };
    creation_system: 'config' | 'manual';
    detok_ratelimit_apikey: string;
    is_demo: boolean;
}

export const configApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getConfig: builder.query<IConfigResponse, void>({
            query: () => `config`,
        }),
    }),
    overrideExisting: false,
});

export const useGetConfigQuery = configApi.endpoints.getConfig.useQuery;
export const useGetConfigLazyQuery = configApi.endpoints.getConfig.useLazyQuery;
export const selectConfig = createSelector(configApi.endpoints.getConfig.select(), ({ data }) => data);

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { AppDispatch } from 'general/store';
import { UiChronoRangePicker } from 'sharedComponents/shared/UiChronoRangePicker/UiChronoRangePicker';
import {
    DiscoveryDashboardFilterEnum,
    selectDateRangeOrPreset,
    selectSelectedServiceLabels,
    selectSelectedServices,
    selectServicesOrLabelsToggle,
    updateDiscoveryDashboardControls,
} from 'api/slices/discoveryDashboardControlsSlice';
import { useGetConfigQuery } from 'api/configApi';
import { ServiceMultiSelect } from 'sharedComponents/ServiceMultiSelect/ServiceMultiSelect';
import { ServiceLabelMultiSelect } from './ServiceLabelMultiSelect/ServiceLabelMultiSelect';
import { UiToggle } from 'sharedComponents/UiToggle/UiToggle';
import {
    DEFAULT_PRESET,
    getPresetDateRanges,
    isDisabledDate,
    TDatetimeRangeOrPreset,
} from 'sharedComponents/shared/UiChronoRangePicker/utils';

import './DiscoveryDashboardControls.scss';

export const DiscoveryDashboardControls = () => {
    const rtkDispatch = useDispatch<AppDispatch>();
    const { data: config } = useGetConfigQuery();
    const dateRange: TDatetimeRangeOrPreset = useSelector(selectDateRangeOrPreset);
    const now = useMemo(() => moment(), []);
    const datePresets = useMemo(() => {
        return (
            config &&
            getPresetDateRanges(
                config,
                ['Today', 'Last 24 Hours', 'Last 7 Days', 'Last 14 Days', 'Last 30 Days', DEFAULT_PRESET],
                now
            )
        );
    }, [config, now.format('x')]);
    const disabledDate = useMemo(() => (config ? isDisabledDate(config, now) : () => false), [config, now]);
    const selectedServices = useSelector(selectSelectedServices);
    const selectedServiceLabels = useSelector(selectSelectedServiceLabels);
    const servicesOrLabelsToggle = useSelector(selectServicesOrLabelsToggle);

    const handleOnChangeRangePicker = useCallback(
        (newRange: TDatetimeRangeOrPreset) => {
            rtkDispatch(
                updateDiscoveryDashboardControls({
                    dateRangeOrPreset:
                        typeof newRange === 'string' ? newRange : (newRange.map((m) => m.unix()) as [number, number]),
                })
            );
        },
        [dateRange]
    );

    const handleOnChangeToggle = useCallback((serviceFilterBy: string) => {
        rtkDispatch(
            updateDiscoveryDashboardControls({
                serviceFilterBy: serviceFilterBy as DiscoveryDashboardFilterEnum,
            })
        );
    }, []);

    return (
        <div className="DiscoveryDashboardControls">
            {dateRange && datePresets && (
                <UiChronoRangePicker
                    label="Time Frame"
                    value={dateRange}
                    disabledDate={disabledDate}
                    presets={datePresets}
                    onChange={handleOnChangeRangePicker}
                />
            )}
            <UiToggle
                firstOption={{ label: 'Services', value: DiscoveryDashboardFilterEnum.SERVICES }}
                secondOption={{ label: 'Service Labels', value: DiscoveryDashboardFilterEnum.SERVICE_LABELS }}
                initialOptionValue={servicesOrLabelsToggle}
                onChange={handleOnChangeToggle}
            />
            {servicesOrLabelsToggle === DiscoveryDashboardFilterEnum.SERVICES ? (
                <ServiceMultiSelect
                    width={268}
                    value={selectedServices}
                    maxTagCount={2}
                    onChange={(selectedServices) => {
                        rtkDispatch(updateDiscoveryDashboardControls({ selectedServices }));
                    }}
                />
            ) : (
                <ServiceLabelMultiSelect
                    width={268}
                    value={selectedServiceLabels}
                    maxTagCount={2}
                    onChange={(selectedServiceLabels) => {
                        rtkDispatch(updateDiscoveryDashboardControls({ selectedServiceLabels }));
                    }}
                />
            )}
        </div>
    );
};

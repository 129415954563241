import { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import { IAlertFilterSet } from 'features/alerts/Alerts';
import { AlertFilterItem } from './AlertFilterItem/AlertFilterItem';
import { StrictModeDroppable } from './StrictModeDroppable/StrictModeDroppable';

import './AlertFilterList.scss';

interface IAlertFilterTagListProps {
    alertFilterTags: IAlertFilterSet[];
    onRemoveAlertFilter: Function;
    onSelectFilter: Function;
    onReorder: Function;
    selectedFilter: string;
    modified: boolean;
    busy: boolean;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any, isSelected: boolean) => ({
    ...draggableStyle,
    display: 'inline-block',
    height: '48px',
    minWidth: isDragging && !isSelected ? '6em' : 'auto',
    maxWidth: isDragging && !isSelected ? '11em' : 'auto',
    border: isDragging && !isSelected ? '1px dashed black' : 'none',
    margin: 0,
});

const reorder = (list: IAlertFilterSet[], startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const AlertFilterList = (props: IAlertFilterTagListProps) => {
    const { alertFilterTags, onRemoveAlertFilter, onSelectFilter, onReorder, selectedFilter, modified, busy } = props;
    const [items, setItems] = useState<IAlertFilterSet[]>(alertFilterTags);

    useEffect(() => {
        if (alertFilterTags) {
            setItems([...alertFilterTags]);
        }
    }, [alertFilterTags]);

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(items, result.source.index, result.destination.index);
        setItems(newItems);
        onReorder(result.destination.index, result.source.index);
    };

    return (
        <div className="AlertFilterList">
            <AlertFilterItem
                key="All"
                alertFilter={{ id: 'All', name: 'All Alerts', filter_model: '{}' } as IAlertFilterSet}
                selected={selectedFilter === 'All'}
                onRemoveAlertFilter={onRemoveAlertFilter}
                onSelectFilter={onSelectFilter}
                modified={false}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="afl-item-container">
                            {items.map((alertFilterSet) => (
                                <Draggable
                                    key={alertFilterSet.id}
                                    draggableId={alertFilterSet.name}
                                    index={alertFilterSet.ordered_index}
                                    isDragDisabled={busy}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                selectedFilter === alertFilterSet.id
                                            )}
                                        >
                                            <AlertFilterItem
                                                key={alertFilterSet.id}
                                                alertFilter={alertFilterSet}
                                                selected={selectedFilter === alertFilterSet.id}
                                                onRemoveAlertFilter={onRemoveAlertFilter}
                                                onSelectFilter={onSelectFilter}
                                                modified={selectedFilter === alertFilterSet.id && modified}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </div>
    );
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { DiscoveryEndpointsByApiTypeWidget } from './pieWidgets/DiscoveryEndpointsByApiTypeWidget/DiscoveryEndpointsByApiTypeWidget';
import { DiscoveryServiceRiskWidget } from './pieWidgets/DiscoveryServiceRiskWidget/DiscoveryServiceRiskWidget';
import { DiscoveryServiceStatsWidget } from './DiscoveryServiceStatsWidget/DiscoveryServiceStatsWidget';
import { DiscoveryServicesWithSensitiveDataWidget } from './sensitiveDataBarCharts/DiscoveryServicesWithSensitiveDataWidget/DiscoveryServicesWithSensitiveDataWidget';
import { DiscoveryServiceRiskChangeWidget } from './DiscoveryServiceRiskChangeWidget/DiscoveryServiceRiskChangeWidget';
import { DiscoveryCallsWithSensitiveDataWidget } from './sensitiveDataBarCharts/DiscoveryCallsWithSensitiveDataWidget/DiscoveryCallsWithSensitiveDataWidget';
import { DiscoveryNewEndpointsByServiceWidget } from './DiscoveryNewEndpointsByServiceWidget/DiscoveryNewEndpointsByServiceWidget';
import { selectDateRange, selectServicesOrLabels } from 'api/slices/discoveryDashboardControlsSlice';
import { useRedirectToServicePage } from 'features/dashboards/DiscoveryDashboard/useRedirectToServicePage';
import Spinner from 'sharedComponents/spinner/Spinner';
import { IPaginatedDiscoveryQuery } from 'api/discoveryApi';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

import './DiscoveryDashboard.scss';

export const modifyWidgetRequestLimit = (limit: number, commonRequestPayload: IPaginatedDiscoveryQuery) => ({
    query: {
        ...commonRequestPayload.query,
        limit,
    },
});

export const DiscoveryDashboard = () => {
    const servicesOrLabels = useSelector(selectServicesOrLabels);
    const dateRange = useSelector(selectDateRange);
    const handleRedirectServiceLink = useRedirectToServicePage(dateRange);
    const resizeDebounceRef = useRef<NodeJS.Timeout>();
    const elemRootRef = useRef<HTMLDivElement | null>(null);
    const [forceRender, setForceRender] = useState<number>(0);
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const linkToMoreServices = useMemo(
        () =>
            dateRange &&
            `/${currentTenantKey}/discovery/services/?from_timestamp=${dateRange![0].format(
                'X'
            )}&to_timestamp=${dateRange![1].format('X')}`,
        [dateRange, currentTenantKey]
    );

    const handleResize = () => {
        if (resizeDebounceRef?.current) {
            clearTimeout(resizeDebounceRef?.current);
        }
        resizeDebounceRef.current = setTimeout(() => {
            setForceRender(Math.floor(Math.random() * 1000000) + 1);
        }, 200);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);
        elemRootRef?.current && resizeObserver.observe(elemRootRef?.current);

        return () => {
            elemRootRef?.current && resizeObserver.unobserve(elemRootRef?.current);
            resizeObserver.disconnect();
        };
    }, []);

    if (!dateRange) {
        return <Spinner show paddingBottom={50} />;
    }

    const commonRequestPayload = dateRange && {
        query: {
            from_timestamp: dateRange[0].format('X'),
            to_timestamp: dateRange[1].format('X'),
            ...servicesOrLabels,
            limit: 200,
        },
    };

    const widgetProps = { commonRequestPayload, forceRender };
    const widgetPropsWithLink = { ...widgetProps, handleRedirectServiceLink };

    return (
        <div ref={elemRootRef} className="DiscoveryDashboard">
            <DiscoveryServiceStatsWidget {...{ commonRequestPayload }} />
            <DiscoveryServiceRiskChangeWidget {...widgetPropsWithLink} linkToMoreServices={linkToMoreServices} />
            <DiscoveryServiceRiskWidget {...widgetProps} />
            <DiscoveryEndpointsByApiTypeWidget {...widgetProps} />
            <DiscoveryNewEndpointsByServiceWidget {...widgetPropsWithLink} linkToMoreServices={linkToMoreServices} />
            <DiscoveryServicesWithSensitiveDataWidget {...widgetPropsWithLink} />
            <DiscoveryCallsWithSensitiveDataWidget {...widgetPropsWithLink} />
        </div>
    );
};

import { Dropdown, MenuProps } from 'antd';

import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

export const ExportButton = () => {
    const exportButtonOptions: MenuProps['items'] = [
        {
            key: '1',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">CSV</span>
                </div>
            ),
        },
        {
            key: '2',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">PDF</span>
                </div>
            ),
        },
        {
            key: '3',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">JSON</span>
                </div>
            ),
        },
    ];
    return (
        <UiTooltip title="Coming soon" disableCopyButton>
            <Dropdown
                className="alert-dropdown"
                overlayClassName="UiDropdownOverlay"
                trigger={['click']}
                placement="bottomRight"
                disabled
                menu={{ items: exportButtonOptions }}
            >
                <div className="dropdown-container">
                    <span>Export</span>
                    <UiIcon name="downArrowWhite" />
                </div>
            </Dropdown>
        </UiTooltip>
    );
};

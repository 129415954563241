import React from 'react';
import { GridApi, ICellRendererParams } from '@ag-grid-community/core';

import { removeTrailingSlashes } from 'general/utils';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import { IEndpointDeletionRequest } from 'api/exclusionRuleApi';

export const endpointTableMenu = (
    setModalData: Function,
    history: any,
    base64EncodedServiceName: string,
    deleteEndpoints: (endpointDeletionRequest: IEndpointDeletionRequest) => Promise<void>,
    gridApi?: GridApi
) => {
    const pathnameWithoutTrailingSlashes = removeTrailingSlashes(history.location.pathname);

    return (params: ICellRendererParams) => {
        const {
            data: { id, pending_deletion, endpoint_path, path_parameters },
            api,
        } = params;

        const editParameterNamesMenuItem = {
            icon: <UiIcon name={'rename'} />,
            label: `Edit Parameter Names`,
            onClick: () => {
                setModalData({
                    pathParameters: path_parameters,
                    serviceName: base64EncodedServiceName,
                    endpointPath: endpoint_path,
                });
            },
        };

        const goToEndpointMenuItem = {
            icon: <UiIcon name="goTo" />,
            label: `Go to Endpoint`,
            onClick: () => {
                history.push(
                    `${pathnameWithoutTrailingSlashes}/endpoints/${encodeURIComponent(params.data.id)}/${
                        history.location.search
                    }`
                );
            },
        };

        const deleteEndpointMenuItem = {
            icon: <UiIcon name="trash" />,
            label: 'Delete Endpoint',
            onClick: async () => {
                await deleteEndpoints({ endpoints_ids: [id] });
                gridApi?.refreshServerSide();
            },
        };

        let menuItems = [];
        if (params.data?.calls_count) {
            menuItems.push(goToEndpointMenuItem);
        }
        if (params.data.path_parameters?.length > 0) {
            menuItems.push(editParameterNamesMenuItem);
        }
        menuItems.push(deleteEndpointMenuItem);
        return { menuItems, hidden: pending_deletion };
    };
};

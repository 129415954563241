import { Divider } from 'antd';
import Markdown from 'react-markdown';

import { ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { LabelList } from 'sharedComponents/LabelList/LabelList';
import { IApplicationGroupedFinding, IScan, IScanGroupedFinding } from 'api/apiTestingApi';
import { FindingDetailsScan } from 'features/apiTesting/findings/FindingDetails/FindingDetailsFinding/FindingDetailsScan/FindingDetailsScan';
import { FindingDetailsApplicationScan } from 'features/apiTesting/findings/FindingDetails/FindingDetailsFinding/FindingDetailsApplicationScan/FindingDetailsApplicationScan';

import './FindingDetailsFinding.scss';

interface IFindingDetailsFindingProps {
    groupedFinding: IApplicationGroupedFinding | IScanGroupedFinding;
    scan?: IScan;
}

export const FindingDetailsFinding = ({ groupedFinding, scan }: IFindingDetailsFindingProps) => {
    return (
        <div className="FindingDetailsFinding">
            <div className="finding-details-container-title">Finding</div>
            <div className="finding-details-container-content finding-details-finding-content">
                <div className="finding-details-description">
                    <ValueWithLabel label="problem" value={<Markdown>{groupedFinding.problem}</Markdown>} />
                    <ValueWithLabel label="references" value={<Markdown>{groupedFinding.references}</Markdown>} />
                    <ValueWithLabel
                        label="solution recommendations"
                        value={<Markdown>{groupedFinding.solution_recommendations}</Markdown>}
                    />
                </div>
                <Divider />
                <ValueWithLabel
                    label="labels"
                    value={
                        <LabelList labels={groupedFinding.labels.map((label) => ({ label, is_automated: false }))} />
                    }
                />
                <Divider />
                {scan ? (
                    <FindingDetailsScan scan={scan} />
                ) : (
                    <FindingDetailsApplicationScan groupedFinding={groupedFinding as IApplicationGroupedFinding} />
                )}
            </div>
        </div>
    );
};

import React from 'react';
import 'features/user/summary-details/add-exception/AddExceptionModal.scss';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiSwitch } from 'sharedComponents/switch/Switch';

export interface IAddExceptionModal {
    isVisible: boolean;
    toggleModal: Function;
}

export const AddExceptionModal = (props: IAddExceptionModal) => {
    const title = 'Add Exception';
    const controls = [
        { text: 'user', value: 'Merchant 03485748' },
        { text: 'Alert Type', value: '200' },
        { text: 'Category', value: 'Impersonation' },
        { text: 'Source', value: 'Analytics' },
        { text: 'user', value: 'Value' },
    ];

    const apply = () => {
        console.log('apply');
    };

    return (
        <>
            <UiModal
                isVisible={props.isVisible}
                onCancel={() => props.toggleModal()}
                acceptButton={{ text: 'Apply', onClick: apply }}
                title={title}
            >
                <div className="add-exception-modal">
                    <UiTitle title="Policy name" type="large" />
                    <div className="controls-wrapper">
                        {controls.map((control, index) => (
                            <div className="control-item" key={index}>
                                <UiSwitch text={control.text} />
                                <span className="control-value">{control.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </UiModal>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Auth } from 'aws-amplify';

import { mockRules, mockRulesResponse } from './actions.mock';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { stringifyRuleAction, stringifyRulePredicate, TimeDisplayResEnum } from 'general/utils';
import { openDialog } from 'services/dialog/dialog-service';
import { PAGE_SIZE_LIMIT, recursiveHttpGet } from 'general/http-service';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { IEntity, useGetEntitiesQuery } from 'api/entitiesApi';
import { IAutomatedActionRule } from 'api/automatedActionRulesApi';
import {
    CellRenderDetokenizable,
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { CustomTooltip } from 'sharedComponents/UiBarChart/CustomTooltip';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';

import '../AutomatedActionRuleList/AutomatedActionRuleList.scss';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        cellRenderDetokenizable: CellRenderDetokenizable,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: any) {
            return !params.data?.isActiveAction || false;
        },
    },
};

export const AutomatedActionRuleListMock = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string };
    const activeOrg = params.activeOrg;
    const [tableData, setTableData] = useState<any[]>([]);
    const [user, setUser] = useState();
    const [endpointList, setEndpointList] = useState<IEndpointDisplay[]>([]);
    const { data: entities } = useGetEntitiesQuery({ includeHidden: true });
    const mockRulesData = mockRules[activeOrg] || mockRules['payments'];

    const columnsDefs = [
        {
            headerName: 'No.',
            rowDrag: true,
            field: 'order',
            type: 'basic',
            width: 80,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Order',
        },
        {
            headerName: 'State',
            field: 'isActiveAction',
            type: 'basic',
            width: 100,
            sortable: false,
            cellRenderer: 'cellRenderToggle',
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: any) => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, ruleId: string) => onRowToggleHandler(e, idx, ruleId),
                };
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action name',
        },
        {
            headerName: 'Match',
            field: 'match',
            type: 'basic',
            flex: 2,
            cellRenderer: 'cellRenderDetokenizable',
            sortable: false,
            headerTooltip: 'Action match conditions',
        },
        {
            headerName: 'Action',
            field: 'action',
            type: 'basic',
            flex: 2,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action to take on rule match',
        },
        {
            headerName: 'Hits',
            field: 'hits',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'The number of rule hits',
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'author',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action creator',
        },
        {
            headerName: 'Last Modified',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'LastModifiedBy',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            resizable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: any) => {
                const menuItems = [
                    {
                        label: `Edit Action`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/automated-actions-m/rule/${params.data?.id}`);
                        },
                    },
                    {
                        label: `Delete Action`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete Rule ${params.data.name}?`,
                                icon: 'trash2',
                                title: 'Delete Rule',
                            })
                                .then(() => deleteRuleHandler(params.data.id))
                                .catch(() => {});
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((authUser) => {
            setUser(authUser);
        });

        recursiveHttpGet(
            `organizations/${activeOrg}/discovery/endpoints?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${moment()
                .subtract(1, 'years')
                .format('X')}&to_timestamp=${moment().format('X')}`,
            0,
            PAGE_SIZE_LIMIT,
            [],
            (endpointList: IEndpointDisplay[]) => setEndpointList(endpointList),
            (error: any) => console.error(error)
        );
    }, []);

    useEffect(() => {
        if (user && endpointList?.length && entities) {
            getAndSetRulesData(entities.items);
        }
    }, [user, endpointList, entities]);

    function getAndSetRulesData(entities: IEntity[]) {
        (async () => {
            const res = (await mockRulesResponse) as any;
            const tableItems = (res[activeOrg] || res['payments'])?.map((action: IAutomatedActionRule) => {
                return {
                    isActiveAction: action.status === 'enabled',
                    id: action.id,
                    // @ts-ignore
                    order: action.rule_order + 1,
                    name: action.name || 'no name provided',
                    match: stringifyRulePredicate(
                        action.predicate,
                        entities?.filter((e) => !e.active).map((e) => e.name) || []
                    ),
                    // @ts-ignore
                    action: stringifyRuleAction(action.action),
                    hits: action.hit_count,
                    createdAt: action.created_at,
                    author: action.created_by,
                    lastModified: action.updated_at,
                    LastModifiedBy: action.updated_by,
                };
            });
            setTableData(tableItems);
        })();
    }

    function deleteRuleHandler(ruleId: string) {
        const index = mockRulesData?.findIndex((actionItem: IAutomatedActionRule) => actionItem.id === ruleId);
        mockRulesData?.splice(index, 1);
        getAndSetRulesData(entities!.items);
    }

    function onRowToggleHandler(e: boolean, rowNumber: number, ruleId: string) {
        const rule = mockRulesData?.find((actionItem: IAutomatedActionRule) => actionItem.id === ruleId);
        rule && (rule.status = rule?.status === 'enabled' ? 'disabled' : 'enabled');
        getAndSetRulesData(entities!.items);
    }

    function onRowDragEnd(e: any) {
        return;
    }

    return (
        <div className="suppression-rules-container">
            <SettingsSectionHeader
                title="Automated Actions"
                urlPath="settings/automated-actions-m/rule"
                buttonText="Add Action"
            />
            <div className="suppression-rules-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={tableData}
                    showRowCount={true}
                    columns={columnsDefs}
                    rowDragEndHandler={onRowDragEnd}
                    rowCountTitle="Automated Actions"
                />
            </div>
        </div>
    );
};

import { LabelMultiSelect } from './LabelMultiSelect/LabelMultiSelect';
import { EndpointMultiSelect } from './EndpointMultiSelect/EndpointMultiSelect';
import { ServiceMultiSelect } from 'sharedComponents/ServiceMultiSelect/ServiceMultiSelect';
import { EntityMultiSelectList } from './EntityMultiSelectList/EntityMultiSelectList';
import { EntityMultiSelectState } from './EntityMultiSelectList/EntityMultiSelect';
import { ContainsTextPredicateInput } from './ContainsTextPredicateInput/ContainsTextPredicateInput';
import { AlertNameMultiSelect } from './AlertNameMultiSelect/AlertNameMultiSelect';
import { AlertCategoryMultiSelect } from './AlertCategoryMultiSelect/AlertCategoryMultiSelect';
import { AlertSeverityMultiSelect } from './AlertSeverityMultiSelect/AlertSeverityMultiSelect';

export enum PredicateType {
    label = 'label',
    endpoint = 'endpoint',
    service = 'service',
    associatedEntity = 'associatedEntity',
    containsText = 'containsText',
    alertName = 'alertName',
    alertCategory = 'alertCategory',
    alertSeverity = 'alertSeverity',
}

export interface SuppressionRulePredicateProps {
    label?: string;
    predicateType: PredicateType;
    value: any;
    onChange: (event: SuppressionRulePredicateProps['value']) => any;
}

export const SuppressionRulePredicate = (props: SuppressionRulePredicateProps) => {
    let predicate;

    switch (props.predicateType) {
        case PredicateType.label:
            predicate = (
                <LabelMultiSelect
                    label="Label"
                    value={props.value}
                    onChange={(event) => props.onChange(event)}
                    width={500}
                />
            );
            break;
        case PredicateType.endpoint:
            predicate = (
                <EndpointMultiSelect value={props.value} onChange={(event) => props.onChange(event)} width={500} />
            );
            break;
        case PredicateType.service:
            predicate = (
                <ServiceMultiSelect
                    label="Service"
                    value={props.value}
                    onChange={(event) => props.onChange(event)}
                    width={500}
                />
            );
            break;
        case PredicateType.associatedEntity:
            predicate = (
                <EntityMultiSelectList
                    initialState={props.value.map((entity: EntityMultiSelectState['entity']) => ({ entity }))}
                    onChange={(event) => props.onChange(event)}
                />
            );
            break;
        case PredicateType.containsText:
            predicate = (
                <ContainsTextPredicateInput
                    label="Alert description"
                    value={props.value}
                    onChange={(event: { contains: boolean; text: string }) => props.onChange(event)}
                />
            );
            break;
        case PredicateType.alertName:
            predicate = (
                <AlertNameMultiSelect value={props.value} width={500} onChange={(event) => props.onChange(event)} />
            );
            break;
        case PredicateType.alertCategory:
            predicate = (
                <AlertCategoryMultiSelect value={props.value} width={500} onChange={(event) => props.onChange(event)} />
            );
            break;
        case PredicateType.alertSeverity:
            predicate = (
                <AlertSeverityMultiSelect value={props.value} width={500} onChange={(event) => props.onChange(event)} />
            );
            break;
        default:
            predicate = null;
    }

    return predicate;
};

import React, { ReactNode } from 'react';

import './gridCellGenerator.scss';

export const gridCellGenerator = (label: string, description: ReactNode, isDoubleCellHeight?: boolean) => (
    <div className={`gridCell ${isDoubleCellHeight && 'double-cell-height'}`}>
        <div className="label">{label}</div>
        <div className="cell-content" title={typeof description !== 'object' ? description?.toString() : ''}>
            {description || '-'}
        </div>
    </div>
);

import { useMemo } from 'react';
import { Select } from 'antd';

import { IEntity, useGetEntitiesQuery } from 'api/entitiesApi';

import './EntitySelect.scss';

export interface EntitySelectProps {
    value: IEntity['name'];
    onChange: (entity: IEntity['name']) => void;
    width?: number | string;
    minWidth?: number | string;
    activeOnly?: boolean;
}

export const EntitySelect = ({ value, minWidth, width, onChange, activeOnly }: EntitySelectProps) => {
    const { data: entities } = useGetEntitiesQuery();

    const filterEntities = useMemo(() => {
        return activeOnly ? (entity: IEntity) => entity.active : () => true;
    }, [activeOnly]);

    if (!entities) {
        return (
            <div className="EntitySelect">
                <Select style={{ width, minWidth }}></Select>
            </div>
        );
    }

    return (
        <div className="EntitySelect">
            <Select
                value={value}
                options={entities.items.filter(filterEntities).map((entity: IEntity) => ({
                    label: `${entity.name}${entity.active ? '' : ' (deleted)'}`,
                    value: entity.name,
                }))}
                style={{ width, minWidth }}
                onChange={onChange}
            />
        </div>
    );
};

import React, { useState } from 'react';
import { GridApi, RowNode } from '@ag-grid-community/core';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { CheckboxFilters } from './CheckboxFilters';
import { IEndpointDeletionRequest } from 'api/exclusionRuleApi';
import { getPluralSuffix } from 'general/utils';

import './TableActions.scss';

interface ITableActions {
    setFilters: Function;
    selectedRows?: RowNode[];
    gridApi?: GridApi;
    serviceData?: any;
    deleteEndpoints: (endpointDeletionRequest: IEndpointDeletionRequest) => Promise<void>;
}

export const TableActions = ({ setFilters, selectedRows, gridApi, serviceData, deleteEndpoints }: ITableActions) => {
    const selectedRowsCount = selectedRows?.length || 0;
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
    const handleClick = async () => {
        if (selectedRowsCount == 1) {
            await deleteSelectedEndpoints();
        } else {
            setShowDeleteConfirmationModal(true);
        }
    };

    const deleteSelectedEndpoints = async () => {
        setIsDeleteInProgress(true);
        const endpointIds = selectedRows?.map((rowNode) => rowNode.data.id.toString());
        if (endpointIds && endpointIds.length > 0) {
            const endpointDeletionRequest: IEndpointDeletionRequest = {
                endpoints_ids: endpointIds,
            };
            await deleteEndpoints(endpointDeletionRequest);
            setShowDeleteConfirmationModal(false);
            gridApi?.deselectAll();
            gridApi?.refreshServerSide({});
        }
        setIsDeleteInProgress(false);
    };

    return (
        <div className="IconTableActions">
            {selectedRowsCount > 0 ? (
                <>
                    <div className="delete-endpoints-container">
                        <span className="selected-endpoints-count">
                            {`${selectedRowsCount} ENDPOINT${getPluralSuffix('S', selectedRowsCount)} SELECTED`}
                        </span>
                        <span
                            onClick={() => !isDeleteInProgress && handleClick()}
                            aria-disabled={isDeleteInProgress}
                            className="delete-link"
                        >
                            <UiIcon name="deleteSelected" className="delete-icon" isNotDefaultStyle />
                            {`Delete Endpoint${getPluralSuffix('s', selectedRowsCount)}`}
                        </span>
                        <span className="vertical-divider"></span>
                    </div>
                    {showDeleteConfirmationModal && (
                        <UiModal
                            title={`Delete ${selectedRowsCount} Endpoints`}
                            icon={'trash'}
                            acceptButton={{
                                text: 'Delete',
                                onClick: deleteSelectedEndpoints,
                                disabled: isDeleteInProgress,
                            }}
                            rejectButton={{ text: 'Cancel', onClick: () => setShowDeleteConfirmationModal(false) }}
                            onCancel={() => setShowDeleteConfirmationModal(false)}
                        >
                            {`Are you sure you want to delete ${selectedRowsCount} endpoints? This action cannot be undone.`}
                        </UiModal>
                    )}
                </>
            ) : (
                <></>
            )}
            <CheckboxFilters setFilters={setFilters} serviceData={serviceData} />
        </div>
    );
};

import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';

import { ITableItem, UiSimpleTable } from 'sharedComponents/SingleCallDetails/UiSimpleTable';
import { ICall } from 'api/apiTestingApi';

import './FindingCallDetails.scss';

export interface IFindingCallDetailsProps {
    call: ICall;
}

const getCallTableRows = (call: ICall, tab: 'request' | 'response'): ITableItem[] => {
    const key = `${tab}_headers`;

    return (
        tab === 'request'
            ? [
                  { id: 'path-header-id', isHeader: true, name: 'path', value: '', expandable: '' },
                  { id: 'path', isHeader: false, name: 'path', value: call.path },
              ]
            : []
    )
        .concat(
            tab === 'request' && call.query_params && Object.entries(call.query_params).length
                ? [
                      { id: 'headers-header-id', isHeader: true, name: 'query params', value: '' },
                      ...Object.entries(call.query_params).map(([name, value]) => ({
                          id: name,
                          isHeader: false,
                          name,
                          value,
                      })),
                  ]
                : []
        )
        .concat(
            [
                { id: 'headers-header-id', isHeader: true, name: 'headers', value: '' },
                ...Object.entries(call[key as 'request_headers' | 'response_headers']).map(([name, value]) => ({
                    id: name,
                    isHeader: false,
                    name,
                    value,
                })),
            ].concat(
                tab === 'response'
                    ? [
                          {
                              id: 'status-code',
                              isHeader: false,
                              name: 'status',
                              value: call.response_status_code.toString(),
                          },
                      ]
                    : []
            )
        )
        .concat(
            call[`${tab}_body` as 'request_body' | 'response_body']
                ? [
                      { id: 'body-header-id', isHeader: true, name: 'body', value: '' },
                      {
                          id: 'body',
                          isHeader: false,
                          name: 'body',
                          value: call[`${tab}_body` as 'request_body' | 'response_body'],
                          expandable: 'pretty',
                      },
                  ]
                : []
        );
};

export const FindingCallDetails = ({ call }: IFindingCallDetailsProps) => {
    return (
        <div className="FindingCallDetails">
            <Tabs type="card">
                {['request', 'response'].map((tab: string) => {
                    return (
                        <TabPane tab={tab} key={tab}>
                            <UiSimpleTable
                                headers={[]}
                                rowsData={getCallTableRows(call, tab as 'request' | 'response')}
                                resizable
                            />
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

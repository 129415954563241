import { useParams, useHistory } from 'react-router-dom';

import { ISettingsPathParams } from '../../Settings';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiButton } from 'sharedComponents/button/Button';

import './SettingsSectionHeader.scss';

export interface SettingsSectionHeaderProps {
    title: string;
    urlPath?: string;
    buttonText?: string;
    disableButton?: boolean;
    buttonAction?: () => void;
}

export const SettingsSectionHeader = (props: SettingsSectionHeaderProps) => {
    const { title, urlPath, buttonText, disableButton = false, buttonAction } = props;

    const { activeOrg } = useParams<ISettingsPathParams>();
    const history = useHistory();

    return (
        <div className="SettingsSectionHeader">
            <UiTitle type="x-large" title={title} />
            <UiButton
                type="primary"
                text={buttonText as string}
                onClick={() => {
                    if (urlPath) {
                        history.push(`/${activeOrg}/${urlPath}`);
                    } else {
                        buttonAction?.();
                    }
                }}
                disabled={disableButton}
            />
        </div>
    );
};

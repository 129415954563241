import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { nameRule, formFieldRequired } from 'general/forms';
import { skipToken } from '@reduxjs/toolkit/query';

import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { UiButton } from 'sharedComponents/button/Button';
import { IApitRouteParams } from '../../ApitTestingRoutes';
import { API_TESTING_PATH } from '../../ApitTestingRoutes';
import { useCreateAuthConfigMutation, useGetAuthConfigQuery, useUpdateAuthConfigMutation } from 'api/apiTestingApi';
import { useGetAuthConfigTemplatesQuery } from 'api/apiTestingApi';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';
import { ChangeTemplateConfirmModal } from 'features/apiTesting/authConfigs/AuthConfigAddEditClone/ChangeTemplateConfirmModal';

import './AuthConfigAddEditClone.scss';

export interface IAuthConfigAddEditCloneProps {
    mode: 'add' | 'edit' | 'clone';
}

export const AuthConfigAddEditClone = ({ mode }: IAuthConfigAddEditCloneProps) => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [form] = Form.useForm();
    const { authConfigId } = useParams<IApitRouteParams>();
    const [editorValue, setEditorValue] = useState<string | undefined>('{}');
    const [isEditorValid, setIsEditorValid] = useState(true);
    const [isFormValid, setFormValid] = useState(true);
    const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number | undefined>();
    const [selectedTemplateValue, setSelectedTemplateValue] = useState<number | undefined | null>(null);
    const { data: templateData } = useGetAuthConfigTemplatesQuery();
    const { data: currentAuthConfig } = useGetAuthConfigQuery(authConfigId ? { authConfigId } : skipToken);
    const [triggerCreateAuthConfig] = useCreateAuthConfigMutation();
    const [triggerUpdateAuthConfig] = useUpdateAuthConfigMutation();
    const isCloneMode = mode === 'clone';
    const isEditMode = mode === 'edit';
    const isEditorValueEmpty = editorValue === '' || editorValue === '{}';

    const templateOptions = (templateData?.items || [])?.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    useEffect(() => {
        if ((isEditMode || isCloneMode) && currentAuthConfig && authConfigId) {
            const { name, content } = currentAuthConfig;
            setEditorValue(content);
            form.setFieldValue('name', `${isCloneMode ? 'copy of ' : ''}${name}`);
            setIsTemplateSelected(true);
        }
    }, [authConfigId, currentAuthConfig, form, mode]);

    const handleFormFieldsChange = useCallback(() => {
        const fieldErrors = form.getFieldsError(['name']);
        setFormValid(fieldErrors?.[0]?.errors.length < 1);
    }, [form]);

    const changeTemplate = (selectedId?: number) => {
        const currentId = selectedId || selectedTemplateId;
        const selectedTemplate = templateData?.items.filter(({ id }) => id === currentId);
        setEditorValue(selectedTemplate?.[0].content);
        setSelectedTemplateValue(currentId);
        setIsTemplateSelected(true);
        setSelectedTemplateId(undefined);
    };

    const onChangeTemplateSelect = (selectedId: number, option: any) => {
        if (!selectedTemplateValue) {
            setSelectedTemplateValue(selectedId);
        }
        setSelectedTemplateId(selectedId);
        if (!isTemplateSelected) {
            changeTemplate(selectedId);
        }
    };

    const onEditorContentChange = (value?: string, ev?: editor.IModelContentChangedEvent) => {
        isFormValid && setIsEditorValid(true);
        setEditorValue(value);
    };

    const handleEditorValidate = (markers: editor.IMarker[]) => {
        setIsEditorValid(markers.length < 1);
    };

    const handleCancelClick = () => {
        history.push(`/${currentTenantKey}/${API_TESTING_PATH}/auth-configs/`);
    };

    const handleCreateClick = useCallback(async () => {
        setFormValid(false);
        try {
            await form.validateFields();
        } catch (error) {
            setFormValid(true);
            return;
        }

        const formValues = {
            name: form.getFieldValue('name'),
            content: editorValue || '',
        };

        try {
            if (isEditMode) {
                await triggerUpdateAuthConfig({
                    id: Number(authConfigId),
                    ...formValues,
                }).unwrap();
            } else {
                await triggerCreateAuthConfig(formValues).unwrap();
            }
            form.resetFields();
            history.push(`/${currentTenantKey}/${API_TESTING_PATH}/auth-configs/`);
        } catch (error) {
            errorMessage(extractErrorMessage(error));
        } finally {
            setFormValid(true);
        }
    }, [form, editorValue]);

    return (
        <div className="AuthConfigAddEditClone">
            <header className="header">
                <span className="header-title">{isEditMode ? 'Edit' : 'New'} Auth Configuration</span>
                <div className="header-actions">
                    <UiButton
                        type="secondary"
                        text="Cancel"
                        onClick={handleCancelClick}
                        className="header-button"
                    ></UiButton>
                    <UiButton
                        type="primary"
                        text={isEditMode ? 'Save' : 'Create'}
                        onClick={handleCreateClick}
                        disabled={!isFormValid || !isEditorValid || isEditorValueEmpty}
                        className="header-button"
                    ></UiButton>
                </div>
            </header>
            <div className="form">
                <Form form={form} layout="vertical" className="form" onFieldsChange={handleFormFieldsChange}>
                    <Form.Item
                        label="name"
                        name="name"
                        rules={[{ required: true, message: formFieldRequired.errorMessage }, nameRule]}
                    >
                        <Input placeholder="Enter the name of your new Auth Configuration" />
                    </Form.Item>
                    {mode !== 'clone' && (
                        <Form.Item className="select-template" label="template" name="template">
                            <Select
                                placeholder="Select a template"
                                onChange={onChangeTemplateSelect}
                                options={templateOptions}
                                className="form-input"
                            />
                        </Form.Item>
                    )}
                </Form>
            </div>
            {!isTemplateSelected ? (
                <div className="auth-config-editor-empty">Select a template</div>
            ) : (
                <div className="auth-config-editor">
                    <Editor
                        height="60vh"
                        language="json"
                        value={editorValue}
                        onChange={onEditorContentChange}
                        onValidate={handleEditorValidate}
                        options={{
                            automaticLayout: true,
                            scrollBeyondLastLine: false,
                            minimap: {
                                enabled: false,
                            },
                        }}
                    ></Editor>
                </div>
            )}
            {isTemplateSelected && selectedTemplateId && (
                <ChangeTemplateConfirmModal
                    onClose={() => {
                        setSelectedTemplateId(undefined);
                    }}
                    onReject={() => {
                        form.setFieldValue('template', selectedTemplateValue);
                    }}
                    changeTemplate={changeTemplate}
                />
            )}
        </div>
    );
};

import { useSelector } from 'react-redux';
import { Dispatch, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Select, Upload, UploadFile } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { httpPOSTMultiPart } from 'general/http-service';
import { extractErrorMessage } from 'general/utils';
import { errorMessage } from 'general/toast-service';
import { formFieldRequired, oasUploadRule, oasUploadRuleInvalidValue } from 'general/forms';
import { UiSingleCustomOptionSelect } from 'sharedComponents/UiSingleCustomOptionSelect/UiSingleCustomOptionSelect';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { UiButton } from 'sharedComponents/button/Button';
import {
    useCreateScanMutation,
    useGetApplicationQuery,
    useGetAuthConfigsQuery,
    useGetSuitesQuery,
} from 'api/apiTestingApi';
import { API_TESTING_PATH, IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { ActionType, AppStateContext, IAppState } from 'contexts/AppStateContext';

import './AddScanFormModal.scss';

export interface IScanAddModalProps {
    onClose: () => void;
}

const uploadItemRender: (
    originNode: ReactElement,
    file: UploadFile,
    fileList: object[],
    actions: { download: Function; preview: Function; remove: Function }
) => ReactNode = (originNode, file, fileList, actions) => {
    return <Select className="upload-item-render" disabled value={file.name} loading={file.status === 'uploading'} />;
};

const UploadSuccessMessage = (endpointCount: number) => (
    <div>
        <span className="oas-upload-success-message-part-i">
            <UiIcon className="oas-upload-success-icon" name="checkCircleOutlined" isNotDefaultStyle />
            &nbsp;Your OAS has been successfully validated
        </span>
        <span> | </span>
        <span>{endpointCount} Endpoints will be tested</span>
    </div>
);

const goToCreateAuthConfig = (onClick: () => void) => (
    <div className="create-auth-config">
        <span className="italic-text">Select or</span>
        <UiButton type="text" onClick={onClick} text="create" />
        <span>an auth configuration for better scan result</span>
    </div>
);

export const AddScanFormModal = ({ onClose }: IScanAddModalProps) => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId } = useParams<IApitRouteParams>();
    const [form] = Form.useForm();
    const [isFormValid, setIsFormValid] = useState(true);
    const [urlOptions, setUrlOptions] = useState<{ label: string; value: string }[]>([]);
    const [uploadMessage, setUploadMessage] = useState<JSX.Element>();
    const [isAuthConfigSelected, setIsAuthConfigSelected] = useState<boolean>();
    const { state }: { state: IAppState; dispatch: Dispatch<ActionType> } = useContext(AppStateContext);
    const [createScan, { isLoading: isCreateScanInProgress }] = useCreateScanMutation();
    const { data: suitesResponse } = useGetSuitesQuery();
    const { data: authConfigs } = useGetAuthConfigsQuery();
    const { refetch: refetchApplicationSummary } = useGetApplicationQuery({ appId });

    const suitesOptions = useMemo(
        () =>
            suitesResponse?.items.map((suite) => ({
                label: `${suite.name} [${suite.tests?.length} tests]`,
                value: suite.id,
            })),
        [suitesResponse]
    );

    const authConfigOptions = useMemo(
        () =>
            (authConfigs?.items || []).reduce((acc: { label: string; value: string }[], { name }) => {
                acc.push({ label: name, value: name });
                return acc;
            }, []),
        [authConfigs]
    );
    const noAuthConfigs = authConfigOptions.length < 1;

    const onUpload = useCallback(async (customRequestObj: any) => {
        const formData = new FormData();
        formData.append('file', customRequestObj.file);
        form.resetFields(['target_url']);

        try {
            const uploadResponse = await httpPOSTMultiPart(`organizations/${currentTenantKey}/testing/oas`, formData);
            setUrlOptions(
                uploadResponse.data.parsed_target_urls?.map((url: string) => ({ label: url, value: url })) || []
            );
            setUploadMessage(UploadSuccessMessage(uploadResponse.data.endpoints_count));
            form.setFieldValue('oas_file_id', uploadResponse.data.oas_file_id);
            customRequestObj.onSuccess();
        } catch (error: any) {
            setUrlOptions([]);
            form.setFieldValue('oas_file_id', oasUploadRuleInvalidValue);
            customRequestObj.onError(extractErrorMessage(error));
            setUploadMessage(<></>);
        } finally {
            await form.validateFields(['oas_file_id']);
        }
    }, []);

    const handleFormFieldsChange = useCallback(() => {
        setIsFormValid(!form.getFieldsError().some((field) => field.errors.length));
    }, []);

    const resetForm = () => {
        form.resetFields();
        setUrlOptions([]);
        setUploadMessage(undefined);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const acceptButton = useMemo(
        () => ({
            text: 'Create',
            onClick: async () => {
                try {
                    await form.validateFields();
                } catch (error) {
                    return;
                }

                const { auth_config, ...fieldsValue } = form.getFieldsValue();
                const auth_config_id =
                    auth_config && authConfigs?.items.filter(({ name }) => name === auth_config)?.[0].id;

                try {
                    await createScan({
                        ...fieldsValue,
                        auth_config_id,
                        app_id: appId,
                    }).unwrap();
                    if (state.tableApi?.gridApi?.rowModel) {
                        state.tableApi?.gridApi.refreshServerSide({ purge: true });
                    } else {
                        refetchApplicationSummary();
                    }
                    handleClose();
                } catch (error) {
                    errorMessage(extractErrorMessage(error));
                }
            },
            disabled: !isFormValid || isCreateScanInProgress,
        }),
        [form, isFormValid, state.tableApi, isCreateScanInProgress, suitesResponse?.items, authConfigs?.items]
    );

    return (
        <UiModal
            title="New Scan"
            isFormModal
            width={700}
            acceptButton={acceptButton}
            rejectButton={{
                text: 'Cancel',
                onClick: handleClose,
            }}
            onCancel={handleClose}
        >
            <Form
                form={form}
                layout="vertical"
                colon={false}
                className="AddScanFormModal"
                requiredMark={false}
                onFieldsChange={handleFormFieldsChange}
            >
                <Form.Item
                    label="target oas"
                    name="oas_file_id"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }, oasUploadRule]}
                >
                    <div>
                        <Upload
                            className="oas-upload"
                            maxCount={1}
                            customRequest={onUpload}
                            itemRender={uploadItemRender}
                        >
                            <UiButton type="primary" text="Browse" />
                        </Upload>
                        <div className="oas-upload-message">{uploadMessage}</div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="target_url"
                    label="target url"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                >
                    <UiSingleCustomOptionSelect
                        options={urlOptions}
                        disabled={
                            !form.getFieldValue('oas_file_id') ||
                            form.getFieldValue('oas_file_id') === oasUploadRuleInvalidValue
                        }
                    />
                </Form.Item>
                <Form.Item className="select-auth-config" label="auth configuration" name="auth_config">
                    <Select
                        placeholder="Select auth configuration"
                        options={authConfigOptions}
                        disabled={noAuthConfigs}
                        onChange={(value: string) => value && setIsAuthConfigSelected(true)}
                        allowClear
                    />
                </Form.Item>
                {(noAuthConfigs || !isAuthConfigSelected) &&
                    goToCreateAuthConfig(() =>
                        history.push(`/${currentTenantKey}/${API_TESTING_PATH}/auth-configs/add`)
                    )}
                <Form.Item className="test-suite" label="test suite" name="suite_id">
                    <Select options={suitesOptions} placeholder="Select test suite" />
                </Form.Item>
            </Form>
        </UiModal>
    );
};

import { ColDef } from '@ag-grid-community/core';
import { Drawer, Tabs, TabsProps } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import {
    CellExpandButton,
    CellRenderDetokenizable,
    CellRenderHoverIcon,
    CellRenderVerticalCenter,
    CustomNoRowsOverlay,
    TableColumnHeader,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { errorMessage } from 'general/toast-service';
import { IEndpointPathParameter } from 'interfaces/endpoint.interface';
import PrettyRenderer from 'sharedComponents/PrettyRenderer/PrettyRenderer';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { EditEndpointParameterNameModal } from '../../EditEndpointParameterNameModal/EditEndpointParameterNameModal';

import './discovery.scss';
import './DiscoveryMasterDetail.scss';
import './EndpointStatTable.scss';

const BodyRenderer = (props: any) => <PrettyRenderer content={props.data.value} contentType={props.data.contentType} />;

const distColumnDefs = [
    {
        headerName: 'Index',
        field: 'index',
        type: 'basic',
        width: 120,
        comparator: (a: any, b: any) => parseFloat(a) - parseFloat(b),
    },
    {
        headerName: 'Value',
        field: 'value',
        type: 'basic',
        flex: 1,
        cellRenderer: 'cellRenderDetokenizable',
        resizable: false,
    },
];

const distTableExpandColumn = {
    headerName: '',
    width: 60,
    field: 'overlay',
    cellRenderer: 'expandButtonCellRenderer',
    suppressMenu: true,
    suppressMovable: true,
};

const getFrequency = (call_count: number, attributes: any) => {
    if (call_count === 0) {
        return 'N/A';
    }
    const frequency = (attributes.count / call_count) * 100;
    return (frequency < 5 ? frequency.toFixed(1) : Math.floor(frequency)) + '%';
};

interface IProps {
    call_count: number;
    attrData: any[];
    activeOrg: string;
    timeRangeFromInput: TDatetimeRange;
    base64EncodedServiceName: string;
    endpointPath: string;
    onParameterNamingSuccess: () => void;
}

enum RequestParts {
    Path = 'Path',
    Enrichment = 'Enrichment',
    Log = 'Log',
    Headers = 'Headers',
}

export interface IDiscoveryTableData {
    id: string;
    count: number;
    foundIn: string;
    frequency: string;
    name: string;
    reqResp: string;
    typicalValue: JSX.Element | string | number;
}

export const EndpointStatTable = ({
    attrData,
    call_count,
    activeOrg,
    timeRangeFromInput,
    base64EncodedServiceName,
    endpointPath,
    onParameterNamingSuccess,
}: IProps) => {
    const [distributionTableData, setDistributionTableData] = useState([]);
    const [modalData, setModalData] = useState<IEndpointPathParameter | null>(null);
    const [tabItems, setTabItems] = useState<TabsProps['items']>();
    const [isDetailExpandable, setIsDetailExpandable] = useState(false);
    const [contentType, setContentType] = useState();

    const distTableGridOptions = {
        columnTypes: {
            basic: BASIC_AGGRID_COL_TYPE,
        },
        components: {
            cellRenderVerticalCenter: CellRenderVerticalCenter,
            customNoRowsOverlay: CustomNoRowsOverlay,
            tableDetailCelRenderer: BodyRenderer,
            expandButtonCellRenderer: CellExpandButton,
            cellRenderDetokenizable: CellRenderDetokenizable,
        },
        masterDetail: true,
        detailRowAutoHeight: true,
    };

    const detailGridOptions = {
        detailRowAutoHeight: true,
    };

    useEffect(() => {
        if (!attrData?.length) {
            return setTabItems([]);
        }

        const tableData: { [key: string]: IDiscoveryTableData[] } = attrData.reduce(
            (acc, item, index) => {
                let key;
                if (item.part_of.toLowerCase() === 'attributes') {
                    key = item.in.toLowerCase();
                } else {
                    key = item.part_of.toLowerCase();
                }

                const mappedItem = {
                    id: `attr_${index.toString().padStart(2, '0')}`,
                    item_id: item.id,
                    foundIn: item.in,
                    name: item.name,
                    count: item.count,
                    frequency: getFrequency(call_count, item),
                    typicalValue: item.typical_value || 'N/A',
                    top_10_values: item.top_10_values,
                };

                if (acc[key]) {
                    acc[key].push(mappedItem);
                } else {
                    acc[key] = [mappedItem];
                }
                return acc;
            },
            { request: [], response: [], enrichment: [], log: [] }
        );
        setDistributionTableData([]);

        for (const attrGroup in tableData) {
            tableData[attrGroup].sort((a: IDiscoveryTableData, b: IDiscoveryTableData) =>
                a.foundIn.toLowerCase().localeCompare(b.foundIn.toLowerCase())
            );
        }

        const tabList = getTabsForDisplay(tableData);
        if (tabList?.length) {
            const data = (tabList[0].children as any).props.data;
            const cType = data.find((item: any) => item.name.toLowerCase() === 'content-type');
            setContentType(cType ? cType.typicalValue : '');
        }
        setTabItems(tabList);
    }, [attrData]);

    const CellRenderMore = (data: any) => {
        return (
            <span
                onClick={() => {
                    moreIconClicked({ id: Math.random().toString(), selectedRowData: data.data });
                }}
            >
                <div className="rotate-90">
                    <UiIcon name="more" />
                </div>
            </span>
        );
    };

    const moreIconClicked = useCallback(({ id, selectedRowData }: { id: string; selectedRowData: any }) => {
        try {
            const newTableData = selectedRowData.top_10_values.map((value: string, index: number) => ({
                value: value,
                rawValue: value,
                id: `value_statistics_${index}`,
                index: index + 1,
            }));
            setIsDetailExpandable(selectedRowData.name.toLowerCase() === 'payload');
            setDistributionTableData(newTableData);
        } catch (error) {
            errorMessage('Could not load distribution data', {
                duration: 4,
            });
            setDistributionTableData([]);
        }
    }, []);

    const columns = [
        {
            headerName: 'Found In',
            field: 'foundIn',
            type: 'basic',
            minWidth: 100,
            headerComponentParams: {
                headerTooltipContent: 'Part of the HTTP message where parameter resides',
            },
        },
        {
            headerName: 'Parameter',
            field: 'name',
            type: 'basic',
            headerComponentParams: {
                headerTooltipContent: 'Parameter',
            },
        },
        {
            headerName: 'Count',
            field: 'count',
            type: 'basic',
            comparator: (a: number, b: number) => a - b,
            headerComponentParams: {
                headerTooltipContent: 'Number of calls in which the parameter was observed during the time range',
            },
        },
        {
            headerName: 'Frequency',
            field: 'frequency',
            type: 'basic',
            comparator: (a: string, b: string) => parseFloat(a) - parseFloat(b),
            headerComponentParams: {
                headerTooltipContent: 'Percentage of calls in which the parameter was observed during the time range',
            },
        },
        {
            headerName: 'Typical Value',
            field: 'typicalValue',
            minWidth: 240,
            type: 'basic',
            flex: 4,
            comparator: (a: string, b: string) => (a.toString().toLowerCase() < b.toString().toLowerCase() ? -1 : 1),
            headerComponentParams: {
                headerTooltipContent: 'Highest frequency value',
            },
            cellRenderer: 'cellRenderDetokenizable',
        },
        {
            headerName: '',
            field: 'rename',
            maxWidth: 60,
            resizable: false,
            suppressMenu: true,
            cellRenderer: 'hoverIconButtonRender',
            cellRendererParams: {
                isShow: (item: any) => item.foundIn === RequestParts.Path,
                icon: 'rename',
                onClick: (params: any) => {
                    setModalData({
                        pathParameters: [
                            {
                                attribute_name: params.data.name,
                                attribute_id: params.data.item_id,
                            },
                        ],
                        serviceName: base64EncodedServiceName,
                        endpointPath: endpointPath,
                    });
                },
                tooltipMessage: 'Edit Parameter Name',
            },
        },
        {
            headerName: '',
            field: 'more',
            maxWidth: 60,
            resizable: false,
            suppressMenu: true,
            cellRenderer: 'cellRenderMore',
        },
    ];

    function getColumnsDefs(tabName: string): ColDef[] {
        const columnsToMap = tabName === 'Log' ? [columns[0], { ...columns[1], flex: 2 }] : columns;

        return columnsToMap.map((item: ColDef) => {
            return {
                type: 'agTextFilter',
                flex: 0.5,
                cellRenderer: 'cellRenderVerticalCenter',
                sortable: item.sortable !== false,
                resizable: true,
                headerComponentParams: {
                    headerTooltipContent: item.headerTooltip || '',
                },
                ...item,
            };
        });
    }

    const mainTableGridOptions = {
        columnTypes: {
            // col type that cols inherit from
            basic: BASIC_AGGRID_COL_TYPE,
            agSetFilter: agSetFilter,
            agTextFilter: agTextFilter,
        },
        components: {
            agColumnHeader: TableColumnHeader,
            cellRenderVerticalCenter: CellRenderVerticalCenter,
            cellRenderMore: CellRenderMore,
            customNoRowsOverlay: CustomNoRowsOverlay,
            hoverIconButtonRender: CellRenderHoverIcon,
            cellRenderDetokenizable: CellRenderDetokenizable,
        },
    };

    function getTabsForDisplay(tableData: { [key: string]: IDiscoveryTableData[] }): TabsProps['items'] {
        const entries: [string, any][] = Object.entries(tableData);

        return entries.map((tabData: [string, any[]], idx) => {
            const label = tabData[0].substring(0, 1).toUpperCase() + tabData[0].substring(1, tabData[0].length);
            const data = tabData[1];
            return {
                key: (idx + 1).toString(),
                label,
                children: (
                    <UiAgGridCSRM
                        columns={getColumnsDefs(label)}
                        options={mainTableGridOptions}
                        data={data}
                        showRowCount={false}
                    />
                ),
            };
        });
    }

    function onClose() {
        setDistributionTableData([]);
    }

    const tableDataWithContentType = useMemo(
        () => distributionTableData.map((item: any) => ({ ...item, contentType })),
        [contentType, distributionTableData]
    );

    return (
        <>
            <div className="discovery-table">
                <div className="box-title">Statistics</div>
                <div
                    className="tables-wrapper"
                    onWheel={(e) => {
                        e.deltaY < 0 && e.stopPropagation();
                    }}
                >
                    <Tabs
                        onTabClick={(tab) => {
                            const selected = tabItems?.find((item) => item.key === tab);
                            if (selected) {
                                const data = (selected.children as any).props.data;
                                const cType = data.find((item: any) => item.name.toLowerCase() === 'content-type');
                                setContentType(cType ? cType.typicalValue : '');
                            }
                        }}
                        type="card"
                        items={tabItems}
                    />
                </div>
            </div>
            <div
                className="top-values-wrapper"
                onWheel={(e) => {
                    e.deltaY < 0 && e.stopPropagation();
                }}
            >
                <Drawer
                    placement="right"
                    width="50%"
                    closable
                    mask
                    onClose={onClose}
                    className="cardinality-drawer"
                    getContainer=".tables-wrapper"
                    open={!!distributionTableData.length}
                    title="Top 10 Values"
                    keyboard
                    maskStyle={{ backgroundColor: 'transparent' }}
                    style={{ position: 'absolute' }}
                    zIndex={1000}
                >
                    <div className={'distribution-table'}>
                        <UiAgGridCSRM
                            options={distTableGridOptions}
                            columns={isDetailExpandable ? [...distColumnDefs, distTableExpandColumn] : distColumnDefs}
                            data={tableDataWithContentType}
                            customDetailComponent="tableDetailCelRenderer"
                            showRowCount={false}
                            blockExpand={!isDetailExpandable}
                            masterDetail={true}
                            getDetailData={() => {}}
                            detailGridOptions={detailGridOptions}
                        />
                    </div>
                </Drawer>
            </div>
            {modalData && (
                <EditEndpointParameterNameModal
                    activeOrg={activeOrg}
                    parameters={modalData}
                    onModalSuccess={onParameterNamingSuccess}
                    onModalClose={() => setModalData(null)}
                    timeRangeFromInput={timeRangeFromInput}
                />
            )}
        </>
    );
};

import React, { useMemo } from 'react';
import ReactJson from 'react-json-view';

interface IJsonRendererProps {
    content: string;
    onConversionFailure: () => void;
}

export const JSONRenderer = ({ content, onConversionFailure }: IJsonRendererProps) => {
    const contentToRender = useMemo(() => {
        try {
            return JSON.parse(content);
        } catch (e) {
            onConversionFailure();
            return '';
        }
    }, [content, onConversionFailure]);

    return (
        <ReactJson
            enableClipboard={false}
            onEdit={false}
            onAdd={false}
            displayObjectSize={false}
            quotesOnKeys={false}
            displayDataTypes={false}
            src={contentToRender}
        />
    );
};

import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

export const convertSnakeCaseToText = (str?: string) => {
    if (!str) return;
    try {
        const text = str.split('_').join(' ');
        return text.charAt(0).toUpperCase() + text.slice(1);
    } catch (e) {
        return str;
    }
};

export const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload?.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '0.1px solid var(--border)', padding: '10px' }}>
                <div className="label">{label}</div>
                {/*// @ts-ignore*/}
                {payload.map(({ dataKey, value, fill }) => (
                    <div style={{ color: fill }} key={dataKey}>
                        {convertSnakeCaseToText(dataKey?.toString())}: {value}
                    </div>
                ))}
            </div>
        );
    }

    return null;
};

import React from 'react';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { UiButton } from 'sharedComponents/button/Button';
import { gotoACC } from 'general/utils';
import { tenantStatusConfiguration } from 'general/tenantStatusConfigurations';
import { TenantStatusIndicator } from 'sharedComponents/tenantStatusIndicator/TenantStatusIndicator';
import { ITenant, selectTenantList } from 'api/tenantListApi';
import {
    currentTenantKeyChanged,
    loadAppStateFromStorage,
    saveAppStateToStorage,
    selectFeatureFlagMap,
} from 'api/slices/appInfoSlice';

import './TenantDrawer.scss';

interface ITenantDrawerProps {
    visible: boolean;
    currentTenant?: ITenant;
    ownerOrganizationTotalUsagePercent?: ITenant['owner_organization_total_usage_percent'];
    ownerOrganizationTotalUsageUpdatedAt?: ITenant['owner_organization_total_usage_updated_at'];
    onClose: (e: any) => void;
}

export const sortTenantsByName = (tA: ITenant, tB: ITenant) => {
    return tA.name.toLowerCase().localeCompare(tB.name.toLowerCase());
};

export const TenantDrawer = ({ currentTenant, onClose, visible }: ITenantDrawerProps) => {
    const dispatch = useDispatch();
    const featureFlagMap = useSelector(selectFeatureFlagMap);
    const tenantList = useSelector(selectTenantList);

    const navigationClicked = (newTenantKey: string) => () => {
        onClose(null);
        if (newTenantKey !== currentTenant?.key) {
            dispatch(saveAppStateToStorage());
            dispatch(loadAppStateFromStorage({ newTenantKey: newTenantKey }));
            dispatch(currentTenantKeyChanged({ tenantKey: newTenantKey, shouldNavigate: true, refetchApi: true }));
        }
    };

    const getTenantList = () =>
        tenantList &&
        Object.values(tenantList)
            .sort(sortTenantsByName)
            .map(
                ({
                    key,
                    name,
                    latest_transaction_status,
                    owner_organization_total_usage_percent,
                    owner_organization_total_usage_updated_at,
                }) => {
                    const { messages, iconType } = tenantStatusConfiguration(
                        latest_transaction_status,
                        owner_organization_total_usage_percent,
                        owner_organization_total_usage_updated_at
                    );
                    return (
                        <div
                            onClick={navigationClicked(key)}
                            key={key}
                            className={`tenant-container ${name === currentTenant?.name ? ' active ' : ''}`}
                        >
                            <div className="tenant-item">
                                <span>{name}</span>
                                {messages.length > 0 && <TenantStatusIndicator icon={iconType} messages={messages} />}
                            </div>
                        </div>
                    );
                }
            );

    return (
        <Drawer
            placement="left"
            width={300}
            closable={false}
            mask={true}
            onClose={onClose}
            className={'tenant-drawer'}
            getContainer={'.app-container'}
            open={visible}
            keyboard
            zIndex={2000}
            title={'Switch to'}
            footer={
                !!featureFlagMap?.accManagementUrl && (
                    <div>
                        <span>Akamai Control Center</span>
                        <UiButton type="primary" text="Go to configuration" onClick={() => gotoACC()} />
                    </div>
                )
            }
        >
            <div className="list">{getTenantList()}</div>
        </Drawer>
    );
};

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { formatDateTimeString } from 'general/utils';
import { useLazyGetSwaggerFilesQuery } from 'api/discoveryApi';

export interface IDownloadSwaggerProps {
    serviceNames: string[];
    tenant: string;
    fromTimestamp: number;
    toTimestamp: number;
}

export const useDownloadSwagger = ({ serviceNames, tenant, fromTimestamp, toTimestamp }: IDownloadSwaggerProps) => {
    const [triggerGetSwaggerFiles] = useLazyGetSwaggerFilesQuery();
    return async (): Promise<void> => {
        const res = await triggerGetSwaggerFiles({
            service_names: serviceNames,
            from_timestamp: fromTimestamp,
            to_timestamp: toTimestamp,
        }).unwrap();
        const dateTime = formatDateTimeString(new Date());
        const contentType = 'application/json;charset=utf-8';
        if (serviceNames.length > 1) {
            const zip = new JSZip();
            res.swaggers.forEach((swaggerJson: any) => {
                const blob = new Blob([JSON.stringify(swaggerJson.data, null, 2)], {
                    type: contentType,
                });
                zip.file(`${swaggerJson.service_name.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '')}.json`, blob);
            });
            const zipContent = await zip.generateAsync({ type: 'blob' });
            saveAs(zipContent, `${dateTime}_neosec_${tenant}_swagger.zip`);
        } else {
            const blob = new Blob([JSON.stringify(res.swaggers[0].data, null, 2)], {
                type: contentType,
            });
            saveAs(blob, `${dateTime}_neosec_${tenant}_swagger.json`);
        }
    };
};

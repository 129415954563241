import React from 'react';

export const AgGridIcons: { [key: string]: any } = {
    sortAscending: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14" height="14" fill="transparent"/>
                         <g clipPath="url(#clip0)">
                         <path d="M10.8181 8.09096L3.18187 8.09096C3.03414 8.09096 2.90626 8.1449 2.79835 8.25287C2.6905 8.36081 2.63635 8.48863 2.63635 8.63637C2.63635 8.7841 2.69047 8.91186 2.79835 9.01995L6.61649 12.8381C6.72437 12.9459 6.85216 13 6.99998 13C7.1478 13 7.2756 12.9459 7.38357 12.8381L11.2017 9.01995C11.3097 8.91198 11.3636 8.7841 11.3636 8.63637C11.3636 8.48866 11.3097 8.36081 11.2017 8.25287C11.0936 8.1449 10.9658 8.09096 10.8181 8.09096Z" fill="#DCDCDC"/>
                          <path d="M3.18187 5.909L10.8181 5.909C10.9659 5.909 11.0937 5.85503 11.2017 5.74717C11.3097 5.6392 11.3636 5.51141 11.3636 5.36368C11.3636 5.21594 11.3097 5.08806 11.2017 4.98015L7.38357 1.16205C7.27548 1.05408 7.14769 1.00002 6.99998 1.00002C6.85228 1.00002 6.72437 1.05408 6.61649 1.16205L2.79835 4.98018C2.6905 5.08806 2.63635 5.21594 2.63635 5.36371C2.63635 5.51141 2.69047 5.6392 2.79835 5.7472C2.90623 5.85514 3.03414 5.909 3.18187 5.909Z" fill="#648FFF"/>
                          </g>
                          <defs>
                          <clipPath id="clip0">
                          <rect width="12" height="12" fill="transparent" transform="translate(13 13) rotate(-180)"/>
                          </clipPath>
                          </defs>
                     </svg>

`,
    sortDescending: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14" height="14" fill="transparent"/>
                        <path d="M3.18188 5.90904H10.8181C10.9659 5.90904 11.0937 5.8551 11.2017 5.74713C11.3095 5.63919 11.3637 5.51137 11.3637 5.36363C11.3637 5.2159 11.3095 5.08814 11.2017 4.98005L7.38351 1.16191C7.27563 1.05409 7.14784 1 7.00002 1C6.8522 1 6.7244 1.05409 6.61643 1.16191L2.79829 4.98005C2.69032 5.08802 2.63638 5.2159 2.63638 5.36363C2.63638 5.51134 2.69032 5.63919 2.79829 5.74713C2.90638 5.8551 3.03418 5.90904 3.18188 5.90904Z" fill="#DCDCDC"/>
                        <path d="M10.8181 8.091H3.18188C3.03406 8.091 2.90627 8.14497 2.79829 8.25283C2.69032 8.3608 2.63638 8.48859 2.63638 8.63632C2.63638 8.78406 2.69032 8.91194 2.79829 9.01985L6.61643 12.8379C6.72452 12.9459 6.85232 13 7.00002 13C7.14772 13 7.27563 12.9459 7.38351 12.8379L11.2017 9.01982C11.3095 8.91194 11.3637 8.78405 11.3637 8.63629C11.3637 8.48859 11.3095 8.3608 11.2017 8.2528C11.0938 8.14485 10.9659 8.091 10.8181 8.091Z" fill="#648FFF"/>
                    </svg>

`,
    sortUnSort: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14" height="14" fill="transparent"/>
                        <path d="M3.18188 5.90904H10.8181C10.9659 5.90904 11.0937 5.8551 11.2017 5.74713C11.3095 5.63919 11.3637 5.51137 11.3637 5.36363C11.3637 5.2159 11.3095 5.08814 11.2017 4.98005L7.38351 1.16191C7.27563 1.05409 7.14784 1 7.00002 1C6.8522 1 6.7244 1.05409 6.61643 1.16191L2.79829 4.98005C2.69032 5.08802 2.63638 5.2159 2.63638 5.36363C2.63638 5.51134 2.69032 5.63919 2.79829 5.74713C2.90638 5.8551 3.03418 5.90904 3.18188 5.90904Z" fill="#DCDCDC"/>
                        <path d="M10.8181 8.091H3.18188C3.03406 8.091 2.90627 8.14497 2.79829 8.25283C2.69032 8.3608 2.63638 8.48859 2.63638 8.63632C2.63638 8.78406 2.69032 8.91194 2.79829 9.01985L6.61643 12.8379C6.72452 12.9459 6.85232 13 7.00002 13C7.14772 13 7.27563 12.9459 7.38351 12.8379L11.2017 9.01982C11.3095 8.91194 11.3637 8.78405 11.3637 8.63629C11.3637 8.48859 11.3095 8.3608 11.2017 8.2528C11.0938 8.14485 10.9659 8.091 10.8181 8.091Z" fill="#DCDCDC"/>
                </svg>
`,
    groupContracted: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="18" height="18" fill="transparent"/>
<path opacity="0.8" d="M14 8.96619C14 9.26493 13.9 9.46408 13.7 9.66324L6.7 16.6337C6.3 17.032 5.7 17.032 5.3 16.6337C4.9 16.2354 4.9 15.6379 5.3 15.2396L11.6 8.96619L5.3 2.6928C4.9 2.2945 4.9 1.697 5.3 1.2987C5.7 0.900398 6.3 0.900398 6.7 1.2987L13.7 8.26914C13.9 8.4683 14 8.66746 14 8.96619Z" fill="#436ACE"/>
</svg>
>

`,
    groupExpanded: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="18" height="18" fill="transparent"/>
<path opacity="0.8" d="M8.96625 14C8.66751 14 8.46836 13.9 8.2692 13.7L1.29873 6.7C0.900422 6.3 0.900422 5.7 1.29873 5.3C1.69705 4.9 2.29452 4.9 2.69283 5.3L8.96625 11.6L15.2396 5.3C15.6379 4.9 16.2354 4.9 16.6337 5.3C17.032 5.7 17.032 6.3 16.6337 6.7L9.6633 13.7C9.46414 13.9 9.26498 14 8.96625 14Z" fill="#436ACE"/>
</svg>
`,
    filter: `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4999 0H6.4999H0.4999C0.0999 0 -0.2001 0.5 0.0999001 0.9L2.8999 4.2L4.9999 6.5V12.1C4.9999 12.4 5.5999 12.5 5.7999 12.3L7.8999 10.4C7.9999 10.3 7.9999 10.3 7.9999 10.2V6.5L10.0999 4.1L12.8999 0.8C13.1999 0.5 12.8999 0 12.4999 0Z" fill="currentColor"/>
</svg>`,

    menu: `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4999 0H6.4999H0.4999C0.0999 0 -0.2001 0.5 0.0999001 0.9L2.8999 4.2L4.9999 6.5V12.1C4.9999 12.4 5.5999 12.5 5.7999 12.3L7.8999 10.4C7.9999 10.3 7.9999 10.3 7.9999 10.2V6.5L10.0999 4.1L12.8999 0.8C13.1999 0.5 12.8999 0 12.4999 0Z" fill="#436ACE"/>
</svg>`,
};

export const BASIC_AGGRID_COL_TYPE = {
    unSortIcon: true,
    sortable: true,
    resizable: true,
    suppressMenu: true,
    suppressMovable: true,
    tooltipComponent: 'customTooltip',
};

export const agSetFilter = {
    unSortIcon: true,
    sortable: true,
    resizable: true,
    menuTabs: ['filterMenuTab'],
    suppressMovable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
        buttons: ['clear', 'apply'],
        filterOptions: ['contains', 'notContains'],
        closeOnApply: true,
    },
};
export const agTextFilter = {
    ...agSetFilter,
    filter: 'agTextColumnFilter',
};

export function getContextMenuItems(params: any) {
    return ['copy', 'separator', 'autoSizeAll', 'resetColumns'];
}

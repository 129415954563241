import { skipToken } from '@reduxjs/toolkit/query';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { copyToClipboard } from 'general/utils';
import { IScan, useGetScanCliCommandQuery } from 'api/apiTestingApi';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';

import './RunLocallyModal.scss';

export interface IRunLocallyModalProps {
    scan?: IScan;
    onClose: () => void;
}

export const RunLocallyModal = ({ scan, onClose }: IRunLocallyModalProps) => {
    const { appId } = useParams<IApitRouteParams>();
    const { data: cliCommandData } = useGetScanCliCommandQuery(
        scan ? { appId, scanId: scan?.id.toString() } : skipToken
    );

    return (
        <UiModal
            className="RunLocallyModal"
            title={scan?.status === 'Created' ? 'Run Locally' : `Re-run Scan ${scan?.id}`}
            acceptButton={{
                text: 'OK',
                onClick: onClose,
            }}
            onCancel={onClose}
            width={640}
            isFormModal
        >
            <section>
                <div className="subtitle">Instructions</div>
                <div className="section-content instructions">
                    <ol>
                        <li>Make sure Docker is installed on your machine</li>
                        <li>Run the two commands below to execute the scan</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="subtitle">Commands</div>
                <div className="section-content command">
                    <>
                        <Button
                            ghost
                            icon={<CopyOutlined />}
                            onClick={() => copyToClipboard(cliCommandData?.cli_command || '')}
                            className="copy-button"
                        />
                        <span className="command-content">{cliCommandData?.cli_command}</span>
                    </>
                </div>
            </section>
        </UiModal>
    );
};

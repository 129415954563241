import { skipToken } from '@reduxjs/toolkit/query';
import { Divider } from 'antd';

import { useGetServiceAndEndpointCountQuery } from 'api/discoveryApi';
import Spinner from 'sharedComponents/spinner/Spinner';
import { DiscoveryDashboardWidgetProps } from '../discoveryDashboardTypes';
import { formatNumberWithSeparators } from 'general/utils';

import './DiscoveryServiceStatsWidget.scss';

const DiscoveryStat = (props: { title: string; newCount: string; totalCount: string; activeCount: string }) => {
    return (
        <div className="DiscoveryStat">
            <div className="dss-title">{props.title}</div>
            <div className="dss-count">
                <span className="dss-count-title">{props.newCount || '–'}</span>
                <span className="dss-new-table-title">NEW</span>
            </div>
            <Divider className="dss-divider" type="vertical" />
            <div className="dss-count">
                <span className="dss-count-title">{props.activeCount || '–'}</span>
                <span className="dss-new-table-title">ACTIVE</span>
            </div>
            <Divider className="dss-divider" type="vertical" />
            <div className="dss-count">
                <span className="dss-count-title">{props.totalCount || '–'}</span>
                <span className="dss-new-table-title">TOTAL</span>
            </div>
        </div>
    );
};

export const DiscoveryServiceStatsWidget = ({ commonRequestPayload }: DiscoveryDashboardWidgetProps) => {
    const { data: serviceStatsData, isLoading: isServiceStatsLoading } = useGetServiceAndEndpointCountQuery(
        commonRequestPayload || skipToken
    );

    if (isServiceStatsLoading) {
        return <Spinner show />;
    }

    return (
        <div className="DiscoveryServiceStatsWidget">
            <DiscoveryStat
                title="Services"
                newCount={
                    serviceStatsData?.new_services_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.new_services_count)
                        : '–'
                }
                activeCount={
                    serviceStatsData?.active_services_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.active_services_count)
                        : '–'
                }
                totalCount={
                    serviceStatsData?.total_services_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.total_services_count)
                        : '–'
                }
            />
            <DiscoveryStat
                title="Endpoints"
                newCount={
                    serviceStatsData?.new_endpoints_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.new_endpoints_count)
                        : '–'
                }
                activeCount={
                    serviceStatsData?.active_endpoints_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.active_endpoints_count)
                        : '–'
                }
                totalCount={
                    serviceStatsData?.total_endpoints_count
                        ? formatNumberWithSeparators.format(serviceStatsData?.total_endpoints_count)
                        : '–'
                }
            />
        </div>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

import { IWizardStep, UiWizard } from 'sharedComponents/UiWizard/UiWizard';
import {
    ExclusionRuleOperator,
    ExclusionRuleOption,
    IExclusionRuleForm,
    selectExclusionRuleById,
    useCreateExclusionRuleMutation,
    useGetExclusionRuleAttributesQuery,
    useLazyPreviewExclusionRuleQuery,
    useUpdateExclusionRuleMutation,
} from 'api/exclusionRuleApi';
import ExclusionRuleOrDeleteEpForm, { formFieldKeys } from './ExclusionRuleOrDeleteEpForm/ExclusionRuleOrDeleteEpForm';
import { PreviewTable } from 'sharedComponents/exclusionRuleOrDeleteEndpointsWizard/FormPreviewTable/PreviewTable';
import { errorMessage } from 'general/toast-service';
import {
    useCreateEndpointDeletionRequestMutation,
    useLazyPreviewEndpointDeletionRequestQuery,
} from 'api/endpointDeletionRequestApi';
import { extractErrorMessage } from 'general/utils';
import { useGetTotalCallsForDaysQuery } from 'api/discoveryApi';

import './ExclusionRuleOrDeleteEndpointsWizard.scss';

export enum WizardModeOptions {
    'ExclusionRule' = 'ExclusionRule',
    'DeleteEpService' = 'DeleteEpService',
    'DeleteEpServices' = 'DeleteEpServices',
}

interface IExclusionRuleOrDeleteEndpointsWizardProps {
    mode: WizardModeOptions;
    title: string;
    onCancel: () => void;
    onFinish: () => void;
}

const initialValues = {
    filter_option: ExclusionRuleOption.PATH,
    operator: ExclusionRuleOperator.MATCH,
    regex: '',
};

export const ExclusionRuleOrDeleteEndpointsWizard = ({
    mode,
    title,
    onCancel,
    onFinish,
}: IExclusionRuleOrDeleteEndpointsWizardProps) => {
    const { endpointFilterId } = useParams<{ endpointFilterId: string }>();
    const { encodedServiceName } = useParams<{ encodedServiceName: string }>();
    const serviceName = encodedServiceName ? decodeURIComponent(encodedServiceName) : undefined;
    const [form] = Form.useForm();
    const [selectedExclusionRule, setSelectedExclusionRule] = useState<IExclusionRuleForm>(initialValues);
    const [triggerExclusionRulePreview, { data: exclusionRulePreviewResponse }] = useLazyPreviewExclusionRuleQuery();
    const [triggerPreviewEndpointDeletion] = useLazyPreviewEndpointDeletionRequestQuery();
    const [triggerUpdateRule] = useUpdateExclusionRuleMutation();
    const [triggerAddRule] = useCreateExclusionRuleMutation();
    const [triggerEndpointDeletionRequest] = useCreateEndpointDeletionRequestMutation();
    const { data: exclusionRuleAttributes } = useGetExclusionRuleAttributesQuery();
    const exclusionRule = useSelector(selectExclusionRuleById(endpointFilterId));
    const isExclusionRule = mode === WizardModeOptions.ExclusionRule;
    const { data: totalCallsResponse } = useGetTotalCallsForDaysQuery({ days: 7 });

    useEffect(() => {
        if (endpointFilterId && exclusionRule) {
            setSelectedExclusionRule(exclusionRule);
        } else if (serviceName) {
            setSelectedExclusionRule({ ...selectedExclusionRule, service_pattern: serviceName });
        }
    }, [endpointFilterId]);

    const getPreview = useCallback(
        async (offset: number, sortBy?: string) => {
            let attributesValues;
            const { attribute, description, service_pattern, method, ...restOfFormValues } =
                form.getFieldsValue(formFieldKeys);

            if (attribute) {
                const attributeDetails = exclusionRuleAttributes?.find(({ name }) => name === attribute);
                attributesValues = attributeDetails && {
                    filter_option: 'attribute',
                    attribute_name: attributeDetails.name,
                    attribute_part_of: attributeDetails.part_of,
                    attribute_in: attributeDetails.in,
                };
            }

            const newFilterValues: IExclusionRuleForm = {
                ...restOfFormValues,
                method: form.getFieldValue('method') || [],
                ...(service_pattern ? { service_pattern } : {}),
                ...(isExclusionRule ? { description } : {}),
                ...attributesValues,
                active: selectedExclusionRule?.id ? selectedExclusionRule.active : true,
                offset,
                limit: 100,
                sort_by: sortBy || undefined,
            };

            setSelectedExclusionRule(newFilterValues);

            const triggerPreview = isExclusionRule ? triggerExclusionRulePreview : triggerPreviewEndpointDeletion;
            try {
                return await triggerPreview(newFilterValues).unwrap();
            } catch ({ status, data }) {
                if (data) {
                    switch (status) {
                        case 422:
                            errorMessage('Input validation failed');
                            break;
                        default:
                            errorMessage(
                                extractErrorMessage({ data }, 'Unexpected error while saving endpoint filter')
                            );
                    }
                }
            }
        },
        [exclusionRuleAttributes]
    );

    const handleSaveClick = async () => {
        const addOrEditErApi = endpointFilterId ? triggerUpdateRule : triggerAddRule;
        const exclusionRuleOrDeleteEpApi = isExclusionRule ? addOrEditErApi : triggerEndpointDeletionRequest;

        try {
            await exclusionRuleOrDeleteEpApi(
                endpointFilterId ? { id: endpointFilterId, ...selectedExclusionRule } : selectedExclusionRule
            ).unwrap();
            form.resetFields();
            onFinish();
        } catch (error) {
            errorMessage(extractErrorMessage({ error }, 'Unexpected error while saving endpoint filter'));
        }
    };

    const handleNextClick = async () => {
        try {
            await form.validateFields();
        } catch (error: any) {
            return false;
        }

        return true;
    };

    const exclusionRuleStepTitle = `${selectedExclusionRule?.id === undefined ? 'Create' : 'Edit'} Rule`;
    const wizardSteps: IWizardStep[] = [
        {
            title: `${isExclusionRule ? exclusionRuleStepTitle : 'Delete endpoints'}`,
            content: (
                <div className="endpoint-filter-form-container">
                    <ExclusionRuleOrDeleteEpForm form={form} editedRuleOrRequest={selectedExclusionRule} mode={mode} />
                </div>
            ),
        },
        {
            title: `Confirm`,
            content: (
                <PreviewTable
                    title="This traffic will be filtered out from Discovery."
                    getPreview={getPreview}
                    totalFilteredCalls={exclusionRulePreviewResponse?.totalFilteredCalls}
                    totalCalls={totalCallsResponse?.total_calls}
                />
            ),
        },
    ];

    return (
        <UiWizard
            wrapClassName="ExclusionRuleOrDeleteEndpointsWizard"
            title={
                <div className="er-wizard-title-container">
                    <span className="er-wizard-title">{title}</span>
                    <span className="er-wizard-subtitle">{serviceName ? ` ${serviceName}` : ''}</span>
                </div>
            }
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onNext={handleNextClick}
            onFinish={handleSaveClick}
            steps={wizardSteps}
            maskClosable={false}
        />
    );
};

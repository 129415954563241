import { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';

import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import {
    CellRenderLabelList,
    CellRenderRangeSensitiveMore,
    CellRenderSeverityBasic,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { IFilter } from 'interfaces/filter.interface';
import { IServerQueryResponse } from 'api/baseServerApi';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { API_TESTING_PATH, IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { findingTableCols } from 'features/apiTesting/findings/FindingList/findingTableCols';
import { IGroupedFindingListItem, useGetFindingsMetadataQuery } from 'api/apiTestingApi';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';

import './FindingList.scss';

export const FindingList = () => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId, scanId } = useParams<IApitRouteParams>();
    const [filters, setFilters] = useState<IFilter[][]>([]);
    const { data: findingsMetadata } = useGetFindingsMetadataQuery({ appId, scanId });
    const scanFindingsUrl = scanId ? `scans/${scanId}/` : '';

    const handleFilterChange = (params: any) => {
        const filterArray: IFilter[][] = Object.keys(params).reduce((acc: IFilter[][], columnName: any) => {
            const column = params[columnName];

            if (column.filterType === 'set') {
                return [
                    ...acc,
                    column.values?.map((value: any) => ({
                        name: columnName,
                        operator: 'eq',
                        value,
                    })),
                ];
            }

            if (column.filterType === 'number') {
                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: column.type === 'lessThan' ? 'lt' : column.type === 'greaterThan' ? 'gt' : 'eq',
                            value: column.filter,
                        },
                    ],
                ];
            }

            if (column.filterType === 'text') {
                if (column.operator === 'OR') {
                    return [
                        ...acc,
                        [
                            {
                                name: columnName,
                                operator: column.condition1.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition1.filter,
                            },
                            {
                                name: columnName,
                                operator: column.condition2.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition2.filter,
                            },
                        ],
                    ];
                }
                if (column.operator === 'AND') {
                    return [
                        ...acc,
                        [
                            {
                                name: columnName,
                                operator: column.condition1.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition1.filter,
                            },
                        ],
                        [
                            {
                                name: columnName,
                                operator: column.condition2.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition2.filter,
                            },
                        ],
                    ];
                }

                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: column.type === 'notContains' ? 'notcontains' : 'contains',
                            value: column.filter,
                        },
                    ],
                ];
            }

            if (column.filterType === 'customDateFilter') {
                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: 'lt',
                            value: column.value.filterDateRange[0],
                            value_type: 'timestamp',
                        },
                    ],
                    [
                        {
                            name: columnName,
                            operator: 'ge',
                            value: column.value.filterDateRange[1],
                            value_type: 'timestamp',
                        },
                    ],
                ];
            }

            return [
                ...acc,
                [
                    {
                        name: columnName,
                        operator: column.type,
                        value: column.filter,
                        value_type: column.filterType,
                    },
                ],
            ];
        }, []);
        setFilters(filterArray);
    };

    const goToFindings = (id: number) =>
        `/${currentTenantKey}/${API_TESTING_PATH}/applications/${appId}/${scanFindingsUrl}findings/${id}`;

    const filterTableMenu = useCallback(
        ({ data }: { data: IGroupedFindingListItem }) => {
            return {
                menuItems: [
                    {
                        icon: <UiIcon name="viewDetails" />,
                        label: 'View Details',
                        onClick: () => {
                            history.push(goToFindings(data?.id));
                        },
                    },
                ],
                id: data?.id,
            };
        },
        [currentTenantKey, appId]
    );

    const TableCol = useMemo(
        () => findingTableCols(filterTableMenu, !scanId, goToFindings, findingsMetadata),
        [filterTableMenu, findingsMetadata]
    );

    const getData = useCallback(
        async (
            start: number,
            end: number,
            sortParams?: string
        ): Promise<AxiosResponse<IServerQueryResponse<IGroupedFindingListItem>>> => {
            const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(id)';
            return httpPOST(
                `organizations/${currentTenantKey}/testing/applications/${appId}/${scanFindingsUrl}grouped_findings/query`,
                {
                    filters,
                    sort_by,
                    offset: start,
                    limit: 1000,
                }
            ).catch((error) => {
                return errorMessage(`${error.response?.data?.detail || 'Unexpected error while fetching data'}`);
            });
        },
        [currentTenantKey, appId, scanFindingsUrl, filters]
    );

    return (
        <div className={scanId ? 'FindingList' : 'FindingListNoTitle'}>
            <UiAgGridSSRM
                newCustomTitle={(numRows: number) => `Findings (${numRows})`}
                columns={TableCol}
                options={{
                    columnTypes: {
                        basic: BASIC_AGGRID_COL_TYPE,
                        agSetFilter: agSetFilter,
                        agTextFilter: agTextFilter,
                    },
                    components: {
                        moreButtonRender: CellRenderRangeSensitiveMore,
                        cellRenderVerticalCenter: CellRenderVerticalCenter,
                        labelListRender: CellRenderLabelList,
                        severityRender: CellRenderSeverityBasic,
                        cellRenderTimeStamp: CellRenderTimeStamp,
                    },
                }}
                getData={getData}
                onFilterChange={handleFilterChange}
                dataMappingFunction={(data: any) => data}
                ignoreUrlSortFieldDict={true}
            />
        </div>
    );
};

import React, { ReactNode, useMemo } from 'react';

import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { IScan, ITest } from 'api/apiTestingApi';

import './TestSuiteModal.scss';

interface ITestsSuiteModalProps {
    scan?: IScan;
    onClose: () => void;
    getCopyToClipboardIcon: (copyValue?: string) => ReactNode;
}

export const TestSuiteModal = ({ scan, onClose, getCopyToClipboardIcon }: ITestsSuiteModalProps) => {
    const { suite } = scan || {};

    const formatTestNamesToText = useMemo(() => {
        const testsNames: string[] = [];
        suite?.tests.forEach((test) => testsNames.push(test.name));
        return testsNames.join('\n');
    }, [scan]);

    return (
        <UiModal
            className="TestSuiteModal"
            isFormModal
            width={700}
            title={`${suite?.name} | ${suite?.test_count} tests`}
            acceptButton={{ text: 'Ok', onClick: onClose }}
            onCancel={onClose}
        >
            <div>
                <div className="copy-to-clipboard-icon">{getCopyToClipboardIcon(formatTestNamesToText)}</div>
                <div className="label"> INCLUDED</div>
                {suite?.tests ? (
                    suite?.tests.map((test: ITest) => <div key={`${test?.id} + ${test?.name}`}>{test.name}</div>)
                ) : (
                    <div>No tests Found</div>
                )}
            </div>
        </UiModal>
    );
};

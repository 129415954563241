import { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DashboardsEnum, selectDashboardsSlice, updateActiveDashboardId } from 'api/slices/dashboardsSlice';
import { UiTabsNav } from 'sharedComponents/UiTabsNav/UiTabsNav';
import { OverviewDashboard } from './OverviewDashboard/OverviewDashboard';
import { DiscoveryDashboard } from './DiscoveryDashboard/DiscoveryDashboard';
import { DiscoveryDashboardControls } from './DiscoveryDashboard/DiscoveryDashboardControls/DiscoveryDashboardControls';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

import './Dashboards.scss';

type TDashboardControlsDict = { [activeDashboardId in DashboardsEnum]?: ReactNode };

export const Dashboards = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { activeDashboardId } = useSelector(selectDashboardsSlice);
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    const dashboardControlsComponents: TDashboardControlsDict = {
        [DashboardsEnum.DISCOVERY]: <DiscoveryDashboardControls />,
    };
    const ControlsElement = dashboardControlsComponents[activeDashboardId];

    const handleTabSelected = useCallback(
        (key: string | number) => {
            const newKey = key.toString() as DashboardsEnum;
            dispatch(updateActiveDashboardId({ activeDashboardId: newKey }));
            history.push(`/${currentTenantKey}/dashboards/${newKey}`);
        },
        [currentTenantKey, activeDashboardId]
    );

    return (
        <div className="Dashboards">
            <div className="dashboards-header">
                <UiTabsNav
                    className="dashboards-tabs"
                    items={[
                        { key: 'overview', label: 'Overview' },
                        { key: 'discovery', label: 'Discovery' },
                    ]}
                    defaultActiveKey={activeDashboardId}
                    onTabSelected={handleTabSelected}
                />
                <div className="dashboards-controls">{ControlsElement}</div>
            </div>
            {activeDashboardId === DashboardsEnum.OVERVIEW && <OverviewDashboard />}
            {activeDashboardId === DashboardsEnum.DISCOVERY && <DiscoveryDashboard />}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { YAxis } from 'recharts';
import { Link } from 'react-router-dom';

import { useGetServiceRiskChangeQuery } from 'api/discoveryApi';
import { UiBarChart } from 'sharedComponents/UiBarChart/UiBarChart';
import Spinner from 'sharedComponents/spinner/Spinner';
import { CustomTooltip } from 'sharedComponents/UiBarChart/CustomTooltip';
import { DiscoveryDashboardWidgetProps } from '../discoveryDashboardTypes';
import serviceRiskChangeEmptyState from './ServiceRiskChangeEmptyState.svg';
import { DiscoveryDashboardEmptyState } from '../DiscoveryDashboardEmptyState/DiscoveryDashboardEmptyState';
import { modifyWidgetRequestLimit } from '../DiscoveryDashboard';
import { formatNumberHumanReadable } from 'general/utils';

import './DiscoveryServiceRiskChangeWidget.scss';

const LIMIT_NUM_SERVICES = 7;

const range = (start: number, end: number) => {
    return Array(Math.abs(end - start) + 1)
        .fill(0)
        .map((_, idx) => start + idx);
};

export const DiscoveryServiceRiskChangeWidget = ({
    forceRender,
    commonRequestPayload,
    handleRedirectServiceLink,
    linkToMoreServices,
}: DiscoveryDashboardWidgetProps) => {
    const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
    const { data, isLoading } = useGetServiceRiskChangeQuery(
        modifyWidgetRequestLimit(LIMIT_NUM_SERVICES, commonRequestPayload) || skipToken
    );

    const style = getComputedStyle(document.documentElement);
    const chartPositiveColor = style.getPropertyValue('--chart-red');
    const chartNegativeColor = style.getPropertyValue('--chart-green');

    useEffect(() => {
        data && setYAxisTicks(range(-1 * data.highestRiskChange, data.highestRiskChange));
    }, [data]);

    if (isLoading) {
        return <Spinner show paddingBottom={50} />;
    }

    if (
        !data ||
        data?.total === 0 ||
        (data.negativeServiceData.length === 0 && data.positiveServiceData.length === 0)
    ) {
        return <DiscoveryDashboardEmptyState title="Service Risk Change" widgetSvg={serviceRiskChangeEmptyState} />;
    }

    const moreServices = data.total - data.positiveServiceData.length - data.negativeServiceData.length;

    return (
        <div className="DiscoveryServiceRiskChangeWidget">
            <div className="dd-title-container">
                <div>
                    <div className="widget-title">Service Risk Change</div>
                    <div className="ddsrc-subtitle">Over time</div>
                </div>
                {moreServices > 0 && (
                    <Link
                        className="link-to-services"
                        to={`${linkToMoreServices}&sort_by=desc(risk_change)&use_slice=true`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        +{moreServices} more
                    </Link>
                )}
            </div>
            <div className="ddsrc-chart-container" key={forceRender + 'DiscoveryServiceRiskChangeWidget'}>
                <UiBarChart
                    graphKeys={['risk_change']}
                    graphData={data.positiveServiceData.length !== 0 ? data.positiveServiceData : [{}]}
                    customYAxis={<YAxis ticks={yAxisTicks} hide tickFormatter={formatNumberHumanReadable} />}
                    legend={() => <></>}
                    colorsMap={{ risk_change: chartPositiveColor }}
                    margin={{}} // do not remove
                    onClick={handleRedirectServiceLink}
                    customTooltip={CustomTooltip}
                    isStacked={false}
                />
                <UiBarChart
                    graphKeys={['risk_change']}
                    graphData={data.negativeServiceData.length !== 0 ? data.negativeServiceData : [{}]}
                    customYAxis={
                        <YAxis
                            ticks={yAxisTicks}
                            axisLine={false}
                            tickLine={true}
                            tickFormatter={formatNumberHumanReadable}
                        />
                    }
                    legend={() => <></>}
                    colorsMap={{ risk_change: chartNegativeColor }}
                    margin={{
                        left: -30,
                    }}
                    customTooltip={CustomTooltip}
                    isStacked={false}
                    onClick={handleRedirectServiceLink}
                />
            </div>
        </div>
    );
};

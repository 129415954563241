import { ITimeRange } from './timeRange.interface';
import { IEndpointDisplay } from './endpoint.interface';
import { TimestampQuery } from '../features/discovery/shared/discoveryInterfaces';
import { IBaseLabel } from './labels.interface';
import { IApiCall } from './apiCall.interface';

export interface ICallsQuery {
    entity_filter?: { name: string; value: string };
    offset?: number;
    time_range: ITimeRange;
    limit?: number;
    sort_by?: string;
    call_filter: ICallFilter;
}

export interface ICallAttributesQuery {
    calls_ids: IApiCall['id'][];
}

export interface IQueryPredicateData {
    id: string | undefined;
    operator: 'And' | 'Or';
    conditions?: IQueryCondition[];
}

export interface IQueryCondition {
    partOf?: string;
    in?: string;
    id?: string | number;
    operator?: string;
    value?: any;
}

export interface IQueryRow {
    endpointPath?: string;
    endpointMethod?: string;
    endpointServiceName?: string;
    defaultValue?: string;
    part_of?: string;
    in?: string;
    conditions: IQueryCondition[];
}

export interface IAttributeFilter {
    name: string;
    value?: string;
    operator: string;
    in:
        | 'Parameters'
        | 'Headers'
        | 'Cookies'
        | 'Query'
        | 'String'
        | 'Body'
        | 'Start Line'
        | 'Log'
        | 'Enrichment'
        | string;
    part_of?: string;
    value_type: string;
}

export interface ICallFilter {
    attribute_filters: IAttributeFilter[];
}

export interface IQueryRowProps {
    onAddCondition: Function;
    endpointsData: IEndpointDisplay[];
    onSelectedAutoComplete: any;
    onConditionsChanged: any;
    defaultValue?: string;
    queryRowObj?: IQueryRow;
    onConditionRemove: Function;
    validation?: IQueryRowValidation;
    endpoints?: any;
    timeRange: TimestampQuery;
    title: string;
    attrSortOrderObj?: { [key: string]: { [key: string]: number } };
}

export interface ICallTableData extends Omit<IApiCall, 'labels'> {
    endpoint: { method: string; name: string }[];
    content: {
        requestType: IApiCall['request_content_type'];
        requestSize: IApiCall['request_size'];
        responseType: IApiCall['response_content_type'];
        responseSize: IApiCall['response_size'];
    };
    callDetails: IApiCall;
    more: IApiCall;
    currOrg: { key: string };
    labels: IBaseLabel[];
}

export interface IQueryRowValidation {
    endpoint: string | null;
    conditions: (string | null)[];
}

export interface IQueryValidation {
    rows: IQueryRowValidation[];
}

export const AUTO_COMPLETE_WIDTH = 366;

export interface ISelectOptionGrp {
    title: string;
    options: (IEndpointSelectOption | IAttributeSelectOption)[];
}

export interface IEndpointSelectOption {
    label: JSX.Element;
    key: string;
    value: string;
    endpointPath: string;
}

export interface IAttributeSelectOption {
    key: string;
    value: number;
    label: string;
}

export interface IStat {
    count: number;
    frequency: number;
    value: string;
}

export interface IAttribute {
    in: string;
    name: string;
    part_of: string;
    statistics: {
        count: number;
        frequency: number;
        low_cardinality: boolean;
        typical_value: String;
        value_statistics: IStat[];
    };
}

export const attrSortOrderObj = {
    part_of: {
        Request: 1,
        Response: 2,
        Attributes: 3,
        Attribute: 3,
    },
    in: {
        Path: 1,
        Query: 2,
        Headers: 3,
        Start_Line: 4,
        Cookies: 5,
        Log: 6,
        Enrichment: 7,
        Body: 8,
    },
};

import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';

import { IAttributeFilter } from 'interfaces/query.interface';
import { IServerQueryResponse, tenantApi } from './baseServerApi';
import { fetchAll } from './apiUtils';
import { IDiscoveryService, IService } from 'interfaces/service.interface';
import { IServiceLabel, LabelSuppressPeriod } from 'interfaces/labels.interface';
import {
    labelColors,
    transformResponseEndpointApiTypeCount,
    transformResponseRiskChange,
    transformResponseSensitive,
} from 'api/discoveryUtils';
import { RootState } from 'general/store';
import { urlEncode } from 'general/utils';
import {
    selectSelectedServiceLabels,
    updateDiscoveryDashboardControls,
} from 'api/slices/discoveryDashboardControlsSlice';
import { IFilter } from 'interfaces/filter.interface';

export interface IPaginatedDiscoveryQuery {
    query: {
        from_timestamp: string;
        to_timestamp: string;
        service_names?: IService['name'][];
        service_labels?: string[];
        limit?: number;
    };
}

export interface IServiceAndEndpointCountResponse {
    total_services_count: number;
    new_services_count: number;
    total_endpoints_count: number;
    new_endpoints_count: number;
    active_endpoints_count: number;
    active_services_count: number;
}

export interface IServiceRisk {
    service_name: IService['name'];
    risk_score: number;
}

export interface IServiceRiskChange {
    service_name: IService['name'];
    risk_change: number;
}

export type TServiceRiskResponse = Pick<IServerQueryResponse<IServiceRisk>, 'items' | 'total'> & { other: number };

export interface IServiceRiskChangeResponse {
    positive: IServiceRiskChange[];
    negative: IServiceRiskChange[];
    total: number;
}
export interface IServiceRiskChangeTransformedResponse {
    negativeServiceData: { name: string; risk_change: number }[];
    positiveServiceData: { name: string; risk_change: number }[];
    highestRiskChange: number;
    total: number;
}

export enum EndpointActivityStatus {
    ACTIVE = 'ACTIVE',
    NEW = 'NEW',
    TOTAL = 'TOTAL',
}

export interface IEndpointApiTypeCount {
    label: string;
    count: number;
}

export type IEndpointApiTypeCountResponse = { [key in EndpointActivityStatus]?: IEndpointApiTypeCount[] };

export interface IEndpointApiTypeCountPieFormatItem {
    name: string;
    value: number;
}

export type IEndpointApiTypeCountPieFormat = { [key in EndpointActivityStatus]?: IEndpointApiTypeCountPieFormatItem[] };

export interface IServiceEndpointCount {
    service_name: IService['name'];
    total_endpoint_count: number;
    new_endpoint_count: number;
    active_endpoint_count: number;
}

export type TServiceEndpointCountResponse = Pick<IServerQueryResponse<IServiceEndpointCount>, 'items' | 'total'>;

export interface IServiceSensitiveResponse {
    labels: string[];
    stats: {
        [serviceName: string]: { label: string; count: number }[];
    };
}

export interface IServiceSensitiveFormattedBarChart {
    labels: string[];
    stats: Record<string, any>[];
}

export interface IDownloadSwaggerQuery {
    service_names: string[];
    from_timestamp: number;
    to_timestamp: number;
}

export interface IQueryParams {
    from_timestamp: string;
    to_timestamp: string;
    limit: number;
    offset: number;
    sort_by?: string;
    filters: IFilter[][];
}

export const discoveryApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getServices: builder.query<IServerQueryResponse<IService>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IService>(
                    `discovery/services?from_timestamp=${moment()
                        .clone()
                        .subtract(1, 'year')
                        .unix()}&to_timestamp=${moment().unix()}`,
                    1000,
                    baseQuery
                ),
            providesTags: ['serviceLabels'],
        }),
        getServicesPaginated: builder.query<IServerQueryResponse<IDiscoveryService>, IQueryParams>({
            query: (body) => ({ url: `discovery/services/query`, method: 'POST', body }),
        }),
        getServiceNames: builder.query<IService['name'][], void>({
            query: () => `discovery/services/names`,
        }),
        getServiceLabels: builder.query<IServiceLabel['label'][], void>({
            query: () => `discovery/services/label_names`,
            providesTags: ['serviceLabels'],
            onCacheEntryAdded: async (arg, { getState, cacheDataLoaded, dispatch }) => {
                const serviceLabels = (await cacheDataLoaded).data;
                const selectedServiceLabels = selectSelectedServiceLabels(getState() as RootState).filter(
                    (selectedLabel) => serviceLabels.includes(selectedLabel)
                );
                dispatch(updateDiscoveryDashboardControls({ selectedServiceLabels }));
            },
        }),
        createServiceLabel: builder.mutation<void, { serviceName: IService['name']; label: IServiceLabel['label'] }>({
            query: ({ serviceName, label }) => ({
                url: `discovery/services/${urlEncode(serviceName)}/labels`,
                method: 'POST',
                body: { label },
            }),
            invalidatesTags: ['serviceLabels'],
        }),
        suppressServiceLabel: builder.mutation<
            void,
            { serviceName: IService['name']; label: IServiceLabel['label']; suppress_by: LabelSuppressPeriod }
        >({
            query: ({ serviceName, label, suppress_by }) => ({
                url: `discovery/services/${urlEncode(serviceName)}/labels/${urlEncode(label)}`,
                method: 'POST',
                body: { suppress_by },
            }),
            invalidatesTags: ['serviceLabels'],
        }),
        deleteServiceLabel: builder.mutation<void, { serviceName: IService['name']; label: IServiceLabel['label'] }>({
            query: ({ serviceName, label }) => ({
                url: `discovery/services/${urlEncode(serviceName)}/labels/${urlEncode(label)}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['serviceLabels'],
        }),
        getAttributes: builder.query<IServerQueryResponse<IAttributeFilter>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IAttributeFilter>(
                    `discovery/attributes?from_timestamp=${moment()
                        .clone()
                        .subtract(1, 'year')
                        .unix()}&to_timestamp=${moment().unix()}`,
                    1000,
                    baseQuery,
                    5000
                ),
        }),
        getServiceAndEndpointCount: builder.query<IServiceAndEndpointCountResponse, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/services_endpoints_count`,
                method: 'POST',
                body: query,
            }),
        }),
        getServiceRisk: builder.query<TServiceRiskResponse, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/risk`,
                method: 'POST',
                body: query,
            }),
        }),
        getServiceRiskChange: builder.query<IServiceRiskChangeTransformedResponse, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/risk_change`,
                method: 'POST',
                body: query,
            }),
            transformResponse: transformResponseRiskChange,
        }),
        getEndpointApiTypeCount: builder.query<IEndpointApiTypeCountPieFormat, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/endpoints/stats/api_type_count`,
                method: 'POST',
                body: query,
            }),
            transformResponse: transformResponseEndpointApiTypeCount,
        }),
        getServiceEndpointCount: builder.query<TServiceEndpointCountResponse, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/endpoints_count`,
                method: 'POST',
                body: query,
            }),
            transformResponse: (response: TServiceEndpointCountResponse) => {
                return {
                    ...response,
                    items: response.items.map((item) => ({ ...item, name: item.service_name })),
                };
            },
        }),
        getServiceSensitiveEndpoints: builder.query<IServiceSensitiveFormattedBarChart, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/sensitive_endpoints`,
                method: 'POST',
                body: query,
            }),
            transformResponse: transformResponseSensitive,
        }),
        getServiceSensitiveCalls: builder.query<IServiceSensitiveFormattedBarChart, IPaginatedDiscoveryQuery>({
            query: ({ query }) => ({
                url: `discovery/services/stats/sensitive_calls`,
                method: 'POST',
                body: query,
            }),
            transformResponse: transformResponseSensitive,
        }),
        getSwaggerFiles: builder.query<any, IDownloadSwaggerQuery>({
            query: (body) => ({
                url: `discovery/services/export`,
                method: 'POST',
                body,
            }),
        }),
        getTotalCallsForDays: builder.query<{ total_calls: number }, { days: number }>({
            query: ({ days }: { days: number }) => `discovery/calls/metadata?days=${days}`,
        }),
    }),
    overrideExisting: false,
});

export const useGetServicesQuery = discoveryApi.endpoints.getServices.useQuery;
export const useGetServicesPaginatedQuery = discoveryApi.endpoints.getServicesPaginated.useQuery;
export const useGetServiceNamesQuery = discoveryApi.endpoints.getServiceNames.useQuery;
export const useGetServiceLabelsQuery = discoveryApi.endpoints.getServiceLabels.useQuery;
export const useCreateServiceLabelMutation = discoveryApi.endpoints.createServiceLabel.useMutation;
export const useSuppressServiceLabelMutation = discoveryApi.endpoints.suppressServiceLabel.useMutation;
export const useDeleteServiceLabelMutation = discoveryApi.endpoints.deleteServiceLabel.useMutation;
export const useGetAttributesQuery = discoveryApi.endpoints.getAttributes.useQuery;
export const useGetServiceAndEndpointCountQuery = discoveryApi.endpoints.getServiceAndEndpointCount.useQuery;
export const useGetServiceRiskQuery = discoveryApi.endpoints.getServiceRisk.useQuery;
export const useGetServiceRiskChangeQuery = discoveryApi.endpoints.getServiceRiskChange.useQuery;
export const useGetEndpointApiTypeCountQuery = discoveryApi.endpoints.getEndpointApiTypeCount.useQuery;
export const useGetServiceEndpointCountQuery = discoveryApi.endpoints.getServiceEndpointCount.useQuery;
export const useGetServiceSensitiveEndpointsQuery = discoveryApi.endpoints.getServiceSensitiveEndpoints.useQuery;
export const useGetServiceSensitiveCallsQuery = discoveryApi.endpoints.getServiceSensitiveCalls.useQuery;
export const useLazyGetSwaggerFilesQuery = discoveryApi.endpoints.getSwaggerFiles.useLazyQuery;
export const useGetTotalCallsForDaysQuery = discoveryApi.endpoints.getTotalCallsForDays.useQuery;

export const selectSensitiveEndpoints = (state: RootState, id: IPaginatedDiscoveryQuery) =>
    discoveryApi.endpoints.getServiceSensitiveEndpoints.select(id)(state)?.data;

export const selectSensitiveCalls = (state: RootState, id: IPaginatedDiscoveryQuery) =>
    discoveryApi.endpoints.getServiceSensitiveCalls.select(id)(state)?.data;

export const selectSensitiveBarsColors = createSelector(
    selectSensitiveEndpoints,
    selectSensitiveCalls,
    (sensitiveEndpoints, sensitiveCalls) => {
        const sensitiveLabels = [
            ...new Set([...(sensitiveEndpoints?.labels || []), ...(sensitiveCalls?.labels || [])]),
        ];
        let colorsMap = Object.assign(
            {},
            ...sensitiveLabels.map((label, index) => ({ [label]: labelColors[index % labelColors.length] }))
        );
        return colorsMap;
    }
);

import React, { useMemo } from 'react';
import { Tag } from 'antd';
import { LinkProps, NavLink } from 'react-router-dom';

import { UiTooltip } from '../UiTooltip/UiTooltip';

import './ApiLabel.scss';

interface IApiLabelProps {
    apiType: string[];
    apiName?: string;
    tooltip?: string;
    labelWidth?: number | 'unset';
    minWidth?: number;
    path?: LinkProps['to'];
    disable?: boolean;
    place?:
        | 'right'
        | 'top'
        | 'left'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined;
    pendingDeletion?: boolean;
    deletedBy?: string;
}

export const colors: any = {
    GET: '#17B0E0',
    POST: '#49CC90',
    DELETE: '#F66060',
    PUT: '#FFA52B',
    OPTIONS: '#5540ca',
    HEAD: '#49CC90',
    CONNECT: '#F66060',
    TRACE: '#FFA52B',
    PATCH: '#7569c8',
};

const apiVerbMap = new Map([
    ['GET', 'GET'],
    ['POST', 'POST'],
    ['PUT', 'PUT'],
    ['PATCH', 'PATCH'],
    ['DELETE', 'DEL'],
    ['COPY', 'COPY'],
    ['HEAD', 'HEAD'],
    ['OPTIONS', 'OPT'],
    ['LINK', 'LINK'],
    ['UNLINK', 'UNLINK'],
    ['UNLOCK', 'UNLCK'],
    ['PROPFIND', 'PROP'],
    ['VIEW', 'VIEW'],
]);

const defaultColor = '#7569c8';

export const tagStyle = (apiType: string) => ({
    color: colors[apiType?.toUpperCase()] || defaultColor,
});

const getApiVerb = (method: string): string => {
    return apiVerbMap.get(method.toUpperCase()) || method.toUpperCase().substring(0, 5);
};

export const ApiLabel = ({
    apiType,
    apiName,
    tooltip,
    place,
    minWidth,
    path = '',
    disable = false,
    pendingDeletion,
    deletedBy,
}: IApiLabelProps) => {
    const ttPlacement = place ? place : 'top';
    const style = { minWidth };
    const mappedApiVerbs = useMemo<string[]>(() => {
        return apiType.filter((method) => !!method).map(getApiVerb);
    }, [apiType]);

    const tooltipTitle = pendingDeletion ? (
        <i>{`${deletedBy} marked this endpoint for deletion.`}</i>
    ) : (
        `${mappedApiVerbs.join(' ')} ${tooltip || apiName}`
    );

    return (
        <div className={`ApiLabel${disable ? ' disabled' : ''}${pendingDeletion ? ' pending-deletion' : ''}`}>
            <div className="api-tag-container">
                {mappedApiVerbs.map((apiVerb, index) => (
                    <Tag className="api-tag" style={tagStyle(apiType[index])}>
                        {apiVerb}
                    </Tag>
                ))}
            </div>
            {apiName ? (
                <UiTooltip title={tooltipTitle} placement={ttPlacement}>
                    {path && !pendingDeletion ? (
                        <NavLink to={path}>
                            <div className="api-name api-name-with-path ag-grid-row-expand-trigger" style={style}>
                                {apiName}
                            </div>
                        </NavLink>
                    ) : (
                        <div className="api-name ag-grid-row-expand-trigger" style={style}>
                            <i>{pendingDeletion ? '[Deleting] ' : ''}</i>
                            {apiName}
                        </div>
                    )}
                </UiTooltip>
            ) : (
                ''
            )}
        </div>
    );
};

import { useMemo, useState, useCallback, ReactNode } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from '@ag-grid-community/core';

import {
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
    CellRenderGenericMore,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { agSetFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiChekcbox } from 'sharedComponents/UiChekcbox/UiCheckbox';
import { IEntity, useGetEntitiesQuery, usePatchEntityMutation } from 'api/entitiesApi';
import { ReactComponent as NoEntitiesImage } from 'features/settings/shared/assets/no_documents.svg';
import { EmptyStateList } from 'sharedComponents/EmptyStateList/EmptyStateList';
import Spinner from 'sharedComponents/spinner/Spinner';
import { AddEntityWizard } from '../AddEntityWizard/AddEntityWizard';
import { getEntityListCols } from './getEntityListCols';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';
import { SettingsSectionHeader } from 'features/settings/components/SettingsSectionHeader/SettingsSectionHeader';

import './EntityList.scss';

export interface IEntityListProps {
    addMode?: boolean;
}

const MAX_ENTITY_COUNT = 20;

export const EntityList = ({ addMode }: IEntityListProps) => {
    const { data: entities, isLoading } = useGetEntitiesQuery({ includeHidden: true });
    const [patchEntity, { isLoading: isPatchEntityLoading }] = usePatchEntityMutation();
    const [entityToPatch, setEntityToPatch] = useState<IEntity>();
    const [showDeletedEntities, setShowDeletedEntities] = useState(true);

    const CellRenderShowHide = ({ data: { name, hidden, active } }: ICellRendererParams): ReactNode => {
        const handleClick = () =>
            patchEntity({ entityName: name, body: { hidden: !hidden } })
                .unwrap()
                .catch((error) => errorMessage(extractErrorMessage(error)));

        if (active) {
            return <></>;
        }

        return (
            <Button className={hidden ? '' : 'hide-show-entity'} type="text" onClick={handleClick}>
                <UiIcon name={hidden ? 'showTransparent' : 'hide'} />
            </Button>
        );
    };

    const gridOptions: any = {
        rowHeight: 70,
        components: {
            cellRenderTimeStamp: CellRenderTimeStamp,
            cellRenderGenericMore: CellRenderGenericMore,
            cellRenderVerticalCenter: CellRenderVerticalCenter,
            cellRenderShowHide: CellRenderShowHide,
        },
        columnTypes: {
            basic: BASIC_AGGRID_COL_TYPE,
            agSetFilter: agSetFilter,
        },
        rowClassRules: {
            'disabled-row': (params: ICellRendererParams) => !params.data?.active,
        },
    };

    const tableMenuItems = ({ data: entity }: { data: IEntity }) => {
        const menuItems = [];

        if (entity.active) {
            menuItems.push({
                //TODO - replace label with below comment when implementing reactivation.
                //label: params.data.active ? 'Delete' : 'Reactivate',
                label: 'Delete',
                icon: <UiIcon name="trash" />,
                onClick: () => {
                    setEntityToPatch(entity);
                },
            });
        }

        if (!entity.active) {
            menuItems.push({
                label: entity.hidden ? 'Show' : 'Hide',
                icon: <UiIcon name={entity.hidden ? 'showTransparent' : 'hide'} />,
                onClick: () => {
                    patchEntity({ entityName: entity.name, body: { hidden: !entity.hidden } })
                        .unwrap()
                        .catch((error) => errorMessage(extractErrorMessage(error)));
                },
            });
        }

        return { menuItems };
    };

    const cachedTableMenuItems = useCallback(tableMenuItems, []);
    const cachedGetEntityListCols = useCallback(getEntityListCols, []);

    const columnsDefs = useMemo(
        () => cachedGetEntityListCols(cachedTableMenuItems),
        [cachedTableMenuItems, cachedGetEntityListCols]
    );

    const deletedEntitiesCount = useMemo(() => {
        return entities?.items.filter((entity) => !entity.active).length;
    }, [entities]);

    const deletedEntitiesCheckbox = () => {
        return (
            <UiChekcbox
                onChange={() => setShowDeletedEntities(!showDeletedEntities)}
                value={showDeletedEntities}
                isDisabled={false}
                label={`Show deleted entities(${deletedEntitiesCount || 0})`}
            ></UiChekcbox>
        );
    };

    const checkMaxEntities = (): boolean => !entities?.items || entities.items.length >= MAX_ENTITY_COUNT;

    return (
        <div className="EntityList">
            <SettingsSectionHeader
                title="Entity Management"
                buttonText={`${checkMaxEntities() ? 'Entity Amount Limit' : 'Add Entity'}`}
                urlPath="settings/entities/add"
                disableButton={checkMaxEntities()}
            />
            <div className="subtitle">{`${entities?.items?.length || 0} Entities`}</div>
            <div className="content">
                <Spinner show={isLoading} />
                {entities?.count ? (
                    <div className="entities-table">
                        <UiAgGridCSRM
                            columns={columnsDefs}
                            options={gridOptions}
                            data={
                                showDeletedEntities
                                    ? entities?.items
                                    : entities?.items.filter((entity) => entity.active) || []
                            }
                            showRowCount={false}
                            isHideRowCount={true}
                            tableActions={deletedEntitiesCheckbox()}
                        ></UiAgGridCSRM>
                    </div>
                ) : (
                    !isLoading && (
                        <EmptyStateList
                            text={
                                <>
                                    Start leveraging the power of
                                    <br />
                                    behavioural analytics by adding an Entity
                                </>
                            }
                            img={<NoEntitiesImage />}
                        />
                    )
                )}
                <UiModal
                    className="el-confirm-modal"
                    icon="trash"
                    onCancel={() => setEntityToPatch(undefined)}
                    isVisible={!!entityToPatch}
                    acceptButton={{
                        //TODO - replace text with below comment when implementing reactivation
                        //text: entityToPatch?.active ? 'Delete' : 'Reactivate',
                        text: 'Delete',
                        onClick: () => {
                            patchEntity({ entityName: entityToPatch!.name, body: { active: !entityToPatch!.active } })
                                .unwrap()
                                .catch((error) => errorMessage(extractErrorMessage(error)))
                                .finally(() => setEntityToPatch(undefined));
                        },
                        disabled: isPatchEntityLoading,
                    }}
                    rejectButton={{
                        text: 'Cancel',
                        onClick: () => setEntityToPatch(undefined),
                    }}
                    title={'Delete entity'}
                >
                    <div>
                        <p>
                            Are you sure you want to delete this entity? Models based on this entity will stop being
                            calculated.
                        </p>
                        <span style={{ display: 'flex', gap: '8px' }}>
                            <div>
                                <UiIcon name="alertLow" />
                            </div>
                            <div>
                                Note:{' '}
                                <Link className="suppression-link" to="suppression">
                                    Suppression rules
                                </Link>{' '}
                                may be affected by deletion of entities.
                            </div>
                        </span>
                    </div>
                </UiModal>
            </div>
            {addMode && <AddEntityWizard />}
        </div>
    );
};

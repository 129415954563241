import React, { ErrorInfo } from 'react';

class ErrorBoundary extends React.Component<{ children: React.PropsWithChildren['children'] }, any> {
    state = {
        hasError: false,
        errorMessage: '',
    };

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: true, errorMessage: error });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>{this.state.errorMessage}</h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;

import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppsOrAuthConfigs } from 'features/apiTesting/AppsOrAuthConfigs';
import { AuthConfigAddEditClone } from 'features/apiTesting/authConfigs/AuthConfigAddEditClone/AuthConfigAddEditClone';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { ApplicationDetails } from 'features/apiTesting/applications/ApplicationDetails/ApplicationDetails';
import { ScanDetails } from 'features/apiTesting/scans/ScanDetails/ScanDetails';
import { FindingDetails } from 'features/apiTesting/findings/FindingDetails/FindingDetails';
import { ApitEnum } from 'api/slices/apitSlice';

import './ApitTestingRoutes.scss';

export interface IApitRouteParams {
    appsOrAuthConfigsTabId: string;
    scansOrFindings?: string;
    appId?: string;
    scanId?: string;
    authConfigId?: string;
    findingId?: string;
}

export const API_TESTING_PATH = 'api-testing';
export const BASE_URL = `/:currentTenantKey/${API_TESTING_PATH}`;
export const BASE_URL_APPLICATIONS = `${BASE_URL}/applications`;
export const BASE_URL_AUTH_CONFIGS = `${BASE_URL}/auth-configs`;

export const ApitTestingRoutes = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    return (
        <div className="ApitTestingRoutes">
            <Switch>
                <Route exact path={`${BASE_URL}/:appsOrAuthConfigsTabId`}>
                    <AppsOrAuthConfigs />
                </Route>
                <Route exact path={`${BASE_URL}/${ApitEnum.AUTH_CONFIGURATIONS}/add`}>
                    <AuthConfigAddEditClone mode="add" />
                </Route>
                <Route exact path={`${BASE_URL}/${ApitEnum.APPLICATIONS}/add`}>
                    <AppsOrAuthConfigs />
                </Route>
                <Route exact path={`${BASE_URL_AUTH_CONFIGS}/:authConfigId/edit`}>
                    <AuthConfigAddEditClone mode="edit" />
                </Route>
                <Route exact path={`${BASE_URL_AUTH_CONFIGS}/:authConfigId/clone`}>
                    <AuthConfigAddEditClone mode="clone" />
                </Route>
                <Route exact path={`${BASE_URL_APPLICATIONS}/:appId/edit`}>
                    <AppsOrAuthConfigs />
                </Route>
                <Route exact path={`${BASE_URL_APPLICATIONS}/:appId/:scansOrFindings`}>
                    <ApplicationDetails />
                </Route>
                <Route exact path={`${BASE_URL_APPLICATIONS}/:appId/scans/:scanId`}>
                    <ScanDetails />
                </Route>
                <Route exact path={`${BASE_URL_APPLICATIONS}/:appId/scans/:scanId/findings/:findingId`}>
                    <FindingDetails />
                </Route>
                <Route exact path={`${BASE_URL_APPLICATIONS}/:appId/findings/:findingId`}>
                    <FindingDetails />
                </Route>
                <Route>
                    <Redirect to={`/${currentTenantKey}/${API_TESTING_PATH}/applications`} />
                </Route>
            </Switch>
        </div>
    );
};

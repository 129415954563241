import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import moment from 'moment';
import { ColumnApi, GridApi, RowNode, SelectionChangedEvent } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import { IFilter } from 'interfaces/filter.interface';
import { DiscoveryHeader } from 'features/discovery/DiscoveryHeader/DiscoveryHeader';
import { getTableDataServices, QUERY_RESULTS_LIMIT } from 'features/discovery/shared/discoveryApisLegacy';
import { ServicesTable } from 'features/discovery/services/ServicesTable/ServicesTable';
import ServicesTableActions from 'features/discovery/services/ServicesTable/ServicesCheckbox/ServicesTableActions';
import {
    DiscoveryDashboardFilterEnum,
    selectDiscoveryDashboardControls,
} from 'api/slices/discoveryDashboardControlsSlice';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';

import 'features/discovery/endpoint/Container.scss';
import './Services.scss';

export const Services = () => {
    const {
        selectedServices: globalInitialServices,
        selectedServiceLabels,
        serviceFilterBy,
    } = useSelector(selectDiscoveryDashboardControls);

    const params = useParams() as { activeOrgParam: string };

    const location = useLocation();
    const queryParams: any = new URLSearchParams(location.search);

    const [servicesFilter, setServicesFilter] = useState<IFilter[] | []>([]);
    const [servicesCheckboxFilters, setServicesCheckboxFilters] = useState<IFilter[]>([]);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [selectedServices, setSelectedServices] = useState<RowNode[]>([]);
    const [selectedServicesNames, setSelectedServicesNames] = useState<string[]>([]);
    const [timeRangeFromInput, setTimeRangeFromInput] = useState<TDatetimeRange>([
        moment.unix(queryParams.get('from_timestamp')),
        moment.unix(queryParams.get('to_timestamp')),
    ]);
    const [gridApi, setGridApi] = useState<GridApi>();
    const onGridReady = useCallback((params: { api: GridApi; columnApi: ColumnApi }) => {
        !gridApi && setGridApi(params.api);
    }, []);

    useEffect(() => {
        if (queryParams.get('use_slice') === 'true') {
            const isServices = serviceFilterBy === DiscoveryDashboardFilterEnum.SERVICES;
            const filterByList = (isServices ? globalInitialServices : selectedServiceLabels)?.map((value: string) =>
                isServices ? { name: 'name', operator: 'eq', value } : { name: serviceFilterBy, operator: 'has', value }
            );

            // @ts-ignore
            setServicesFilter([filterByList]);
            const serviceNamesToFilter = filterByList.reduce((acc, filterItem) => {
                // @ts-ignore
                acc.push(filterItem?.value);
                return acc;
            }, []);
            if (serviceNamesToFilter.length > 0) {
                gridApi?.setFilterModel({
                    [isServices ? 'name' : 'labels']: { values: serviceNamesToFilter, filterType: 'set' },
                });
                gridApi?.onFilterChanged();
            }
        }
    }, [serviceFilterBy, globalInitialServices, selectedServiceLabels, gridApi]);

    const getData = useCallback(
        getTableDataServices(
            params.activeOrgParam,
            QUERY_RESULTS_LIMIT,
            [...servicesCheckboxFilters, ...servicesFilter],
            timeRangeFromInput
        ),
        [params.activeOrgParam, servicesFilter, servicesCheckboxFilters, timeRangeFromInput]
    );

    const timeRangeHandler = (range: TDatetimeRange) => {
        setTimeRangeFromInput(range);
    };

    useEffect(() => {
        getSelectedServices();
    }, [selectedServices]);

    function getSelectedServices() {
        const selectedNames = selectedServices.map((selectedService: RowNode) => {
            return selectedService.data.name;
        });
        if (selectedNames) {
            setSelectedServicesNames([...selectedNames]);
        }
    }

    function parseAndSetSelectedServices(event: SelectionChangedEvent) {
        setSelectedServices(event.api.getSelectedNodes());
    }

    return (
        <div className="services-container container">
            <DiscoveryHeader
                queryParams={queryParams}
                selectedServices={selectedServicesNames}
                timeRangeFromInput={timeRangeFromInput}
                onTimeChange={timeRangeHandler}
                pathParams={params}
                title={`Services (${rowsCount})`}
                isNewLabel={false}
                isLabelList={false}
                isEndpointSelect={true}
                isSwagger={true}
                labelList={[]}
                onLabelCreate={() => Promise.resolve()}
                onLabelDelete={() => Promise.resolve()}
                onLabelSuppress={() => Promise.resolve()}
                canDeleteLabel={() => false}
                gridApi={gridApi}
            />
            <ServicesTable
                tableActions={
                    <ServicesTableActions
                        setFilters={setServicesCheckboxFilters}
                        selectedServices={selectedServicesNames}
                        pathParams={params}
                        timeRangeFromInput={timeRangeFromInput}
                    />
                }
                getData={getData}
                activeOrgParam={params.activeOrgParam}
                sortBy={queryParams.get('sort_by')}
                onFilterChange={setServicesFilter}
                onSelectionChanged={parseAndSetSelectedServices}
                setRowsCount={setRowsCount}
                onGridReady={onGridReady}
            />
        </div>
    );
};

import { createListenerMiddleware, isAnyOf, ListenerEffectAPI } from '@reduxjs/toolkit';

import { history } from 'App';
import { AppStartListening } from 'general/store';
import { selectUserEmail } from 'api/userApi';
import {
    currentTenantKeyChanged,
    loadAppStateFromStorage,
    saveAppStateToStorage,
    calcTenantKeyFromRouteOrStorage,
    selectCurrentTenantKey,
    selectPreventSaveStateToStorage,
    saveStateToStorageDisabled,
} from 'api/slices/appInfoSlice';
import { ITenant, selectTenantList } from 'api/tenantListApi';
import {
    selectDiscoveryDashboardControls,
    updateDiscoveryDashboardControls,
    DEFAULT_STATE as DASHBOARD_CONTROLS_DEFAULT_STATE,
} from 'api/slices/discoveryDashboardControlsSlice';
import {
    selectDiscoveryPagesSlice,
    updateDiscoveryPages,
    DEFAULT_STATE as DISCOVEY_PAGES_DEFAULT_STATE,
    checkboxShowServicesWithoutActivityToggled,
} from 'api/slices/discoveryPagesSlice';
import {
    selectDashboardsSlice,
    updateDashboardState,
    DEFAULT_STATE as DASHBOARS_DEFAULT_STATE,
    updateActiveDashboardId,
} from 'api/slices/dashboardsSlice';
import { tenantApi } from 'api/baseServerApi';

export const listenerMiddleware = createListenerMiddleware();
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const fourOFour = '404';

export const getTenantKeyFromPath = (path?: string | null) => {
    const p = path?.split('/');
    return p && p[1] && p[1] !== '' ? p[1] : undefined;
};
// if the tenantKey doesn't exist in tenant list then go to 404 component
const updateValidTenantKey = (
    tenantKey: string,
    tenantList: Record<string, ITenant>,
    api: ListenerEffectAPI<any, any>,
    shouldNavigate?: boolean
) => {
    if (tenantList[tenantKey]) {
        api.dispatch(currentTenantKeyChanged({ tenantKey, shouldNavigate }));
        api.dispatch(loadAppStateFromStorage({ newTenantKey: tenantKey }));
    } else {
        api.dispatch(currentTenantKeyChanged({ tenantKey: fourOFour }));
    }
};

startAppListening({
    matcher: isAnyOf(calcTenantKeyFromRouteOrStorage),
    effect: (action, api: ListenerEffectAPI<any, any>) => {
        const tenantList = selectTenantList(api.getState());
        const lastRoute = localStorage.getItem('lastRoute');
        localStorage.removeItem('lastRoute');

        if (!tenantList) return;

        const tenantListKeys = Object.keys(tenantList);
        if (tenantListKeys.length === 0) return;

        // for ACC support - lastRoute is saved only if not authenticated
        const lastRouteTenantKey = getTenantKeyFromPath(lastRoute);
        if (
            lastRoute &&
            lastRouteTenantKey &&
            lastRouteTenantKey !== '/' &&
            lastRouteTenantKey !== '' &&
            lastRouteTenantKey !== `/${fourOFour}`
        ) {
            console.log('lastRoute', lastRoute);
            updateValidTenantKey(lastRouteTenantKey, tenantList, api, false);
            history?.navigate?.push(lastRoute);
            return;
        }

        // Get tenant key from route
        const routeTenantKey = getTenantKeyFromPath(history?.location?.pathname);
        if (routeTenantKey) {
            updateValidTenantKey(routeTenantKey, tenantList, api);
            return;
        }

        let tenantKey = tenantListKeys[0];
        // if not - Get tenant key from localStorage
        const activeTenantFromStorage = localStorage.getItem('tenantKey');
        if (activeTenantFromStorage) {
            const activeTenantFromStorageParse = activeTenantFromStorage;
            if (activeTenantFromStorageParse && activeTenantFromStorageParse !== fourOFour) {
                tenantKey = activeTenantFromStorage;
            }
        }
        // if not - save first tenant key from list and save new key in rtk, localStorage & navigate to route
        updateValidTenantKey(tenantKey, tenantList, api, true);
    },
});

startAppListening({
    matcher: isAnyOf(currentTenantKeyChanged),
    effect: ({ payload }, api: ListenerEffectAPI<any, any>) => {
        const tenantList = selectTenantList(api.getState());
        const { tenantKey, shouldNavigate, refetchApi } = payload;

        if (tenantList && tenantList[tenantKey]) {
            localStorage.setItem('tenantKey', tenantKey);
        }
        if (refetchApi) {
            api.dispatch(tenantApi.util.resetApiState());
        }
        if (shouldNavigate) {
            history?.navigate?.push(`/${tenantKey}`);
        }
    },
});

// This listener loads tenant/user state from storage and typically runs on:
// 1 - app first load
// 2 - tenant switch
startAppListening({
    matcher: isAnyOf(loadAppStateFromStorage),
    effect: ({ payload }, api: ListenerEffectAPI<any, any>) => {
        const userEmail = selectUserEmail(api.getState());
        const { newTenantKey } = payload;
        if (userEmail && newTenantKey) {
            api.dispatch(saveStateToStorageDisabled({ preventSaveStateToStorage: true }));
            const persistentState = localStorage.getItem(`${userEmail}__${newTenantKey}`);
            const parsePersistentState = persistentState && JSON.parse(persistentState);
            const { discoveryDashboardControls, discoveryPages, dashboards } = parsePersistentState || {};
            api.dispatch(
                updateDiscoveryDashboardControls(discoveryDashboardControls || DASHBOARD_CONTROLS_DEFAULT_STATE)
            );
            api.dispatch(updateDiscoveryPages(discoveryPages || DISCOVEY_PAGES_DEFAULT_STATE));
            api.dispatch(updateDashboardState(dashboards || DASHBOARS_DEFAULT_STATE));
        }
        api.dispatch(saveStateToStorageDisabled({ preventSaveStateToStorage: false }));
    },
});

// save tenant/user state to storage
// 1 - redux state changes
// 2 - tenant switch
startAppListening({
    matcher: isAnyOf(
        saveAppStateToStorage,
        updateDiscoveryDashboardControls,
        checkboxShowServicesWithoutActivityToggled,
        updateActiveDashboardId
    ),
    effect: (_, api: ListenerEffectAPI<any, any>) => {
        const userEmail = selectUserEmail(api.getState());
        const currentTenantKey = selectCurrentTenantKey(api.getState());
        const preventSaveStateToStorage = selectPreventSaveStateToStorage(api.getState());

        if (userEmail && currentTenantKey && !preventSaveStateToStorage) {
            const discoveryDashboardControls = selectDiscoveryDashboardControls(api.getState());
            const discoveryPages = selectDiscoveryPagesSlice(api.getState());
            const dashboards = selectDashboardsSlice(api.getState());
            localStorage.setItem(
                `${userEmail}__${currentTenantKey}`,
                JSON.stringify({
                    discoveryDashboardControls,
                    discoveryPages,
                    dashboards,
                })
            );
        }
    },
});

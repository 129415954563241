import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { NavLink, Switch, Route } from 'react-router-dom';
import { Allotment } from 'allotment';

import { AutomatedActionRuleList } from './components/AutomatedActionRuleList/AutomatedActionRuleList';
import { AutomatedActionRule } from './components/AutomatedActionRule/AutomatedActionRule';
import { CollectorConfigurationAddEdit } from './components/collectorConfigurations/CollectorConfigurationAddEdit/CollectorConfigurationAddEdit';
import { CollectorConfigurationList } from './components/collectorConfigurations/CollectorConfigurationList/CollectorConfigurationList';
import { DataSourcesRoutes } from './components/dataSources/DataSourcesRoutes/DataSourcesRoutes';
import {
    AddOrEditMode,
    EndpointExclusionRuleList,
} from 'features/settings/components/EndpointExclusionRuleList/EndpointExclusionRuleList';
import { EndpointPatternsList } from './components/endpoint-patterns-list/EndpointPatternsList';
import { ServiceMappingRuleList } from './components/ServiceMappingRuleList/ServiceMappingRuleList';
import { EntityList } from './components/entity-management/EntityList/EntityList';
import { SuppressionRuleList } from './components/SuppressionRuleList/SuppressionRuleList';
import { SuppressionRule } from './components/SuppressionRule/SuppressionRule';
import { AkamaiConfigurationList } from './components/akamaiConfigurations/akamaiConfigurationList/AkamaiConfigurationList';
import { AkamaiConfigurationEdit } from './components/akamaiConfigurations/akamaiConfigurationEdit/AkamaiConfigurationEdit';
import { useGetPluginsConfigurations } from 'api/akamaiConfigsApi';
import { ReleaseNotes } from './components/ReleaseNotes/ReleaseNotes';
import { AutomatedActionRuleMock } from './components/AutomatedActionRuleMock/AutomatedActionRuleMock';
import { AutomatedActionRuleListMock } from './components/AutomatedActionsRuleListMock/AutomatedActionRuleListMock';
import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { gotoACC } from 'general/utils';

import './Settings.scss';

interface ISettingItem {
    id: SettingsSubsectionsEnum;
    displayName: string;
    path: string;
    extraClassname?: string;
}

interface IToolbarItem {
    item: ISettingItem;
    section: ToolbarSection;
    show: boolean; // Check feature
}

export interface ISettingsPathParams {
    activeOrg: string;
}

enum SettingsSubsectionsEnum {
    SUPPRESSION = 'Suppression',
    AUTOMATED_ACTIONS = 'Automated Actions',
    AUTOMATED_ACTIONS_MOCK = 'Automated Actions Mock',
    DATA_SOURCES = 'Data Sources',
    COLLECTOR_CONFIGURATIONS = 'Node Configurations',
    EXCLUSION_RULES = 'Exclusion Rules',
    ENDPOINT_PATTERNS = 'Endpoint Patterns',
    ENTITY_MANAGEMENT = 'Entity Management',
    AKAMAI_CONFIGURATIONS = 'Plugin Configurations',
    SERVICE_MAPPING = 'Service Mapping',
}

const SETTINGS_SECTIONS = ['Alerts', 'Discovery', 'Data Acquisition', 'API Testing', 'Management'] as const;

type ToolbarSection = typeof SETTINGS_SECTIONS[number];

export const Settings = () => {
    const featureFlagMap = useSelector(selectFeatureFlagMap);
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [toolbarItems, setToolbarItems] = useState<IToolbarItem[]>([]);
    const { data: pluginsConfigurations } = useGetPluginsConfigurations();

    function getMenuItems(items: IToolbarItem[]) {
        const relevantItems = items.filter(({ show }) => show);
        return SETTINGS_SECTIONS.map((section) => (
            <div key={section}>
                {relevantItems
                    .filter(({ section: activeSec }) => section === activeSec)
                    .map(({ item }, index) => (
                        <div key={item.id}>
                            {index === 0 && (
                                <div key={section} className="title-container">
                                    {section}
                                </div>
                            )}
                            <NavLink
                                to={`/${currentTenantKey}/${item.path}`}
                                className={`navigation-item ${item.extraClassname}`}
                                key={item.id}
                                activeClassName="selected"
                            >
                                {item.displayName}
                            </NavLink>
                        </div>
                    ))}
            </div>
        ));
    }

    useEffect(() => {
        setToolbarItems([
            {
                item: {
                    id: SettingsSubsectionsEnum.SUPPRESSION,
                    displayName: 'Suppression',
                    path: 'settings/suppression',
                },
                show: true,
                section: 'Alerts',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.AUTOMATED_ACTIONS,
                    displayName: 'Automated Actions',
                    path: 'settings/automated-actions',
                },
                show: featureFlagMap.automatedActions,
                section: 'Alerts',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.AUTOMATED_ACTIONS_MOCK,
                    displayName: 'Automated Actions',
                    path: 'settings/automated-actions-m',
                },
                show: featureFlagMap.automatedActionsMock,
                section: 'Alerts',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.DATA_SOURCES,
                    displayName: SettingsSubsectionsEnum.DATA_SOURCES,
                    path: 'settings/data-sources',
                },
                show: !!featureFlagMap?.collectorsMgmt,
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.COLLECTOR_CONFIGURATIONS,
                    displayName: SettingsSubsectionsEnum.COLLECTOR_CONFIGURATIONS,
                    path: 'settings/node-configurations',
                },
                show: !!featureFlagMap?.collectorsMgmt,
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.AKAMAI_CONFIGURATIONS,
                    displayName: SettingsSubsectionsEnum.AKAMAI_CONFIGURATIONS,
                    path: 'settings/akamai-configs',
                },
                show:
                    !!(pluginsConfigurations && pluginsConfigurations?.items.length > 0) &&
                    !!featureFlagMap?.akamaiConfigs,
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENTITY_MANAGEMENT,
                    displayName: SettingsSubsectionsEnum.ENTITY_MANAGEMENT,
                    path: 'settings/entities',
                },
                show: !!featureFlagMap?.entityManagement,
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.EXCLUSION_RULES,
                    displayName: 'Exclusion Rules',
                    path: 'settings/exclusion-rules',
                },
                show: !!featureFlagMap?.exclusionRules,
                section: 'Discovery',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.SERVICE_MAPPING,
                    displayName: 'Service Mapping',
                    path: 'settings/service-mappings',
                },
                show: !!featureFlagMap?.serviceMapping,
                section: 'Discovery',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENDPOINT_PATTERNS,
                    displayName: 'Endpoint Patterns',
                    path: 'settings/endpoint-patterns',
                },
                show: !!featureFlagMap?.endpointPatterns,
                section: 'Discovery',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENDPOINT_PATTERNS,
                    displayName: 'API Keys',
                    path: 'settings/api-keys',
                },
                show: !!featureFlagMap?.testing,
                section: 'API Testing',
            },
        ]);
    }, [featureFlagMap, pluginsConfigurations]);

    return (
        <div className="settings-container">
            <Allotment vertical={false} defaultSizes={[250, 1500]}>
                <Allotment.Pane minSize={250} maxSize={500}>
                    <div className="settings-menu">
                        <div className="settings-menu-items">
                            {getMenuItems(toolbarItems)}
                            <NavLink
                                to={`/${currentTenantKey}/settings/release-notes`}
                                className="navigation-item release-notes-menu-item"
                                activeClassName="selected"
                            >
                                Release Notes
                            </NavLink>
                        </div>
                        {!!featureFlagMap?.accManagementUrl && (
                            <div className="settings-menu-footer">
                                <div className="settings-menu-footer-title">Akamai Control Center</div>
                                <a
                                    className="settings-menu-footer-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        gotoACC();
                                    }}
                                >
                                    Go to configuration
                                    <UiIcon name="externalLink" />
                                </a>
                            </div>
                        )}
                    </div>
                </Allotment.Pane>
                <Allotment.Pane>
                    <div className="settings-content-container">
                        <Switch>
                            <Route exact path="/:activeOrg/settings">
                                <Redirect to={`/${currentTenantKey}/settings/suppression`} />
                            </Route>
                            <Route exact path="/:activeOrg/settings/suppression">
                                <SuppressionRuleList />
                            </Route>
                            <Route path="/:activeOrg/settings/suppression/:ruleId">
                                <SuppressionRule />
                            </Route>
                            <Route exact path="/:activeOrg/settings/automated-actions">
                                <AutomatedActionRuleList />
                            </Route>
                            <Route path="/:activeOrg/settings/automated-actions/:ruleId">
                                <AutomatedActionRule />
                            </Route>
                            <Route exact path="/:activeOrg/settings/automated-actions-m">
                                <AutomatedActionRuleListMock />
                            </Route>
                            <Route path="/:activeOrg/settings/automated-actions-m/rule/:ruleId?">
                                <AutomatedActionRuleMock />
                            </Route>
                            <Route path="/:activeOrg/settings/data-sources">
                                <DataSourcesRoutes />
                            </Route>
                            <Route exact path="/:activeOrg/settings/node-configurations/:configId/edit">
                                <CollectorConfigurationAddEdit />
                            </Route>
                            <Route exact path="/:activeOrg/settings/node-configurations/add">
                                <CollectorConfigurationAddEdit />
                            </Route>
                            <Route path="/:activeOrg/settings/node-configurations">
                                <CollectorConfigurationList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/exclusion-rules">
                                <EndpointExclusionRuleList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/exclusion-rules/add">
                                <EndpointExclusionRuleList mode={AddOrEditMode.ADD} />
                            </Route>
                            <Route exact path="/:activeOrg/settings/exclusion-rules/edit/:endpointFilterId">
                                <EndpointExclusionRuleList mode={AddOrEditMode.EDIT} />
                            </Route>
                            <Route path="/:activeOrg/settings/release-notes">
                                <ReleaseNotes />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns">
                                <EndpointPatternsList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns/add">
                                <EndpointPatternsList editMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns/edit/:endpointPatternId">
                                <EndpointPatternsList editMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/entities">
                                <EntityList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/entities/add">
                                <EntityList addMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/akamai-configs">
                                <AkamaiConfigurationList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/akamai-configs/:pluginName/edit">
                                <AkamaiConfigurationEdit />
                            </Route>
                            <Route exact path="/:activeOrg/settings/api-keys">
                                <h1>API Keys - Coming Soon</h1>
                            </Route>
                            <Route exact path="/:activeOrg/settings/api-keys/add">
                                <h1>API Keys Add - Coming Soon</h1>
                            </Route>
                            <Route exact path="/:activeOrg/settings/service-mappings">
                                <ServiceMappingRuleList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/service-mappings/add">
                                <ServiceMappingRuleList addMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/service-mappings/:serviceMappingRuleId/edit">
                                <ServiceMappingRuleList editMode />
                            </Route>
                        </Switch>
                    </div>
                </Allotment.Pane>
            </Allotment>
        </div>
    );
};

import { RowDragCallbackParams } from '@ag-grid-community/core';

import { SMALL_COLUMN } from 'sharedComponents/ui-ag-grid/agGridConstants';
import { TimeDisplayResEnum } from 'general/utils';

const commonTableProperties = {
    type: 'basic',
    flex: 1,
    sortable: false,
};

export const getServiceMappingRulesTableCols = (onRowToggleHandler: Function, tableMenuItems: Function) => [
    {
        ...commonTableProperties,
        flex: 0.5,
        headerName: 'No.',
        rowDrag: (params: RowDragCallbackParams) => params.data.pattern !== 'Default Rule',
        field: 'index',
        width: SMALL_COLUMN,
        cellRenderer: 'cellRenderVerticalCenter',
        headerTooltip: 'Order',
    },
    {
        ...commonTableProperties,
        flex: 0.5,
        headerName: 'Status',
        field: 'active',

        width: SMALL_COLUMN,
        cellRenderer: 'cellRenderToggle',
        cellRendererParams: () => ({
            onRowToggleHandler,
        }),
    },
    {
        ...commonTableProperties,
        headerName: 'Mapping Rule',
        field: 'pattern',
        flex: 2.5,
        minWidth: 300,
        cellRenderer: 'cellRendererCustomMappingRule',
    },
    {
        ...commonTableProperties,
        headerName: 'Description',
        field: 'description',
        flex: 2.5,
        minWidth: 300,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: 'Created At',
        field: 'created_at',
        flex: 1,
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            timeResolution: TimeDisplayResEnum.MIN,
        },
    },
    {
        ...commonTableProperties,
        headerName: 'Author',
        field: 'created_by',
        flex: 1,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified',
        field: 'updated_at',
        flex: 1,
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            timeResolution: TimeDisplayResEnum.MIN,
        },
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified By',
        field: 'updated_by',
        flex: 1,
        cellRenderer: 'cellRenderVerticalCenter',
        resizable: false,
    },
    {
        ...commonTableProperties,
        flex: 0,
        width: 60,
        headerName: '',
        field: 'overlay',
        resizable: false,
        cellRenderer: 'cellRenderGenericMore',
        cellRendererParams: tableMenuItems,
    },
];

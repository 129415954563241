import { IApplicationGroupedFinding } from 'api/apiTestingApi';
import { ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { FindingDetailsScanLink } from 'features/apiTesting/findings/FindingDetails/FindingDetailsFinding/FindingDetailsApplicationScan/FindingDetailsScanLink/FindingDetailsScanLink';

export interface IFindingDetailsApplicationScanProps {
    groupedFinding: IApplicationGroupedFinding;
}

export const FindingDetailsApplicationScan = ({ groupedFinding }: IFindingDetailsApplicationScanProps) => {
    return (
        <div className="FindingDetailsApplicationScan">
            <ValueWithLabel
                label="first seen"
                value={<FindingDetailsScanLink scan={(groupedFinding as IApplicationGroupedFinding).first_scan} />}
            />
            <ValueWithLabel
                label="last seen"
                value={<FindingDetailsScanLink scan={(groupedFinding as IApplicationGroupedFinding).last_scan} />}
            />
        </div>
    );
};

import { tenantApi, cmsApi } from './baseServerApi';

export interface IAkamaiConfig {
    updatedAt: string;
    updatedBy: string;
    pluginName: string;
    displayName: string;
    userConfig: any;
}

export interface IAkamaiConfigResponse {
    items: IAkamaiConfig[];
}

export const AkamaiConfigurationApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getPluginConfigurations: builder.query<IAkamaiConfigResponse, void>({
            query: () => `cms/plugin_configurations`,
            providesTags: ['akamaiConfigurationList'],
        }),
        updatePluginConfiguration: builder.mutation<null, { pluginName: string; configuration: any }>({
            query: ({ pluginName, configuration }) => ({
                url: `cms/plugin_configurations/${encodeURIComponent(pluginName)}`,
                method: 'PATCH',
                body: { configuration },
            }),
            invalidatesTags: ['akamaiConfigurationList'],
        }),
    }),
    overrideExisting: false,
});

export const AkamaiDefaultConfigurationApi = cmsApi.injectEndpoints({
    endpoints: (builder) => ({
        getDefualtConfiguration: builder.query<{ userConfig: string }, { pluginName: string }>({
            query: ({ pluginName }) => ({
                url: `default_plugin_configurations/${encodeURIComponent(pluginName)}`,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useGetPluginsConfigurations = AkamaiConfigurationApi.endpoints.getPluginConfigurations.useQuery;
export const useUpdatePluginConfiguration = AkamaiConfigurationApi.endpoints.updatePluginConfiguration.useMutation;
export const useGetDefualtConfiguration = AkamaiDefaultConfigurationApi.endpoints.getDefualtConfiguration.useQuery;

import { UiIcon } from 'sharedComponents/icon/UiIcon';

import 'sharedComponents/ExpandButton/ExpandButton.scss';

interface IProps {
    isExpanded: boolean;
    onClick: () => void;
}

export function ExpandButton({ isExpanded, onClick }: IProps) {
    return (
        <div className={`expand-button-container ${isExpanded ? 'active' : ''}`} onClick={onClick}>
            <UiIcon name="treeOpen" className="expand-button" />
        </div>
    );
}

import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { BASIC_COLUMN_DEFINITION, DISABLE_FILTER_AND_SORT } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { colors as methodOptions, tagStyle } from 'sharedComponents/ApiLabel/ApiLabel';
import { IFindingMetadata } from 'api/apiTestingApi';
import { CellRenderLink } from 'sharedComponents/ui-ag-grid/customCellRenderers';

const applicationExtraCols = [
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Times Found',
        field: 'times_found',
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'First Seen',
        field: 'first_seen',
        cellRenderer: 'cellRenderTimeStamp',
        flex: 1,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Last Seen',
        field: 'last_seen',
        cellRenderer: 'cellRenderTimeStamp',
        flex: 1,
    },
];

const idCol = [
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'ID',
        field: 'id',
        sort: 'desc',
        width: 70,
        flex: 0,
        sortable: false,
        resizable: false,
    },
];

export const findingTableCols = (
    filterTableMenu: Function,
    isApp: boolean,
    goToFindings: (id: number) => string,
    findingsMetadata?: IFindingMetadata
) => [
    ...(!isApp ? idCol : []),
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: 'Finding',
        field: 'finding_name',
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: findingsMetadata?.finding_names,
        },
        cellRenderer: CellRenderLink,
        cellRendererParams: ({ data }: any) => ({ path: goToFindings(data.id), text: data.finding_name }),
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: 'Method',
        field: 'method',
        flex: 1,
        cellRenderer: ({ value }: ICellRendererParams) => {
            return <div style={tagStyle(value)}>{value}</div>;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
            values: Object.keys(methodOptions),
        },
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: 'Endpoint',
        field: 'endpoint',
        flex: 2.5,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: findingsMetadata?.endpoints,
        },
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: 'Severity',
        field: 'severity',
        flex: 1,
        cellRenderer: 'severityRender',
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Info', 'Low', 'Medium', 'High'],
        },
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Labels',
        field: 'labels',
        cellRenderer: 'labelListRender',
        cellRendererParams: (params: any) => {
            return {
                value: params.value?.map((label: string) => ({ label })),
            };
        },
        flex: 2,
    },
    ...(isApp ? applicationExtraCols : []),
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: '',
        field: 'more',
        width: 64,
        flex: 0,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: filterTableMenu,
        suppressMenu: true,
        suppressMovable: true,
        filter: false,
        filterParams: null,
        sortable: false,
        resizable: false,
    },
];

import './UiTitle.scss';

interface ITitleProps {
    title: string | JSX.Element;
    type: 'x-large' | 'large' | 'medium' | 'small';
}

export const UiTitle = (props: ITitleProps) => {
    return (
        <div className="ui-title">
            <span className={props.type}>{props.title}</span>
        </div>
    );
};

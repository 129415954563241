import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CollectorCard } from '../CollectorCard/CollectorCard';
import {
    getCollectors,
    ICollector,
    collectorStateMachine,
    CollectorStates,
    NodeImageStatus,
    usePatchCollectorMutation,
} from '../shared/collectorApis';
import { errorMessage } from 'general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { useFetchAllCollectorConfigurations } from '../../collectorConfigurations/shared/useFetchAllCollectorConfigurations';
import { ICollectorConfiguration } from '../../collectorConfigurations/shared/collectorConfigurationApis';
import { ConfirmAssignConfigToCollectorModal } from '../ConfirmAssignConfigToCollctorModal/ConfirmAssignConfigToCollectorModal';
import useInterval from '../../../../../hooks/useInterval';
import { ReactComponent as NoCollectorsImage } from '../../../shared/assets/no_boxes.svg';
import { DeployCollectorModal } from '../deployCollectorModal/DeployCollectorModal';
import { CollectorDeleteOrUndeleteModal } from '../CollectorDeleteOrUndeleteModal/CollectorDeleteOrUndeleteModal';
import { CollectorEnableOrDisableModal } from '../CollectorEnableOrDisableModal/CollectorEnableOrDisableModal';
import { CollectorAddEditModal } from '../CollectorAddEditModal/CollectorAddEditModal';
import { extractErrorMessage } from 'general/utils';
import { UpgradeCollectorModal } from '../UpgradeCollectorModal/UpgradeCollectorModal';
import { IOverlayMenu, UiOverlayMenu } from 'sharedComponents/UiOverlayMenu/UiOverlayMenu';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import Spinner from 'sharedComponents/spinner/Spinner';
import { EmptyStateList } from 'sharedComponents/EmptyStateList/EmptyStateList';
import TroubleshootingModal, {
    TroubleshootingContext,
} from 'sharedComponents/TroubleshootingModal/TroubleshootingModal';

import './CollectorList.scss';

const semverLt = require('semver/functions/lt');

export interface IDropDownOptionsProps {
    value: string;
    label: string;
}

export interface ICollectorListProps {
    addMode?: boolean;
}

interface ICollectorListPathParams extends ISettingsPathParams {
    dataSourceId: string;
}

export const formatCollectorConfigsForDropdown = (collectorConfigs: ICollectorConfiguration[]) => {
    return collectorConfigs.reduce((acc: IDropDownOptionsProps[], config: ICollectorConfiguration) => {
        acc.push({ value: config.id, label: config.name });
        return acc;
    }, []);
};

const filterDeletedOut = (c: ICollector) => c.state !== CollectorStates.deleted;

export const CollectorList = (props: ICollectorListProps) => {
    const { addMode, ...restOfProps } = props;
    const { activeOrg, dataSourceId } = useParams<ICollectorListPathParams>();

    const [collectors, setCollectors] = useState<ICollector[]>();
    const [collectorToDeploy, setCollectorToDeploy] = useState<ICollector>();
    const [collectorToUpgrade, setCollectorToUpgrade] = useState<ICollector>();
    const [collectorToDeleteOrUndelete, setCollectorToDeleteOrUndelete] = useState<ICollector>();
    const [collectorToEnableOrDisable, setCollectorToEnableOrDisable] = useState<ICollector>();
    const [troubleshootOpen, setTroubleshootOpen] = useState<Boolean>(false);
    const [collectorConfigsOptions, setCollectorConfigsOptions] = useState<IDropDownOptionsProps[]>([]);
    const [collectorConfigsOptionsDict, setCollectorConfigsOptionsDict] = useState<Record<string, string>>({});
    const [assignCollectorConfig, setAssignCollectorConfig] = useState<{ confId: string; collector: ICollector }>();
    const [updateListDelay, setUpdateListDelay] = useState<number>(500);
    const [patchCollector] = usePatchCollectorMutation();

    const history = useHistory();

    useFetchAllCollectorConfigurations(setCollectorConfigsOptions, formatCollectorConfigsForDropdown);

    const getCollectorlist = useCallback(async () => {
        setUpdateListDelay(15000);
        try {
            const collectorsRes = await getCollectors(activeOrg, dataSourceId);
            if (JSON.stringify(collectors) !== JSON.stringify(collectorsRes)) {
                setCollectors(collectorsRes.filter(filterDeletedOut));
            }
        } catch (e) {
            errorMessage('Error: cannot fetch nodes');
        }
    }, [activeOrg, collectors, dataSourceId]);

    useInterval(getCollectorlist, updateListDelay);

    useEffect(() => {
        setCollectorConfigsOptionsDict(
            collectorConfigsOptions?.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
        );
    }, [collectorConfigsOptions]);

    const getContextMenuItems = useCallback((collector: ICollector) => {
        const stateBtns = collectorStateMachine[collector.state];

        const menuItems: IOverlayMenu['menuItems'] = [
            {
                label: 'Deploy Node',
                onClick: () => setCollectorToDeploy(collector),
                disabled: collector.edges.nodeImage && collector.edges.nodeImage.status !== NodeImageStatus.ready,
                icon: <UiIcon name="deploy" />,
            },
            {
                label: 'Upgrade Node',
                onClick: () => setCollectorToUpgrade(collector),
                icon: <UiIcon name="upgrade" />,
                disabled:
                    !collectorStateMachine[collector.state].isUpgradable ||
                    !collector.id ||
                    !collector.edges?.currentPackageVersion?.version ||
                    semverLt(collector.edges?.currentPackageVersion?.version, '0.2.10', false),
            },
            {
                label: 'Troubleshooting',
                onClick: () => setTroubleshootOpen(true),
                icon: <UiIcon name="troubleshooting" />,
            },
        ];

        // when disable and not currentConfigId -> no enable menu item
        if (stateBtns?.isDisableable !== undefined) {
            const label = `${stateBtns?.isDisableable ? 'Disable' : 'Enable'} Collection`;
            menuItems.push({
                label:
                    stateBtns?.isDisableable || collector?.currentConfigId ? (
                        label
                    ) : (
                        <UiTooltip title="Choose a configuration to enable" disableCopyButton>
                            {label}
                        </UiTooltip>
                    ),
                onClick: () => setCollectorToEnableOrDisable(collector),
                icon: <UiIcon name={stateBtns?.isDisableable ? 'disable' : 'enable'} />,
                disabled: !collector?.currentConfigId,
            });
        }

        if (stateBtns?.isDeletable !== undefined) {
            menuItems.push({
                label: `${stateBtns?.isDeletable ? 'Delete' : 'Undelete'} Node`,
                onClick: () => setCollectorToDeleteOrUndelete(collector),
                icon: <UiIcon name={stateBtns?.isDeletable ? 'trash' : 'undelete'} />,
            });
        }

        if (!collector.isHeartbeatActive && stateBtns?.isDeletable !== true && stateBtns?.isDeletable !== false) {
            menuItems.push({
                label: 'Delete Node',
                onClick: () => setCollectorToDeleteOrUndelete(collector),
                icon: <UiIcon name="trash" />,
            });
        }

        return <UiOverlayMenu menuItems={menuItems} />;
    }, []);

    const handleClosingAddEditModal = (refreshNeeded = false) => {
        const currentPath = history.location.pathname;
        if (currentPath.endsWith('edit')) {
            // remove the suffix '/<collector-id>/edit' to send back to collector list:
            history.push({ pathname: currentPath.replace(/\/[\w_-]+\/edit$/, '') });
        } else {
            history.push({ pathname: currentPath.replace(/new\/?$/, '') });
        }
        refreshNeeded && setUpdateListDelay(1);
    };

    return (
        <>
            <div className="CollectorList">
                <div className="content">
                    <Spinner show={!collectors} />
                    {collectors?.length === 0 && (
                        <EmptyStateList
                            isBackground
                            text={
                                <>
                                    Add a Node to send API activity data <br /> to Neosec Cloud
                                </>
                            }
                            img={<NoCollectorsImage />}
                        />
                    )}
                    <div className="card-grid">
                        {collectors?.map((collector) => (
                            <CollectorCard
                                key={collector.id}
                                menu={getContextMenuItems(collector)}
                                collector={collector}
                                collectorConfigsOptions={collectorConfigsOptions}
                                configNames={collectorConfigsOptionsDict}
                                setAssignCollectorConfig={setAssignCollectorConfig}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {addMode && (
                <CollectorAddEditModal
                    onClose={(refreshNeeded) => handleClosingAddEditModal(refreshNeeded)}
                    collectors={collectors}
                />
            )}
            {collectorToDeploy && (
                <DeployCollectorModal
                    onClose={() => setCollectorToDeploy(undefined)}
                    collectorToDeploy={collectorToDeploy}
                    setTroubleshootOpen={setTroubleshootOpen}
                />
            )}
            {collectorToUpgrade && (
                <UpgradeCollectorModal
                    collectorToUpgrade={collectorToUpgrade}
                    collectorConfigOptions={collectorConfigsOptions.filter(
                        (configOption) => configOption.value !== collectorToUpgrade?.currentConfigId
                    )}
                    onClose={() => setCollectorToUpgrade(undefined)}
                    onUpgrade={async (desiredPackageVersion, collectorConfId) => {
                        try {
                            await patchCollector({
                                tenant: activeOrg,
                                collectorId: collectorToUpgrade?.id,
                                sourceId: collectorToUpgrade?.sourceId,
                                desiredPackageVersion,
                                collectorConfId,
                                state: CollectorStates.upgrading,
                            }).unwrap();
                            setCollectorToUpgrade(undefined);
                            setUpdateListDelay(1000);
                        } catch (error) {
                            errorMessage(extractErrorMessage(error));
                        }
                    }}
                />
            )}
            {troubleshootOpen && (
                <TroubleshootingModal
                    troubleshootContext={TroubleshootingContext.collector}
                    onDismiss={() => setTroubleshootOpen(false)}
                />
            )}
            {collectorToDeleteOrUndelete && (
                <CollectorDeleteOrUndeleteModal
                    collectorToDeleteOrUndelete={collectorToDeleteOrUndelete}
                    onClose={() => setCollectorToDeleteOrUndelete(undefined)}
                    setUpdateListDelay={setUpdateListDelay}
                />
            )}
            {collectorToEnableOrDisable && (
                <CollectorEnableOrDisableModal
                    collectorToEnableOrDisable={collectorToEnableOrDisable}
                    onClose={() => setCollectorToEnableOrDisable(undefined)}
                    setUpdateListDelay={setUpdateListDelay}
                />
            )}
            {assignCollectorConfig && (
                <ConfirmAssignConfigToCollectorModal
                    onClose={(refreshNeeded?: boolean) => {
                        setAssignCollectorConfig(undefined);
                        refreshNeeded && setUpdateListDelay(1000);
                    }}
                    assignCollectorConfig={assignCollectorConfig}
                    configNames={collectorConfigsOptionsDict}
                />
            )}
        </>
    );
};

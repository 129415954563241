import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Markdown from 'react-markdown';

import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { ICollector, INodePackageResponse, useGetCollectorPackagesQuery } from '../shared/collectorApis';
import { formFieldRequired } from 'general/forms';
import { EmptyStateList } from 'sharedComponents/EmptyStateList/EmptyStateList';
import { ReactComponent as NoDataSourceImage } from 'features/settings/shared/assets/no_servers.svg';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { decodeUtf8Base64String } from 'general/utils';

import './UpgradeCollectorModal.scss';

const semverMajor = require('semver/functions/major');

const { Option } = Select;

interface IUpgradeCollectorModalProps {
    collectorToUpgrade: ICollector;
    collectorConfigOptions: { value: string; label: string }[];
    onClose: () => void;
    onUpgrade: (packageId: string, configId?: string) => Promise<any>;
}

export const UpgradeCollectorModal = ({
    collectorToUpgrade,
    collectorConfigOptions,
    onClose,
    onUpgrade,
}: IUpgradeCollectorModalProps) => {
    const { data }: { data?: INodePackageResponse[] | undefined } = useGetCollectorPackagesQuery({
        collectorId: collectorToUpgrade.id,
        sourceId: collectorToUpgrade.sourceId,
    });
    const [form] = Form.useForm();
    const [selectedPackage, setSelectedPackage] = useState<INodePackageResponse>();
    const [isConfigChangeRequired, setIsConfigChangeRequired] = useState<boolean>(false);
    const [isUpgradeButtonEnabled, setIsUpgradeButtonEnabled] = useState<boolean>(true);

    const upgrade = async () => {
        setIsUpgradeButtonEnabled(false);
        try {
            await form.validateFields(['packageId', 'configId']);
            await onUpgrade(form.getFieldValue('packageId'), form.getFieldValue('configId') || undefined);
        } catch (error) {
            setIsUpgradeButtonEnabled(true);
        }
    };

    const onVersionSelect = (packageId: string) => {
        const packageItem = data?.find((pkg) => pkg.id === packageId);
        const packageVersion = packageItem?.version;

        try {
            if (
                packageVersion &&
                semverMajor(packageVersion, false) >
                    semverMajor(collectorToUpgrade.edges.currentPackageVersion?.version, false)
            ) {
                setIsConfigChangeRequired(true);
            } else {
                setIsConfigChangeRequired(false);
            }
        } catch (e) {
            setIsConfigChangeRequired(false);
        }

        return setSelectedPackage(packageItem);
    };

    useEffect(() => {
        if (data?.length === 0) {
            setIsUpgradeButtonEnabled(false);
        }
    }, [data?.length]);

    return (
        <UiModal
            isFormModal
            isVisible
            onCancel={() => onClose()}
            title="Upgrade Node"
            acceptButton={{ text: 'Upgrade', onClick: upgrade, disabled: !isUpgradeButtonEnabled }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            width={700}
        >
            {data === undefined || (data && data.length > 0) ? (
                <div className="UpgradeCollectorModal">
                    <Row gutter={[32, 32]}>
                        <Col>
                            <Row>
                                <span className="ucm-label">name</span>
                            </Row>
                            <Row>
                                <span>{collectorToUpgrade.name}</span>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <span className="ucm-label">current version</span>
                            </Row>
                            <Row>
                                <span>{collectorToUpgrade.edges?.currentPackageVersion?.version}</span>
                            </Row>
                        </Col>
                    </Row>
                    <Form form={form} layout="vertical" requiredMark={false}>
                        <Row>
                            <Form.Item
                                label="upgrade node to version"
                                name="packageId"
                                rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                            >
                                <Select className="ucm-version-select" onChange={onVersionSelect}>
                                    {data &&
                                        data.map((pkg) => (
                                            <Option value={pkg.id}>
                                                <span>{pkg.version}</span>
                                                {semverMajor(pkg.version, false) >
                                                    semverMajor(
                                                        collectorToUpgrade.edges.currentPackageVersion?.version,
                                                        false
                                                    ) && (
                                                    <UiTooltip
                                                        disableCopyButton
                                                        title="This upgrade requires changes to the configuration"
                                                    >
                                                        <ExclamationCircleOutlined />
                                                    </UiTooltip>
                                                )}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Row>
                        {selectedPackage && (
                            <div className="ucm-section">
                                {isConfigChangeRequired && (
                                    <>
                                        <Row className="ucm-warning">
                                            <ExclamationCircleOutlined />
                                            <span>This upgrade requires changes to the configuration file.</span>
                                        </Row>
                                        <Row>
                                            <Form.Item
                                                label="configuration"
                                                name="configId"
                                                rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                                            >
                                                <Select
                                                    className="ucm-config-select"
                                                    options={collectorConfigOptions}
                                                />
                                            </Form.Item>
                                        </Row>
                                    </>
                                )}
                                <Row>
                                    <span className="ucm-label">version changelog</span>
                                </Row>
                                <Row className="changelog-md-fixer">
                                    <Markdown>
                                        {decodeUtf8Base64String(
                                            selectedPackage.changeLog,
                                            'No Changelog / unable to parse Changelog'
                                        )}
                                    </Markdown>
                                </Row>
                            </div>
                        )}
                    </Form>
                </div>
            ) : (
                <EmptyStateList text="There is no upgrade available yet" img={<NoDataSourceImage />} />
            )}
        </UiModal>
    );
};

import { investigateInNewTab } from 'general/utils';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import moment from 'moment/moment';

export const callsTableColumns = (openModal: Function) => [
    { field: 'callId', hide: true },
    {
        headerName: 'Time',
        field: 'timestamp',
        type: 'basic',
        minWidth: 80,
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            displaySingleLine: true,
        },
    },
    {
        headerName: 'Entity ID',
        field: 'entityId',
        type: 'basic',
        cellRenderer: 'cellRenderDetokenizable',
    },
    {
        headerName: 'Endpoint',
        field: 'endpoint',
        type: 'basic',
        cellRenderer: 'endpointQueryRender',
    },
    {
        headerName: 'Path',
        field: 'call_path',
        type: 'basic',
        flex: 1,
        minWidth: 200,
        cellRenderer: 'cellRenderDetokenizable',
    },
    {
        headerName: 'status Code',
        field: 'status_code',
        type: 'basic',
        minWidth: 80,
    },
    {
        headerName: 'Source IP',
        field: 'caller_ip',
        type: 'basic',
        minWidth: 80,
    },
    {
        headerName: 'Labels',
        field: 'labels',
        resizable: true,
        minWidth: 200,
        suppressMenu: true,
        suppressMovable: true,
        cellRenderer: 'labelListRender',
    },
    {
        headerName: 'Content',
        field: 'content',
        resizable: true,
        minWidth: 120,
        suppressMenu: true,
        suppressMovable: true,
        cellRenderer: 'callContentRender',
    },
    {
        headerName: '',
        field: 'callDetails',
        width: 60,
        cellRenderer: 'cellRenderInfo',
        cellRendererParams: {
            openModalCb: openModal,
        },
        suppressMenu: true,
        suppressMovable: true,
    },
    {
        headerName: '',
        field: 'more',
        width: 60,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: (params: any) => {
            const menuItems =
                params.data?.entities
                    ?.filter((entity: { name: string; class: string; family: string; value: string }) => entity.value)
                    .map((entity: { name: string; class: string; family: string; value: string }) => ({
                        label: `Investigate ${entity.name}`,
                        onClick: () => {
                            investigateInNewTab(
                                process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                                params.data.currOrg,
                                EventTypesEnum.Call,
                                params.data.id,
                                entity.name,
                                entity.value,
                                moment(params.data.timestamp).valueOf()
                            );
                        },
                    })) || [];

            return { menuItems };
        },
        suppressMenu: true,
        suppressMovable: true,
    },
];

import moment from 'moment';
import { dateStringFormat } from 'general/utils';
import { latestTransactionStatus } from 'api/tenantListApi';

const errorText =
    'An error occurred while applying changes to this tenant. Data may not appear as expected for up to a day. Our support team has been notified and is working on a solution.';
const pendingText =
    'Ongoing changes are in progress for this tenant, data may not appear as expected until the activation is finished.';

const STATUS_READABLE_TEXT = {
    PENDING: pendingText,
    FAILED: errorText,
    TIMED_OUT: errorText,
} as const;

const formatUsageTimestamp = (timestamp: string) => {
    return `${dateStringFormat(timestamp)}${moment(timestamp).format(' HH:mm')}`;
};

export const tenantStatusConfiguration = (
    status?: latestTransactionStatus | null,
    ownerOrganizationTotalUsagePercent?: number,
    ownerOrganizationTotalUsageUpdatedAt?: string
) => {
    let messages: string[] = [];
    let iconType = 'alertWarning';

    if (ownerOrganizationTotalUsagePercent && ownerOrganizationTotalUsageUpdatedAt) {
        const formattedDate = formatUsageTimestamp(ownerOrganizationTotalUsageUpdatedAt);

        switch (true) {
            case ownerOrganizationTotalUsagePercent >= 80 && ownerOrganizationTotalUsagePercent < 100:
                messages.push(`You reached 80% of your account's API Security commitment on ${formattedDate}.`);
                break;
            case ownerOrganizationTotalUsagePercent >= 100 && ownerOrganizationTotalUsagePercent < 180:
                messages.push(
                    `You reached 100% of your account's API Security commitment on ${formattedDate}, and will be billed for overages if overage protection is not in place.`
                );
                break;
            case ownerOrganizationTotalUsagePercent >= 180 && ownerOrganizationTotalUsagePercent < 200:
                messages.push(
                    `You reached 180% of your account's API Security commitment on ${formattedDate} - API Security will stop processing traffic above 200%.`
                );
                break;
            case ownerOrganizationTotalUsagePercent >= 200:
                messages.push(
                    `API Security stopped processing new API activity data on ${formattedDate} since you reached 200% of your account's monthly API activity commitment.`
                );
                iconType = 'alertError';
                break;
            default:
                break;
        }
    }

    if (status && status !== 'SUCCEED') {
        messages.push(STATUS_READABLE_TEXT[status]);
    }

    return { messages, iconType };
};

import React, { ReactNode } from 'react';
import Editor from '@monaco-editor/react';

import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { IScan } from 'api/apiTestingApi';

import './AuthConfigModal.scss';

interface IAuthConfigModalProps {
    scan?: IScan;
    onClose: () => void;
    getCopyToClipboardIcon: (copyValue?: string) => ReactNode;
}

export const AuthConfigModal = ({ scan, onClose, getCopyToClipboardIcon }: IAuthConfigModalProps) => {
    const { auth_config_data, auth_config_name } = scan || {};

    return (
        <UiModal
            className="AuthConfigModal"
            isFormModal
            width={1000}
            title={`${auth_config_name} | Auth Config`}
            acceptButton={{ text: 'Ok', onClick: onClose }}
            onCancel={onClose}
        >
            <div className="copy-to-clipboard-icon">{getCopyToClipboardIcon(auth_config_data)}</div>
            <Editor
                className="file-content"
                height="400px"
                width="97%"
                value={auth_config_data}
                language="json"
                options={{
                    readOnly: true,
                    automaticLayout: true,
                    scrollBeyondLastLine: false,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </UiModal>
    );
};

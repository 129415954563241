import React, { ReactNode } from 'react';

import { ReactComponent as ArrowImage } from 'features/settings/shared/assets/upward_arrow.svg';

import 'sharedComponents/EmptyStateList/EmptyStateList.scss';

interface IEmptyListProps {
    text: ReactNode;
    img: ReactNode;
    isBackground?: boolean;
}

export const EmptyStateList = ({ text, img, isBackground }: IEmptyListProps) => (
    <div className={`EmptyStateList ${isBackground ? 'blue-background' : ''}`}>
        <div className="message">
            {img}
            <span className="text">{text}</span>
        </div>
        <ArrowImage className="arrow-image" />
    </div>
);

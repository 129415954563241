import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import xs, { Listener, Producer, Subscription } from 'xstream';

let authListener: Listener<boolean> = {} as Listener<boolean>;
const authProducer: Producer<boolean> = {
    start: (listener) => {
        console.log('Starting Auth service producer');
        authListener = listener;
    },
    stop: () => console.log('Stopping Auth service producer'),
};
const authStream = xs.create(authProducer);

export type AuthServiceSubscription = Subscription;

export const subscribe = (next: (state: boolean) => any, error: (error: any) => any): AuthServiceSubscription => {
    return authStream.subscribe({ next, error });
};

export const postAuth = (state: boolean) => {
    authListener.next(state);
};

export const isAuthenticated = () => {
    return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            return true;
        })
        .catch((error) => {
            console.log('error isAuthenticated:', error);
            postAuth(false);
            return false;
        });
};

export const getJWTToken = () => {
    return isAuthenticated().then((res) => {
        return Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                if (user) {
                    const getSignInUserSession = user.getSignInUserSession();
                    return getSignInUserSession && getSignInUserSession.getAccessToken().getJwtToken();
                }
                return '';
            })
            .catch((err) => {
                console.log('could not get JWT', err);
            });
    });
};

export const signOut = () => {
    return Auth.signOut();
};

export const getActiveOrg = () => {
    const activeOrgFromStorage = localStorage.getItem('tenantKey') || '';
    return activeOrgFromStorage !== '' ? JSON.parse(activeOrgFromStorage) : null;
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import './404.scss';

export const FourOFour = () => {
    let history = useHistory();
    return (
        <div className="four-o-four-container">
            <div className="content">
                <div className="title">404</div>
                <div className="copy">The page you requested does not exist</div>
                <button type="button" onClick={() => history.goBack()}>
                    Go back
                </button>
            </div>
        </div>
    );
};

import humanizeDuration from 'humanize-duration';

import { IScan } from 'api/apiTestingApi';
import { ValueWithIcon, ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './ScanDetailsSummary.scss';

interface IScanDetailsSummaryProps {
    scan?: IScan;
}

export const ScanDetailsSummary = ({ scan }: IScanDetailsSummaryProps) => {
    const {
        status,
        triggered_by,
        scan_engine,
        target_url,
        requests_count,
        duration,
        findings_medium_count,
        findings_low_count,
        findings_high_count,
        findings_info_count,
    } = scan || {};

    const totalFindings =
        (findings_medium_count || 0) +
        (findings_low_count || 0) +
        (findings_high_count || 0) +
        (findings_info_count || 0);

    const statusWithIcon = () => {
        if (!status) {
            return null;
        }
        return (
            <div className="scan-status-cell">
                <UiIcon name={status.toLowerCase()} className={status.toLowerCase()} />
                <span>{status}</span>
            </div>
        );
    };

    return (
        <div className="ScanDetailsSummary">
            <ValueWithLabel label="status" value={statusWithIcon()} />
            <ValueWithLabel label="findings" value={totalFindings} />
            <ValueWithLabel label="high" value={<ValueWithIcon value={findings_high_count} iconName="alertHigh" />} />
            <ValueWithLabel
                label="medium"
                value={<ValueWithIcon value={findings_medium_count} iconName="alertMedium" />}
            />
            <ValueWithLabel
                label="low"
                value={<ValueWithIcon value={findings_low_count} iconName="alertLowOpaque" />}
            />
            <ValueWithLabel label="info" value={<ValueWithIcon value={findings_info_count} iconName="alertInfo" />} />
            <ValueWithLabel label="requests" value={requests_count} />
            <ValueWithLabel label="duration" value={duration && humanizeDuration(duration)} />
            <ValueWithLabel label="target url" value={target_url} />
            <ValueWithLabel label="triggered by" value={triggered_by} />
            <ValueWithLabel label="scan engine" value={scan_engine} />
        </div>
    );
};

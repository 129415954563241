import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { UiIcon } from '../icon/UiIcon';
import '../UiDropDown/UiDropDown.scss';
import { UiTitle } from '../UiTitle/UiTitle';

import './UiAutoComplete.scss';

const { Option, OptGroup } = Select;

interface IAutocompleteOption {
    label: any;
    title: string;
    value?: any;
    hidden?: boolean;
    loading?: boolean;
    key: string;
    endpointPath?: string;
    options?: { label: any; value: any; key: string }[];
}

interface IProps {
    width: number | string;
    height?: number | string;
    onOptionSelect?: Function;
    options: any;
    loading?: boolean;
    className?: string;
    defaultValue?: any;
    placeholder?: string;
    value?: any;
    mode?: 'multiple' | 'tags';
    onChange?: (e: any) => any;
    dropDownOpen?: Function;
    title?: { label: string | JSX.Element; value: string };
    errorMessage?: string | null;
    isAlwaysOpen?: boolean;
    autoFocus?: boolean;
    enableInputFreeText?: boolean;
}

export const getParsedItemValue = (itemValue?: number | string): number | string => {
    if (typeof itemValue === 'number') {
        return itemValue || 0;
    }
    return itemValue || '';
};

export const UiAutoComplete = (props: IProps) => {
    const [newOptions, setNewOptions] = useState<IAutocompleteOption[]>([]);
    const [endpointSearchVal, setEndpointSearchVal] = useState<string>('');
    const [selectState, setSelectState] = useState<boolean>(props.isAlwaysOpen || false);
    const dropDownRef = useRef(null);

    useEffect(() => {
        if (props.isAlwaysOpen !== undefined) {
            setSelectState(props.isAlwaysOpen);
        }
    }, [props.isAlwaysOpen]);

    useEffect(() => {
        let tempOptions: any = [];
        props.options.forEach((item: any, index: number) => {
            const newItem = { ...item };

            if (item?.options?.length) {
                const filteredOptions = item.options.filter((option: any) => {
                    const searchStr = option.label?.props?.apiName || option.label?.props?.children || option.label;
                    if (!endpointSearchVal) {
                        return true;
                    } else if (!searchStr || typeof searchStr !== 'string') {
                        return false;
                    } else {
                        const filtered: boolean = searchStr?.toLowerCase()?.includes(endpointSearchVal.toLowerCase());
                        return filtered;
                    }
                });
                if (filteredOptions.length > 0) {
                    newItem.minimized = false;
                    newItem.options = filteredOptions;

                    tempOptions.push(newItem);
                }
            } else {
                tempOptions = props.options.map((option: any, index: number) => {
                    return { ...option, value: index.toString() };
                });
                tempOptions = tempOptions.filter((option: any) => {
                    if (!endpointSearchVal) {
                        return true;
                    } else {
                        const filtered: boolean = option.label.toLowerCase().includes(endpointSearchVal.toLowerCase());
                        return filtered;
                    }
                });
            }
        });
        setNewOptions(tempOptions);
    }, [props.options, endpointSearchVal]);

    const toggleGroup = (index: number) => {
        const temp = [...newOptions];
        temp[index].hidden = !temp[index].hidden;
        setNewOptions(temp);
    };

    const getOptionsGroupHeader = (optionsHeader: IAutocompleteOption, index: number) => (
        <div className="custom-group" onClick={() => toggleGroup(index)}>
            <UiTitle title={optionsHeader.title || 'newLabel'} type="medium" />
            <UiIcon name={optionsHeader.hidden ? 'downArrowSmall' : 'upArrowSmall'} />
        </div>
    );

    const getOptionsList = (item: IAutocompleteOption) =>
        !item.hidden && item.options && item.options.map((subItem: any) => getSingleOption(subItem));

    const getSingleOption = (item: IProps['title'] | IAutocompleteOption) => {
        const itemValue = getParsedItemValue(item?.value);
        return (
            <Option key={itemValue} value={itemValue}>
                {item?.label}
            </Option>
        );
    };

    const getItems = (): JSX.Element[] => {
        let items = props.title
            ? [getSingleOption({ value: props.title.value, label: props.title.label })]
            : ([] as JSX.Element[]);

        items = items.concat(
            newOptions.map((item, index) => {
                if (item.options) {
                    return (
                        <OptGroup key={`${item.title}-${index}`} label={getOptionsGroupHeader(item, index)}>
                            {getOptionsList(item)}
                        </OptGroup>
                    );
                } else {
                    return getSingleOption(item);
                }
            })
        );

        if (props.loading) {
            items = items.concat([
                <Option disabled key="Loading" value="Loading">
                    Loading...
                </Option>,
            ]);
        }

        return items;
    };

    const onDropdownChange = (dropDownOpen: boolean) => {
        const selectedEndpointTag = (dropDownRef?.current as any).querySelector('.api-tag');
        if (selectedEndpointTag) {
            dropDownOpen ? (selectedEndpointTag.style.opacity = '0.5') : (selectedEndpointTag.style.opacity = '1');
        }
        if (props.isAlwaysOpen === undefined) {
            setSelectState(dropDownOpen);
        }
    };

    const onChangeHandler = (e: any) => {
        setEndpointSearchVal(e);
    };

    const onSelectHandler = (key: any) => {
        setEndpointSearchVal('');
        if (props.onOptionSelect) {
            props.onOptionSelect(key);
        }
    };

    const onChange = (e: any) => {
        setEndpointSearchVal('');
        if (props.onChange) {
            props.onChange(e);
        }
    };

    const handleOnKeyDown = (e: any) => {
        if (props.onOptionSelect && props.enableInputFreeText && e.key === 'Enter') {
            props.onOptionSelect(endpointSearchVal);
        }
    };

    return (
        <div ref={dropDownRef} style={{ position: 'relative' }}>
            <Select
                mode={props.mode}
                showSearch
                value={props.value}
                filterOption={false}
                popupClassName={props.className}
                loading={props.loading}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder || 'Type or select a Value'}
                style={{ width: props.width, height: props.height }}
                suffixIcon={
                    <UiIcon
                        name="treeOpen"
                        className={selectState ? 'arrow-icon expanded-icon' : 'arrow-icon'}
                        onClick={() => setSelectState(!selectState)}
                    />
                }
                onDropdownVisibleChange={(open) => onDropdownChange(open)}
                dropdownMatchSelectWidth={366}
                onSelect={(key) => {
                    onSelectHandler(key);
                }}
                onChange={onChange}
                onSearch={onChangeHandler}
                open={selectState}
                autoFocus={props.autoFocus as boolean}
                onInputKeyDown={handleOnKeyDown}
            >
                {getItems()}
            </Select>
            {props.errorMessage ? (
                <div style={{ position: 'absolute', right: 0, color: 'red' }}>
                    <p>{props.errorMessage}</p>
                </div>
            ) : null}
        </div>
    );
};

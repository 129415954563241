import { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useSelector } from 'react-redux';

import { formFieldRequired, isRegexValidator } from 'general/forms';
import {
    ExclusionRuleOperator,
    ExclusionRuleOption,
    IExclusionRuleForm,
    selectExclusionRuleAttributeOptions,
} from 'api/exclusionRuleApi';
import { UiAutoComplete } from 'sharedComponents/UiAutoComplete/UiAutoComplete';
import { WizardModeOptions } from 'sharedComponents/exclusionRuleOrDeleteEndpointsWizard/ExclusionRuleOrDeleteEndpointsWizard';

import './ExclusionRuleOrDeleteEpForm.scss';

export interface IExclusionRuleOrDeleteEpFormProps {
    form: FormInstance;
    editedRuleOrRequest?: IExclusionRuleForm;
    mode: WizardModeOptions;
}

const HTTP_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'CONNECT', 'TRACE'];

const regexHintList = {
    [ExclusionRuleOption.ATTRIBUTE]: 'E.g. .*text.*',
    [ExclusionRuleOption.PATH]: 'E.g. *i-[a-z0-9]*\\..*api.acme.com',
};

export const formFieldKeys = [
    'operator',
    'filter_option',
    'regex',
    'description',
    'method',
    'service_pattern',
    'attribute',
];

export default function ExclusionRuleOrDeleteEpForm({
    form,
    editedRuleOrRequest,
    mode,
}: IExclusionRuleOrDeleteEpFormProps) {
    const [regexHint, setRegexHint] = useState<string>(regexHintList[ExclusionRuleOption.PATH]);
    const [showAttributeDropdown, setShowAttributeDropdown] = useState<boolean>(
        form.getFieldValue('filter_option') === 'attribute'
    );
    const [isMethodSelectVisible, setIsMethodSelectVisible] = useState<boolean>(false);
    const [isServiceRegexVisible, setIsServiceRegexVisible] = useState<boolean>(false);
    const attributeOptions = useSelector(selectExclusionRuleAttributeOptions);

    useEffect(() => {
        if (editedRuleOrRequest) {
            const isAttributeValue = !!(editedRuleOrRequest?.filter_option === 'attribute');
            setShowAttributeDropdown(isAttributeValue);
            const attributeValues = isAttributeValue && {
                attribute: editedRuleOrRequest?.attribute_name,
            };
            setIsMethodSelectVisible(!!editedRuleOrRequest?.method && editedRuleOrRequest?.method.length > 0);
            setIsServiceRegexVisible(!!editedRuleOrRequest.service_pattern);
            form.setFieldsValue({ ...editedRuleOrRequest, ...attributeValues });
        }
    }, [editedRuleOrRequest]);

    function toggleMethodSelect(e: any) {
        form.setFieldValue('method', []);
        setIsMethodSelectVisible(e.target.checked);
    }

    function toggleServiceRegex(e: any) {
        if (!e.target.checked) {
            form.setFieldValue('service_pattern', undefined);
        }
        setIsServiceRegexVisible(e.target.checked);
    }

    function handleFilterOptionChange(event: RadioChangeEvent) {
        const isAttributeSelected = event.target?.value === 'attribute';
        setRegexHint(regexHintList[event.target?.value as IExclusionRuleForm['filter_option']]);
        setShowAttributeDropdown(isAttributeSelected);
        !isAttributeSelected && form.resetFields(['attribute']);
    }

    return (
        <div className="ExclusionRuleOrDeleteEpForm">
            <Form form={form} colon={false} requiredMark={false} layout="vertical" initialValues={editedRuleOrRequest}>
                <div className="form-radios">
                    <Form.Item
                        name="operator"
                        label={
                            mode === WizardModeOptions.ExclusionRule
                                ? 'Do not learn endpoints from calls that:'
                                : 'Delete endpoints that:'
                        }
                    >
                        <Radio.Group>
                            <Radio value={ExclusionRuleOperator.MATCH}>Match</Radio>
                            <Radio value={ExclusionRuleOperator.NOTMATCH}>Don't Match</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="filter_option">
                        <Radio.Group onChange={handleFilterOptionChange}>
                            <Radio value={ExclusionRuleOption.PATH}>Path</Radio>
                            <Radio value={ExclusionRuleOption.ATTRIBUTE}>Attribute</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {showAttributeDropdown && (
                    <Form.Item
                        name="attribute"
                        label="attribute"
                        rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                    >
                        <UiAutoComplete
                            options={attributeOptions || []}
                            placeholder="Type or select parameter key"
                            width={300}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label={`${showAttributeDropdown ? 'value' : 'path'} (regex)`}
                    name="regex"
                    rules={[
                        { required: true, message: formFieldRequired.errorMessage },
                        {
                            validator: (_, value) => isRegexValidator(value),
                            message: 'Invalid regex',
                        },
                        {
                            pattern: /^[\w\W]{1,2048}$/,
                            message: 'Regex can be up to 2048 characters long',
                        },
                    ]}
                >
                    <Input placeholder={regexHint} />
                </Form.Item>
                {mode !== WizardModeOptions.DeleteEpService && (
                    <div>
                        <Checkbox checked={isServiceRegexVisible} onChange={toggleServiceRegex}>
                            Service (Regex)
                        </Checkbox>
                    </div>
                )}
                {mode !== WizardModeOptions.DeleteEpService && isServiceRegexVisible && (
                    <Form.Item
                        name="service_pattern"
                        rules={[
                            { required: true, message: formFieldRequired.errorMessage },
                            {
                                validator: (_, value) => isRegexValidator(value),
                                message: 'Invalid regex',
                            },
                            {
                                pattern: /^[\w\W]{1,2048}$/,
                                message: 'Regex can be up to 2048 characters long',
                            },
                        ]}
                    >
                        <Input placeholder={regexHint} />
                    </Form.Item>
                )}
                <Checkbox checked={isMethodSelectVisible} onChange={toggleMethodSelect}>
                    Method
                </Checkbox>
                {isMethodSelectVisible && (
                    <Form.Item name="method" rules={[{ required: true, message: formFieldRequired.errorMessage }]}>
                        <Select
                            mode="multiple"
                            options={HTTP_METHODS.map((method) => ({ label: method, value: method }))}
                        />
                    </Form.Item>
                )}
                {mode === WizardModeOptions.ExclusionRule && (
                    <Form.Item
                        label="rule Description"
                        name="description"
                        className="exclusion-rule-description"
                        rules={[
                            {
                                pattern: /^[\w\W]{0,256}$/,
                                message: 'Description can be up to 256 characters long',
                            },
                        ]}
                    >
                        <Input placeholder="Enter your description" />
                    </Form.Item>
                )}
            </Form>
        </div>
    );
}

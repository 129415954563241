import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';

import { useCreateApplicationMutation, useUpdateApplicationMutation } from 'api/apiTestingApi';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { formFieldRequired, nameRule } from 'general/forms';
import { extractErrorMessage } from 'general/utils';
import { errorMessage } from 'general/toast-service';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';

import './AddEditApplicationModal.scss';

export interface IAddEditApplicationModalProps {
    onCreateUpdate: () => void;
    onClose: () => void;
    name?: string;
}

export const AddEditApplicationModal = ({ onCreateUpdate, onClose, name }: IAddEditApplicationModalProps) => {
    const [form] = Form.useForm();

    const { appId } = useParams<IApitRouteParams>();
    const [isFormValid, setIsFormValid] = useState(true);

    const [createApplication, { isLoading: isCreateInProgress }] = useCreateApplicationMutation();
    const [updateApplication, { isLoading: isUpdateInProgress }] = useUpdateApplicationMutation();

    useEffect(() => {
        form.setFieldValue('name', name ?? '');
    }, [name]);

    const title = useMemo(() => `${appId ? 'Edit' : 'New'} Application`, [appId]);

    const handleFormFieldsChange = useCallback(() => {
        setIsFormValid(!form.getFieldsError().some((field) => field.errors.length));
    }, [form]);

    const saveButton = useMemo(
        () => ({
            text: appId ? 'Save' : 'Create',
            onClick: async () => {
                try {
                    try {
                        await form.validateFields();
                    } catch {
                        return;
                    }

                    if (appId) {
                        await updateApplication({
                            id: Number.parseFloat(appId),
                            name: form.getFieldValue('name'),
                        }).unwrap();
                    } else {
                        await createApplication({ name: form.getFieldValue('name') }).unwrap();
                    }
                    form.resetFields();
                    onCreateUpdate();
                } catch (e) {
                    errorMessage(extractErrorMessage(e));
                }
            },
            disabled: !isFormValid || isCreateInProgress || isUpdateInProgress,
        }),
        [onCreateUpdate, appId, isFormValid, isCreateInProgress, isUpdateInProgress]
    );

    const cancelButton = useMemo(
        () => ({
            text: 'Cancel',
            onClick: () => {
                form.resetFields();
                onClose();
            },
        }),
        [onClose]
    );

    return (
        <UiModal
            className="AddEditApplicationModal"
            title={title}
            isFormModal
            width={700}
            onCancel={onClose}
            acceptButton={saveButton}
            rejectButton={cancelButton}
        >
            <Form form={form} layout="vertical" requiredMark={false} onFieldsChange={handleFormFieldsChange}>
                <Form.Item
                    label="NAME"
                    name="name"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }, nameRule]}
                >
                    <UiInput placeholder="Enter your application name" maxLength={128} />
                </Form.Item>
            </Form>
        </UiModal>
    );
};

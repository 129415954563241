import React, { CSSProperties } from 'react';
import './Button.scss';
import { Button } from 'antd';
import { UiIcon } from '../icon/UiIcon';
import { ButtonProps } from 'antd/lib/button';

interface IButtonProps extends Omit<ButtonProps, 'type' | 'onClick' | 'icon'> {
    type: 'primary' | 'secondary' | 'text' | any;
    text: string;
    disabled?: boolean;
    onClick?: Function;
    icon?: string;
    className?: string;
    fullWidth?: boolean;
    style?: CSSProperties;
}

export const UiButton = (props: IButtonProps) => {
    const { disabled, className, type, fullWidth, style, onClick, icon, text, ...rest } = props;

    const onClickHandler = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button
            disabled={disabled}
            className={className}
            type={type}
            block={fullWidth}
            style={style}
            onClick={onClickHandler}
            icon={icon && <UiIcon name={icon} />}
            {...rest}
        >
            {text}
        </Button>
    );
};

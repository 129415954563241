import { createSelector } from '@reduxjs/toolkit';

import { serverApi } from 'api/baseServerApi';
import { calcTenantKeyFromRouteOrStorage } from 'api/slices/appInfoSlice';

export enum latestTransactionStatus {
    SUCCEED = 'SUCCEED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    TIMED_OUT = 'TIMED_OUT',
}

export interface ITenant {
    id: string;
    name: string;
    key: string;
    latest_transaction_status?: latestTransactionStatus | null;
    is_on_ch_cluster?: boolean;
    cluster_name?: string;
    owner_organization_commitment?: number;
    owner_organization_current_month_usage?: number;
    owner_organization_total_usage_percent?: number;
    owner_organization_total_usage_updated_at?: string;
}

export interface ITenantResponse {
    count: number;
    items: ITenant[];
    total: number;
}

export const tenantListApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getTenantsList: builder.query<Record<string, ITenant>, void>({
            query: () => 'organizations',
            transformResponse: (responseData: ITenantResponse, meta) => {
                const tenantListFormatted = responseData.items.reduce((acc, tenant) => {
                    acc[tenant.key] = tenant;
                    return acc;
                }, {} as Record<string, ITenant>);
                return tenantListFormatted;
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
                // After get tenant list request fulfilled, get tenant key from route, localStorage or first from tenant list
                cacheDataLoaded.then(() => {
                    dispatch(calcTenantKeyFromRouteOrStorage());
                });
            },
        }),
    }),
    overrideExisting: false,
});

/// -------------------------- Actions ---------------------------------------

export const { useGetTenantsListQuery } = tenantListApi;
/// ------------------------------------------------------------------------------

/// -------------------------- Selectors ---------------------------------------
export const selectGetTenantListResult = tenantListApi.endpoints.getTenantsList.select();
export const selectTenantList = createSelector(selectGetTenantListResult, ({ data }) => data);

/// ------------------------------------------------------------------------------

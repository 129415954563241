import { AlertSeveritySelect } from 'features/settings/components/AutomatedActionRuleAction/AlertSeveritySelect/AlertSeveritySelect';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { UiButton } from 'sharedComponents/button/Button';
import { successMessage } from 'general/toast-service';
import { WebhookInput } from './webhook-input/WebhookInput';
import { JiraTicketInput } from './JiraTicketInput/JiraTicketInput';
import { KongIntegrationAction } from './KongIntegrationAction';
import { F5IntegrationAction } from './F5IntegrationAction/F5IntegrationAction';

export enum ActionType {
    ChangeSeverity = 'change_severity',
    Label = 'label',
    Email = 'email',
    Webhook = 'webhook',
    JiraTicket = 'jira_ticket',
    KongIntegration = 'kong_integration',
    F5Integration = 'f5_integration',
}

interface IAutomatedActionRuleActionProps {
    actionType: ActionType;
    value: any;
    onChange: (event: IAutomatedActionRuleActionProps['value']) => any;
}

export const AutomatedActionRuleAction = (props: IAutomatedActionRuleActionProps) => {
    let action: JSX.Element | null;

    switch (props.actionType) {
        case ActionType.ChangeSeverity:
            action = (
                <AlertSeveritySelect
                    label="Change alert severity"
                    value={props.value}
                    width={500}
                    onChange={(event) => props.onChange(event)}
                />
            );
            break;
        case ActionType.Label:
            action = (
                <div style={{ position: 'relative', marginTop: '-5px' }}>
                    <div className="control-label" style={{ marginBottom: '8px' }}>
                        Add Label
                    </div>
                    <UiInput
                        defaultValue={props.value}
                        width={500}
                        onBlur={(event: any) => props.onChange(event.target.value)}
                    />
                </div>
            );
            break;
        case ActionType.Email:
            action = (
                <div style={{ position: 'relative', marginTop: '-5px' }}>
                    <div className="control-label" style={{ marginBottom: '8px' }}>
                        Send Email
                    </div>
                    <div style={{ display: 'flex' }}>
                        <UiInput
                            label="To:"
                            defaultValue={props.value}
                            width={500}
                            onBlur={(event: any) => props.onChange(event.target.value)}
                        />
                        <UiButton
                            type="primary"
                            text="Test"
                            style={{ marginLeft: '15px' }}
                            onClick={() => {
                                successMessage(`Test email was sent`);
                            }}
                        />
                    </div>
                </div>
            );
            break;
        case ActionType.Webhook:
            action = <WebhookInput webhook={props.value} onChange={props.onChange} />;
            break;
        case ActionType.JiraTicket:
            action = <JiraTicketInput ticket={props.value} onChange={props.onChange} />;
            break;
        case ActionType.KongIntegration:
            action = <KongIntegrationAction config={props.value} onChange={props.onChange} />;
            break;
        case ActionType.F5Integration:
            action = <F5IntegrationAction config={props.value} onChange={props.onChange} />;
            break;
        default:
            action = null;
    }

    return action;
};

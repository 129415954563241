import { useCallback, useMemo, useState } from 'react';
import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import {
    CellRenderRangeSensitiveMore,
    CellRenderScanStatus,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import CustomDateFilter from 'sharedComponents/ui-ag-grid/customDateFilter';
import { IServerQueryResponse } from 'api/baseServerApi';
import { IScan, useGetApplicationQuery, useGetSuitesQuery } from 'api/apiTestingApi';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import { scanTableCols } from 'features/apiTesting/scans/scanList/scanTableCols';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { DeleteScanConfirmModal } from 'features/apiTesting/scans/scanList/DeleteScanConfirmModal/DeleteScanConfirmModal';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { API_TESTING_PATH, IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { RunLocallyModal } from 'features/apiTesting/scans/scanList/RunLocallyModal/RunLocallyModal';
import { extractErrorMessage } from 'general/utils';

import './ScanList.scss';

export const ScanList = () => {
    const { appId } = useParams<IApitRouteParams>();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const history = useHistory();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [scanIdToRun, setScanIdToRun] = useState<IScan>();
    const [scanToDelete, setScanToDelete] = useState<IScan>();
    const { data: applicationData, refetch: refetchApplicationSummary } = useGetApplicationQuery({ appId });
    const { data: suitesResponse } = useGetSuitesQuery();

    const onGridReady = useCallback((params: { api: GridApi; columnApi: ColumnApi }) => {
        !gridApi && setGridApi(params.api);
    }, []);

    const getData = useCallback(
        async (
            start: number,
            end: number,
            sortParams?: string
        ): Promise<AxiosResponse<IServerQueryResponse<IScan>>> => {
            const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(id)';
            return httpPOST(`organizations/${currentTenantKey}/testing/applications/${appId}/scans/query`, {
                undefined,
                sort_by,
                offset: start,
                limit: 100,
            }).catch((error) => {
                return errorMessage(extractErrorMessage(error.response?.data?.detail));
            });
        },
        [currentTenantKey]
    );

    const scanTableMenu = ({ data }: { data: IScan }) => {
        const isFirstRun = data.status === 'Created';

        return {
            menuItems: [
                {
                    icon: <UiIcon name="viewDetails" />,
                    label: 'View Details',
                    onClick: () => {
                        history.push(
                            `/${currentTenantKey}/${API_TESTING_PATH}/applications/${appId}/scans/${data?.id}`
                        );
                    },
                },
                {
                    icon: <UiIcon name="clicker" />,
                    label: isFirstRun ? 'Run Locally' : 'Re-run',
                    onClick: () => {
                        setScanIdToRun(data);
                    },
                },
                {
                    icon: <UiIcon name="trash" />,
                    label: 'Delete',
                    tooltip: 'Will be available in the near future',
                    disabled: ['Running', 'Queued'].includes(data.status),
                    onClick: () => {
                        setScanToDelete(data);
                    },
                },
            ],
            id: data.id,
        };
    };

    const TableCol = useMemo(
        () => suitesResponse && scanTableCols(scanTableMenu, suitesResponse.items),
        [scanTableMenu, suitesResponse]
    );
    const closeDeleteModal = (refresh?: boolean) => {
        if (applicationData?.totalScans === 1) {
            refetchApplicationSummary();
        }
        refresh && gridApi?.refreshServerSide({});
        setScanToDelete(undefined);
    };

    return (
        <div className="ScanList">
            <UiAgGridSSRM
                isHideRowsCount
                columns={TableCol}
                refetchInterval={30000}
                options={{
                    columnTypes: {
                        basic: BASIC_AGGRID_COL_TYPE,
                        agSetFilter: agSetFilter,
                        agTextFilter: agTextFilter,
                    },
                    components: {
                        moreButtonRender: CellRenderRangeSensitiveMore,
                        cellRenderScanStatus: CellRenderScanStatus,
                        cellRenderTimeStamp: CellRenderTimeStamp,
                        cellRenderVerticalCenter: CellRenderVerticalCenter,
                        customDateFilter: CustomDateFilter,
                    },
                }}
                getData={getData}
                dataMappingFunction={(data: any) => data}
                onGridReady={onGridReady}
            />
            {scanIdToRun && <RunLocallyModal scan={scanIdToRun} onClose={() => setScanIdToRun(undefined)} />}
            {scanToDelete && <DeleteScanConfirmModal scan={scanToDelete} onClose={closeDeleteModal} />}
        </div>
    );
};

import React, { useCallback, useState, WheelEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Divider } from 'antd';
import { GridApi, RowNode, SelectionChangedEvent } from '@ag-grid-community/core';
import moment from 'moment';

import { INavigationLinks } from 'sharedComponents/breadcrumb/BreadCrumb';
import {
    ContentVisibilityModeEnum,
    MultiExpandableContainer,
} from 'sharedComponents/MultiExpandableContainer/MultiExpandableContainer';
import { ServiceSummary } from './ServiceSummary/ServiceSummary';
import { errorMessage, infoMessage } from 'general/toast-service';
import { extractErrorMessage, urlEncode } from 'general/utils';
import { IFilter } from 'interfaces/filter.interface';
import { LabelSuppressPeriod } from 'interfaces/labels.interface';
import Spinner from '../../../sharedComponents/spinner/Spinner';
import { EntityRequestCharts } from '../../user/entity-request-chart/EntityRequestCharts';
import { DiscoveryHeader } from '../DiscoveryHeader/DiscoveryHeader';
import { getTableData, QUERY_RESULTS_LIMIT } from '../shared/discoveryApisLegacy';
import { EndpointFilterableTable } from './EndpointFilterableTable/EndpointFilterableTable';
import { TableActions } from './TableActions/TableActions';
import { getService, useFetchServiceStats } from './useFetchServiceStats';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import {
    useCreateServiceLabelMutation,
    useDeleteServiceLabelMutation,
    useGetServicesPaginatedQuery,
    useSuppressServiceLabelMutation,
} from 'api/discoveryApi';
import { IEndpointDeletionRequest } from 'api/exclusionRuleApi';
import { useCreateEndpointDeletionByIdsRequestMutation } from 'api/endpointDeletionRequestApi';
import { useGetConfigQuery } from 'api/configApi';
import { ResourceNotFound } from 'features/discovery/ResourceNotFound/ResourceNotFound';
import { IEntityChartSeries, IEntityChartTotals } from 'interfaces/user.interface';

import './Service.scss';
import 'features/discovery/endpoint/Container.scss';

export const Service = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams: any = new URLSearchParams(location.search);
    const params = useParams() as { activeOrgParam: string; encodedServiceName: string };
    const encodedServiceName = params.encodedServiceName;
    const activeOrg = params.activeOrgParam;
    const serviceName = decodeURIComponent(params.encodedServiceName);
    const base64EncodedServiceName = urlEncode(decodeURIComponent(params.encodedServiceName));
    const { data: config } = useGetConfigQuery();
    const [createServiceLabel] = useCreateServiceLabelMutation();
    const [suppressServiceLabel] = useSuppressServiceLabelMutation();
    const [deleteServiceLabel] = useDeleteServiceLabelMutation();
    const [createEndpointDeletionByIdsRequest] = useCreateEndpointDeletionByIdsRequestMutation();
    const { data: isValidServiceName, isFetching: isFetchingServiceName } = useGetServicesPaginatedQuery({
        from_timestamp: config?.timeframe?.start_timestamp.toString(10) || '',
        to_timestamp: config?.timeframe?.end_timestamp.toString(10) || '',
        offset: 0,
        limit: 1,
        sort_by: 'desc(last_seen)',
        filters: [[{ name: 'name', operator: 'eq', value: serviceName }]],
    });

    const [endpointFilter, setEndpointFilter] = useState<IFilter[]>([]);
    const [endpointCheckboxFilters, setEndpointCheckboxFilters] = useState<IFilter[]>([]);
    const [timeRangeFromInput, setTimeRangeFromInput] = useState<[moment.Moment, moment.Moment]>([
        moment.unix(queryParams.get('from_timestamp')),
        moment.unix(queryParams.get('to_timestamp')),
    ]);
    const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<RowNode[]>();
    const [gridApi, setGetGridApi] = useState<GridApi>();

    const { serviceData, setServiceData, totalRequests, seriesRequests, setIsUpdateHiddenCount } = useFetchServiceStats(
        { timeRangeFromInput }
    );

    const onTableDataUpdate = useCallback(() => gridApi?.refreshServerSide({}), [gridApi]);

    const getData = useCallback(
        getTableData(
            activeOrg,
            QUERY_RESULTS_LIMIT,
            [...endpointCheckboxFilters, ...endpointFilter],
            timeRangeFromInput,
            base64EncodedServiceName
        ),
        [endpointCheckboxFilters, endpointFilter, timeRangeFromInput]
    );

    const timeRangeHandler = (range: TDatetimeRange) => {
        setTimeRangeFromInput(range);
    };

    const breadcrumbList: INavigationLinks[] = [
        {
            url: `/${activeOrg}/discovery/services`,
            text: 'Services',
        },
        {
            url: `/${activeOrg}/discovery/services/${encodedServiceName}/`,
            text: serviceName,
        },
    ];

    const handleWheelEvent = (event: WheelEvent<HTMLDivElement>) => {
        setIsHeaderExpanded(event.deltaY < 0);
    };

    const createLabel = async (label: string): Promise<any> => {
        try {
            await createServiceLabel({ serviceName, label });
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            if (error.response?.status === 422) {
                errorMessage('Invalid label text');
            } else {
                errorMessage(extractErrorMessage(error));
            }
            throw Error('Could not create label');
        }
    };

    const deleteLabel = async (label: string): Promise<any> => {
        try {
            await deleteServiceLabel({ serviceName, label });
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw Error('Could not delete label');
        }
    };

    const suppressLabel = async (label: string, suppress_by: LabelSuppressPeriod): Promise<any> => {
        try {
            await suppressServiceLabel({ serviceName, label, suppress_by });
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw Error('Could not suppress label');
        }
    };

    const deleteEndpoints = async (endpointDeleteRequest: IEndpointDeletionRequest) => {
        try {
            await createEndpointDeletionByIdsRequest(endpointDeleteRequest).unwrap();
            const endpointIdsCount = endpointDeleteRequest.endpoints_ids?.length || 0;
            const text =
                endpointIdsCount === 1
                    ? 'You have marked an endpoint for deletion'
                    : `You have marked ${endpointIdsCount} endpoints for deletion.`;
            infoMessage(text);
        } catch (e) {
            errorMessage(extractErrorMessage(e));
        }
    };

    if (isFetchingServiceName) {
        return <Spinner show />;
    }

    if (isValidServiceName && isValidServiceName?.items.length < 1) {
        return <ResourceNotFound breadcrumb={breadcrumbList} selectedRouteName={serviceName} />;
    }

    return (
        <div className="service-container container" onWheel={handleWheelEvent}>
            <DiscoveryHeader
                queryParams={queryParams}
                selectedServices={[serviceName]}
                timeRangeFromInput={timeRangeFromInput}
                onTimeChange={timeRangeHandler}
                pathParams={params}
                title={serviceName}
                isNewLabel={true}
                isLabelList={true}
                labelList={(serviceData?.service_labels || []).concat(serviceData?.endpoint_labels || [])}
                isEndpointSelect={true}
                breadcrumb={breadcrumbList}
                selectedRouteName={breadcrumbList[1].text}
                isSwagger={true}
                onLabelCreate={createLabel}
                onLabelDelete={deleteLabel}
                onLabelSuppress={suppressLabel}
                canDeleteLabel={(label) => !serviceData?.endpoint_labels.find((l) => l.label === label.label)}
                gridApi={gridApi}
            />
            {serviceData && totalRequests && seriesRequests ? (
                <MultiExpandableContainer
                    contentArray={[
                        {
                            content: <ServiceSummary service={serviceData} />,
                            mode: ContentVisibilityModeEnum.SHOWN_ALWAYS,
                        },
                        {
                            content: (
                                <Divider
                                    type="horizontal"
                                    className={`service-expandable-divider${isHeaderExpanded ? ' show' : ''}`}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                        {
                            content: (totalRequests || seriesRequests) && (
                                <EntityRequestCharts
                                    chartsData={{
                                        totals: totalRequests as IEntityChartTotals,
                                        series: seriesRequests as IEntityChartSeries,
                                    }}
                                    customHeight={isHeaderExpanded ? 341 : 0}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                    ]}
                    isExpanded={isHeaderExpanded}
                    toggleExpanded={() => setIsHeaderExpanded((prev) => !prev)}
                />
            ) : (
                <Spinner show />
            )}
            <EndpointFilterableTable
                tableActions={
                    <TableActions
                        setFilters={setEndpointCheckboxFilters}
                        selectedRows={selectedRows}
                        gridApi={gridApi}
                        serviceData={serviceData}
                        deleteEndpoints={deleteEndpoints}
                    />
                }
                onSelectionChanged={(e: SelectionChangedEvent) => setSelectedRows(e.api.getSelectedNodes())}
                setGetGridApi={setGetGridApi}
                gridApi={gridApi}
                getData={getData}
                activeOrg={activeOrg}
                onFilterChange={setEndpointFilter}
                base64EncodedServiceName={base64EncodedServiceName}
                timeRangeFromInput={timeRangeFromInput}
                onTableDataUpdate={onTableDataUpdate}
                setIsUpdateHiddenCount={setIsUpdateHiddenCount}
                newCustomTitle={(numRows: number) =>
                    `Endpoints (${numRows}${serviceData ? ` of ${serviceData?.total_endpoints_count})` : ')'}`
                }
                deleteEndpoints={deleteEndpoints}
            />
        </div>
    );
};

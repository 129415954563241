import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import { NavigationMenu } from './NavigationMenu/NavigationMenu';
import { UiIcon } from '../icon/UiIcon';
import { UiIconButton } from '../icon-button/UiIconButton';
import { TenantDrawer } from '../TenantDrawer/TenantDrawer';
import { UserBadge } from '../UserBadge/UserBadge';
import { Logo } from 'sharedComponents/logo/Logo';
import { tenantStatusConfiguration } from 'general/tenantStatusConfigurations';
import { TenantStatusIndicator } from 'sharedComponents/tenantStatusIndicator/TenantStatusIndicator';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';
import { selectCurrentTenantDetails, selectFeatureFlagMap } from 'api/slices/appInfoSlice';

import './NavBar.scss';

interface INavBarProps {
    showNavigation: boolean;
    signOut: Function;
}

export const NavBar = (props: INavBarProps) => {
    const location = useLocation();
    const featureFlagMap = useSelector(selectFeatureFlagMap);
    const currentTenant = useSelector(selectCurrentTenantDetails);
    const { data: user } = useGetUserSessionTokenPayloadQuery<{ data: { [key: string]: any } }>();
    const routeName = location.pathname.replace('/', '');
    const [current, setCurrent] = useState(routeName || 'dashboards');
    const [tenantDrawerVisible, setTenantDrawerVisible] = useState<boolean>(false);

    useEffect(() => {
        setCurrent(routeName);
    }, [routeName]);

    const handleClick = (e: any) => {
        setTenantDrawerVisible(false);
    };

    const onClose = useCallback(() => {
        setTenantDrawerVisible(false);
    }, []);

    function toggleDrawer() {
        setTenantDrawerVisible((prev) => !prev);
    }

    const { messages, iconType } = tenantStatusConfiguration(
        currentTenant?.latest_transaction_status,
        currentTenant?.owner_organization_total_usage_percent,
        currentTenant?.owner_organization_total_usage_updated_at
    );

    return (
        <>
            <div className="navbar">
                <div className="mast-head">
                    <div className="tenant-button">
                        <UiIcon name={tenantDrawerVisible ? 'closeTenant' : 'tenants'} onClick={toggleDrawer} />
                    </div>
                    <div className="title-and-tenant-container">
                        <Logo />
                        {currentTenant?.name && (
                            <>
                                <Divider className="tenant-separator" type="vertical" />
                                <span className="tenant-name" title={currentTenant?.name}>
                                    {currentTenant?.name}
                                </span>
                                {!!featureFlagMap?.detokenization && (
                                    <span className="detok-icons">
                                        <UiIcon name="threeStars" className="three-stars" />
                                        <UiIcon name="checkCircleOutlinedGreen" className="check-circle" />
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {props.showNavigation && <NavigationMenu handleClick={handleClick} current={current} />}

                <div className="controls">
                    {messages.length > 0 && <TenantStatusIndicator icon={iconType} messages={messages} />}
                    <UserBadge className="navbar-user-badge" email={user?.email} />
                    {props.showNavigation && (
                        <Link
                            className={`tab-button ${current.includes('settings') ? 'active' : ''}`}
                            to={`/${currentTenant?.key}/settings`}
                            onClick={() => {
                                setTenantDrawerVisible(false);
                            }}
                        >
                            <UiIconButton name="settings" />
                        </Link>
                    )}
                    <div className="tab-button" onClick={() => props.signOut()}>
                        <UiIconButton name="logout" />
                    </div>
                </div>
            </div>
            <TenantDrawer visible={tenantDrawerVisible} onClose={onClose} currentTenant={currentTenant} />
        </>
    );
};

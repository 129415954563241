import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { useOnEscOrClickOutside } from 'hooks/useOnEscOrClickOutside';
import { UiButton } from 'sharedComponents/button/Button';
import {
    DATE_FORMAT,
    DEFAULT_PRESET,
    minutesOfDayToHoursMinutes,
    fitToMaxSupportedDateRange,
    PRESET_TO_HOURS,
    splitToDateAndTimeRange,
    TDatetimeRange,
    TDatetimeRangeOrPreset,
    TPresetName,
    TTimeRange,
} from './utils';
import { UiTimeRangePicker } from './UiTimeRangePicker/UiTimeRangePicker';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';

import './UiChronoRangePicker.scss';

const { RangePicker } = DatePicker;

export type PresetDateRanges = Partial<Record<TPresetName, TDatetimeRange>>;

export interface IChronoRangePickerProps {
    value: TDatetimeRangeOrPreset;
    onChange: (range: TDatetimeRangeOrPreset) => void;
    presets?: PresetDateRanges;
    label?: string;
    disabledDate?: (date: moment.Moment) => boolean;
}

export const UiChronoRangePicker = (props: IChronoRangePickerProps) => {
    const now = useMemo(() => moment(), []);
    const [selectedDateRange, setSelectedDateRange] = useState<TDatetimeRange>([now, now]);
    const [selectedTimeRange, setSelectedTimeRange] = useState<TTimeRange>(['0', '0']);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedPreset, setSelectedPreset] = useState<TPresetName | undefined>();

    const wrapperRef = useRef<HTMLDivElement>(null);

    const setInitialDateTime = (initialDatetimeRangeOrPreset: IChronoRangePickerProps['value']) => {
        const initialSelectedPreset = typeof props.value === 'string' ? props.value : undefined;

        if (initialSelectedPreset) {
            const foundDatetimeRange = props.presets && props.presets[initialSelectedPreset];
            if (!foundDatetimeRange) {
                throw Error(`Preset not found: ${initialSelectedPreset}`);
            }
            setSelectedPreset(initialSelectedPreset);
            return setDateTimeByRange(foundDatetimeRange);
        }

        setSelectedPreset(undefined);
        return setDateTimeByRange(initialDatetimeRangeOrPreset as TDatetimeRange);
    };

    useEffect(() => {
        setInitialDateTime(props.value);
    }, [JSON.stringify(props.value)]);

    // TODO: find a way to close upon click outside without closing when selecting time
    // (currently clicking the time options is treated as click outside)
    // useOnEscOrClickOutside(wrapperRef, () => onCancel(), 'crp-dropdown');

    const setDateTimeByRange = (range: TDatetimeRange) => {
        const [timeRange, dateRange] = splitToDateAndTimeRange(range);
        setSelectedTimeRange(timeRange);
        setSelectedDateRange(dateRange);
    };

    const getPresetOptions = (presets?: PresetDateRanges) => {
        if (!presets) {
            return null;
        }

        return Object.entries(presets).map(([title, range]) => (
            <UiTooltip
                key={title}
                title={
                    title === DEFAULT_PRESET && range[1].diff(range[0], 'hours') < PRESET_TO_HOURS[title]
                        ? `Currently showing ${range[1].diff(range[0], 'days')} days of data`
                        : ''
                }
                disableCopyButton
            >
                <UiButton
                    key={title}
                    type="Secondary"
                    className={`range-options-button ${selectedPreset === title ? 'active' : ''}`}
                    text={title}
                    onClick={() => {
                        setSelectedPreset(title as TPresetName);
                        setDateTimeByRange(range);
                    }}
                    disabled={props.disabledDate?.(range[0]) || props.disabledDate?.(range[1])}
                />
            </UiTooltip>
        ));
    };

    const onCalendarChange = (
        range: TDatetimeRange,
        rangeFormat: [string, string],
        info: { range: 'start' | 'end' }
    ) => {
        if (!range[0] || !range[1]) {
            return;
        }
        const normalizedDateRange = fitToMaxSupportedDateRange(range, info, 30);
        normalizedDateRange && setSelectedDateRange(normalizedDateRange);
        selectedPreset && setSelectedPreset(undefined);
    };

    const onTimeRangePickerChange = (timeRange: TTimeRange, isSelection: boolean) => {
        setSelectedTimeRange(timeRange);
        isSelection && selectedPreset && setSelectedPreset(undefined);
    };

    const onApply = () => {
        if (selectedPreset) {
            props.onChange(selectedPreset);
            setIsOpen(false);
            return;
        }

        const start = moment(selectedDateRange?.[0]).startOf('day').add(selectedTimeRange[0], 'minutes');
        const end = moment(selectedDateRange?.[1]).startOf('day').add(selectedTimeRange[1], 'minutes');

        if (start && end) {
            props.onChange([start, end]);
            setIsOpen(false);
        }
    };

    const onCancel = () => {
        setInitialDateTime(props.value);
        setIsOpen(false);
    };

    const selectedDateTimeFormatter = (date: moment.Moment): string => {
        const index = selectedDateRange?.findIndex((selectedDate: moment.Moment) => selectedDate === date);
        return index === undefined || selectedTimeRange[index] === undefined
            ? date.format(DATE_FORMAT)
            : `${date.format(DATE_FORMAT)} | ${minutesOfDayToHoursMinutes(selectedTimeRange[index])}`;
    };

    if (selectedDateRange?.[1].unix() <= 0) {
        return (
            <div className="UiChronoRangePicker" ref={wrapperRef}>
                {props.label && <span className="control-label">{props.label}</span>}
                <RangePicker disabled value={[now, now]} mode={['date', 'date']}></RangePicker>
            </div>
        );
    }

    return (
        <div
            className="UiChronoRangePicker"
            onClick={() => {
                !isOpen && setIsOpen(true);
            }}
            ref={wrapperRef}
        >
            {props.label && <span className="control-label">{props.label}</span>}
            <RangePicker
                open={isOpen}
                mode={['date', 'date']}
                format={selectedDateTimeFormatter}
                value={selectedDateRange}
                allowClear={false}
                // @ts-ignore
                onCalendarChange={onCalendarChange}
                disabledDate={props.disabledDate || (() => false)}
                popupClassName="crp-dropdown"
                panelRender={(panel) => (
                    <>
                        <div className="crp-range-options">{getPresetOptions(props.presets)}</div>
                        <div className="crp-controls">{panel}</div>
                    </>
                )}
                renderExtraFooter={() => (
                    <div className="crp-footer-container">
                        <UiTimeRangePicker
                            selectedDateRange={selectedDateRange}
                            supportedHours={24}
                            value={selectedTimeRange}
                            onChange={onTimeRangePickerChange}
                        />
                        <div className="crp-buttons">
                            <UiButton type="secondary" text="Cancel" onClick={onCancel} />
                            <UiButton type="primary" text="Apply" onClick={onApply} />
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

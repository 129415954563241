import { IAlert } from 'interfaces/alert.interface';
import { IAttribute } from 'interfaces/query.interface';
import { IEndpointGrp } from 'interfaces/endpoint.interface';

export interface IIntervalOptions {
    label: string;
    value: string;
}

export interface IDiscoveryData {
    endpointGroup: IEndpointGrp[];
    intervalSelectOptions: IIntervalOptions[];
    labelsData: string[];
    alertsData: IAlert[] | null;
    attrData: IAttribute[];
}

export interface IEndpoint {
    id: string;
    service_name: string;
    endpoint_path: string;
    method: string;
    // optional attributes below (aka 'slim endpoints'):
    labels?: string[];
    hidden_at?: string;
    hidden_by?: string;
    hidden?: boolean;
    call_count?: number;
    call_percentage?: number;
    first_seen?: string;
    last_seen?: string;
    max_severity?: string;
    pending_deletion?: boolean;
    deleted_by?: string;
}

export enum IEndpointOptionalFields {
    labels = 'labels',
    hidden = 'hidden',
    call_count = 'call_count',
    call_percentage = 'call_percentage',
    first_seen = 'first_seen',
    last_seen = 'last_seen',
    max_severity = 'max_severity',
}

export interface IEndpointFilterOptions {
    service_name: string;
    alerted: boolean;
}

export type TimestampQuery = { from_timestamp: string; to_timestamp: string };

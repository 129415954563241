import { IUser } from 'interfaces/user.interface';
import { tenantApi } from 'api/baseServerApi';

export interface ITopAlertingUsersResponse {
    count: number;
    total: number;
    items: IUser[];
}

export const overviewDashboardApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getTopAlertingUser: builder.query<ITopAlertingUsersResponse, void>({
            query: () => `users?limit=20&sort_by=desc(open_alerts),desc(open_alerts_max_severity)&alerted=true`,
        }),
    }),
    overrideExisting: false,
});

export const useGetTopAlertingUsersQuery = overviewDashboardApi.endpoints.getTopAlertingUser.useQuery;

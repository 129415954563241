import { useCallback, useMemo, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import {
    ExclusionRuleOption,
    IExclusionRule,
    useDeleteExclusionRuleMutation,
    useGetExclusionRulesQuery,
    useUpdateExclusionRuleMutation,
} from 'api/exclusionRuleApi';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { getEndpointFilterListCols } from './getEndpointFilterListCols';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { useHistory } from 'react-router';
import {
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
    CustomNoRowsOverlay,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { CustomTooltip } from 'sharedComponents/ui-ag-grid/customToolTip';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { EndpointExclusionRuleCondCellRenderer } from 'features/settings/components/EndpointExclusionRuleList/EndpointExclusionRuleCondCellRenderer/EndpointExclusionRuleCondCellRenderer';
import {
    ExclusionRuleOrDeleteEndpointsWizard,
    WizardModeOptions,
} from 'sharedComponents/exclusionRuleOrDeleteEndpointsWizard/ExclusionRuleOrDeleteEndpointsWizard';
import { SettingsSectionHeader } from 'features/settings/components/SettingsSectionHeader/SettingsSectionHeader';

import './EndpointExclusionRuleList.scss';
import 'sharedComponents/ui-ag-grid/customRenderers.scss';

export const gridOptions = {
    rowHeight: 70,
    components: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        customTooltip: CustomTooltip,
        endpointFilterRuleCondCellRenderer: EndpointExclusionRuleCondCellRenderer,
    },
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': ({ data }: { data: IExclusionRule }) => !data.active,
        'deprecated-row': ({ data }: { data: IExclusionRule }) =>
            !(data.filter_option === ExclusionRuleOption.PATH || data.filter_option === ExclusionRuleOption.ATTRIBUTE),
    },
};

export enum AddOrEditMode {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export interface IEndpointExclusionListProps {
    mode?: AddOrEditMode;
}

export const EndpointExclusionRuleList = ({ mode }: IEndpointExclusionListProps) => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { data: rules } = useGetExclusionRulesQuery();
    const [triggerUpdate] = useUpdateExclusionRuleMutation();
    const [triggerDelete] = useDeleteExclusionRuleMutation();
    const [filterRuleIdToDelete, setFilterRuleIdToDelete] = useState<string>();

    const onRowToggleHandler = async (toggleStatus: boolean, idx: number, id: string) => {
        triggerUpdate({ id, active: toggleStatus });
    };

    const tableMenuItems = (params: ICellRendererParams) => {
        const exclusionRule: IExclusionRule = params.data;
        const isExclusionRuleDeprecated = !(
            exclusionRule.filter_option === ExclusionRuleOption.PATH ||
            exclusionRule.filter_option === ExclusionRuleOption.ATTRIBUTE
        );

        return {
            menuItems: [
                {
                    label: `Edit`,
                    icon: <UiIcon name="pencil" disabled={isExclusionRuleDeprecated} />,
                    disabled: isExclusionRuleDeprecated,
                    onClick: () => {
                        history.push(`/${currentTenantKey}/settings/exclusion-rules/edit/${params.data?.id}`);
                    },
                },
                {
                    label: 'Delete',
                    icon: <UiIcon name="trash" />,
                    onClick: () => {
                        setFilterRuleIdToDelete(params?.data.id);
                    },
                },
            ],
        };
    };

    const cachedTableMenuItems = useCallback(tableMenuItems, []);
    const cachedOnRowToggleHandler = useCallback(onRowToggleHandler, []);

    const columns = useMemo(
        () => getEndpointFilterListCols(cachedOnRowToggleHandler, cachedTableMenuItems),
        [cachedOnRowToggleHandler, cachedTableMenuItems]
    );

    const handleDelete = () => {
        if (filterRuleIdToDelete) {
            triggerDelete(filterRuleIdToDelete);
            setFilterRuleIdToDelete(undefined);
        }
    };

    const generateDeleteConfirmationText = () => {
        const { description, regex } = rules?.items.find((rule) => rule.id === filterRuleIdToDelete) || {};
        const ruleText = description || regex || '';
        const ruleToDeleteConcatText = ruleText.length > 256 ? `${ruleText?.slice(0, 256)}...` : ruleText;
        return `Are you sure you want to permanently delete the rule '${ruleToDeleteConcatText}'?`;
    };

    const onDeleteCloseOrFinish = () => {
        history.push(`/${currentTenantKey}/settings/exclusion-rules`);
    };

    return (
        <div className="EndpointFilterList">
            <SettingsSectionHeader
                title="Exclusion Rules"
                urlPath="settings/exclusion-rules/add"
                buttonText="Add Rule"
                disableButton={rules && rules?.items?.length >= 20}
            />
            <div className="endpoint-filter-list-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    data={rules?.items || []}
                    columns={columns}
                    rowCountTitle="Rules"
                    showRowCount={false}
                />
                {filterRuleIdToDelete && (
                    <UiModal
                        onCancel={() => setFilterRuleIdToDelete(undefined)}
                        acceptButton={{ text: 'Delete', onClick: handleDelete }}
                        rejectButton={{ text: 'Cancel', onClick: () => setFilterRuleIdToDelete(undefined) }}
                        title={`Delete Rule?`}
                        icon="trash2"
                    >
                        {generateDeleteConfirmationText()}
                    </UiModal>
                )}
            </div>
            {mode && (
                <ExclusionRuleOrDeleteEndpointsWizard
                    mode={WizardModeOptions.ExclusionRule}
                    title={`${mode === AddOrEditMode.EDIT ? 'Edit' : 'Create'} Exclusion Rule`}
                    onCancel={onDeleteCloseOrFinish}
                    onFinish={onDeleteCloseOrFinish}
                />
            )}
        </div>
    );
};

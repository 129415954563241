import { Col, Row } from 'antd';

import { formatNumberWithSeparators, humanReadableTimeShowEmpty, isObjectNotEmpty } from 'general/utils';
import { IDiscoveryService } from 'interfaces/service.interface';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import {
    CellProgressBarView,
    cellRenderTimestampTooltip,
    totalAndActiveEndpoints,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';

import './ServiceSummary.scss';

interface IServiceSummaryProps {
    service: IDiscoveryService | null | undefined;
}

export const maxDiffDaysService = 151;

export interface IStatisticsColumnDefinition {
    displayName: string;
    order: number;
    formatter?: Function;
    getIcon?: Function;
    renderer?: Function;
}

export const colDefs: { [key: string]: IStatisticsColumnDefinition } = {
    risk_score: {
        order: 1,
        displayName: 'Risk',
        renderer: CellProgressBarView,
        formatter: formatNumberWithSeparators.format,
    },
    risk_change: {
        order: 2,
        displayName: 'Risk Change',
        getIcon: (value: number) => {
            return value > 0 && <UiIcon name="upArrowRed" />;
        },
        formatter: formatNumberWithSeparators.format,
    },
    behavior_alerts_count: {
        order: 3,
        displayName: 'Runtime',
        formatter: formatNumberWithSeparators.format,
    },
    posture_alerts_count: {
        order: 4,
        displayName: 'Posture',
        formatter: formatNumberWithSeparators.format,
    },
    new_endpoints_count: {
        order: 5,
        displayName: 'New Endpoints',
        getIcon: (value: number) => {
            return (
                value > 0 && (
                    <div className="arrow-change-icon">
                        {' '}
                        <UiIcon name="upArrowRed" />
                    </div>
                )
            );
        },
        formatter: formatNumberWithSeparators.format,
    },
    total_endpoints_count: {
        order: 6,
        displayName: 'Active Endpoints',
        renderer: totalAndActiveEndpoints,
    },
    calls_count: {
        order: 8,
        displayName: 'Calls',
        formatter: formatNumberWithSeparators.format,
    },
    errors_4xx: {
        order: 9,
        displayName: '4xx',
        formatter: formatNumberWithSeparators.format,
    },
    errors_5xx: {
        order: 10,
        displayName: '5xx',
        formatter: formatNumberWithSeparators.format,
    },
    first_seen: {
        order: 11,
        displayName: 'First seen',
        formatter: (timestamp: string) => ({
            timestamp,
            value: humanReadableTimeShowEmpty(timestamp, maxDiffDaysService),
            maxDiffDays: maxDiffDaysService,
        }),
        renderer: cellRenderTimestampTooltip,
    },
    last_seen: {
        order: 12,
        displayName: 'Last seen',
        formatter: (timestamp: string) => ({
            timestamp,
            value: humanReadableTimeShowEmpty(timestamp, maxDiffDaysService),
            maxDiffDays: maxDiffDaysService,
        }),
        renderer: cellRenderTimestampTooltip,
    },
};

export const dummyFunctions = {
    formatter: (e: any) => e,
    getIcon: () => false,
    renderer: (e: any) => e,
};

export const ServiceSummary = ({ service }: IServiceSummaryProps) => {
    if (!service) return null;

    return (
        <div className="service-summary-container">
            {isObjectNotEmpty(service) && (
                <Row className={'service-row'}>
                    {Object.entries(service).map(([key, value], index) => {
                        return (
                            colDefs.hasOwnProperty(key) && (
                                <Col key={index} order={colDefs[key].order}>
                                    <UiTitle title={colDefs[key].displayName} type="medium" />
                                    <div className="value alerts-value">
                                        <div className="text">
                                            <span>
                                                {(colDefs[key].renderer || dummyFunctions.renderer)(
                                                    (colDefs[key].formatter || dummyFunctions.formatter)(value),
                                                    service
                                                )}
                                            </span>
                                            {colDefs[key].getIcon && (
                                                <span className={'icon'}>
                                                    {(colDefs[key].getIcon || dummyFunctions.getIcon)(value)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )
                        );
                    })}
                </Row>
            )}
        </div>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from 'antd';

import { cloneCollectorConfig, ICollectorConfiguration } from '../shared/collectorConfigurationApis';
import { errorMessage, successMessage } from 'general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { formFieldName, validateFormFieldChanged, formFieldRequired, isDuplicatedNameValidator } from 'general/forms';
import useIsFirstRender from '../../../../../hooks/useFirstRender';
import { UiModal } from 'sharedComponents/UiModal/UiModal';

export interface ICloneModalProps {
    onClose: (refreshNeeded?: boolean) => void;
    collectorConfig: ICollectorConfiguration;
    existingConfigs?: ICollectorConfiguration[];
}

export const CollectorConfigurationCloneModal = ({
    onClose,
    collectorConfig: { id, createdBy, name },
    existingConfigs,
}: ICloneModalProps) => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const [newConfigName, setNewConfigName] = useState<string>('');
    const [isAcceptDisabled, setIsAcceptDisabled] = useState<boolean>(true);
    const [nameForm] = Form.useForm();
    const isFirst = useIsFirstRender();

    useEffect(() => {
        !isFirst &&
            nameForm
                .validateFields()
                .finally(() => setIsAcceptDisabled(nameForm.getFieldsError()[0]?.errors.length !== 0));
    }, [newConfigName, nameForm]);

    const handleClone = useCallback(async () => {
        try {
            const cloneRes = await cloneCollectorConfig(activeOrg, id, newConfigName, createdBy);
            if (cloneRes) {
                successMessage('Cloned successfully');
                onClose(true);
            } else {
                errorMessage('Error: cannot clone Collector');
            }
        } catch (e: any) {
            if (e.response.status === 409) {
                errorMessage('Error: Configuration name already exists');
            } else {
                errorMessage('Error: cannot clone Collector');
            }
        } finally {
            onClose();
        }
    }, [activeOrg, newConfigName, createdBy, id, onClose]);

    return (
        <UiModal
            onCancel={() => onClose(false)}
            acceptButton={{
                text: 'Clone',
                onClick: handleClone,
                disabled: isAcceptDisabled,
            }}
            rejectButton={{ text: 'Cancel', onClick: () => onClose(false) }}
            title="Clone Node Configuration"
        >
            <div style={{ width: '100%' }}>
                <Form
                    name="configName"
                    form={nameForm}
                    colon={false}
                    layout="vertical"
                    requiredMark={false}
                    onValuesChange={({ name }) => setNewConfigName(name)}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: formFieldRequired.errorMessage,
                            },
                            {
                                pattern: formFieldName.pattern,
                                message: formFieldName.errorMessage,
                            },
                            { validator: validateFormFieldChanged(name || '') },
                            {
                                validator: (_, value) =>
                                    isDuplicatedNameValidator(existingConfigs!, value || '', 'Configuration'),
                            },
                        ]}
                    >
                        <Input placeholder="E.g. My conf v1" />
                    </Form.Item>
                </Form>
            </div>
        </UiModal>
    );
};

import { Tabs, TabsProps } from 'antd';

import './UiTabsNav.scss';

export interface IUiTabsNavProps extends TabsProps {
    onTabSelected: (key: string | number) => void;
    className?: string;
}

export const UiTabsNav = (props: IUiTabsNavProps) => {
    const { className, items, onTabSelected, ...extraProps } = props;

    return <Tabs items={items} className={`UiTabsNav ${className}`} onChange={onTabSelected} {...extraProps} />;
};

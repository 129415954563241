import React, { useCallback } from 'react';
import { ColDef } from '@ag-grid-community/core';
import { AxiosResponse } from 'axios';

import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { CellRenderEndpointPreview, CellRenderVerticalCenter } from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { IExclusionRulePreviewItem } from 'api/exclusionRuleApi';
import { IServerQueryResponse } from 'api/baseServerApi';

import './PreviewTable.scss';

export interface IPreviewTableProps {
    title: string;
    getPreview: (
        offset: number,
        sortByField?: string
    ) => Promise<IServerQueryResponse<IExclusionRulePreviewItem> | undefined>;
    totalFilteredCalls?: number;
    totalCalls?: number;
}

const commonTableProperties = {
    type: 'agTextFilter',
    cellRenderer: CellRenderVerticalCenter,
};

const filteredEndpointsColumnDefs: ColDef[] = [
    {
        ...commonTableProperties,
        headerName: 'Service',
        field: 'service_name',
        type: 'basic',
        flex: 0.3,
    },
    {
        ...commonTableProperties,
        headerName: 'Unique Calls',
        field: 'path',
        type: 'basic',
        flex: 1,
        resizable: false,
        cellRenderer: CellRenderEndpointPreview,
    },
];

export const PreviewTable = ({ title, getPreview, totalFilteredCalls, totalCalls }: IPreviewTableProps) => {
    const getData = useCallback(async (start: number, end: number, sortParams?: string) => {
        const sortByField = sortParams && sortParams.split('=')[1];
        const data = await getPreview(start, sortByField);

        return {
            data,
            // hack to wrap data like in AxiosResponse
            status: 200,
        } as AxiosResponse;
    }, []);

    return (
        <div className="PreviewTable">
            <div className="filtered-endpoint-grid">
                <UiAgGridSSRM
                    newCustomTitle={() => (
                        <div className="endpoint-filter-preview">
                            <div className="endpoint-filter-warning">
                                <UiIcon name="warning" />
                                <span className="warning-message">{title}</span>
                            </div>
                            {totalFilteredCalls && totalCalls ? (
                                <div className="display-count">
                                    Showing{' ~'}
                                    {((totalFilteredCalls / Math.max(totalFilteredCalls, totalCalls)) * 100).toFixed(0)}
                                    % ({totalFilteredCalls.toLocaleString()}/
                                    {Math.max(totalFilteredCalls, totalCalls).toLocaleString()}) unique calls from the
                                    last 7 days
                                </div>
                            ) : null}
                        </div>
                    )}
                    columns={filteredEndpointsColumnDefs}
                    options={{
                        columnTypes: { basic: BASIC_AGGRID_COL_TYPE },
                    }}
                    getData={getData}
                    dataMappingFunction={(value: any) => value}
                />
            </div>
        </div>
    );
};

import { Empty, Form, Input, Radio, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';

import Spinner from 'sharedComponents/spinner/Spinner';
import { alphabeticOnlyRule, formFieldRequired } from 'general/forms';
import { IAttributeFilter } from 'interfaces/query.interface';
import { IEntityClass, TEntityFamily } from 'api/entitiesApi';

import './AddEntityForm.scss';

export interface IAddEntityFormProps {
    form: FormInstance;
    attributes?: IAttributeFilter[];
    classes?: IEntityClass[];
    setFamily: (family: TEntityFamily) => void;
}

const initialValues = {
    name: '',
    family: 'actor',
};

export function AddEntityForm({ form, attributes, classes, setFamily }: IAddEntityFormProps) {
    const handleFamilyChange = ({ target }: RadioChangeEvent) => {
        setFamily(target.value);
        form.resetFields(['class']);
    };

    return (
        <div className="AddEntityForm">
            <Form form={form} colon={false} requiredMark={false} layout="vertical" initialValues={initialValues}>
                <Form.Item
                    name="name"
                    label="Name"
                    validateFirst
                    rules={[
                        {
                            required: true,
                            message: formFieldRequired.errorMessage,
                        },
                        alphabeticOnlyRule('Name can only contain letters without spaces'),
                    ]}
                >
                    <Input placeholder="My Entity" />
                </Form.Item>
                <Form.Item name="family" label="Family">
                    <Radio.Group onChange={handleFamilyChange}>
                        <Radio value="actor">Actor</Radio>
                        <Radio value="business">Business</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="class"
                    label="Class"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                >
                    <Select
                        options={
                            classes &&
                            [...new Set(classes.map(({ class_name }) => class_name))].map((class_name) => ({
                                label: class_name,
                                value: class_name,
                            }))
                        }
                        placeholder="Type or select a value"
                        notFoundContent={
                            classes === undefined ? (
                                <Spinner show size="small" paddingTop={10} paddingBottom={10} />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="source_attribute"
                    label="Attribute"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                >
                    <Select
                        showSearch
                        options={
                            attributes &&
                            [...new Set(attributes.map(({ name }) => name))].map((attribute) => ({
                                label: attribute,
                                value: attribute,
                            }))
                        }
                        placeholder="Type or select a value"
                        notFoundContent={
                            attributes === undefined ? (
                                <Spinner show size="small" paddingTop={10} paddingBottom={10} />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

import { httpPOST } from './http-service';

interface IRequestBody {
    offset: number;
    limit: number;
}

export const recursiveHttpPost = <T>(
    url: string,
    body: IRequestBody,
    results: any[],
    resolve: (params: T[]) => void,
    reject: Function
): void => {
    // recursively calling httpPOST until no more results
    httpPOST(url, body)
        .then((response: any) => {
            const totalItems = response.data.total;
            const retrievedItems: T[] = results.concat(response.data.items);
            if (retrievedItems.length < totalItems) {
                body.offset += body.limit;
                recursiveHttpPost(url, body, retrievedItems, resolve, reject);
            } else {
                resolve(retrievedItems);
            }
        })
        .catch((error: any) => {
            reject('Something wrong. Please refresh the page and try again.');
            throw new Error(error);
        });
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import {
    CellRenderDetokenizable,
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { CustomTooltip } from 'sharedComponents/ui-ag-grid/customToolTip';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { IRulesTableItem, ISuppressionRule } from 'interfaces/throttle.interface';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage, stringifyRulePredicate, TimeDisplayResEnum } from 'general/utils';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { openDialog } from 'services/dialog/dialog-service';
import { ruleActionTypeEnum } from 'interfaces/ruleActionType.enum';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { IEntity, useGetEntitiesQuery } from 'api/entitiesApi';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import {
    useDeleteThrottlingRuleMutation,
    useGetThrottlingRulesQuery,
    usePatchThrottlingRuleMutation,
} from 'api/throttlingRulesApi';
import Spinner from 'sharedComponents/spinner/Spinner';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

import './SuppressionRuleList.scss';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        cellRenderDetokenizable: CellRenderDetokenizable,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: any) {
            return !params.data?.isActiveRule || false;
        },
    },
};

const getTableItems = (rules: ISuppressionRule[], entities: IEntity[]) => {
    return rules.map((rule: ISuppressionRule) => ({
        isActiveRule: rule.status === 'enabled',
        id: rule.id,
        order: rule.rule_order + 1,
        name: rule.rule_name || 'no name provided',
        match: stringifyRulePredicate(rule.predicate, entities?.filter((e) => !e.active).map((e) => e.name) || []),
        action: rule.frequency === 0 ? ruleActionTypeEnum.SUPPRESS : ruleActionTypeEnum.THROTTLE,
        hits: rule.hit_count,
        author: rule.created_by,
        lastModified: rule.updated_at,
        lastModifiedBy: rule.updated_by,
    }));
};

export const SuppressionRuleList = () => {
    const history = useHistory();
    const activeOrg = useSelector(selectCurrentTenantKey);
    const [tableData, setTableData] = useState<IRulesTableItem[]>();
    const { data: rulesResponse, error: rulesError } = useGetThrottlingRulesQuery();
    const [patchRule] = usePatchThrottlingRuleMutation();
    const [deleteRule] = useDeleteThrottlingRuleMutation();
    const { data: entitiesResponse, error: entitiesError } = useGetEntitiesQuery({ includeHidden: true });

    const columnsDefs = [
        {
            headerName: 'No.',
            rowDrag: true,
            field: 'order',
            type: 'basic',
            width: 80,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Order',
        },
        {
            headerName: 'State',
            field: 'isActiveRule',
            type: 'basic',
            width: 100,
            sortable: false,
            cellRenderer: 'cellRenderToggle',
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: any) => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, ruleId: string) => onRowToggleHandler(e, idx, ruleId),
                };
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'basic',
            width: 120,
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Rule name',
        },
        {
            headerName: 'Match',
            field: 'match',
            type: 'basic',
            width: 180,
            flex: 1,
            cellRenderer: 'cellRenderDetokenizable',
            sortable: false,
            headerTooltip: 'Rule match conditions',
        },
        {
            headerName: 'Action',
            field: 'action',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action to take on rule match',
        },
        {
            headerName: 'Hits',
            field: 'hits',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'The number of rule hits',
        },
        {
            headerName: 'Created At',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'author',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Rule creator',
        },
        {
            headerName: 'Last Modified',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'lastModifiedBy',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: any) => {
                const menuItems = [
                    {
                        label: `Edit Rule`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/suppression/${params.data?.id}`);
                        },
                    },
                    {
                        label: `Delete Rule`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete Rule ${params.data.name}?`,
                                icon: 'trash2',
                                title: 'Delete Rule',
                            })
                                .then(() => deleteRuleHandler(params.data.id))
                                .catch(() => {});
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    useEffect(() => {
        if (rulesResponse && entitiesResponse) {
            setTableData(getTableItems(rulesResponse.items, entitiesResponse.items));
        }
    }, [rulesResponse, entitiesResponse]);

    useEffect(() => {
        if (rulesError || entitiesError) {
            errorMessage(extractErrorMessage(rulesError || entitiesError));
        }
    }, [rulesError, entitiesError]);

    function deleteRuleHandler(id: ISuppressionRule['id']) {
        deleteRule({ id });
    }

    function onRowToggleHandler(e: boolean, rowNumber: number, id: string) {
        const status = e ? 'enabled' : 'disabled';
        patchRule({ id, body: { status } });
    }

    function onRowDragEnd(e: any) {
        patchRule({ id: e.node.data.id, body: { rule_order: e.overIndex } });
    }

    return (
        <div className="suppression-rules-container">
            <SettingsSectionHeader
                title="Suppression and Throttling Rules"
                urlPath="settings/suppression/add"
                buttonText="Add Rule"
            />
            {tableData ? (
                <div className="suppression-rules-table">
                    <UiAgGridCSRM
                        options={gridOptions}
                        draggableRows
                        data={tableData}
                        showRowCount={true}
                        columns={columnsDefs}
                        rowDragEndHandler={onRowDragEnd}
                        rowCountTitle="Rules"
                    />
                </div>
            ) : (
                <Spinner show />
            )}
        </div>
    );
};

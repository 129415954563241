import moment, { Moment } from 'moment';

import { LabelValue } from 'interfaces/labels.interface';
import { IConfigResponse } from 'api/configApi';
import { PresetDateRanges } from 'sharedComponents/shared/UiChronoRangePicker/UiChronoRangePicker';
import { warningMessage } from 'general/toast-service';

export const ONE_DAY_IN_MINUTES = 1440;

export const EOD_TIME_LABEL_VALUE: LabelValue = { label: 'EOD', value: (ONE_DAY_IN_MINUTES - 1).toString() };

export type TDatetimeRange = [moment.Moment, moment.Moment];
export type TDatetimeRangeOrPreset = TDatetimeRange | TPresetName;
export type TTimeRange = [string, string];

export function isSameDay(fromTime: Moment, toTime: Moment): boolean {
    return toTime.isSame(fromTime, 'date');
}

// get a range of quantity of minutes in 24 hours with 15 minute intervals
// ['00', '01', '02', ... , '23'] -> [0, 15, 30, 45, 60, 75, ... , 1425]
export const get15MinuteIntervals = (hourRange: string[]): number[] => {
    return hourRange
        .map((hour) => parseInt(hour))
        .flatMap((hour) => [hour * 60, hour * 60 + 15, hour * 60 + 30, hour * 60 + 45]);
};

// ['00', '01', '02', ... , '23'] -> ['00:00', '00:15', '00:30', '00:45', ... , '23:45']
export const get15MinuteIntervalsFormatted = (hourRange: string[]): string[] => {
    return hourRange.flatMap((hour) => [`${hour} : 00`, `${hour} : 15`, `${hour} : 30`, `${hour} : 45`]);
};

export const allTimeOptions = (): LabelValue[] => {
    const hourRange = [...Array(24).keys()].map((hour) => String(hour).padStart(2, '0'));
    const timeLabels = get15MinuteIntervalsFormatted(hourRange);
    const timeValues = get15MinuteIntervals(hourRange);
    const options = timeLabels
        .map((v, i) => ({ label: v, value: String(timeValues[i]) }))
        .concat([EOD_TIME_LABEL_VALUE]);

    return options;
};

export const DATE_FORMAT = 'DD MMM YYYY';

export const minutesOfDayToHoursMinutes = (minutes: string): string => {
    if (minutes === (ONE_DAY_IN_MINUTES - 1).toString()) {
        return 'EOD'; // 1439 minutes === 23:59
    }

    return `${Math.floor(Number(minutes) / 60)
        .toString()
        .padStart(2, '0')}:${(Number(minutes) % 60).toString().padStart(2, '0')}`;
};

export const DEFAULT_PRESET = 'Last 14 Days of Data';
export type TPresetName =
    | 'Today'
    | 'Last 24 Hours'
    | 'Last 7 Days'
    | 'Last 14 Days'
    | 'Last 30 Days'
    | typeof DEFAULT_PRESET;

export const PRESET_TO_HOURS: Record<TPresetName, number> = {
    Today: 0,
    'Last 24 Hours': 24,
    'Last 7 Days': 7 * 24,
    'Last 14 Days': 14 * 24,
    'Last 30 Days': 30 * 24,
    'Last 14 Days of Data': 14 * 24,
};

export const getDateRangeFromPreset = (
    range: TDatetimeRangeOrPreset,
    config: IConfigResponse,
    now: moment.Moment
): TDatetimeRange => {
    if (!config.timeframe.start_timestamp || !config.timeframe.end_timestamp) {
        return [moment(0), moment(0)];
    }

    if (typeof range === 'string') {
        if (range === 'Today') {
            return [now.clone().startOf('day'), now.clone()];
        }

        if (range === 'Last 24 Hours') {
            return [now.clone().subtract(PRESET_TO_HOURS[range], 'hours'), now.clone()];
        }

        if (range === DEFAULT_PRESET) {
            const configStartMoment = moment(config.timeframe.start_timestamp);
            const configEndMoment = moment(config.timeframe.end_timestamp);
            const last14DaysMoment = configEndMoment.clone().subtract(PRESET_TO_HOURS[range], 'hours');
            const startMoment = configStartMoment < last14DaysMoment ? last14DaysMoment : configStartMoment;
            return [
                startMoment.startOf('day'),
                isSameDay(now, configEndMoment) ? configEndMoment : configEndMoment.endOf('day'),
            ];
        }

        return [now.clone().startOf('day').subtract(PRESET_TO_HOURS[range], 'hours'), now.clone()];
    }

    return range;
};

export const getPresetDateRanges = (
    config: IConfigResponse,
    presetNames: TPresetName[],
    now: moment.Moment
): PresetDateRanges => {
    return presetNames.reduce((presets, presetName) => {
        presets[presetName] = getDateRangeFromPreset(presetName, config, now);
        return presets;
    }, {} as PresetDateRanges);
};

export const isPresetWithinAvailableDateRange = (
    preset: TPresetName,
    availableRange: TDatetimeRange,
    now: moment.Moment
): boolean => {
    const rangeToCheck = [now.clone().startOf('day').subtract(PRESET_TO_HOURS[preset], 'hours'), now.clone()];
    return rangeToCheck[0] < availableRange[1];
};

export const fitToMaxSupportedDateRange = (
    range: TDatetimeRange,
    info: { range: 'start' | 'end' },
    maxDiff: number
): TDatetimeRange => {
    const normalizedRange = [range[0].clone(), range[1].clone()] as TDatetimeRange;
    const dayDiff = normalizedRange[1].diff(normalizedRange[0], 'days');
    if (dayDiff > maxDiff) {
        if (info.range === 'start') {
            normalizedRange[1] = moment(normalizedRange[0]).add(maxDiff, 'days');
        } else if (info.range === 'end') {
            normalizedRange[0] = moment(normalizedRange[1]).subtract(maxDiff, 'days');
        }
        warningMessage(`Query is limited to a ${maxDiff} day range`);
    }
    return normalizedRange;
};

export function isDateInLast24Hours(now: Moment, date: Moment): boolean {
    const diff = now.diff(date, 'hours');
    return diff >= 0 && diff < 24;
}

export const splitToDateAndTimeRange = (range: TDatetimeRange) => {
    const timeRange = range.map(
        (selectedTime) => (selectedTime.unix() - selectedTime.clone().startOf('day').unix()) / 60
    );

    const selectedTimeRange = [
        (Math.ceil(timeRange[0] / 15) * 15).toFixed(0).toString(),
        Math.floor(timeRange[1]) === 1439 ? '1439' : (Math.ceil(timeRange[1] / 15) * 15).toFixed(0).toString(),
    ] as TTimeRange;

    const selectedDateRange = range.map((dateRange) => dateRange.clone().startOf('day')) as TDatetimeRange;
    return [selectedTimeRange, selectedDateRange] as [TTimeRange, TDatetimeRange];
};

const MAX_RETENTION_DAYS = 120;
export const isDisabledDate =
    (config: IConfigResponse, now: moment.Moment) =>
    (current: moment.Moment): boolean => {
        if (config?.timeframe) {
            const startDate = moment(config?.timeframe?.start_timestamp).startOf('day');
            const endDate = moment(config?.timeframe?.end_timestamp).endOf('day');
            const retentionDays = current < now.clone().subtract(MAX_RETENTION_DAYS, 'days');

            return (!config?.is_demo && retentionDays) || current < startDate || current > endDate;
        }
        return false;
    };

export function timeFormatter(timeStamp: number): string {
    const hours = Math.floor(timeStamp / 60);
    const minutes = timeStamp % 60;
    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    if (minutes < 10) minutesString = '0' + minutesString;
    if (hours < 10) hoursString = '0' + hoursString;
    if (hoursString === '23' && minutesString === '59') return 'EOD';
    return hoursString + ':' + minutesString;
}

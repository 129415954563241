import { datadogRum } from '@datadog/browser-rum';

export function startDDRumTracking(tenantKey: any) {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_RUM_APP_ID as string,
        clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN as string,
        site: 'datadoghq.com',
        service: 'neosec-ui',
        env: process.env.REACT_APP_ENV,
        version: process.env.REACT_APP_VERSION,
        trackViewsManually: true, // needed for using <RumRoute> - tracking route changes in React
        sessionSampleRate: 100, // percentage of sessions recording (0 means NO session recording)
        useSecureSessionCookie: true, // Send only on HTTPS
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.setGlobalContextProperty('tenant', tenantKey);

    // datadogRum.addAction('customBoaz', {
    //     value1: 11,
    //     value2: 22,
    // });
    //
}

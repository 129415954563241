import { Badge } from 'antd';

import { IAlertFilterSet } from 'features/alerts/Alerts';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { openDialog } from 'services/dialog/dialog-service';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';

import 'features/alerts/alertFilter/AlertFilterList/AlertFilterItem/AlertFilterItem.scss';

interface IAlertFilterMenuItemProps {
    alertFilter: IAlertFilterSet;
    onRemoveAlertFilter: Function;
    onSelectFilter: Function;
    selected: boolean;
    modified: boolean;
}

export const AlertFilterItem = (props: IAlertFilterMenuItemProps) => {
    const { alertFilter, onRemoveAlertFilter, onSelectFilter, selected, modified } = props;

    const modalDelete = (alertFilterSetName: string, alertFilterId: string | undefined, onSuccess: Function) => {
        openDialog({
            text: `Are you sure you want to delete the saved alert filter '${alertFilterSetName}'?`,
            icon: 'trash2',
            title: 'Delete Saved Filter',
            resolveTitle: 'Delete',
            rejectTitle: 'Cancel',
        })
            .then(() => onSuccess(alertFilterId))
            .catch(() => {});
    };

    return (
        <div
            key={alertFilter.id}
            className={`AlertFilterItem ${selected ? ' selected' : ''}`}
            onClick={() => onSelectFilter(alertFilter.id)}
        >
            <div className={`draggable-icon-box ${alertFilter.id === 'All' ? 'non-interactive' : ''}`}>
                <UiIcon name="holder" />
            </div>
            <Badge className="alert-filter-badge" dot={modified}>
                {alertFilter.id === 'All' ? (
                    <span className="tag-title">{alertFilter.name}</span>
                ) : (
                    <UiTooltip title={alertFilter.name} placement={'top'}>
                        <span className="tag-title">{alertFilter.name}</span>
                    </UiTooltip>
                )}
            </Badge>
            <div
                className={`remove-icon-box ${alertFilter.id === 'All' ? 'non-interactive' : ''}`}
                onClick={(e) => {
                    e.stopPropagation();
                    modalDelete(alertFilter.name, alertFilter.id, onRemoveAlertFilter);
                }}
            >
                <UiIcon name="close" />
            </div>
        </div>
    );
};

import {Entity} from "./data-service";

export enum EntityName {
    Config = 'config',
    EntityType = 'entities',
    Alerts = 'alerts',
    SuppressionRule = 'throttling_rules',
    AutomatedActionRule = 'action_rules',
    Endpoint = 'discovery/endpoints',
    Label = 'labels',
    Service = 'services',
    AlertName = 'alert_names',
    AlertCategory = 'alert_categories',
    AlertSeverity = 'alert_severities',
    AlertFilter = 'alert_filter'
}

export const EntityForm: Map<EntityName, Entity> = new Map([
    [EntityName.SuppressionRule, {
        rule_type: 'alert',
        rule_name: '',
        rule_order: null,
        status: 'enabled',
        frequency: 0,
        hit_count: 0,
        timeframe: 'day',
        created_at: undefined,
        updated_at: undefined,
        predicate: {}
    }],
    [EntityName.AutomatedActionRule, {
        rule_type: 'alert',
        rule_name: '',
        rule_order: null,
        status: 'enabled',
        frequency: 0,
        hit_count: 0,
        timeframe: 'day',
        created_at: undefined,
        updated_at: undefined,
        predicate: {},
        action: {}
    }]
]);

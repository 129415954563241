import { useEffect, useMemo, useState } from 'react';

import { ApiLabel } from '../ApiLabel/ApiLabel';
import { UiModal } from '../UiModal/UiModal';
import { UiTitle } from '../UiTitle/UiTitle';
import { SingleCallDetails } from '../SingleCallDetails/SingleCallDetails';
import { copyToClipboard, dateStringFormat, TimeDisplayResEnum, timeStringFormat } from 'general/utils';
import { LabelList } from '../LabelList/LabelList';
import { IApiCall } from 'interfaces/apiCall.interface';
import { UiPagination } from 'sharedComponents/UiPagination/UiPagination';
import { ICall, IGroupedFinding } from 'api/apiTestingApi';
import { FindingCallDetails } from 'features/apiTesting/findings/FindingDetails/FindingDetailsEvidence/FindingDetailsOffendingCall/FindingCallDetails/FindingCallDetails';
import { UiButton } from 'sharedComponents/button/Button';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import Spinner from 'sharedComponents/spinner/Spinner';

import './CallDetailsModal.scss';

export interface ICallDetailsModalProps {
    toggleModal: () => void;
    calls: (IApiCall | ICall)[];
    callIndex?: number;
    severity?: IGroupedFinding['severity'];
    onCallIndexChange?: (index: number) => void;
    curl?: string;
}

export const CallDetailsModal = ({
    toggleModal,
    calls,
    callIndex,
    severity,
    curl,
    onCallIndexChange,
}: ICallDetailsModalProps) => {
    const [currentCallIndex, setCurrentCallIndex] = useState<number>(callIndex || 0);

    const call: IApiCall | ICall | undefined = useMemo(() => {
        return calls[currentCallIndex];
    }, [calls, currentCallIndex]);

    useEffect(() => {
        setCurrentCallIndex(callIndex || 0);
    }, [callIndex]);

    if (!call) {
        return (
            <UiModal title={<></>} width="70vw" className="CallDetailsModal" onCancel={() => toggleModal()}>
                <Spinner show />
            </UiModal>
        );
    }

    return (
        <UiModal
            width="70vw"
            className="CallDetailsModal"
            onCancel={() => toggleModal()}
            acceptButton={{
                text: 'OK',
                onClick: toggleModal,
            }}
            title={
                <div className="header">
                    <div className="header-title">
                        {(call as ICall).is_offending && severity && <UiIcon name={`alert${severity}`} />}
                        <UiTitle
                            title={
                                <ApiLabel
                                    apiType={[call.method]}
                                    place="top"
                                    apiName={(call as IApiCall).endpoint_path || (call as ICall).url}
                                />
                            }
                            type="large"
                        />
                        <UiTitle
                            title={`${dateStringFormat(call.timestamp)} | ${timeStringFormat(
                                call.timestamp,
                                TimeDisplayResEnum.MS
                            )} ${(call as IApiCall).labels?.length ? ' |' : ''}`}
                            type="medium"
                        />
                        <LabelList labels={(call as IApiCall).labels || []} />
                    </div>
                    <div className="header-controls">
                        {calls.length > 1 && (
                            <div>
                                <UiPagination
                                    initialPage={currentCallIndex + 1}
                                    totalPageCount={calls.length}
                                    onPageChange={(page) => {
                                        setCurrentCallIndex(page - 1);
                                        onCallIndexChange?.(page - 1);
                                    }}
                                />
                            </div>
                        )}
                        {curl && (
                            <span className="curl-button">
                                <UiButton type="secondary" text="Copy CURL" onClick={() => copyToClipboard(curl)} />
                            </span>
                        )}
                    </div>
                </div>
            }
        >
            <div className="single-call-container">
                {(call as IApiCall).attributes ? (
                    <SingleCallDetails data={call as IApiCall} />
                ) : (
                    <FindingCallDetails call={call as ICall} />
                )}
            </div>
        </UiModal>
    );
};

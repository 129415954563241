import React, { useMemo } from 'react';
import { Divider } from 'antd';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';

import './TenantStatusIndicator.scss';

interface ITenantStatusIndicatorProps {
    icon: string;
    messages: string[];
}

export const TenantStatusIndicator = ({ icon, messages }: ITenantStatusIndicatorProps) => {
    const tooltipContent = useMemo(() => {
        return messages.filter((message) => message.trim() !== '');
    }, [messages]);

    return (
        <div className="TenantStatusIndicator">
            {tooltipContent.length > 0 && (
                <UiTooltip
                    title={
                        <div className="tooltipContent">
                            {tooltipContent.map((message, index) => (
                                <div key={index}>
                                    {index > 0 && <Divider type="horizontal" />}
                                    {message}
                                </div>
                            ))}
                        </div>
                    }
                    disableCopyButton={true}
                >
                    <UiIcon name={icon} />
                </UiTooltip>
            )}
        </div>
    );
};

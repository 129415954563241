import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'general/store';

export enum ApitEnum {
    APPLICATIONS = 'applications',
    AUTH_CONFIGURATIONS = 'auth-configs',
}

export interface IApitState {
    appsOrAuthConfigsTabId: ApitEnum;
}

export const DEFAULT_STATE = { appsOrAuthConfigsTabId: ApitEnum.APPLICATIONS };

export const apitSlice = createSlice({
    name: 'apitSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        updateAppsOrAuthConfigsTabId: (
            state: IApitState,
            { payload: { appsOrAuthConfigsTabId } }: PayloadAction<IApitState>
        ) => ({
            ...state,
            appsOrAuthConfigsTabId,
        }),
    },
});

export const { updateAppsOrAuthConfigsTabId } = apitSlice.actions;
export const selectApitSlice = (state: RootState) => state.apitSlice;

import React from 'react';

import './RiskTooltip.scss';

const RISK_CATEGORIES: { title: string; range: string; dotClass: string }[] = [
    { title: 'Info', range: '0-9', dotClass: 'info-dot' },
    { title: 'Low', range: '10-24', dotClass: 'low-dot' },
    { title: 'Medium', range: '25-69', dotClass: 'medium-dot' },
    { title: 'High', range: '70-99', dotClass: 'high-dot' },
    { title: 'Critical', range: '100+', dotClass: 'critical-dot' },
];

export const RiskTooltip = () => {
    const buildRiskTooltip = () => {
        return RISK_CATEGORIES.map((item, i) => (
            <div className="risk-tooltip-row" key={i}>
                <div className="dot-container">
                    <div className={`dot ${item.dotClass}`} />
                    <span>{item.range}</span>
                </div>
                <div>
                    <span>{item.title}</span>
                </div>
            </div>
        ));
    };

    return <div className="RiskTooltip">{buildRiskTooltip()}</div>;
};

import { useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Cell } from 'recharts';
import { Link } from 'react-router-dom';

import { UiBarChart } from 'sharedComponents/UiBarChart/UiBarChart';
import { useGetServiceEndpointCountQuery } from 'api/discoveryApi';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import Spinner from 'sharedComponents/spinner/Spinner';
import { legendTexticon } from 'sharedComponents/UiBarChart/RenderCustomizedLegend';
import { convertSnakeCaseToText, CustomTooltip } from 'sharedComponents/UiBarChart/CustomTooltip';
import newEndpointsByServiceEmptyStateSvg from './DiscoveryNewEndpointsByServiceWidgetEmptyState.svg';
import { DiscoveryDashboardWidgetProps } from 'features/dashboards/DiscoveryDashboard/discoveryDashboardTypes';
import { modifyWidgetRequestLimit } from 'features/dashboards/DiscoveryDashboard/DiscoveryDashboard';
import { DiscoveryDashboardEmptyState } from 'features/dashboards/DiscoveryDashboard/DiscoveryDashboardEmptyState/DiscoveryDashboardEmptyState';

import './DiscoveryNewEndpointsByServiceWidget.scss';

const LIMIT_NUM_SERVICES = 8;

const RenderCustomizedLegend = ({ payload }: any) => {
    const prettyLegendsNames = [...payload].map(({ dataKey, ...rest }) => ({
        ...rest,
        dataKey: convertSnakeCaseToText(dataKey),
    }));

    return (
        <div className="customized-legend">
            <UiTitle title="legend" type="small" />
            {legendTexticon(prettyLegendsNames)}
        </div>
    );
};

const opacityBars = ['30%', '55%', '95%'];

export const DiscoveryNewEndpointsByServiceWidget = ({
    forceRender,
    commonRequestPayload,
    handleRedirectServiceLink,
    linkToMoreServices,
}: DiscoveryDashboardWidgetProps) => {
    const { data, isLoading } = useGetServiceEndpointCountQuery(
        modifyWidgetRequestLimit(8, commonRequestPayload) || skipToken
    );

    const customBars = useCallback(
        (key: string, index: number) =>
            data?.items.map(() => <Cell cursor="pointer" key={key} opacity={opacityBars[index]} />),
        [data]
    );

    if (isLoading) {
        return <Spinner show paddingBottom={50} />;
    }

    if (!data?.items.length) {
        return (
            <DiscoveryDashboardEmptyState
                title="New Endpoints by Service"
                widgetSvg={newEndpointsByServiceEmptyStateSvg}
            />
        );
    }

    const moreServices = data.total - LIMIT_NUM_SERVICES;

    return (
        <div className="NewEndpointsByServiceWidget">
            <div className="dd-title-container">
                <div className="widget-title">New Endpoints by Service</div>
                {moreServices > 0 && (
                    <Link
                        className="link-to-services"
                        to={`${linkToMoreServices}&sort_by=desc(new_endpoints_count)&use_slice=true`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        +{moreServices} more
                    </Link>
                )}
            </div>
            <div className="widget-chart" key={forceRender + 'NewEndpointsByServiceWidget'}>
                <UiBarChart
                    graphKeys={['total_endpoint_count', 'active_endpoint_count', 'new_endpoint_count']}
                    graphData={data.items}
                    margin={{
                        top: 24,
                    }}
                    customBars={customBars}
                    customTooltip={CustomTooltip}
                    legend={RenderCustomizedLegend}
                    colorsMap={{
                        new_endpoint_count: '#2D57C2',
                        active_endpoint_count: '#9AA5D9',
                        total_endpoint_count: '#b5c8f6',
                    }}
                    isStacked={false}
                    onClick={handleRedirectServiceLink}
                />
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import './MenuFilterTag.scss';
import { IFilterTagProps, listDisplayOption } from '../FilterTagList';

export const MenuFilterTagList = (props: IFilterTagProps) => {
    const { filterTags } = props;
    const [displayData, setDisplayData] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const data = listDisplayOption(props);
        setDisplayData(data);
    }, [filterTags, props]);

    return <div className="menu-filter-tag-list">{displayData}</div>;
};

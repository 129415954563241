import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';

import { errorMessage, successMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { IScan, useDeleteScanMutation, useGetApplicationQuery } from 'api/apiTestingApi';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';

import './DeleteScanConfirmModal.scss';

export interface IDeleteScanConfirmModalProps {
    scan: IScan;
    onClose: (refresh?: boolean) => void;
}

const getLatestTimestamp = (scan?: IScan) => {
    if (!scan) {
        return '';
    }

    const { started_at, ended_at, queued_at, created_at } = scan;
    return [started_at, ended_at, queued_at, created_at]
        .filter(Boolean)
        .map((value) => moment(value))
        .sort((a: Moment, b: Moment) => a.unix() - b.unix())[0]
        .format('D MMMM YYYY, HH:mm:ss');
};

export const DeleteScanConfirmModal = ({ scan, onClose }: IDeleteScanConfirmModalProps) => {
    const { appId } = useParams<IApitRouteParams>();
    const { id: scanId } = scan;
    const [deleteScan] = useDeleteScanMutation();
    const { data: applicationData } = useGetApplicationQuery({ appId });

    return (
        <UiModal
            title="Delete Scan"
            icon="trash"
            onCancel={() => onClose()}
            acceptButton={{
                onClick: async () => {
                    if (!scanId) return;
                    try {
                        await deleteScan({ appId, scanId: scanId.toString() }).unwrap();
                        successMessage('Successfully Deleted Scan');
                    } catch (e) {
                        errorMessage(extractErrorMessage(e, 'Unexpected error deleting scan'));
                    }
                    onClose(true);
                },
                text: 'Delete',
            }}
            rejectButton={{
                text: 'Cancel',
                onClick: onClose,
            }}
            width={420}
            className="ScanDeleteConfirmationModal"
        >
            {`Delete scan ${scanId} on ${applicationData?.name} from ${getLatestTimestamp(scan)}?`}
        </UiModal>
    );
};

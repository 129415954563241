import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

import { UiButton } from '../button/Button';
import { UiIcon } from '../icon/UiIcon';

import './UiModal.scss';

export interface IUiModalProps extends ModalProps {
    isVisible?: boolean;
    title: any;
    children: ReactNode | any;
    width?: number | string;
    className?: string;
    acceptButton?: { text: string; onClick: Function; disabled?: boolean };
    rejectButton?: { text: string; onClick: Function; disabled?: boolean };
    icon?: string;
    extraFooterContent?: ReactNode;
    /**
     * There are 2 design we usually use:
     * 1. confirmation modal style:  icon + text or just text
     * 2. form modal style: blue background with white box inside
     * by using isFormModal prop you are choosing the second style.
     */
    isFormModal?: boolean;
}

export const UiModal = ({
    rejectButton,
    acceptButton,
    isVisible = true,
    children,
    icon,
    extraFooterContent,
    isFormModal,
    className,
    ...rest
}: IUiModalProps) => {
    const customFooter = (
        <div className="custom-footer">
            <div>{extraFooterContent}</div>
            <div>
                {rejectButton && <UiButton type="secondary" {...rejectButton} />}
                {acceptButton && <UiButton type="primary" {...acceptButton} />}
            </div>
        </div>
    );

    return (
        <Modal
            className={[isFormModal ? 'FormModal' : '', className].join(' ')}
            footer={customFooter}
            open={isVisible}
            keyboard
            centered
            closeIcon={<UiIcon name={'close'} />}
            {...rest}
        >
            <div className={isFormModal ? 'form-modal-body' : 'modal-body'}>
                {icon && (
                    <div className="circle-icon">
                        <UiIcon name={icon} />
                    </div>
                )}
                {children}
            </div>
        </Modal>
    );
};

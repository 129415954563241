import { ICellRendererParams } from '@ag-grid-community/core';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { ExclusionRuleOperator, ExclusionRuleOption, IExclusionRuleForm } from 'api/exclusionRuleApi';

import 'features/settings/components/EndpointExclusionRuleList/EndpointExclusionRuleCondCellRenderer/EndpointExclusionRuleCondCellRenderer.scss';

const operatorMap = {
    [ExclusionRuleOperator.MATCH]: 'Match',
    [ExclusionRuleOperator.NOTMATCH]: 'Not Match',
};

//this map is used to display the value of filter_option received from backend, when the value is either EndpointFilterOption.SERVICE or EndpointFilterOption.URL
//in the case where the value of filter_option is EndpointFilterOption.ATTRIBUTE, we do not display the filter option('Attribute'), rather we display the values of the attributes themselves
const filterOptionMap = {
    [ExclusionRuleOption.PATH]: 'Path',
};

export const EndpointExclusionRuleCondCellRenderer = (params: ICellRendererParams) => {
    const { operator, filter_option, regex, attribute_name, attribute_in, attribute_part_of, method } =
        params.data as IExclusionRuleForm;

    const generateCellContent = () => {
        return (
            <span>
                <span className="ccr-prefixer">
                    {operatorMap[operator]}{' '}
                    {filter_option !== ExclusionRuleOption.ATTRIBUTE
                        ? filterOptionMap[filter_option]
                        : `${attribute_part_of} ${attribute_in} ${attribute_name}`}
                </span>
                <span className="ccr-value">{regex}</span>
                {method?.length !== 0 && (
                    <span className="ccr-method">
                        <span className="ccr-prefixer">METHOD</span>
                        <span className="ccr-value">{method?.join(', ')}</span>
                    </span>
                )}
            </span>
        );
    };

    const getTooltipCopyText = () => {
        return (
            `${operatorMap[operator]} ` +
            (filter_option !== ExclusionRuleOption.ATTRIBUTE
                ? `${filterOptionMap[filter_option]} `
                : `${attribute_part_of} ${attribute_in} ${attribute_name} `) +
            `${regex} ` +
            (method?.length !== 0 ? `METHOD ${method?.join(', ')}` : ``)
        );
    };

    return (
        <div className={`custom-cell-renderer EndpointFilterRuleCondCellRenderer`}>
            <UiTooltip
                placement={'bottomRight'}
                title="Rules that use endpoint paths are deprecated and should be recreated using call paths."
                disableCopyButton
            >
                {!(filter_option === ExclusionRuleOption.PATH || filter_option === ExclusionRuleOption.ATTRIBUTE) && (
                    <span className="ccr-deprecated">[Deprecated]</span>
                )}
            </UiTooltip>
            <UiTooltip placement={'bottomRight'} title={generateCellContent()} copyText={getTooltipCopyText()}>
                {generateCellContent()}
            </UiTooltip>
        </div>
    );
};

import React, { CSSProperties } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { icons } from '../../assets/icons';

import './UiIcon.scss';

interface IUiIconProps extends IconComponentProps {
    name: string;
    onClick?: any;
    disabled?: boolean;
    className?: string;
    size?: number;
    isNotDefaultStyle?: boolean;
}

export const UiIcon = ({
    name,
    onClick,
    disabled,
    className,
    isNotDefaultStyle,
    role,
    ...extraProps
}: IUiIconProps) => {
    const iconStyle: CSSProperties = isNotDefaultStyle
        ? {}
        : disabled
        ? {
              color: '#565B75',
              pointerEvents: 'none',
          }
        : { color: '#436ace' };

    return icons[name] ? (
        <Icon
            component={icons[name]}
            role={role}
            style={iconStyle}
            onClick={onClick}
            className={className}
            {...(extraProps as typeof Icon)}
        />
    ) : null;
};

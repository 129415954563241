import React from 'react';
import { DatePicker } from 'antd';
import './UiDatePicker.scss';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface IDatePickerProps {
    showTime?: boolean;
    defaultValue?: any;
    getPopupContainer?: ((node: HTMLElement) => HTMLElement) | undefined;
    label?: string;
    value: [moment.Moment, moment.Moment];
    onChange?: (dates: [moment.Moment, moment.Moment] | any, dateStrings: [string, string]) => void;
    onCalendarChange?: (
        dates: [moment.Moment, moment.Moment] | any,
        dateStrings: [string, string],
        info: { range: 'start' | 'end' }
    ) => void;
    isDisabledDateFunction?: (date: moment.Moment) => boolean;
}

export const UiDatePicker = (props: IDatePickerProps) => {
    const defaultTime = props.defaultValue && props.defaultValue.map((time: any) => moment(time));

    return (
        <div className="ui-date-picker-container">
            {props.label && <span className="control-label">{props.label}</span>}

            <RangePicker
                defaultValue={defaultTime}
                showTime={props.showTime}
                value={[
                    props.value[0]?.startOf && props.value[0].startOf('minute'),
                    props.value[1]?.startOf && props.value[1].startOf('minute'),
                ]}
                getPopupContainer={props.getPopupContainer}
                format="DD MMM YYYY | HH:mm"
                size="small"
                onChange={props.onChange || (() => {})}
                disabledDate={props.isDisabledDateFunction || (() => false)}
                onCalendarChange={props.onCalendarChange || (() => {})}
            />
        </div>
    );
};

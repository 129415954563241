import { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { UiPieChart } from 'sharedComponents/UiPieChart/UiPieChart';
import { EndpointActivityStatus, useGetEndpointApiTypeCountQuery } from 'api/discoveryApi';
import Spinner from 'sharedComponents/spinner/Spinner';
import { defaultGraphColors } from 'styles/consts';
import { DiscoveryDashboardWidgetProps } from '../../discoveryDashboardTypes';
import { DiscoveryDashboardEmptyState } from '../../DiscoveryDashboardEmptyState/DiscoveryDashboardEmptyState';
import pieWidgetEmptyState from '../pieWidgetEmptyState.svg';
import { modifyWidgetRequestLimit } from '../../DiscoveryDashboard';

import './DiscoveryEndpointsByApiTypeWidget.scss';

export const DiscoveryEndpointsByApiTypeWidget = ({
    forceRender,
    commonRequestPayload,
}: DiscoveryDashboardWidgetProps) => {
    const { ACTIVE, NEW, TOTAL } = EndpointActivityStatus;
    const [selectedRadioOption, setSelectedRadioOption] = useState(EndpointActivityStatus.ACTIVE);
    const { data, isLoading } = useGetEndpointApiTypeCountQuery(
        modifyWidgetRequestLimit(5, commonRequestPayload) || skipToken
    );

    if (isLoading) {
        return <Spinner show paddingBottom={50} />;
    }

    const onChange = (e: RadioChangeEvent) => setSelectedRadioOption(e.target.value);
    const pieData = data?.[selectedRadioOption] || [];
    const noData = !data || data?.[ACTIVE]?.length == 0 || data?.[NEW]?.length == 0 || data?.[TOTAL]?.length == 0;

    if (noData) {
        return <DiscoveryDashboardEmptyState title="Endpoints By API Type" widgetSvg={pieWidgetEmptyState} />;
    }

    return (
        <div className="DiscoveryEndpointsByApiTypeWidget">
            <div className="pie-widget-title">
                <div>Endpoints By API Type</div>
                <Radio.Group size="small" onChange={onChange} value={selectedRadioOption}>
                    <Radio value={NEW}>{NEW}</Radio>
                    <Radio value={ACTIVE}>{ACTIVE}</Radio>
                    <Radio value={TOTAL}>{TOTAL}</Radio>
                </Radio.Group>
            </div>
            <div className="pie-widget-chart" key={forceRender + 'DiscoveryEndpointsByApiTypeWidget'}>
                <UiPieChart
                    data={pieData}
                    colors={defaultGraphColors}
                    rechartsLegendProps={{ align: 'right', verticalAlign: 'middle', layout: 'vertical' }}
                />
            </div>
        </div>
    );
};

import moment from 'moment/moment';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'general/store';
import {
    DEFAULT_PRESET,
    TPresetName,
    TDatetimeRange,
    getDateRangeFromPreset,
    isPresetWithinAvailableDateRange,
} from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { selectConfig } from 'api/configApi';

export enum DiscoveryDashboardFilterEnum {
    SERVICES = 'services',
    SERVICE_LABELS = 'labels',
}

export enum DiscoveryDashboardNewTotalEnum {
    NEW = 'new',
    TOTAL = 'total',
}

export interface IDiscoveryDashboardControlsState {
    dateRangeOrPreset: [number, number] /* seconds */ | TPresetName;
    serviceFilterBy: DiscoveryDashboardFilterEnum;
    selectedServices: string[];
    selectedServiceLabels: string[];
    apiTypeNewOrTotal: DiscoveryDashboardNewTotalEnum;
}

export const DEFAULT_STATE = {
    dateRangeOrPreset: DEFAULT_PRESET,
    serviceFilterBy: DiscoveryDashboardFilterEnum.SERVICES,
    selectedServices: [],
    selectedServiceLabels: [],
    apiTypeNewOrTotal: DiscoveryDashboardNewTotalEnum.NEW,
} as Partial<IDiscoveryDashboardControlsState>;

export const discoveryDashboardControlsSlice = createSlice({
    name: 'discoveryDashboardControlsSlice',
    initialState: DEFAULT_STATE as IDiscoveryDashboardControlsState,
    reducers: {
        updateDiscoveryDashboardControls: (
            state,
            action: PayloadAction<Partial<IDiscoveryDashboardControlsState>>
        ) => ({ ...state, ...action.payload }),
    },
});

export const { updateDiscoveryDashboardControls } = discoveryDashboardControlsSlice.actions;

export const selectDiscoveryDashboardControls = (state: RootState) => state.discoveryDashboardControlsSlice;

export const selectDateRangeOrPreset = createSelector(
    selectConfig,
    selectDiscoveryDashboardControls,
    (config, { dateRangeOrPreset }) => {
        const now = moment();
        const isPreset = typeof dateRangeOrPreset === 'string';

        if (isPreset) {
            return isPresetWithinAvailableDateRange(
                dateRangeOrPreset,
                [moment(config?.timeframe.start_timestamp), moment(config?.timeframe.end_timestamp)],
                now
            )
                ? dateRangeOrPreset
                : DEFAULT_PRESET;
        }

        return dateRangeOrPreset.map((d) => moment.unix(d)) as TDatetimeRange;
    }
);

export const selectDateRange = createSelector(selectConfig, selectDateRangeOrPreset, (config, dateRangeOrPreset) => {
    const now = moment();
    return getDateRangeFromPreset(dateRangeOrPreset, config!, now);
});

export const selectSelectedServices = createSelector(
    selectDiscoveryDashboardControls,
    ({ selectedServices }) => selectedServices
);
export const selectSelectedServiceLabels = createSelector(
    selectDiscoveryDashboardControls,
    ({ selectedServiceLabels }) => selectedServiceLabels
);
export const selectServicesOrLabelsToggle = createSelector(
    selectDiscoveryDashboardControls,
    ({ serviceFilterBy }) => serviceFilterBy
);

export const selectServicesOrLabels = createSelector(
    selectServicesOrLabelsToggle,
    selectSelectedServices,
    selectSelectedServiceLabels,
    (filterBy, services, labels) => {
        if (filterBy === DiscoveryDashboardFilterEnum.SERVICES) {
            return services.length > 0 ? { service_names: services } : {};
        }
        return labels.length > 0 ? { service_labels: labels } : {};
    }
);

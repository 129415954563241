import { TimeDisplayResEnum } from 'general/utils';

const commonTableProperties = {
    type: 'basic',
    flex: 1,
    sortable: false,
};

export const getEntityListCols = (tableMenuItems: Function) => [
    {
        ...commonTableProperties,
        flex: 2,
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        flex: 2,
        headerName: 'Parameter',
        field: 'source_attribute',
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        type: 'agSetFilter',
        headerName: 'Family',
        field: 'family',
        cellRenderer: 'cellRenderVerticalCenter',
        sortable: true,
    },
    {
        ...commonTableProperties,
        type: 'agSetFilter',
        headerName: 'Class',
        field: 'class',
        cellRenderer: 'cellRenderVerticalCenter',
        sortable: true,
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified',
        field: 'updated_at',
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            timeResolution: TimeDisplayResEnum.MIN,
        },
        sortable: true,
    },
    {
        ...commonTableProperties,
        headerName: 'Modified By',
        field: 'updated_by',
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: '',
        field: 'hide',
        cellRenderer: 'cellRenderShowHide',
        flex: 0,
        width: 60,
        resizable: false,
    },
    {
        ...commonTableProperties,
        headerName: '',
        flex: 0,
        width: 60,
        field: 'menu',
        cellRenderer: 'cellRenderGenericMore',
        cellRendererParams: tableMenuItems,
        resizable: false,
    },
];

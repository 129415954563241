import React, { useCallback, useEffect, useRef, useState } from 'react';

export const useInfiniteScroll = (callback: Function, queryElement: string) => {
    const [loadExtraData, setLoadExtraData] = useState<any>({ isLoading: false, isTop: false });
    const scrollPosition = useRef<number>(0);
    const wrapperRef = useRef<Element>();

    const onScrollHandler = (e: any) => {
        const target = e.target as HTMLElement;
        const IsScrollingBottom = scrollPosition.current < target.scrollTop;
        scrollPosition.current = target.scrollTop;
        if (target.scrollHeight > target.clientHeight) {
            if (target.scrollTop < 80 && !IsScrollingBottom) {
                setLoadExtraData({ isLoading: true, isTop: true });
            }
            if (target.scrollTop + target.clientHeight > target.scrollHeight - 80 && IsScrollingBottom) {
                setLoadExtraData({ isLoading: true, isTop: false });
            }
        }
    };

    useEffect(() => {
        const wrapper = document.querySelector(queryElement);
        wrapper?.addEventListener('scroll', onScrollHandler);

        wrapperRef.current = wrapper as Element;

        return () => {
            wrapper?.removeEventListener('scroll', onScrollHandler);
        };
    }, [wrapperRef, queryElement]);

    useEffect(() => {
        const position = scrollPosition.current;
        if (loadExtraData.isLoading) {
            callback(loadExtraData.isTop);
            wrapperRef.current?.scrollTo(0, position);
        }
    }, [loadExtraData.isLoading]);

    return [loadExtraData, setLoadExtraData];
};

export const useVirtualScroll = (callback: Function, ref?: any) => {
    const lastFetch = useRef(0);

    const onScrollHandler = useCallback(
        (e: React.UIEvent<HTMLElement>) => {
            const { scrollTop, scrollHeight } = e.currentTarget;
            const now = new Date().getTime();
            if (scrollHeight - 200 < scrollTop && lastFetch.current < now - 1000) {
                callback();
                lastFetch.current = now;
            }
        },
        [callback, lastFetch]
    );

    useEffect(() => {
        ref?.current?.addEventListener('scroll', onScrollHandler);

        return () => {
            ref?.current?.removeEventListener('scroll', onScrollHandler);
        };
    }, [onScrollHandler, ref]);
};

import {
    ContentVisibilityModeEnum,
    MultiExpandableContainer,
} from 'sharedComponents/MultiExpandableContainer/MultiExpandableContainer';
import { EntitySummary } from 'features/user/user-summary/EntitySummary';
import { Divider } from 'antd';
import { EntityRequestCharts } from 'features/user/entity-request-chart/EntityRequestCharts';
import React, { useEffect, useState } from 'react';
import { httpAll, httpGet } from 'general/http-service';
import { IEntityChartSeries, IEntityChartTotals, IEntityStatistics } from 'interfaces/user.interface';
import { dateTimeStringFormat, getDateRangeAround } from 'general/utils';
import { useParams } from 'react-router-dom';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { useSelector } from 'react-redux';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { IInvestigateQueryParams } from 'features/user/User';

export interface IEntityChartTotalsResponse {
    counts: {
        '1xx': number;
        '2xx': number;
        '3xx': number;
        '4xx': number;
        '5xx': number;
        none: number;
    };
    total: number;
}

export const EntitySummaryWithChart = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const params = useParams<IInvestigateQueryParams>();
    const { entityType, eventType, eventId, eventTimestamp } = params;
    const [isTopBarExpanded, setIsTopBarExpanded] = useState<boolean>(false);
    const [chartsData, setChartsData] = useState<{ totals: IEntityChartTotals; series: IEntityChartSeries } | null>(
        null
    );
    const [allData, setAllData] = useState<{ entityStatistics?: IEntityStatistics }>({
        entityStatistics: undefined,
    });
    const eventDataTimestamp = parseInt(eventTimestamp);

    const sharedQueryString =
        `anchor_event_type=${eventType}&` +
        `anchor_id=${eventId}&` +
        `anchor_ts=${eventTimestamp}&` +
        `entity_name=${entityType}`;

    useEffect(() => {
        if (entityType && eventDataTimestamp) {
            const { from, to } = getDateRangeAround(eventDataTimestamp, 7);
            const totalRequestUrl = `organizations/${currentTenantKey}/investigate/request_total?${sharedQueryString}`;
            const reqSeriesUrl = `organizations/${currentTenantKey}/investigate/request_series?${sharedQueryString}&resolution=1d&from_timestamp=${from}&to_timestamp=${to}`;

            httpAll([totalRequestUrl, reqSeriesUrl]).then((res) => {
                const totalsData = {
                    total: res[0].data.total,
                    countsByStatus: Object.entries(res[0].data.counts as IEntityChartTotalsResponse).map(
                        (keyValPair: [string, number]) => {
                            return { name: keyValPair[0], value: keyValPair[1] };
                        }
                    ),
                };

                const seriesData = {
                    data: res[1].data.data,
                    legend: res[1].data.legend,
                    timestamps: res[1].data.timestamps.map((timestamp: number) => {
                        return dateTimeStringFormat(timestamp);
                    }),
                };
                setChartsData({ totals: totalsData, series: seriesData });
            });
        }
    }, []);

    useEffect(() => {
        httpGet(`organizations/${currentTenantKey}/investigate/entity_info?${sharedQueryString}`).then(
            ({ data: entityStatistics }: { data: IEntityStatistics }) => {
                setAllData((prevData: { entityStatistics?: IEntityStatistics }) => ({ ...prevData, entityStatistics }));
            }
        );
    }, []);

    return (
        <MultiExpandableContainer
            contentArray={[
                {
                    content: <EntitySummary entityStatistics={allData.entityStatistics} />,
                    mode: ContentVisibilityModeEnum.SHOWN_ALWAYS,
                },
                {
                    content: (
                        <Divider
                            type="horizontal"
                            className={`user-expandable-divider${isTopBarExpanded ? ' show' : ''}`}
                        />
                    ),
                    mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                },
                {
                    content: (
                        <EntityRequestCharts isDark customHeight={isTopBarExpanded ? 348 : 0} chartsData={chartsData} />
                    ),
                    mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                },
            ]}
            isExpanded={isTopBarExpanded}
            toggleExpanded={() => setIsTopBarExpanded((prev) => !prev)}
            isOverlay
            isDark
            isLoading={!eventDataTimestamp}
        />
    );
};

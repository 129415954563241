import React, { ReactNode } from 'react';
import { Button, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { CopyOutlined } from '@ant-design/icons';

import { copyToClipboard } from 'general/utils';

import './UiTooltip.scss';

interface ITooltipProps {
    title: string | ReactNode | any;
    children: any;
    overlayClassName?: string;
    wide?: boolean;
    unsetWidth?: boolean;
    placement?: TooltipPlacement;
    disableCopyButton?: boolean;
    delay?: number;
    visible?: boolean;
    copyText?: string;
    footer?: JSX.Element;
}

export const UiTooltip = ({
    title,
    children,
    placement,
    overlayClassName,
    wide,
    disableCopyButton,
    delay,
    visible = true,
    unsetWidth,
    copyText,
    footer,
}: ITooltipProps) => {
    const content = title && (
        <div className="tooltip-wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="tooltip-content">{title}</div>
            <div className="tooltip-footer">
                {footer}
                {disableCopyButton ? null : (
                    <Button
                        icon={<CopyOutlined />}
                        type="text"
                        onClick={() => {
                            if (typeof title === 'object') copyToClipboard(copyText || '');
                            else copyToClipboard(title);
                        }}
                    >
                        Copy
                    </Button>
                )}
            </div>
        </div>
    );

    function getClasses() {
        let classes = 'UiTooltip pointer-events ';

        if (wide) {
            classes += 'wide-tooltip ';
        }
        if (unsetWidth) {
            classes += 'unset-width ';
        }
        if (!visible) {
            classes += 'hidden ';
        }
        classes += overlayClassName;

        return classes;
    }

    return (
        <Tooltip
            zIndex={10000}
            title={content}
            mouseEnterDelay={delay}
            overlayClassName={getClasses()}
            placement={placement}
            color="white"
        >
            {children}
        </Tooltip>
    );
};

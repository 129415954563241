import { IAlert } from 'interfaces/alert.interface';
import { AlertItem } from './alert-item/AlertItem';

import './RecentOpenAlertsWidget.scss';

interface IRecentAlertsProps {
    alertsData: IAlert[];
    reloadAlertsCb: () => void;
}

export const RecentOpenAlertsWidget = ({ alertsData, reloadAlertsCb }: IRecentAlertsProps) => {
    return (
        <div className="RecentOpenAlertsWidget">
            {alertsData?.map((item, index) => (
                <AlertItem key={item.id} alertData={item} showGoToEndpointOption reloadAlertsCB={reloadAlertsCb} />
            ))}
        </div>
    );
};

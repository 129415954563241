import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

import { UiAutoComplete } from 'sharedComponents/UiAutoComplete/UiAutoComplete';
import { PAGE_SIZE_LIMIT, recursiveHttpGet } from 'general/http-service';
import { IEndpointDisplay, ISimpleEndpoint } from 'interfaces/endpoint.interface';
import { groupEndpointsByService } from 'general/utils';
import { getOrganizationConfig, IConfigRes } from 'general/organization-service';
import { errorMessage } from 'general/toast-service';
import { ISelectOptionGrp } from 'interfaces/query.interface';

import './EndpointMultiSelect.scss';

export type IEndpointMultiSelectValue = ISimpleEndpoint;

export interface IEndpointMultiSelectProps {
    value: IEndpointMultiSelectValue[];
    onChange: (event: IEndpointMultiSelectValue['id']) => any;
    label?: string;
    width?: number;
}

export const EndpointMultiSelect = (props: IEndpointMultiSelectProps) => {
    const params = useParams() as { activeOrg: string };
    const activeOrg: string = params.activeOrg;
    const [options, setOptions] = useState<ISelectOptionGrp[]>(
        groupEndpointsByService(props.value as IEndpointDisplay[])
    );

    const resolve = (endpointList: IEndpointDisplay[]) => {
        setOptions(groupEndpointsByService(endpointList));
    };

    useEffect(() => {
        getOrganizationConfig(activeOrg)
            .then((config: IConfigRes) => {
                const fromTimestamp = moment(config.timeframe.start_timestamp).unix();
                const toTimestamp = moment(config.timeframe.end_timestamp).unix();

                recursiveHttpGet(
                    `organizations/${activeOrg}/discovery/endpoints?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${fromTimestamp}&to_timestamp=${toTimestamp}`,
                    0,
                    PAGE_SIZE_LIMIT,
                    [],
                    resolve,
                    () => {}
                );
            })
            .catch((error) => {
                console.error(error);
                errorMessage('Error fetching data');
                throw new Error(error);
            });
    }, []);

    return (
        <div className="endpoint-multi-select">
            <div className="ems-label">{props.label || 'Endpoint'}</div>
            <UiAutoComplete
                mode="multiple"
                options={options}
                width={800}
                defaultValue={props.value?.map?.((endpoint) => endpoint.id)}
                onChange={props.onChange}
            />
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Select } from 'antd';

import { SuppressionRulePredicate } from '../SuppressionRule/SuppressionRulePredicate';
import { predicateTypeMap } from '../SuppressionRule/SuppressionRule';
import { IAlertRulePredicate } from 'interfaces/throttle.interface';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { IOverlayMenu, UiOverlayMenu } from 'sharedComponents/UiOverlayMenu/UiOverlayMenu';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { UiButton } from 'sharedComponents/button/Button';
import {
    IAutomatedActionRule,
    useGetAutomatedActionRulesQuery,
    useGetAvailableActionsQuery,
    usePatchAutomatedActionRuleMutation,
    usePostAutomatedActionRuleMutation,
} from 'api/automatedActionRulesApi';
import { ISimpleEndpoint } from 'interfaces/endpoint.interface';
import {
    EntityMultiSelectState,
    isValidEntity,
} from 'features/settings/components/SuppressionRule/EntityMultiSelectList/EntityMultiSelect';

import '../SuppressionRule/SuppressionRule.scss';

const validateRuleForm = (ruleForm: IAutomatedActionRule): { [key: string]: string } => {
    const ruleFormError: { [key: string]: string } = {};

    if (!ruleForm.name) {
        ruleFormError.name = 'This field is required';
    }

    if (
        ruleForm.predicate?.associated_entities &&
        ruleForm.predicate?.associated_entities?.length > 0 &&
        ruleForm.predicate?.associated_entities?.filter((entity) => !isValidEntity(entity)).length > 0
    ) {
        ruleFormError.associated_entity = 'This field is required';
    }

    if (!ruleForm.automated_action_id) {
        ruleFormError.automated_action_id = 'This field is required';
    }

    return ruleFormError;
};

export const AutomatedActionRule: React.FC = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string; ruleId: string };
    const activeOrg = params.activeOrg;
    const ruleId = params.ruleId;
    const [ruleForm, setRuleForm] = useState<IAutomatedActionRule>();
    const [ruleFormError, setRuleFormError] = useState<{ [key: string]: string }>({});
    const { data: rulesResponse, error: rulesError } = useGetAutomatedActionRulesQuery();
    const { data: availableActionsResponse, error: availableActionsError } = useGetAvailableActionsQuery();
    const [postRule] = usePostAutomatedActionRuleMutation();
    const [patchRule] = usePatchAutomatedActionRuleMutation();

    useEffect(() => {
        const rule = rulesResponse?.items.find((rule) => rule.id === ruleId);
        if (rule) {
            setRuleForm(rule);
        }
    }, [rulesResponse, ruleId]);

    useEffect(() => {
        if (rulesError || availableActionsError) {
            errorMessage(extractErrorMessage(rulesError || availableActionsError));
        }
    }, [rulesError, availableActionsError]);

    const save = () => {
        const validationError = validateRuleForm(ruleForm || ({} as IAutomatedActionRule));
        if (Object.keys(validationError as {}).length || !ruleForm) {
            setRuleFormError(validationError);
            return;
        }

        let promise;

        const predicate = {
            ...ruleForm?.predicate,
            endpoints: ruleForm?.predicate?.endpoints?.map((endpoint: ISimpleEndpoint | string) =>
                endpoint.hasOwnProperty('id') ? (endpoint as ISimpleEndpoint).id : (endpoint as string)
            ),
        };

        if (ruleForm?.id) {
            promise = patchRule({
                id: ruleForm.id,
                body: {
                    name: ruleForm.name,
                    predicate,
                    automated_action_id: ruleForm.automated_action_id,
                },
            }).unwrap();
        } else {
            promise = postRule({
                body: {
                    name: ruleForm.name,
                    predicate,
                    automated_action_id: ruleForm.automated_action_id,
                },
            }).unwrap();
        }

        promise
            .then(() => {
                history.push({ pathname: `/${activeOrg}/settings/automated-actions` });
            })
            .catch((error: any) => {
                return errorMessage(extractErrorMessage(error));
            });
    };

    function cancel() {
        history.push(`/${activeOrg}/settings/automated-actions`);
    }

    function onFormChange(key: string, value: any) {
        let newRuleForm: IAutomatedActionRule;

        if (key === 'name' || key === 'automated_action_id') {
            newRuleForm = {
                ...ruleForm,
                [key]: value,
            } as IAutomatedActionRule;
        } else if (key === 'alert_description' && value !== undefined) {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: {
                        operand: value.contains === undefined || value.contains ? 'contains' : 'not contains',
                        value: value.text,
                    },
                },
            } as IAutomatedActionRule;
        } else if (key === 'associated_entities' && value?.length === 0) {
            removePredicate(key);
            return;
        } else if (key === 'associated_entities') {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: value?.map?.((v: EntityMultiSelectState) => v.entity),
                },
            } as IAutomatedActionRule;
        } else {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: value,
                },
            } as IAutomatedActionRule;
        }

        setRuleForm(newRuleForm);
        setRuleFormError(validateRuleForm(newRuleForm));
    }

    function createPredicate(key: string, value?: any, withSeparator?: boolean): JSX.Element {
        return (
            <div key={key} className="sr-predicate-container">
                {withSeparator ? (
                    <div className="sr-separator">
                        AND
                        <div className="sr-separator-line"></div>
                    </div>
                ) : null}
                <div className="sr-predicate">
                    {key !== 'associated_entities' ? (
                        <span className="sr-remove">
                            <UiIcon name="remove" onClick={() => removePredicate(key)} />
                        </span>
                    ) : null}
                    <SuppressionRulePredicate
                        predicateType={predicateTypeMap[key]}
                        value={value || []}
                        onChange={(e: any) => onFormChange(key, e)}
                    />
                </div>
            </div>
        );
    }

    function createPredicateElements(predicate: IAlertRulePredicate): JSX.Element[] {
        return Object.keys(predicate)
            .filter((key) => !!predicate[key as keyof IAlertRulePredicate])
            .map((key, i) =>
                createPredicate(
                    key,
                    key === 'alert_description'
                        ? {
                              contains: predicate.alert_description?.operand === 'contains',
                              text: predicate.alert_description?.value,
                          }
                        : predicate[key as keyof IAlertRulePredicate],
                    i > 0
                )
            );
    }

    function addPredicate(key: string) {
        onFormChange(key, key === 'associated_entities' ? [{ entity: { type: '', id: [] } }] : []);
    }

    function removePredicate(key: string) {
        onFormChange(key, undefined);
    }

    function getPredicateOptions(predicate: IAlertRulePredicate): IOverlayMenu['menuItems'] {
        const menuItems = [];

        if (!predicate.endpoints) {
            menuItems.push({
                label: 'Endpoint',
                onClick: () => addPredicate('endpoints'),
            });
        }

        if (!predicate.associated_entities) {
            menuItems.push({
                label: 'Entity',
                onClick: () => addPredicate('associated_entities'),
            });
        }

        if (!predicate.services) {
            menuItems.push({
                label: 'Service',
                onClick: () => addPredicate('services'),
            });
        }

        if (!predicate.labels) {
            menuItems.push({
                label: 'Label',
                onClick: () => addPredicate('labels'),
            });
        }

        if (!predicate.alert_description) {
            menuItems.push({
                label: 'Alert Description',
                onClick: () => addPredicate('alert_description'),
            });
        }

        if (!predicate.alert_names) {
            menuItems.push({
                label: 'Alert Name',
                onClick: () => addPredicate('alert_names'),
            });
        }

        if (!predicate.alert_categories) {
            menuItems.push({
                label: 'Alert Category',
                onClick: () => addPredicate('alert_categories'),
            });
        }

        if (!predicate.alert_severities) {
            menuItems.push({
                label: 'Alert Severity',
                onClick: () => addPredicate('alert_severities'),
            });
        }

        return menuItems;
    }

    return (ruleForm || ruleId === 'add') && availableActionsResponse ? (
        <div className="suppression-rule">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <UiInput
                    label="Rule Name"
                    defaultValue={ruleForm?.name || ''}
                    onBlur={(event: any) => onFormChange('name', event.target.value)}
                    width={500}
                    error={ruleFormError?.name}
                />
                <div>
                    <UiButton type="primary" text="Save" onClick={() => save()} />
                    <UiButton type="secondary" text="Cancel" onClick={() => cancel()} style={{ marginLeft: '5px' }} />
                </div>
            </div>
            <h3>Conditions</h3>
            <div className="box sr-conditions">
                <div className="sr-predicate-list">{createPredicateElements(ruleForm?.predicate || {})}</div>
                <div style={{ width: '200px' }}>
                    <UiOverlayMenu
                        icon={
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <UiIcon name="add" />
                                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add condition</span>
                            </div>
                        }
                        menuItems={getPredicateOptions(ruleForm?.predicate || {})}
                    />
                </div>
            </div>
            <h3>Action</h3>
            <div className="box" style={{ padding: '15px' }}>
                <div style={{ position: 'relative', width: '300px' }}>
                    <Select
                        style={{ width: 300 }}
                        options={availableActionsResponse.items.map((action) => ({
                            label: action.name,
                            value: action.id,
                        }))}
                        // @ts-ignore
                        defaultValue={ruleForm?.automated_action_id}
                        onChange={(actionId) => onFormChange('automated_action_id', actionId)}
                    />
                    <div className="ui-input-error">{ruleFormError?.automated_action_id}</div>
                </div>
                <div style={{ marginTop: '15px' }}>
                    {
                        availableActionsResponse.items.find((action) => action.id === ruleForm?.automated_action_id)
                            ?.description
                    }
                </div>
            </div>
        </div>
    ) : null;
};

import { useMemo } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';
import { matchPath, useLocation } from 'react-router-dom';

import { EventTypesEnum } from 'enums/eventTypes.enum';
import { getEntityURL, investigateInNewTab, singleLineWithTooltip } from 'general/utils';
import { IApiCall } from 'interfaces/apiCall.interface';
import { IApiCallParameter } from 'interfaces/apiCallParameter.interface';
import { ITableItem, UiSimpleTable } from './UiSimpleTable';
import { IEntity } from 'api/entitiesApi';

import './SingleCallDetails.scss';

interface IApiDetails {
    data: IApiCall;
}

const { TabPane } = Tabs;

export const SingleCallDetails = (props: IApiDetails) => {
    const match = matchPath(useLocation().pathname, {
        path: '/:activeOrg',
        exact: false,
        strict: false,
    });
    const { activeOrg } = match?.params as { activeOrg: string };
    const { data } = props;

    const groupAttrByTab = (targetTab: string, data: IApiCallParameter[]): { [key: string]: IApiCallParameter[] }[] => {
        if (!targetTab || !data) return [];

        const grouped = data.reduce((items: { [key: string]: IApiCallParameter[] }[], attr: IApiCallParameter, i) => {
            let sortingField = 'part_of';
            if (targetTab === 'enrichment' || targetTab === 'log' || targetTab === 'entities') {
                sortingField = 'in';
            }

            if (attr[sortingField as keyof IApiCallParameter]?.toLowerCase() === targetTab.toLowerCase()) {
                // create group or add to existing group
                let index = items.findIndex((group) => group.hasOwnProperty(attr.in));
                if (index > -1) {
                    items[index][attr.in] = [...items[index][attr.in], attr];
                } else {
                    items.push({ [attr.in]: [attr] });
                }
            }
            return items;
        }, []);

        if (targetTab === 'request' || targetTab === 'response') {
            let sorted = [] as { [key: string]: IApiCallParameter[] }[];
            const headers = grouped.find((item) => item.hasOwnProperty('Headers'));
            const query = grouped.find((item) => item.hasOwnProperty('Query'));
            const body = grouped.find((item) => item.hasOwnProperty('Body'));
            const path = grouped.find((item) => item.hasOwnProperty('Path'));

            path && sorted.push(path);
            headers && sorted.push(headers);
            query && sorted.push(query);
            body && sorted.push(body);

            return sorted;
        }

        return grouped;
    };

    const prepareListDisplay = (groupedAttr: { [key: string]: IApiCallParameter[] }[]): ITableItem[] => {
        return groupedAttr.reduce((curr: ITableItem[], group: any) => {
            const groupName: string = Object.keys(group)[0];

            group[groupName].sort((a: IApiCallParameter, b: IApiCallParameter) => a.name.localeCompare(b.name));

            return [
                ...curr,
                { name: groupName, id: 'header-id', value: '', isHeader: true },
                ...group[groupName].map(({ name, value }: IApiCallParameter, index: number) => ({
                    name,
                    value,
                    id: `${name}-${index}`,
                    expandable: name.toLowerCase() === 'payload' ? 'pretty' : false,
                })),
            ];
        }, []);
    };

    type tabList = 'request' | 'response' | 'entities' | 'log' | 'enrichment';

    const prepareEntitiesTab = () => {
        if (!data.entities?.length) {
            return [];
        }

        return data.entities
            .filter((entity: IEntity) => entity.value)
            .reduce(
                (acc: ITableItem[], { name, value, active }: IEntity) => [
                    ...acc,
                    {
                        name: `${name} ${active ? '' : '(deleted)'}`,
                        id: `${name}-id`,
                        value: (
                            <div className="entities value list-row text clamp-1-line">
                                <a
                                    className="ns-link clamp-1-line"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        investigateInNewTab(
                                            process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                                            {
                                                key: activeOrg,
                                                id: activeOrg,
                                                name: activeOrg,
                                            },
                                            EventTypesEnum.Call,
                                            props.data.id,
                                            name,
                                            value!,
                                            moment(data.timestamp).valueOf()
                                        );
                                    }}
                                    href={getEntityURL(
                                        process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                                        activeOrg,
                                        name,
                                        value!,
                                        EventTypesEnum.Call,
                                        data.id
                                    )}
                                >
                                    {singleLineWithTooltip(value!, undefined, undefined, undefined, undefined, true)}
                                </a>
                            </div>
                        ),
                    },
                ],
                [{ name: 'Entities', id: 'header-id', value: '', isHeader: true }]
            );
    };

    const apiData = useMemo<{ [key: string]: ITableItem[] }>(() => {
        const getAttrListForTab = (tab: tabList) => prepareListDisplay(groupAttrByTab(tab, data.attributes));

        return {
            request: getAttrListForTab('request'),
            response: getAttrListForTab('response'),
            entities: prepareEntitiesTab(),
            log: getAttrListForTab('log'),
            enrichment: getAttrListForTab('enrichment'),
        };
    }, [data]);

    return (
        <div className="SingleCallDetails">
            <Tabs type="card">
                {['request', 'response', 'entities', 'enrichment', 'log']
                    .filter((tab) => apiData[tab]?.length > 0)
                    .map((tab) => (
                        <TabPane tab={tab} key={tab}>
                            <UiSimpleTable
                                headers={[apiData[tab][0]?.name ?? '', '']}
                                rowsData={apiData[tab].slice(1)}
                                resizable
                            />
                        </TabPane>
                    ))}
            </Tabs>
        </div>
    );
};

import { apiTags, IServerQueryResponse, tenantApi } from 'api/baseServerApi';
import { IEndpointDeletionRequest, IExclusionRuleForm, IExclusionRulePreviewItem } from 'api/exclusionRuleApi';

export const endpointDeletionRequestApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        previewEndpointDeletionRequest: builder.query<
            IServerQueryResponse<IExclusionRulePreviewItem>,
            IExclusionRuleForm
        >({
            query: (exclusionRule) => ({
                url: `discovery/endpoints/deletion_requests/preview`,
                method: 'POST',
                body: exclusionRule,
            }),
            transformResponse: (responseData: IServerQueryResponse<IExclusionRulePreviewItem>) => {
                return {
                    ...responseData,
                    items: responseData.items.map((previewItem) => ({
                        ...previewItem,
                        methods: previewItem.method ? [previewItem.method] : [],
                    })),
                };
            },
        }),
        createEndpointDeletionRequest: builder.mutation<void, IExclusionRuleForm>({
            query: (exclusionRule) => ({
                url: `discovery/endpoints/deletion_requests`,
                method: 'POST',
                body: exclusionRule,
            }),
            invalidatesTags: [apiTags.endpointExclusionRules],
        }),
        // TODO: Test once BE is ready, blocked by PROT-17693
        createEndpointDeletionByIdsRequest: builder.mutation<void, IEndpointDeletionRequest>({
            query: (body) => ({
                url: `discovery/endpoints/deletion_requests`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const {
    useLazyPreviewEndpointDeletionRequestQuery,
    useCreateEndpointDeletionRequestMutation,
    useCreateEndpointDeletionByIdsRequestMutation,
} = endpointDeletionRequestApi;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Editor, { useMonaco } from '@monaco-editor/react';
import { Form, Input, Menu } from 'antd';

import { ISettingsPathParams } from '../../../Settings';
import {
    getConfiguration,
    ICollectorConfiguration,
    ICollectorConfigurationConfigFile,
} from '../shared/collectorConfigurationApis';
import { errorMessage, successMessage } from 'general/toast-service';
import { SettingsSectionHeader } from '../../SettingsSectionHeader/SettingsSectionHeader';
import { UiButton } from 'sharedComponents/button/Button';
import { formFieldName, formFieldRequired, isDuplicatedNameValidator } from 'general/forms';
import { UiVerticalMenu } from 'sharedComponents/shared/ui-vertical-menu/UiVerticalMenu';
import {
    CollectorConfigurationSaveConfirmationModal,
    IConfirmSaveConfigFiles,
} from './CollectorConfigurationSaveConfirmationModal/CollectorConfigurationSaveConfirmationModal';
import { useFetchAllCollectorConfigurations } from '../shared/useFetchAllCollectorConfigurations';
import { IDataSourceType, useGetCollectorConfigFilesQuery, useGetDataSourceTypesQuery } from 'api/nodeApi';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './CollectorConfigurationAddEdit.scss';

interface ICollectorConfigurationAddEditOPathParams extends ISettingsPathParams {
    configId?: string;
}

// Used to 'find' the ports definition in logstash.conf file and identify collisions:
const portsIdentifyingRegex = /^(?!\s*#).*port\s*=>\s*(\d+)\s*$/gm;

const fileTypesToEditorType = {
    config: 'ruby',
    conf: 'ruby',
    options: 'ruby',
    properties: 'ruby',
    yml: 'yaml',
    json: 'json',
} as Record<string, string>;

export const CollectorConfigurationAddEdit = () => {
    const monaco = useMonaco();
    const [editorContent, setEditorContent] = useState({
        fileContent: '{}',
        fileType: 'ruby',
    });

    const { configId, activeOrg } = useParams<ICollectorConfigurationAddEditOPathParams>();
    const history = useHistory();
    const [formForName] = Form.useForm();
    const queryParams = new URLSearchParams(useLocation().search);
    const nameParam = queryParams.get('name');
    const dataSourceTypeFilenames = queryParams.get('data_source_types');
    const [showPortWarning, setShowPortWarning] = useState(false);
    const { data: universalConfigTemplate } = useGetCollectorConfigFilesQuery(
        {
            dataSourceFilenames: dataSourceTypeFilenames?.split(',') ?? [],
        },
        { skip: Boolean(configId) }
    );
    const [universalConfigTemplateVersion, setUniversalConfigTemplateVersion] = useState('');
    const { data: dataSourceTypes } = useGetDataSourceTypesQuery();

    const [origCollectorConfig, setOrigCollectorConfig] = useState<ICollectorConfiguration>();
    const [existingConfigs, setExistingConfigs] = useState<ICollectorConfiguration[]>();
    const [fileInEditor, setFileInEditor] = useState<ICollectorConfigurationConfigFile>();
    // orig and current config files below help manage the 'modified' state for each file:
    const [origConfigFiles, setOrigConfigFiles] = useState<ICollectorConfigurationConfigFile[]>();
    const [currentConfigFiles, setCurrentConfigFiles] = useState<ICollectorConfigurationConfigFile[]>();
    const [currentConfigName, setCurrentConfigName] = useState<string>('');
    const [confirmSaveConfigFiles, setConfirmSaveConfigFiles] = useState<IConfirmSaveConfigFiles>();

    useFetchAllCollectorConfigurations(setExistingConfigs);

    useEffect(() => {
        monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true);
        const fileType = fileInEditor?.fileName.split('.').pop() || '';
        if (fileInEditor) {
            setEditorContent({
                fileContent: fileInEditor?.fileContent || '{}',
                fileType: fileTypesToEditorType[fileType] || 'ruby',
            });
        }
    }, [monaco, fileInEditor]);

    useEffect(() => {
        formForName.setFieldValue('name', decodeURIComponent(nameParam ?? ''));
        setCurrentConfigName(decodeURIComponent(nameParam ?? ''));
        const files = decodeURIComponent(dataSourceTypeFilenames ?? '').split(',');
        const fileMap =
            dataSourceTypes?.items.reduce((res: { [key: string]: string }, type: IDataSourceType) => {
                res[type.filename] = type.displayName;
                return res;
            }, {}) ?? {};
        formForName.setFieldValue('dataSourceTypesFilenames', files.map((file) => fileMap[file]).join(', '));
    }, [formForName, nameParam, dataSourceTypeFilenames, dataSourceTypes?.items]);

    const detectPortCollision = useCallback(
        (fileContent?: string) => {
            const portsMatched = fileContent?.matchAll(portsIdentifyingRegex);
            let portNumbers: string[] = [];
            let warnDuplicatePorts = false;
            for (const port of portsMatched!) {
                if (portNumbers.includes(port[1])) {
                    warnDuplicatePorts = true;
                    break;
                }
                portNumbers.push(port[1]);
            }
            setShowPortWarning(warnDuplicatePorts);
        },
        [setShowPortWarning]
    );

    // fetch default config files in 'add' use case or get the collector config full details:
    useEffect(() => {
        if (configId) {
            const getConfigFullDetails = async () => {
                try {
                    const config = await getConfiguration(activeOrg, configId);
                    if (!config.edges?.collector_config_files) {
                        throw new Error('No config files - cannot edit the Node Configuration');
                    }
                    const dataSourceTypesFilenames = (config.edges?.data_source_types_of_collector_config ?? [])
                        .map((type) => type.edges.data_source_type.displayName)
                        .join(', ');
                    detectPortCollision(config.edges?.collector_config_files[0]?.fileContent);
                    formForName.setFieldValue('dataSourceTypesFilenames', dataSourceTypesFilenames);
                    setOrigCollectorConfig(config);
                    config.edges?.collector_config_files && setOrigConfigFiles(config.edges.collector_config_files);
                    setUniversalConfigTemplateVersion(config?.universalConfigTemplateVersion);
                    // for some reason the formForName isn't considered valid after initialization so a little help:
                    formForName.setFieldsValue({ name: config.name });
                } catch (e) {
                    errorMessage('Error: cannot fetch Node Configuration details', { duration: 5 });
                    history.push({ pathname: `/${activeOrg}/settings/node-configurations` });
                }
            };
            getConfigFullDetails();
        } else {
            const getConfigFiles = async () => {
                try {
                    if (universalConfigTemplate?.files) {
                        // default config files fetched from server contain no IDs yet we need those IDs in the UI so add ones:
                        detectPortCollision(universalConfigTemplate.files[0]?.fileContent);
                        const enrichedConfigFiles = universalConfigTemplate.files.map(
                            (configFile: ICollectorConfigurationConfigFile, index: number) => {
                                return { id: index, ...configFile };
                            }
                        );
                        setUniversalConfigTemplateVersion(universalConfigTemplate?.universalConfigTemplateVersion);
                        setOrigConfigFiles(enrichedConfigFiles);
                    }
                } catch (e) {
                    errorMessage('Error: cannot fetch Node Configuration details', { duration: 5 });
                }
            };
            getConfigFiles();
        }
    }, [activeOrg, configId, history, formForName, detectPortCollision, universalConfigTemplate?.files]);

    // once origConfigFiles is populated duplicate its data to 'currentConfigFiles' which will aid in determining which
    // files were modified on user interaction:
    useEffect(() => {
        if (origConfigFiles) {
            const clonedOrigFiles = JSON.parse(JSON.stringify(origConfigFiles));
            // we need to add the marker for 'modified' for each config-file
            const configFilesWithModifiedFlag = clonedOrigFiles.map((file: ICollectorConfigurationConfigFile) => {
                return { ...file, modified: false };
            });
            setCurrentConfigFiles(configFilesWithModifiedFlag);
        }
    }, [origConfigFiles]);

    // when currentConfigFiles is initialized make sure to open the first one in the editor:
    useEffect(() => {
        currentConfigFiles && setFileInEditor(currentConfigFiles[0]);
    }, [currentConfigFiles]);

    const selectedNewFileForEdit = useCallback(
        (fileMenuItem: any) => {
            const newlySelectedFile = currentConfigFiles?.find((file: ICollectorConfigurationConfigFile) => {
                return String(file.id) === fileMenuItem.key;
            }) as ICollectorConfigurationConfigFile;

            currentConfigFiles && setFileInEditor(newlySelectedFile);
        },
        [currentConfigFiles]
    );

    const fileContentChanged = (fileContent: string) => {
        // typescript pacifier:
        if (!currentConfigFiles || !fileInEditor || !origConfigFiles) {
            return;
        }
        detectPortCollision(fileContent);

        // update the content of the file in currentConfigFiles:
        (
            currentConfigFiles.find((file) => file.id === fileInEditor?.id) as ICollectorConfigurationConfigFile
        ).fileContent = fileContent;
        setFileInEditor((file) => ({ ...file, fileContent: fileContent } as ICollectorConfigurationConfigFile));

        // also update the 'modified' state in of the file currentConfigFiles:
        const origFileContent = origConfigFiles.find((file) => file.id === fileInEditor.id)?.fileContent || '';
        const currConfigFile = currentConfigFiles.find((file) => file.id === fileInEditor.id);
        (currConfigFile as ICollectorConfigurationConfigFile).modified =
            origFileContent !== currConfigFile?.fileContent;
    };

    const hasModifiedFiles = () => currentConfigFiles && currentConfigFiles.some((configFile) => configFile.modified);

    const saveConfigStart = async () => {
        await formForName.validateFields();
        if (formForName.getFieldValue('name') === origCollectorConfig?.name && !hasModifiedFiles()) {
            successMessage('No changes - nothing to save');
            history.goBack();
        }
        setConfirmSaveConfigFiles({
            currentConfigFiles,
            dataSourceFilenames: dataSourceTypeFilenames?.split(',') ?? [],
            universalConfigTemplateVersion: universalConfigTemplateVersion ?? '',
            name: formForName.getFieldValue('name'),
        });
    };

    const handleSaveConfigConfirmed = (redirectBack = true) => {
        setConfirmSaveConfigFiles(undefined);
        redirectBack && history.goBack();
    };

    const cancelSave = useCallback(() => {
        history.goBack();
    }, [history]);

    const isSaveButtonDisabled = () => {
        const nameHasValidationError = formForName.getFieldsError()[0]?.errors.length !== 0;

        const isEmptyNameValue = formForName.getFieldValue('name')?.trim() === '';
        const nameOrFilesWereNotModified = origCollectorConfig?.name === currentConfigName && !hasModifiedFiles();
        const isInPristineState = currentConfigName === '' && !hasModifiedFiles();
        return isEmptyNameValue || nameHasValidationError || nameOrFilesWereNotModified || isInPristineState;
    };

    const readonly = !!origCollectorConfig?.edges?.collectors;
    return (
        <div className="CollectorConfigurationAddEdit">
            <header>
                <SettingsSectionHeader title={`${configId ? 'Edit' : 'Add'} Node Configuration`} />
                <div className="buttons">
                    <UiButton type="secondary" text="Cancel" onClick={cancelSave} />
                    <UiButton type="primary" text="Save" onClick={saveConfigStart} disabled={isSaveButtonDisabled()} />
                </div>
            </header>
            <div className="content">
                <Form
                    form={formForName}
                    className="config-name-input"
                    colon={false}
                    layout="vertical"
                    requiredMark={false}
                    onFieldsChange={(arg) => {
                        setCurrentConfigName(arg[0].value);
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: formFieldRequired.errorMessage,
                            },
                            {
                                pattern: formFieldName.pattern,
                                message: formFieldName.errorMessage,
                            },
                            {
                                validator: (_, value) =>
                                    isDuplicatedNameValidator(
                                        existingConfigs!,
                                        value,
                                        'Configuration',
                                        origCollectorConfig?.name
                                    ),
                            },
                        ]}
                    >
                        <Input placeholder="E.g. My conf v1" disabled={readonly} />
                    </Form.Item>
                    <Form.Item name="dataSourceTypesFilenames" label="Data Source Type" validateFirst rules={[]}>
                        <div className="data-source-filenames-field">
                            <p>{formForName.getFieldValue('dataSourceTypesFilenames')}</p>
                        </div>
                    </Form.Item>
                    {showPortWarning && (
                        <div className="port-warning">
                            <UiIcon name="redWarning" />
                            WARNING! Multiple integrations use the same port number, creating a potential conflict.
                            Please configure the integrations to use unique ports.
                        </div>
                    )}
                </Form>
                <div className="files-editor-content">
                    <UiVerticalMenu className="files-navigator">
                        {currentConfigFiles && (
                            <Menu
                                defaultSelectedKeys={[String(currentConfigFiles[0]?.id)]}
                                onSelect={selectedNewFileForEdit}
                            >
                                {currentConfigFiles &&
                                    currentConfigFiles.map((configFile) => (
                                        <Menu.Item
                                            key={`${configFile.id}`}
                                            icon={configFile.modified ? <div>*</div> : undefined}
                                        >
                                            {configFile.fileName}
                                        </Menu.Item>
                                    ))}
                            </Menu>
                        )}
                    </UiVerticalMenu>
                    <Editor
                        className="file-content"
                        height="100%"
                        width="95%"
                        value={editorContent.fileContent}
                        language={editorContent.fileType}
                        onChange={(value?: string) => fileContentChanged(value || '')}
                        options={{
                            readOnly: readonly,
                            automaticLayout: true,
                            scrollBeyondLastLine: false,
                            minimap: {
                                enabled: false,
                            },
                        }}
                    />
                </div>
            </div>
            {confirmSaveConfigFiles && (
                <CollectorConfigurationSaveConfirmationModal
                    onClose={handleSaveConfigConfirmed}
                    confirmSaveConfigFiles={confirmSaveConfigFiles}
                    configId={configId}
                />
            )}
        </div>
    );
};

import { useEffect, useState, createContext } from 'react';
import { useParams, useHistory, Switch, Redirect, Route } from 'react-router-dom';
import { Button } from 'antd';

import { ISettingsPathParams } from '../../../Settings';
import { CollectorList } from '../../collectors/CollectorList/CollectorList';
import { getDataSource, IDataSource } from '../shared/dataSourceApis';
import { SettingsSectionHeader } from '../../SettingsSectionHeader/SettingsSectionHeader';
import { baseDataSourceDetailsUrl } from '../DataSourcesRoutes/DataSourcesRoutes';
import { BreadCrumb, INavigationLinks } from 'sharedComponents/breadcrumb/BreadCrumb';
import { UiTabsNav } from 'sharedComponents/UiTabsNav/UiTabsNav';

import './DataSourceDetails.scss';

interface IDataSourceListPathParams extends ISettingsPathParams {
    dataSourceId?: string;
}

export const DataSourceDetailsContext = createContext<{ currentDataSource?: IDataSource }>({
    currentDataSource: undefined,
});

export const DataSourceDetails = () => {
    const { activeOrg, dataSourceId } = useParams<IDataSourceListPathParams>();
    const [dataSource, setDataSource] = useState<IDataSource>();
    const history = useHistory();

    const breadcrumbList: INavigationLinks[] = [
        {
            url: `/${activeOrg}/settings/data-sources`,
            text: 'Data Sources',
        },
        {
            url: `/${activeOrg}/settings/data-sources/${dataSource?.id}`,
            text: dataSource?.name || '',
        },
    ];

    useEffect(() => {
        (async () => {
            const ds = await getDataSource(activeOrg, dataSourceId!);
            setDataSource(ds);
        })();
    }, [activeOrg, dataSourceId]);

    return (
        <DataSourceDetailsContext.Provider value={{ currentDataSource: dataSource }}>
            <div className="DataSourceDetails-wrapper">
                <BreadCrumb breadcrumbList={breadcrumbList} selectedRoute={breadcrumbList[1].text} />
                <SettingsSectionHeader title={dataSource?.name || ''} />
                <div className="DataSourceDetails">
                    <div className="top-bar">
                        <UiTabsNav
                            tabPosition="bottom"
                            items={[{ key: 'Collectors', label: 'Neosec Nodes' }]}
                            onTabSelected={() => {}}
                        />
                        <Switch>
                            <Route path={`${baseDataSourceDetailsUrl}/nodes`}>
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        history.push(`/${activeOrg}/settings/data-sources/${dataSourceId}/nodes/new`)
                                    }
                                >
                                    Add Node
                                </Button>
                            </Route>
                            <Route path={`${baseDataSourceDetailsUrl}/sensors`}>
                                <Button type="primary" onClick={() => alert('Sensor adding will be added later')}>
                                    Add Sensor
                                </Button>
                            </Route>
                        </Switch>
                    </div>
                    <Switch>
                        <Route exact path={`${baseDataSourceDetailsUrl}/nodes/new`}>
                            <CollectorList addMode />
                        </Route>
                        <Route exact path={`${baseDataSourceDetailsUrl}/nodes`}>
                            <CollectorList />
                        </Route>
                        <Route exact path={`${baseDataSourceDetailsUrl}`}>
                            <Redirect to={`${history.location.pathname}/nodes`} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </DataSourceDetailsContext.Provider>
    );
};

import { skipToken } from '@reduxjs/toolkit/query';

import { IServiceRisk, useGetServiceRiskQuery } from 'api/discoveryApi';
import Spinner from 'sharedComponents/spinner/Spinner';
import { defaultGraphColors } from 'styles/consts';
import { DiscoveryDashboardWidgetProps } from '../../discoveryDashboardTypes';
import { DiscoveryDashboardEmptyState } from '../../DiscoveryDashboardEmptyState/DiscoveryDashboardEmptyState';
import pieWidgetEmptyState from '../pieWidgetEmptyState.svg';
import { modifyWidgetRequestLimit } from '../../DiscoveryDashboard';
import { UiPieChart } from 'sharedComponents/UiPieChart/UiPieChart';

import './DiscoveryServiceRiskWidget.scss';

const formatData = (data?: IServiceRisk[]) =>
    (data || []).reduce((acc: { name: string; value: number }[], { service_name, risk_score }: IServiceRisk) => {
        acc.push({ name: service_name, value: risk_score });
        return acc;
    }, []);

export const DiscoveryServiceRiskWidget = ({ forceRender, commonRequestPayload }: DiscoveryDashboardWidgetProps) => {
    const { data, isLoading } = useGetServiceRiskQuery(modifyWidgetRequestLimit(5, commonRequestPayload) || skipToken);

    if (isLoading) {
        return <Spinner show paddingBottom={50} />;
    }

    const noData = !data || data?.items.length === 0;

    const pieData = formatData(data?.items);
    if (data?.other) {
        pieData.push({ name: 'Other', value: data?.other });
    }

    if (noData) {
        return <DiscoveryDashboardEmptyState title="Service Risk" widgetSvg={pieWidgetEmptyState} />;
    }

    return (
        <div className="DiscoveryServiceRiskWidget">
            <div className="pie-widget-title">Service Risk</div>
            <div className="pie-widget-chart" key={forceRender + 'DiscoveryServiceRiskWidget'}>
                <UiPieChart
                    data={pieData}
                    colors={defaultGraphColors}
                    rechartsLegendProps={{ align: 'right', verticalAlign: 'middle', layout: 'vertical' }}
                />
            </div>
        </div>
    );
};

import { useEffect, useRef } from 'react';
import { Form } from 'antd';

import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { FilterTagList, getFilterState } from 'sharedComponents/filterTagList/FilterTagList';
import { IFilterState } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';

import 'features/alerts/alertFilter/AlertFilterForm/AlertFilterForm.scss';

interface IAlertFilterFormProps {
    columns: IFilterState[];
    currentFilters: { [key: string]: any };
    inputHandler: Function;
    alertFilterName: string;
    inputValidationMessage: string;
    inputValidationFlag: boolean;
    visible: boolean;
}

export const AlertFilterForm = (props: IAlertFilterFormProps) => {
    const {
        columns,
        currentFilters,
        alertFilterName,
        inputHandler,
        inputValidationMessage,
        inputValidationFlag,
        visible,
    } = props;

    const nameInputWrapper = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                nameInputWrapper.current?.querySelector('input')?.focus();
            }, 500);
        }
    }, [visible]);

    return (
        <Form layout="vertical" hideRequiredMark className="alert-filter-form">
            <Form.Item name="name">
                <UiTitle title="Tab Name" type="medium" />
                <div ref={nameInputWrapper} className={'tab-input'}>
                    <UiInput
                        placeholder="Please enter Filter Tab name"
                        value={alertFilterName}
                        onChange={(e: any) => {
                            inputHandler(e);
                        }}
                    />
                </div>
                {inputValidationFlag && <p className="validation-message">{inputValidationMessage}</p>}
            </Form.Item>
            <div className="drawer-form-filters">
                <UiTitle title="Filters" type="medium" />
                {currentFilters && <FilterTagList filterTags={columns.map(getFilterState(currentFilters))} />}
            </div>
        </Form>
    );
};

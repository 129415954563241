import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { BASIC_COLUMN_DEFINITION, DISABLE_FILTER_AND_SORT } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { ISuite } from 'api/apiTestingApi';

export const scanTableCols = (scanTableMenu: Function, suites: ISuite[]): ColDef[] => [
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'ID',
        field: 'id',
        sort: 'desc',
        cellRenderer: 'cellRenderVerticalCenter',
        width: 50,
        flex: 0,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'cellRenderScanStatus',
        cellRendererParams: ({ data, value }: ICellRendererParams) => ({
            value: value,
            path: `scans/${data.id}`,
        }),
        flex: 2,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Status Change',
        field: 'status_change',
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            displaySingleLine: true,
        },
        flex: 2,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Triggered By',
        field: 'triggered_by',
        flex: 2,
        sortable: false,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'HIGH',
        headerComponentParams: { prefixIconName: 'alertHigh' },
        field: 'findings_high_count',
        suppressMenu: true,
        width: 120,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'MEDIUM',
        headerComponentParams: { prefixIconName: 'alertMedium' },
        field: 'findings_medium_count',
        suppressMenu: true,
        width: 140,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'LOW',
        headerComponentParams: { prefixIconName: 'alertLowOpaque' },
        field: 'findings_low_count',
        suppressMenu: true,
        width: 120,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'INFO',
        headerComponentParams: { prefixIconName: 'alertInfo' },
        field: 'findings_info_count',
        suppressMenu: true,
        width: 120,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Scan Engine',
        field: 'scan_engine',
        flex: 2,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Test Suite',
        field: 'suite_id',
        cellRenderer: (params: ICellRendererParams) => suites.find((suite) => suite.id === params.value)?.name,
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        ...DISABLE_FILTER_AND_SORT,
        headerName: 'Created By',
        field: 'created_by',
    },
    {
        ...BASIC_COLUMN_DEFINITION,
        headerName: '',
        field: 'more',
        width: 64,
        flex: 0,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: scanTableMenu,
        suppressMenu: true,
        suppressMovable: true,
        filter: false,
        filterParams: null,
        sortable: false,
        resizable: false,
    },
];

import React, { useLayoutEffect } from 'react';

import { XmlEditor } from 'react-xml-editor';
import { DocSpec } from 'react-xml-editor/lib/src/types';

import './css/xonomy.scss';

interface IXmlRendererProps {
    content: string;
    onConversionFailure: () => void;
}

export const XMLRenderer = ({ content, onConversionFailure }: IXmlRendererProps) => {
    const ref = React.useRef<XmlEditor>(null);
    const contentToRender: JSX.Element = React.useMemo(() => {
        try {
            return <XmlEditor ref={ref} docSpec={{} as DocSpec} xml={content} />;
        } catch (e) {
            onConversionFailure();
            return <></>;
        }
    }, [content, onConversionFailure]);

    useLayoutEffect(() => {
        setTimeout(() => {
            const hasChildren = document.querySelectorAll('.xonomy.nerd')[0].childNodes.length;
            if (!hasChildren) {
                onConversionFailure();
            }
        }, 500);
    }, [onConversionFailure]);

    return <div>{contentToRender}</div>;
};

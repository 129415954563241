import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { errorMessage } from 'general/toast-service';
import { TagMultiSelect } from 'sharedComponents/TagMultiSelect/TagMultiSelect';
import { IDiscoveryEndpointResponse, IPathParameter, IEndpointPathParameter } from 'interfaces/endpoint.interface';
import { LabelValue } from 'interfaces/labels.interface';
import { alphaNumericOnlyRule, formFieldRequired } from 'general/forms';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { QUERY_RESULTS_LIMIT, queryAllEndpoints, setAttributes } from 'features/discovery/shared/discoveryApisLegacy';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';

import './EditEndpointParameterNameModal.scss';

export interface IEditEndpointParameterNameProps {
    activeOrg: string;
    parameters: IEndpointPathParameter;
    onModalSuccess: () => void;
    onModalClose: () => void;
    timeRangeFromInput: TDatetimeRange;
}

const getEndpointsAsLabelValue = (
    items: IDiscoveryEndpointResponse[],
    selectedAttributeName: string,
    initialAttributeId: string
): LabelValue[] => {
    return items
        .map((item: IDiscoveryEndpointResponse) => {
            const pathParameterIndex = item.path_parameters.findIndex(
                (pathParameter: IPathParameter) => pathParameter.attribute_name === selectedAttributeName
            );
            const parsedEndpoint: LabelValue = {
                label: `${item.method} ${item.endpoint_path}`,
                value: item.path_parameters[pathParameterIndex]?.attribute_id,
            };
            return parsedEndpoint;
        })
        .filter((item) => item.value !== initialAttributeId);
};

function getAttributeIdList(initialAttributeId: string, attributeList: string[], isMultipleEndpoint: boolean) {
    const selectedAttributeIdList = (attributeList || []).concat([initialAttributeId]) || [];
    return isMultipleEndpoint ? selectedAttributeIdList : [initialAttributeId];
}

function getAttributesDictionary(parameters: IPathParameter[]) {
    return parameters.reduce((previousValue: any, currentValue: IPathParameter) => {
        previousValue[currentValue.attribute_id] = currentValue.attribute_name;
        return previousValue;
    }, {});
}

export const EditEndpointParameterNameModal = ({
    activeOrg,
    parameters,
    onModalSuccess,
    onModalClose,
    timeRangeFromInput,
}: IEditEndpointParameterNameProps) => {
    const initialAttributeId = parameters.pathParameters[0].attribute_id;
    const attributesDictionary = getAttributesDictionary(parameters.pathParameters);
    const [isMultipleEndpoints, setIsMultipleEndpoints] = useState<boolean>(false);
    const [selectedAttributeId, setSelectedAttributeId] = useState<string>(initialAttributeId);
    const [endpoints, setEndpoints] = useState<LabelValue[]>([]);

    const [endpointSelected, setEndpointsSelected] = useState<LabelValue[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [form] = Form.useForm();
    useEffect(() => {
        const params = {
            limit: QUERY_RESULTS_LIMIT,
            filters: [],
            timeRangeFromInput: timeRangeFromInput,
            base64EncodedServiceName: parameters.serviceName,
            queryParams: `?path_parameter=${attributesDictionary[selectedAttributeId]}`,
        };
        setEndpoints([]);
        setEndpointsSelected([]);
        queryAllEndpoints<IDiscoveryEndpointResponse>(
            activeOrg,
            params.limit,
            params.filters,
            params.timeRangeFromInput,
            params.base64EncodedServiceName,
            params.queryParams,
            (items: IDiscoveryEndpointResponse[]) => {
                setEndpoints(
                    getEndpointsAsLabelValue(items, attributesDictionary[selectedAttributeId], initialAttributeId)
                );
            }
        );
    }, [selectedAttributeId]);

    const onSave = useCallback(async () => {
        form.validateFields()
            .then((values) => {
                const attributeIdList = getAttributeIdList(
                    values.selectedAttributeId,
                    values.attributeList,
                    values.isMultipleEndpoint
                );
                return setAttributes(activeOrg, parameters, attributeIdList, values.newParameterName)
                    .then(onModalSuccess)
                    .catch(() => {
                        errorMessage('Error saving data');
                    })
                    .finally(() => {
                        onModalClose();
                    });
            })
            .catch(() => {}); // Nothing to do here. Method validateFields will show an error message
    }, [selectedAttributeId]);

    const handleSelectedParamStateChange = (e: RadioChangeEvent) => {
        setSelectedAttributeId(e.target.value);
    };

    return (
        <UiModal
            isFormModal
            wrapClassName="EditEndpointParameterNameModal"
            width={700}
            onCancel={onModalClose}
            title={`Rename Path Parameter`}
            acceptButton={{
                text: 'Save',
                onClick: onSave,
                disabled: !isFormValid,
            }}
            rejectButton={{
                text: 'Cancel',
                onClick: onModalClose,
            }}
        >
            <div>
                <Form
                    form={form}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    onFieldsChange={() => {
                        setIsFormValid(form.getFieldsError().every((item) => !item.errors.length));
                    }}
                >
                    <Form.Item label="Endpoint path">
                        <span>{parameters.endpointPath}</span>
                    </Form.Item>
                    <Form.Item
                        label="Current parameter name"
                        name="selectedAttributeId"
                        initialValue={initialAttributeId}
                    >
                        {parameters.pathParameters.length > 1 ? (
                            <Radio.Group onChange={handleSelectedParamStateChange} defaultValue={initialAttributeId}>
                                {parameters.pathParameters.map((item: IPathParameter) => {
                                    return <Radio value={item.attribute_id}>{item.attribute_name}</Radio>;
                                })}
                            </Radio.Group>
                        ) : (
                            <span>{attributesDictionary[initialAttributeId]}</span>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="New parameter name"
                        name="newParameterName"
                        rules={[{ required: true, message: formFieldRequired.errorMessage }, alphaNumericOnlyRule]}
                    >
                        <Input placeholder={'Rename to'} />
                    </Form.Item>

                    <Form.Item
                        name="isMultipleEndpoint"
                        className={'is-multiple-endpoints-input'}
                        valuePropName="checked"
                    >
                        <Checkbox
                            name={'multipleEndpoints'}
                            onChange={(e: CheckboxChangeEvent) => setIsMultipleEndpoints(e.target.checked)}
                            style={{ marginBottom: '5px' }}
                            checked={isMultipleEndpoints}
                        >
                            Also apply to other endpoints in the service
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        className={endpoints?.length > 0 ? 'input-ready' : ''}
                        name="attributeList"
                        initialValue={[]}
                        rules={[{ required: isMultipleEndpoints, message: formFieldRequired.errorMessage }]}
                    >
                        <TagMultiSelect
                            options={endpoints}
                            onChange={setEndpointsSelected}
                            placeholder={'Type or select an endpoint'}
                            disabled={!isMultipleEndpoints}
                            value={endpointSelected}
                        />
                    </Form.Item>
                </Form>
            </div>
        </UiModal>
    );
};

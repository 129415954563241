import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { Endpoint } from './endpoint/Endpoint';
import { Service } from './service/Service';
import { Services } from './services/Services';

export const Discovery = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:encodedServiceName/endpoints/:endpointId`}>
                <Endpoint />
            </Route>
            <Route path={`${path}/:encodedServiceName`}>
                <Service />
            </Route>
            <Route path={path}>
                <Services />
            </Route>
        </Switch>
    );
};

import { useEffect, useMemo } from 'react';
import { Timeline } from 'antd';

import {
    getDayByTimeStamp,
    getHoursByTimeStamp,
    getMinutesByTimeStamp,
    getMonthByTimeStamp,
    getSecondsByTimeStamp,
    getYearByTimeStamp,
    TimeDisplayResEnum,
    timeStringFormat,
} from 'general/utils';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { ITimelineEvent } from 'interfaces/user.interface';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';

import 'features/user/EntityTimeline/UiTimeline/UiTimeline.scss';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const checkIfNextDay = (currentTimeStamp: string, nextTimeStamp: string): boolean => {
    const isSameDay =
        getDayByTimeStamp(currentTimeStamp) === getDayByTimeStamp(nextTimeStamp) &&
        getMonthByTimeStamp(currentTimeStamp) === getMonthByTimeStamp(nextTimeStamp) &&
        getYearByTimeStamp(currentTimeStamp) === getYearByTimeStamp(nextTimeStamp);
    return !isSameDay;
};

const checkIfSameTime = (currentTimeStamp: string, nextTimeStamp: string): boolean => {
    const isNextDay = checkIfNextDay(currentTimeStamp, nextTimeStamp);
    return (
        !isNextDay &&
        getHoursByTimeStamp(currentTimeStamp) === getHoursByTimeStamp(nextTimeStamp) &&
        getMinutesByTimeStamp(currentTimeStamp) === getMinutesByTimeStamp(nextTimeStamp) &&
        getSecondsByTimeStamp(currentTimeStamp) === getSecondsByTimeStamp(nextTimeStamp)
    );
};

interface ITimelineItemsProps {
    timelineData: ITimelineEvent[];
    defaultValue?: string | undefined;
    selectedItemId?: string | undefined;
    checkedItems: ITimelineEvent[];
    onItemClicked: Function;
    isLoaded: boolean;
    reachedTimelineStart: boolean;
    reachedTimelineEnd: boolean;
}

export const UiTimeline = (props: ITimelineItemsProps) => {
    useEffect(() => {
        if (props.isLoaded) {
            let element = document.getElementsByClassName('ant-timeline-item selected ant-timeline-item-left');
            element[0]?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [props.isLoaded]);

    const displayData = useMemo<any[]>(() => {
        const timelineData = [];
        if (props.reachedTimelineStart || props.timelineData.length < 50) {
            // if less than 50 items then there are no more items from the BE
            // because we pull at least 100 each query so we need to insert the first date
            const nextTimeStamp = props.timelineData[0]?.timestamp;
            if (nextTimeStamp) {
                const firstDate = `${getDayByTimeStamp(nextTimeStamp)}  ${
                    months[getMonthByTimeStamp(nextTimeStamp) - 1]
                }`;
                timelineData.push({ nextDate: firstDate });
            }
        }

        for (let i = 0; i < props.timelineData.length; i++) {
            timelineData.push(props.timelineData[i]);
            const nextTimeStamp = props.timelineData[i + 1]?.timestamp;
            if (nextTimeStamp && checkIfNextDay(props.timelineData[i].timestamp, nextTimeStamp)) {
                const nextDate = `${getDayByTimeStamp(nextTimeStamp)}  ${
                    months[getMonthByTimeStamp(nextTimeStamp) - 1]
                }`;
                timelineData.push({ nextDate });
            }
        }
        return timelineData;
    }, [props.reachedTimelineStart, props.timelineData]);

    const getTimeLineItemClass = (item: ITimelineEvent) => {
        if (props.selectedItemId === item.id) return 'selected';
        else if (!props.selectedItemId && props.defaultValue === item.id) return 'selected';
        else return props.checkedItems.find((checkedItem) => checkedItem.id === item.id) ? 'checked' : '';
    };

    const getTimeLineItem = (item: any, index: number, timelineArray: ITimelineEvent[]) => {
        if (!item.nextDate) {
            const hideLabel = checkIfSameTime(
                timelineArray[index - 1] && timelineArray[index - 1].timestamp,
                item.timestamp
            );
            const isCall = item.event_type === EventTypesEnum.Call;
            const getApiTypeFromIcon = (itemIcon: string): string => {
                // deal with extended api types i.e. 'callAuth'
                if (itemIcon.includes('call')) {
                    return itemIcon.substring(4).toUpperCase();
                } else {
                    return itemIcon;
                }
            };
            const timeString = timeStringFormat(item.timestamp, TimeDisplayResEnum.SEC);
            const is_queryable = item.is_queryable;
            const apiType = getApiTypeFromIcon(item.icon);
            return (
                <Timeline.Item
                    label={hideLabel ? ' ' : timeString}
                    key={item.id}
                    className={`${getTimeLineItemClass(item)} tl_${item.id} ${!is_queryable ? 'non-query' : ''}`}
                >
                    <div className="custom-content" id={item.id}>
                        <span className="marker">
                            {!isCall ? <UiIcon name={item.icon} /> : <ApiLabel apiType={[apiType]} />}
                        </span>
                        <span className="content-name ellipsis" onClick={() => props.onItemClicked(item)}>
                            <UiTooltip title={`${isCall ? apiType : ''} ${item.call_path || item.name}`} unsetWidth>
                                {item.name}
                            </UiTooltip>
                        </span>
                        {isCall && <span className="http-status-code">{item.status_code}</span>}
                    </div>
                </Timeline.Item>
            );
        } else {
            return (
                <Timeline.Item key={index} className="custom-date">
                    <span className="next-day">{item.nextDate}</span>
                </Timeline.Item>
            );
        }
    };

    return (
        <Timeline mode="left">
            {props.reachedTimelineStart && <Timeline.Item className="timeline-edge" />}
            {displayData.map(getTimeLineItem)}
            {props.reachedTimelineEnd && <Timeline.Item className="timeline-edge" />}
        </Timeline>
    );
};

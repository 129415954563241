import { IServerQueryResponse, tenantApi } from 'api/baseServerApi';
import { HTTPMethod } from 'services/data/data-service';
import { fetchAll } from 'api/apiUtils';
import { TSeverity } from 'sharedComponents/Severity/Severity';

export interface IApplication {
    id: number;
    name: string;
    totalEndpoints: number;
    totalFindings: number;
    highFindings: number;
    mediumFindings: number;
    lowFindings: number;
    infoFindings: number;
    totalScans: number;
    lastScan?: IScan;
}

export interface IAuthConfig {
    id: number;
    name: string;
    content: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
}

export interface IAuthConfigTemplate {
    id: number;
    name: string;
    content: string;
}

export interface ITest {
    id: number;
    name: string;
    external_id: string;
    framework: string;
    mode: string;
    parameters: string;
}

export interface ISuite {
    id: number;
    name: string;
    test_count: number;
    tests: ITest[];
}

export interface ISuiteResponse {
    items: ISuite[];
}

export interface IScan {
    id: number;
    status: string;
    created_by: string;
    created_at: string;
    triggered_by: string;
    queued_at: string;
    started_at: string;
    ended_at: string;
    target_url: string;
    scan_engine: string;
    oas_file_id: number;
    oas_file_name: string;
    suite: ISuite;
    auth_config_name: string;
    auth_config_data: string;
    requests_count: number;
    duration: number;
    findings_high_count: number;
    findings_medium_count: number;
    findings_low_count: number;
    findings_info_count: number;
}

export interface ICreateScanRequest {
    oas_file_id: number;
    target_url: string;
    suite_id: number;
    auth_config_id?: number;
    app_id: number;
}

export interface IApitRequestPathParams {
    appId?: string;
    scanId?: string;
    groupedFindingId?: string;
    findingId?: string;
    authConfigId?: string;
    callId?: string;
}

export interface ICall {
    id: number;
    timestamp: string;
    url: string;
    method: string;
    path: string;
    query_params: Record<string, string>;
    request_headers: Record<string, string>;
    response_headers: Record<string, string>;
    request_body: string;
    response_body: string;
    response_status_code: number;
    is_offending: boolean;
}

export interface IFinding {
    id: number;
    timestamp: string;
    evidence_description: string;
    evidence_parameters: Record<string, string>;
}

export interface IGroupedFinding {
    id: number;
    name: string;
    endpoint: string;
    method: string;
    severity: TSeverity;
    problem: string;
    references: string;
    solution_recommendations: string;
    labels: string[];
}

export interface IApplicationGroupedFinding extends IGroupedFinding {
    first_scan: IScan;
    last_scan: IScan;
    last_scan_findings: IFinding[];
}

export interface IScanGroupedFinding extends IGroupedFinding {
    findings: IFinding[];
}

export interface IGroupFindingListItem {
    id: number;
    finding_name: string;
    method: string;
    endpoint: string;
    severity: string;
    labels: string[];
}

export interface IScanGroupedFindingListItem extends IGroupFindingListItem {}

export interface IApplicationGroupedFindingListItem extends IGroupFindingListItem {
    times_found: number;
    first_seen: string;
    last_seen: string;
}

export type IGroupedFindingListItem = IScanGroupedFindingListItem | IApplicationGroupedFindingListItem;

export interface IFindingMetadata {
    finding_names: string[];
    endpoints: string[];
    labels: string[];
}

const BASE_URL_APPLICATIONS = 'testing/applications';
const BASE_URL_AUTH_CONFIGS = 'testing/auth_configs';
const BASE_URL_AUTH_CONFIG_TEMPLATES = 'testing/auth_config_templates';
const BASE_URL_SUITES = 'testing/suites';

const apiTestingApi = tenantApi.injectEndpoints?.({
    endpoints: (builder) => ({
        getApplication: builder.query<IApplication, IApitRequestPathParams>({
            query: ({ appId }) => ({
                url: `${BASE_URL_APPLICATIONS}/${appId}`,
            }),
            providesTags: ['apiTestingApplications'],
        }),
        createApplication: builder.mutation<void, Pick<IApplication, 'name'>>({
            query: ({ name }) => ({
                url: BASE_URL_APPLICATIONS,
                method: HTTPMethod.POST,
                body: { name },
            }),
            invalidatesTags: ['apiTestingApplications'],
        }),
        updateApplication: builder.mutation<void, Pick<IApplication, 'id' | 'name'>>({
            query: ({ id, name }) => ({
                url: `${BASE_URL_APPLICATIONS}/${id}`,
                method: HTTPMethod.PATCH,
                body: { name },
            }),
            invalidatesTags: ['apiTestingApplications'],
        }),
        deleteApplication: builder.mutation<void, Pick<IApplication, 'id'>>({
            query: ({ id }) => ({
                url: `${BASE_URL_APPLICATIONS}/${id}`,
                method: HTTPMethod.DELETE,
            }),
            invalidatesTags: ['apiTestingApplications'],
        }),
        getAuthConfigs: builder.query<IServerQueryResponse<IAuthConfig>, void>({
            query: () => ({
                url: `${BASE_URL_AUTH_CONFIGS}`,
            }),
            providesTags: ['apiTestingAuthConfigs'],
        }),
        getAuthConfig: builder.query<IAuthConfig, IApitRequestPathParams>({
            query: ({ authConfigId }) => ({
                url: `${BASE_URL_AUTH_CONFIGS}/${authConfigId}`,
            }),
            providesTags: ['apiTestingAuthConfigs'],
        }),
        createAuthConfig: builder.mutation<void, Pick<IAuthConfig, 'name' | 'content'>>({
            query: (body) => ({
                url: `${BASE_URL_AUTH_CONFIGS}`,
                method: HTTPMethod.POST,
                body,
            }),
            invalidatesTags: ['apiTestingAuthConfigs'],
        }),
        updateAuthConfig: builder.mutation<void, Pick<IAuthConfig, 'id' | 'name' | 'content'>>({
            query: ({ id, ...body }) => ({
                url: `${BASE_URL_AUTH_CONFIGS}/${id}`,
                method: HTTPMethod.PATCH,
                body,
            }),
            invalidatesTags: ['apiTestingAuthConfigs'],
        }),
        deleteAuthConfig: builder.mutation<void, Pick<IAuthConfig, 'id'>>({
            query: ({ id }) => ({
                url: `${BASE_URL_AUTH_CONFIGS}/${id}`,
                method: HTTPMethod.DELETE,
            }),
            invalidatesTags: ['apiTestingAuthConfigs'],
        }),
        getAuthConfigTemplates: builder.query<IServerQueryResponse<IAuthConfigTemplate>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IAuthConfigTemplate>(BASE_URL_AUTH_CONFIG_TEMPLATES, 1000, baseQuery),
        }),
        getScan: builder.query<IScan, IApitRequestPathParams>({
            query: ({ scanId, appId }) => ({
                url: `${BASE_URL_APPLICATIONS}/${appId}/scans/${scanId}`,
            }),
            providesTags: ['apiTestingScans'],
        }),
        createScan: builder.mutation<void, ICreateScanRequest>({
            query: ({ app_id, ...body }) => ({
                url: `${BASE_URL_APPLICATIONS}/${app_id}/scans`,
                method: HTTPMethod.POST,
                body,
            }),
            invalidatesTags: ['apiTestingScans'],
        }),
        deleteScan: builder.mutation<void, IApitRequestPathParams>({
            query: ({ scanId, appId }) => ({
                url: `${BASE_URL_APPLICATIONS}/${appId}/scans/${scanId}`,
                method: HTTPMethod.DELETE,
            }),
            invalidatesTags: ['apiTestingScans'],
        }),
        getScanCliCommand: builder.query<{ cli_command: string }, IApitRequestPathParams>({
            query: ({ scanId, appId }) => `${BASE_URL_APPLICATIONS}/${appId}/scans/${scanId}/cli_command`,
        }),
        getSuites: builder.query<ISuiteResponse, void>({
            query: () => `${BASE_URL_SUITES}`,
        }),
        getGroupedFinding: builder.query<IApplicationGroupedFinding | IScanGroupedFinding, IApitRequestPathParams>({
            query: ({ appId, scanId, groupedFindingId }) =>
                `${BASE_URL_APPLICATIONS}/${appId}${
                    scanId ? `/scans/${scanId}` : ''
                }/grouped_findings/${groupedFindingId}`,
        }),
        getFindingCalls: builder.query<IServerQueryResponse<ICall>, IApitRequestPathParams>({
            queryFn: ({ appId, scanId, groupedFindingId, findingId }, _queryApi, _extraOptions, baseQuery) => {
                return fetchAll<ICall>(
                    `${BASE_URL_APPLICATIONS}/${appId}${
                        scanId ? `/scans/${scanId}` : ''
                    }/grouped_findings/${groupedFindingId}/findings/${findingId}/calls`,
                    1000,
                    baseQuery
                );
            },
        }),
        getCallCurl: builder.query<{ command: string }, IApitRequestPathParams>({
            query: ({ appId, scanId, groupedFindingId, findingId, callId }) =>
                `${BASE_URL_APPLICATIONS}/${appId}/scans/${scanId}/grouped_findings/${groupedFindingId}/findings/${findingId}/calls/${callId}/curl`,
        }),
        getFindingsMetadata: builder.query<IFindingMetadata, IApitRequestPathParams>({
            query: ({ scanId, appId }) =>
                `${BASE_URL_APPLICATIONS}/${appId}/grouped_findings/metadata?${scanId ? `scan_id=${scanId}` : ''}`,
        }),
    }),
});

export const {
    useGetApplicationQuery,
    useCreateApplicationMutation,
    useUpdateApplicationMutation,
    useDeleteApplicationMutation,
    useGetAuthConfigQuery,
    useGetAuthConfigsQuery,
    useCreateAuthConfigMutation,
    useUpdateAuthConfigMutation,
    useDeleteAuthConfigMutation,
    useGetAuthConfigTemplatesQuery,
    useGetScanQuery,
    useGetScanCliCommandQuery,
    useCreateScanMutation,
    useDeleteScanMutation,
    useGetSuitesQuery,
    useGetGroupedFindingQuery,
    useGetFindingsMetadataQuery,
    useGetFindingCallsQuery,
    useGetCallCurlQuery,
} = apiTestingApi;

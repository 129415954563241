import React, { useMemo } from 'react';

import { useDeleteApplicationMutation } from 'api/apiTestingApi';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';

import './DeleteApplicationModal.scss';

export interface IDeleteApplicationModalProps {
    onDelete: () => void;
    onClose: () => void;
    id: number;
}

export const DeleteApplicationModal = ({ onDelete, onClose, id }: IDeleteApplicationModalProps) => {
    const [deleteApplication] = useDeleteApplicationMutation();

    const deleteButton = useMemo(
        () => ({
            text: 'Delete',
            onClick: async () => {
                try {
                    await deleteApplication({ id }).unwrap();
                    onDelete();
                } catch (e) {
                    errorMessage(extractErrorMessage(e));
                }
            },
        }),
        [onDelete, id]
    );

    const cancelButton = useMemo(
        () => ({
            text: 'Cancel',
            onClick: onClose,
        }),
        [onClose]
    );

    return (
        <UiModal
            className="DeleteApplicationModal"
            onCancel={onClose}
            acceptButton={deleteButton}
            rejectButton={cancelButton}
            title="Delete Application ?"
            icon="trash"
        >
            <p>
                Are you sure you want to delete your application? You will permanently lose all of its scans and
                findings.
            </p>
        </UiModal>
    );
};

import { useEffect, useState } from 'react';
import moment from 'moment';
import { AxiosResponse } from 'axios';

import { errorMessage } from 'general/toast-service';
import {
    getConfig,
    getSeriesRequests,
    getTotalRequests,
    queryServices,
} from 'features/discovery/shared/discoveryApisLegacy';
import { dateTimeStringFormat, dateTimeStringFormatMinute, getTimerangeGranularity, urlEncode } from 'general/utils';
import { useParams } from 'react-router-dom';
import { IDiscoveryService } from 'interfaces/service.interface';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { IEntityChartSeries, IEntityChartTotals } from 'interfaces/user.interface';

interface IStatsDataProps {
    timeRangeFromInput: [moment.Moment, moment.Moment];
}

const EMPTY_SERVICE: IDiscoveryService = {
    behavior_alerts_count: 0,
    calls_count: 0,
    total_endpoints_count: 0,
    new_endpoints_count: 0,
    active_endpoints_count: 0,
    hidden_endpoints_count: 0,
    errors_4xx: 0,
    errors_5xx: 0,
    first_seen: '',
    labels: [],
    service_labels: [],
    endpoint_labels: [],
    last_seen: '',
    name: '',
    posture_alerts_count: 0,
};

export async function getService(
    timeRange: TDatetimeRange,
    activeOrg: string,
    serviceName: string
): Promise<IDiscoveryService> {
    const config = await getConfig(activeOrg);
    const service = (await queryServices(timeRange, activeOrg, serviceName))?.[0] || { ...EMPTY_SERVICE };
    const serviceFromConfig = (
        await queryServices(
            [moment(config?.timeframe?.start_timestamp), moment(config?.timeframe?.end_timestamp)],
            activeOrg,
            serviceName
        )
    )?.[0] || { ...EMPTY_SERVICE };

    return {
        ...service,
        first_seen: serviceFromConfig.first_seen,
        last_seen: serviceFromConfig.last_seen,
    };
}

export const useFetchServiceStats = ({ timeRangeFromInput }: IStatsDataProps) => {
    const params = useParams() as { activeOrgParam: string; encodedServiceName: string };
    const activeOrg = params.activeOrgParam;
    const base64EncodedServiceName = urlEncode(decodeURIComponent(params.encodedServiceName));
    const serviceName = decodeURIComponent(params.encodedServiceName);

    const [serviceData, setServiceData] = useState<IDiscoveryService>();
    const [totalRequests, setTotalRequests] = useState<IEntityChartTotals>();
    const [seriesRequests, setSeriesRequests] = useState<IEntityChartSeries>();
    const [isUpdateHiddenCount, setIsUpdateHiddenCount] = useState<boolean>(false);

    useEffect(() => {
        getService(timeRangeFromInput, activeOrg, serviceName)
            .then((service) => {
                setServiceData(service);
                setIsUpdateHiddenCount(false);
            })
            .catch(() => errorMessage('Error fetching Data'));
    }, [isUpdateHiddenCount, timeRangeFromInput]);

    useEffect(() => {
        getTotalRequests(timeRangeFromInput, activeOrg, base64EncodedServiceName)
            .then((response: AxiosResponse) => {
                if (response.data) {
                    setTotalRequests({
                        total: response.data.total,
                        countsByStatus: [
                            { name: '1xx', value: response.data.counts['1xx'] },
                            { name: '2xx', value: response.data.counts['2xx'] },
                            { name: '3xx', value: response.data.counts['3xx'] },
                            { name: '4xx', value: response.data.counts['4xx'] },
                            { name: '5xx', value: response.data.counts['5xx'] },
                            { name: 'None', value: response.data.counts['None'] },
                        ],
                    });
                }
            })
            .catch(() => errorMessage('Error fetching Data'));

        getSeriesRequests(timeRangeFromInput, activeOrg, base64EncodedServiceName)
            .then((response: AxiosResponse) => {
                let seriesData = response.data;
                if (seriesData) {
                    let formatFunction: Function = dateTimeStringFormat;
                    if (getTimerangeGranularity(seriesData.timestamps) === '15m') {
                        formatFunction = dateTimeStringFormatMinute;
                    }
                    seriesData.timestamps = seriesData.timestamps.map((timestamp: number) => {
                        return formatFunction(timestamp);
                    });
                    setSeriesRequests(seriesData);
                }
            })
            .catch(() => errorMessage('Error fetching Data'));
    }, [timeRangeFromInput]);

    return { serviceData, setServiceData, totalRequests, seriesRequests, setIsUpdateHiddenCount };
};

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ScanDetailsSummary } from './ScanDetailsSummary/ScanDetailsSummary';
import { BreadCrumb } from 'sharedComponents/breadcrumb/BreadCrumb';
import {
    ContentVisibilityModeEnum,
    MultiExpandableContainer,
} from 'sharedComponents/MultiExpandableContainer/MultiExpandableContainer';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { useGetApplicationQuery, useGetScanQuery } from 'api/apiTestingApi';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import Spinner from 'sharedComponents/spinner/Spinner';
import { ScanExtraDetails } from './ScanExtraDetails/ScanExtraDetails';
import { FindingList } from 'features/apiTesting/findings/FindingList/FindingList';
import { ExportButton } from 'features/apiTesting/shared/ExportButton/ExportButton';

import './ScanDetails.scss';

export const ScanDetails = () => {
    const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId, scanId } = useParams<IApitRouteParams>();
    const { data: scan, isLoading } = useGetScanQuery({ appId, scanId }, { pollingInterval: 6000 });
    const { data: applicationData } = useGetApplicationQuery({ appId });

    if (isLoading) {
        return <Spinner show />;
    }

    return (
        <div className="ScanDetails">
            <BreadCrumb
                isPassParams
                breadcrumbList={[
                    {
                        url: `/${currentTenantKey}/api-testing/applications/${appId}/scans`,
                        text: applicationData?.name || '',
                    },
                    {
                        url: `/${currentTenantKey}/api-testing/applications/${appId}/scans/${scanId}`,
                        text: `Scan ${scanId}`,
                    },
                ]}
                selectedRoute={'Scan ' + scanId}
            />
            <div className="page-header">
                <span className="scan-summary-title">Scan {scan?.id}</span>
                <ExportButton />
            </div>
            <MultiExpandableContainer
                contentArray={[
                    { content: <ScanDetailsSummary scan={scan} />, mode: ContentVisibilityModeEnum.SHOWN_ALWAYS },
                    {
                        content: (
                            <div className={`expanded-section${isHeaderExpanded ? 'expended' : ''}`}>
                                <ScanExtraDetails scan={scan} />
                            </div>
                        ),
                        mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                    },
                ]}
                isExpanded={isHeaderExpanded}
                toggleExpanded={() => setIsHeaderExpanded((prev) => !prev)}
                className="scan-details-expandable"
            />

            <div className="finding-types-table">
                <FindingList />
            </div>
        </div>
    );
};

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import { HomeWidget } from './HomeWidget/HomeWidget';
import { httpGet } from 'general/http-service';
import { RecentOpenAlertsWidget } from 'features/dashboards/OverviewDashboard/RecentOpenAlertsWidget/RecentOpenAlertsWidget';
import { getTimestampQuery } from 'general/utils';
import { TopEndpoints } from './TopEndpoints/TopEndpoints';
import { AlertsOverview, IAlertGridRow } from './AlertsOverview/AlertsOverview';
import Spinner from 'sharedComponents/spinner/Spinner';
import { AlertStatusEnum } from 'features/alerts/Alerts';
import { IAlert } from 'interfaces/alert.interface';
import { errorMessage } from 'general/toast-service';
import { getTenantEndpoints } from 'features/discovery/shared/discoveryApisLegacy';
import { IEndpointOptionalFields } from 'features/discovery/shared/discoveryInterfaces';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { TopAlertingUsers } from './TopAlertingUsers/TopAlertingUsers';

import './OverviewDashboard.scss';

export interface IAlertsMap {
    labels: string[];
    data: number[][];
    severities: string[];
}

export const OverviewDashboard = () => {
    // TODO: Widget data states would be removed once all widgets' logic is extracted to components and queries
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    const [recentAlertsData, setRecentAlertsData] = useState<any[]>([]);
    const [topEndpointsData, setTopEndpointsData] = useState<any[]>([]);
    const [alertsOverviewData, setAlertsOverviewData] = useState<{ heatMap: IAlertGridRow[] }>({ heatMap: [] });

    const history = useHistory();
    const usersRef = useRef<HTMLDivElement>(null);

    // TODO: Spinner states would be removed once all widgets' logic is extracted to components and queries
    // spinner states
    const [alertOverviewSpinner, setAlertOverviewSpinner] = useState<boolean>(true);
    const [recentOpenAlertsSpinner, setRecentOpenAlertsSpinner] = useState<boolean>(true);
    const [topEndpointsSpinner, setTopEndpointsSpinner] = useState<boolean>(true);

    // TODO: useEffect would be removed once all widgets' logic is extracted to components and queries
    useEffect(() => {
        if (currentTenantKey !== '') {
            getData(true);
            const interval = setInterval(getData, 60000, false);
            return () => {
                clearInterval(interval);
            };
        }
    }, [currentTenantKey]);

    const viewAllAlerts = () => {
        if (currentTenantKey) {
            history.push(`/${currentTenantKey}/alerts/`);
        }
    };

    /* TODO: getData would be removed once all widgets' logic is extracted to components and queries
                Each httpGet here should be translated to a query
    */
    const getData = (isInitialCall: boolean = false) => {
        if (isInitialCall) {
            allSpinners(true);
        }
        try {
            reloadRecentOpenAlertsCb();

            httpGet(`organizations/${currentTenantKey}/alerts/label_by_severity`).then(
                (res: AxiosResponse<IAlertsMap>) => {
                    const heatMap = res.data.data.map((alertsArr, i) => {
                        return {
                            label: res.data.labels[i]?.trim(),
                            values: getlimitValArray(alertsArr),
                        };
                    });

                    setAlertsOverviewData((prev: any) => {
                        return {
                            ...prev,
                            heatMap,
                        };
                    });
                    setAlertOverviewSpinner(false);

                    function getlimitValArray(vals: number[]): number[] {
                        // makes sure the results are grouped into exactly 5 severities (info...critical)
                        let returnVals = [];
                        if (vals.length < 5) {
                            returnVals = [0, ...vals, 0];
                        } else if (vals.length > 5) {
                            returnVals = vals.slice(0, 4);
                        } else {
                            returnVals = [...vals];
                        }
                        return returnVals.reverse();
                    }
                }
            );

            httpGet(`organizations/${currentTenantKey}/config`)
                .then(({ data: { default_interval } }: any) => {
                    const timestampQuery = getTimestampQuery(default_interval || '1Y');
                    return getTenantEndpoints(
                        currentTenantKey,
                        timestampQuery.from_timestamp,
                        timestampQuery.to_timestamp,
                        '10',
                        '0',
                        'desc(call_count)',
                        undefined,
                        [IEndpointOptionalFields.call_count, IEndpointOptionalFields.call_percentage]
                    );
                })
                .then((res: any) => {
                    setTopEndpointsData(res.data.items);
                    setTopEndpointsSpinner(false);
                })
                .catch((error) => {
                    console.error(error);
                    setTopEndpointsData([]);
                    setTopEndpointsSpinner(false);
                });
        } catch (err) {
            errorMessage('Error fetching Data');
        }
    };

    // TODO: This callback would be removed once all widgets' logic is extracted to components and queries (extracted to Recent Alerts component)
    const reloadRecentOpenAlertsCb = () => {
        httpGet(`organizations/${currentTenantKey}/alerts?sort_by=desc(timestamp),desc(severity)&limit=10`).then(
            (res: any) => {
                setRecentAlertsData(res.data.items.filter((alert: IAlert) => alert.status === AlertStatusEnum.Open));
                setRecentOpenAlertsSpinner(false);
            }
        );
    };

    // TODO: Spinner states would be removed once all widgets' logic is extracted to components and queries
    const allSpinners = (val: boolean) => {
        setAlertOverviewSpinner(val);
        setRecentOpenAlertsSpinner(val);
        setTopEndpointsSpinner(val);
    };

    return (
        <div className="OverviewDashboard">
            <div className="alert-overview">
                <HomeWidget title="Alert Overview">
                    <Spinner show={alertOverviewSpinner} paddingBottom={50} />
                    {!alertOverviewSpinner &&
                        (!!alertsOverviewData?.heatMap?.length ? (
                            <AlertsOverview alertsData={alertsOverviewData} />
                        ) : (
                            !alertOverviewSpinner && <span className="info-message">No alert data available yet</span>
                        ))}
                </HomeWidget>
            </div>
            <div className="top-alerting-users" ref={usersRef}>
                <TopAlertingUsers />
            </div>

            <div className="recent-alerts-container">
                <HomeWidget title="Recent Alerts" headerButton={{ text: 'view all', onClick: viewAllAlerts }}>
                    <Spinner show={recentOpenAlertsSpinner} paddingBottom={50} />
                    {!recentOpenAlertsSpinner &&
                        (!!recentAlertsData.length ? (
                            <RecentOpenAlertsWidget
                                alertsData={recentAlertsData}
                                reloadAlertsCb={reloadRecentOpenAlertsCb}
                            />
                        ) : (
                            <span className="info-message">No alert data available yet</span>
                        ))}
                </HomeWidget>
            </div>

            <div className="top-endpoints">
                <HomeWidget title="Top Endpoints">
                    <Spinner show={topEndpointsSpinner} paddingBottom={50} />
                    {!topEndpointsSpinner &&
                        (!!topEndpointsData.length ? (
                            <TopEndpoints endpointsData={topEndpointsData} />
                        ) : (
                            <span className="info-message">No endpoint data available yet</span>
                        ))}
                </HomeWidget>
            </div>
        </div>
    );
};

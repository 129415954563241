import axios, { AxiosResponse } from 'axios';

import { getJWTToken } from './auth-service';

export interface IHttpHeaderConfig {
    headers: { Authorization: string };
}

export const PAGE_SIZE_LIMIT = 1000;

const getHeaders = (): Promise<IHttpHeaderConfig | null> => {
    return new Promise(async (resolve) => {
        const JWT = await getJWTToken();
        if (JWT) {
            resolve({ headers: { Authorization: `Bearer ${JWT}` } });
        } else {
            resolve(null);
        }
    });
};

export const httpGet = async (
    url: string,
    params?: { [key: string]: string } | null,
    timeout: number = 0
): Promise<AxiosResponse> => {
    const config: { headers: { Authorization: string }; params?: { [key: string]: string }; timeout?: number } | null =
        await getHeaders();

    if (!config) {
        throw 'httpGet: No auth header';
    }

    if (params) {
        config.params = params;
    }

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    config.timeout = timeout;
    return axios.get(`${backEndUrl}${url}`, config);
};

export const httpPOST = async (url: string, data: any) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.post(`${backEndUrl}${url}`, data, config);
};

export const httpPOSTBin = async (url: string, data: any) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.post(`${backEndUrl}${url}`, data, { ...config, responseType: 'arraybuffer' });
};

export const httpPOSTMultiPart = async (url: string, data: any) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.post(`${backEndUrl}${url}`, data, {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const httpAll = async (urls: string[]) => {
    const apiCalls = urls.map((url) => httpGet(url));
    return axios.all(apiCalls);
};

export const httpPatch = async (url: string, data: any) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.patch(`${backEndUrl}${url}`, data, config);
};

export const httpPut = async (url: string, body: any) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.put(`${backEndUrl}${url}`, body, config);
};

export const httpDelete = async (url: string) => {
    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    const config: any = await getHeaders();

    return axios.delete(`${backEndUrl}${url}`, config);
};

export const recursiveHttpGet = (
    url: string,
    offset: number,
    callLimit: number,
    attr: any[],
    resolve: Function,
    reject: Function,
    maxNumOfItemsToFetch?: number
) => {
    // recursively calling httpGet until no more results
    httpGet(url)
        .then((response) => {
            const totalItems = response.data.total;
            const retrievedItems: unknown[] = attr.concat(response.data.items);
            const maxItemsNotReached =
                maxNumOfItemsToFetch !== undefined ? retrievedItems.length <= maxNumOfItemsToFetch : true;
            if (retrievedItems.length < totalItems && maxItemsNotReached) {
                offset += callLimit;
                if (!url.includes('offset')) {
                    url += '&offset=0';
                }
                const regex = /offset.*/i;
                url = url.replace(regex, `offset=${offset}`);
                recursiveHttpGet(url, offset, callLimit, retrievedItems, resolve, reject, maxNumOfItemsToFetch);
            } else {
                if (maxNumOfItemsToFetch) {
                    resolve(retrievedItems.slice(0, maxNumOfItemsToFetch));
                } else {
                    resolve(retrievedItems);
                }
            }
        })
        .catch((error) => {
            console.log(error);
            // throw new Error(error);
            reject('Something wrong. Please refresh the page and try again.');
        });
};

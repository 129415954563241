import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { GridApi } from '@ag-grid-community/core';

import { IConfigResponse, useGetConfigQuery } from 'api/configApi';
import { ActionsDropdown } from 'features/discovery/ActionsDropdown/ActionsDropdown';
import { getTimeRangeByMaxDays, getTimestampQueryParams } from 'general/utils';
import { LabelList } from 'sharedComponents/LabelList/LabelList';
import { INavigationLinks } from 'sharedComponents/breadcrumb/BreadCrumb';
import { IBaseLabel, IEndpointLabel, IServiceLabel, LabelSuppressPeriod } from 'interfaces/labels.interface';
import { UiChronoRangePicker } from 'sharedComponents/shared/UiChronoRangePicker/UiChronoRangePicker';
import { EndpointRedirect } from './EndpointRedirect/EndpointRedirect';
import {
    DEFAULT_PRESET,
    getDateRangeFromPreset,
    getPresetDateRanges,
    TDatetimeRange,
    TDatetimeRangeOrPreset,
    isDisabledDate,
} from 'sharedComponents/shared/UiChronoRangePicker/utils';

import './DiscoveryHeader.scss';

interface IServicesHeaderProps {
    queryParams: any;
    selectedServices: any[];
    timeRangeFromInput: TDatetimeRange;
    pathParams: any;
    title: string;
    isNewLabel: boolean;
    isLabelList: boolean;
    onTimeChange: (range: TDatetimeRange) => void;
    labelList: IServiceLabel[] | IEndpointLabel[];
    isEndpointSelect?: boolean;
    breadcrumb?: INavigationLinks[];
    selectedRouteName?: string;
    isSwagger?: boolean;
    onLabelCreate: (labelText: string) => Promise<any>;
    onLabelDelete: (labelId: string) => Promise<any>;
    onLabelSuppress: (labelId: string, period: LabelSuppressPeriod) => Promise<any>;
    canDeleteLabel: (label: IBaseLabel) => boolean;
    gridApi?: GridApi;
}

export const DiscoveryHeader = (props: IServicesHeaderProps) => {
    const { queryParams, onTimeChange, timeRangeFromInput, pathParams, title } = props;
    const { data: config } = useGetConfigQuery();
    const location = useLocation();
    const history = useHistory();
    const now = useMemo(() => moment(), []);
    const datePresets = useMemo(() => {
        return (
            config &&
            getPresetDateRanges(config, ['Today', 'Last 24 Hours', 'Last 7 Days', 'Last 14 Days', DEFAULT_PRESET], now)
        );
    }, [config?.timeframe?.start_timestamp, config?.timeframe?.end_timestamp, now.format('x')]);

    const onDateChangeHandler = (range: TDatetimeRangeOrPreset) => {
        const dateRangeFromPreset = getDateRangeFromPreset(range, config!, now);
        const timestampQueryParams = getTimestampQueryParams(location.search, dateRangeFromPreset);
        onTimeChange(dateRangeFromPreset);
        if (queryParams.size === 0) {
            history.push(`${location.pathname}${timestampQueryParams}`);
        }
    };

    const setTimeRangeByConfig = (configParam: IConfigResponse) => {
        const urlTimestamp = {
            fromTimestamp: queryParams.get('from_timestamp'),
            toTimestamp: queryParams.get('to_timestamp'),
        };
        const isTimestampNotInURL = !urlTimestamp.fromTimestamp || !urlTimestamp.toTimestamp;
        if (isTimestampNotInURL && configParam) {
            onDateChangeHandler(getDateRangeFromPreset(DEFAULT_PRESET, configParam, now));
        } else {
            const alteredTimeRange = {
                fromTimestamp: urlTimestamp.fromTimestamp,
                toTimestamp: Math.min(
                    urlTimestamp.toTimestamp,
                    // add(1, 'second') to account for the fact that config timestamp is in milliseconds
                    // which are omitted during conversion to seconds
                    moment(configParam?.timeframe?.end_timestamp).add(1, 'second').unix()
                ),
            };
            const normalizedTimeRange = getTimeRangeByMaxDays(
                alteredTimeRange.fromTimestamp,
                alteredTimeRange.toTimestamp,
                30
            );

            onDateChangeHandler(normalizedTimeRange);
        }
    };

    useEffect(() => {
        config && setTimeRangeByConfig(config);
    }, [config]);

    const onLabelDelete = (labelId: string) => {
        return props.onLabelDelete(labelId);
    };

    const onLabelSuppress = (labelId: string, period: LabelSuppressPeriod) => {
        return props.onLabelSuppress(labelId, period);
    };

    const onLabelCreate = (labelText: string) => {
        return props.onLabelCreate(labelText);
    };

    const disabledDate = useMemo(() => (config ? isDisabledDate(config, now) : () => false), [config, now]);

    return (
        <div className="page-header-container">
            <div className="discovery-header-first-line">
                <div className="services-header">
                    <EndpointRedirect
                        queryParams={queryParams}
                        title={title}
                        pathParams={pathParams}
                        timeRange={timeRangeFromInput}
                        isEndpointSelect={props.isEndpointSelect}
                        breadcrumb={props.breadcrumb}
                        selectedRouteName={props.selectedRouteName}
                    />
                </div>
                <div className="top-controls">
                    <div className={`services-control`}>
                        <UiChronoRangePicker
                            presets={datePresets}
                            label="Time Range"
                            value={timeRangeFromInput}
                            disabledDate={disabledDate}
                            onChange={onDateChangeHandler}
                        />
                    </div>
                    {props.isSwagger && (
                        <>
                            <div className={'services-control'}>
                                <span> | </span>
                            </div>
                            <div className={'services-control'}>
                                <ActionsDropdown
                                    pathParams={pathParams}
                                    timeRangeFromInput={timeRangeFromInput}
                                    gridApi={props.gridApi}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {props.isLabelList && (
                <div className="discovery-header-second-line">
                    <LabelList
                        labels={props.labelList || []}
                        allLabelsOptions={config?.labels?.map((label) => ({ label, value: label }))}
                        canDeleteLabel={props.canDeleteLabel}
                        onLabelDelete={onLabelDelete}
                        onLabelCreate={onLabelCreate}
                        onLabelSuppress={onLabelSuppress}
                        newLabel={props.isNewLabel}
                        isWider={true}
                    />
                </div>
            )}
        </div>
    );
};

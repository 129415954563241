import React, { ReactNode } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ColDef, ColumnApi, GridApi } from '@ag-grid-community/core';

import { httpGet, PAGE_SIZE_LIMIT, recursiveHttpGet } from 'general/http-service';
import { BASIC_COLUMN_DEFINITION, IProps as UISSRMProps } from '../../../../sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter } from 'sharedComponents/ui-ag-grid/commonOptions';
import { FilterableTable } from '../../FilterableTable/FilterableTable';
import { IService } from 'interfaces/service.interface';
import { removeTrailingSlashes } from 'general/utils';
import { SMALL_COLUMN, TINY_COLUMN } from 'sharedComponents/ui-ag-grid/agGridConstants';
import { RiskTooltip } from '../../RiskTooltip/RiskTooltip';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

interface IServiceTableProps {
    tableActions?: ReactNode;
    getData: UISSRMProps['getData'];
    activeOrgParam: string;
    sortBy?: string;
    onFilterChange: Function;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount: Function;
    onSelectionChanged: Function;
    onGridReady: (params: { api: GridApi; columnApi: ColumnApi }) => void;
}

export const ServicesTable = (props: IServiceTableProps) => {
    const {
        tableActions,
        getData,
        activeOrgParam,
        sortBy,
        onFilterChange,
        paginationPageSize,
        pagination,
        onSelectionChanged,
    } = props;
    const history = useHistory();
    const pathnameWithoutTrailingSlashes = removeTrailingSlashes(history.location.pathname);

    const cellRendererParams = (params: any) => {
        const menuItems = [
            {
                icon: <UiIcon name="goTo" />,
                label: `Go to Service`,
                onClick: () => {
                    history.push(
                        `${pathnameWithoutTrailingSlashes}/${encodeURIComponent(params.data.name)}/${
                            history.location.search
                        }`
                    );
                },
            },
        ];
        return { menuItems };
    };

    const columnDef: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'selection',
            flex: 0,
            maxWidth: TINY_COLUMN,
            sortable: false,
            checkboxSelection: true,
            filter: false,
            filterParams: null,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Name',
            field: 'name',
            filterParams: {
                values: (params: any) => {
                    recursiveHttpGet(
                        `organizations/${activeOrgParam}/discovery/services?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${moment()
                            .subtract(1, 'year')
                            .unix()}&to_timestamp=${moment().unix()}`,
                        0,
                        PAGE_SIZE_LIMIT,
                        [],
                        (services: IService[]) => {
                            const serviceNameList = services.map((service) => service.name);
                            params.success(serviceNameList);
                        },
                        (error: any) => params.reject(error)
                    );
                },
            },
            filter: 'agSetColumnFilter',
            cellRenderer: 'redirectableTitle',
            flex: 2,
            headerComponentParams: {
                headerTooltipContent: 'Service name',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk',
            field: 'risk_score',
            cellRenderer: 'cellProgress',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: <RiskTooltip />,
                disableCopyButton: true,
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk Change',
            field: 'risk_change',
            cellRenderer: 'riskChangeRender',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Difference in cumulative risk between start and end of time range',
            },
            sort: sortBy && sortBy?.split('(')?.[1] === 'risk_change)' ? 'desc' : undefined,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Labels',
            field: 'labels',
            cellRenderer: 'labelListRender',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params: any) => {
                    httpGet(`organizations/${activeOrgParam}/config`).then((res) => {
                        params.success(res.data.labels);
                    });
                },
            },
            type: 'agSetFilter',
            flex: 2,
            headerComponentParams: {
                headerTooltipContent: 'Endpoint and service labels for all endpoints in the service',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Posture',
            field: 'posture_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open posture alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Runtime',
            field: 'behavior_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open behavior or runtime alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Active Endpoints',
            field: 'active_endpoints_count',
            flex: 1.7,
            headerComponentParams: {
                headerTooltipContent: 'Number of endpoints that had traffic in the selected timeframe',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Total Endpoints',
            field: 'total_endpoints_count',
            flex: 1.7,
            headerComponentParams: {
                headerTooltipContent: 'Total number of endpoints for this service',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'New Endpoints',
            field: 'new_endpoints_count',
            cellRenderer: 'changeRender',
            flex: 1.7,
            headerComponentParams: {
                headerTooltipContent: 'Number of endpoints that were first seen in the selected timeframe',
            },
            sort: sortBy && sortBy?.split('(')?.[1] === 'new_endpoints_count)' ? 'desc' : undefined,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Calls',
            field: 'calls_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of requests to service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '4XX',
            field: 'errors_4xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of client-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '5XX',
            field: 'errors_5xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of server-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Last seen',
            field: 'last_seen',
            flex: 1.2,
            cellRenderer: 'dateRender',
            initialSort: 'desc',
            headerComponentParams: {
                headerTooltipContent: 'Last traffic to any endpoint in the service',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'more',
            maxWidth: SMALL_COLUMN,
            flex: 0,
            cellRenderer: 'moreButtonRender',
            cellRendererParams,
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
    ];

    return (
        <FilterableTable
            tableActions={tableActions}
            columns={columnDef}
            getData={getData}
            sortBy={sortBy}
            onFilterChange={onFilterChange}
            paginationPageSize={paginationPageSize}
            pagination={pagination}
            onSelectionChanged={onSelectionChanged}
            setRowsCount={props.setRowsCount}
            redirectOption={'SERVICE'}
            tableTitle={''}
            isHideRowsCount={true}
            onGridReady={props.onGridReady}
        />
    );
};

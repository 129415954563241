import { createListenerMiddleware, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppStartListening, RootState } from 'general/store';

export const listenerMiddlewareDiscovery = createListenerMiddleware();
export const startAppListening = listenerMiddlewareDiscovery.startListening as AppStartListening;

export enum DashboardsEnum {
    OVERVIEW = 'overview',
    DISCOVERY = 'discovery',
}

export interface IDashboardsState {
    activeDashboardId: DashboardsEnum;
}
export const DEFAULT_STATE = { activeDashboardId: 'discovery' };

export const dashboardsSlice = createSlice({
    name: 'dashboardsSlice',
    initialState: DEFAULT_STATE as IDashboardsState,
    reducers: {
        updateActiveDashboardId: (state, { payload: { activeDashboardId } }: PayloadAction<IDashboardsState>) => ({
            ...state,
            activeDashboardId,
        }),
        updateDashboardState: (state, { payload }: PayloadAction<IDashboardsState>) => ({ ...state, ...payload }),
    },
});

export const { updateActiveDashboardId, updateDashboardState } = dashboardsSlice.actions;

export const selectDashboardsSlice = (state: RootState) => state.dashboardsSlice;

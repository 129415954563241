import { useSelector } from 'react-redux';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';

import Spinner from '../spinner/Spinner';
import { createSelectDetok } from 'api/deTokApi';
import { errorMessage } from 'general/toast-service';
import { JSONRenderer, RawRenderer, XMLRenderer } from './renderers';
import { copyToClipboard } from 'general/utils';
import { ITableItem } from 'sharedComponents/SingleCallDetails/UiSimpleTable';

import './PrettyRenderer.scss';

enum RenderingEngines {
    xml = 'XML',
    json = 'JSON',
    raw = 'RAW',
}

interface IPrettyRendererProps {
    content: string;
    callParameters?: ITableItem[];
    contentType?: string;
}

const getPrettyRendererByContentType = (type?: string, callParameters?: ITableItem[]) => {
    const detectContentType = (value?: string) => {
        if (!value || typeof value !== 'string') return RenderingEngines.raw;
        if (value.includes(RenderingEngines.json.toLowerCase())) return RenderingEngines.json;
        if (value.includes(RenderingEngines.xml.toLowerCase())) return RenderingEngines.xml;
        return RenderingEngines.raw;
    };
    if (type) return detectContentType(type);
    const contentTypeParameter = (callParameters ?? []).find((item) => item.name.toLowerCase() === 'content-type');
    return detectContentType(contentTypeParameter?.value as string);
};

const PrettyRenderer = ({ callParameters, content, contentType }: IPrettyRendererProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [invalidTypes, setInvalidTypes] = useState<RenderingEngines[]>([]);
    const [selectedRenderer, setSelectedRenderer] = useState<RenderingEngines>(
        getPrettyRendererByContentType(contentType, callParameters)
    );

    const detokenizedContent = useSelector(createSelectDetok({ tokenizedContent: content }));

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, [selectedRenderer]);

    const changeTabPosition = (e: RadioChangeEvent) => {
        setSelectedRenderer(e.target.value);
    };

    const onConversionFailure = useCallback(() => {
        setInvalidTypes((prev) => [...prev, selectedRenderer]);
        errorMessage(`Could not format value to ${selectedRenderer}`);
        setSelectedRenderer(RenderingEngines.raw);
    }, [selectedRenderer]);

    const renderers: { [key in RenderingEngines]: JSX.Element } = {
        [RenderingEngines.xml]: (
            <XMLRenderer onConversionFailure={onConversionFailure} content={detokenizedContent || content} />
        ),
        [RenderingEngines.json]: (
            <JSONRenderer onConversionFailure={onConversionFailure} content={detokenizedContent || content} />
        ),
        [RenderingEngines.raw]: <RawRenderer content={detokenizedContent || content} />,
    };

    useLayoutEffect(() => {
        wrapperRef.current?.scrollIntoView();
    }, []);

    return (
        <div ref={wrapperRef} className="PrettyRenderer">
            <Radio.Group value={selectedRenderer} onChange={changeTabPosition}>
                <Radio.Button value={RenderingEngines.raw}>{RenderingEngines.raw}</Radio.Button>
                <Radio.Button disabled={invalidTypes.includes(RenderingEngines.json)} value={RenderingEngines.json}>
                    {RenderingEngines.json}
                </Radio.Button>
                <Radio.Button disabled={invalidTypes.includes(RenderingEngines.xml)} value={RenderingEngines.xml}>
                    {RenderingEngines.xml}
                </Radio.Button>
            </Radio.Group>
            <div className={`content ${isLoading ? 'is-loading' : ''}`}>
                {!isLoading ? renderers[selectedRenderer] : <Spinner show />}
                <Button ghost icon={<CopyOutlined />} onClick={() => copyToClipboard(content)} className="copy-icon" />
            </div>
        </div>
    );
};
export default PrettyRenderer;

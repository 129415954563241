import { Select } from 'antd';

import { useGetServiceNamesQuery } from 'api/discoveryApi';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';

import './ServiceMultiSelect.scss';

const { Option } = Select;

export type IServiceMultiSelectValue = string;

export interface IServiceMultiSelectProps {
    value: IServiceMultiSelectValue[];
    onChange: (selectedServices: IServiceMultiSelectValue[]) => void;
    label?: string;
    width?: number;
    maxTagCount?: number;
    maxTagTextLength?: number;
}

export const ServiceMultiSelect = (props: IServiceMultiSelectProps) => {
    const { data: serviceNameList } = useGetServiceNamesQuery();

    return (
        <div className="ServiceMultiSelect">
            <div className="sms-label">{props.label}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                value={props.value}
                maxTagCount={props.maxTagCount}
                maxTagTextLength={props.maxTagTextLength}
                onChange={props.onChange}
            >
                {(serviceNameList || []).map((serviceName) => (
                    <Option key={serviceName} value={serviceName}>
                        {serviceName}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    );
};

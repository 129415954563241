import { Form, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { formFieldName, formFieldRequired, isDuplicatedNameValidator } from 'general/forms';
import { ICollectorConfiguration } from '../shared/collectorConfigurationApis';
import useIsFirstRender from '../../../../../hooks/useFirstRender';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { DataSourceTypesMultiSelect } from './DataSourceTypesMultiSelect/DataSourceTypesMultiSelect';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './CollectorConfigurationAddModal.scss';

export interface IAddModalProps {
    onClose: (refreshNeeded?: boolean) => void;
    existingConfigs?: ICollectorConfiguration[];
}

export const CollectorConfigurationAddModal = ({ onClose, existingConfigs }: IAddModalProps) => {
    const [formValue, setFormValue] = useState<any>({});
    const [isAcceptDisabled, setIsAcceptDisabled] = useState<boolean>(true);
    const [showMultipleDSWarning, setShowMultipleDSWarning] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    const isFirst = useIsFirstRender();

    useEffect(() => {
        if (!isFirst && form.isFieldTouched('list') && form.isFieldTouched('name')) {
            form.validateFields().finally(() => {
                setIsAcceptDisabled(form.getFieldError('name').length !== 0 || !form.getFieldValue('list')?.length);
            });
        }
    }, [formValue, form, isFirst]);

    const handleCreate = useCallback(() => {
        history.push(
            `node-configurations/add?data_source_types=${encodeURIComponent(formValue.list)}&name=${encodeURIComponent(
                formValue.name
            )}`
        );
    }, [formValue, history]);

    return (
        <UiModal
            className="CollectorConfigurationAddModal"
            onCancel={() => onClose(false)}
            acceptButton={{
                text: `${showMultipleDSWarning ? 'Yes, ' : ''} Create`,
                onClick: handleCreate,
                disabled: isAcceptDisabled,
            }}
            rejectButton={{ text: 'Cancel', onClick: () => onClose(false) }}
            title="Node Configuration"
        >
            <Form
                name="name"
                form={form}
                colon={false}
                layout="vertical"
                requiredMark={false}
                onValuesChange={(values) => {
                    setFormValue((prev: any) => ({
                        ...prev,
                        ...values,
                    }));
                }}
            >
                <Form.Item
                    name="name"
                    label="Configuration Name"
                    rules={[
                        {
                            required: true,
                            message: formFieldRequired.errorMessage,
                        },
                        {
                            pattern: formFieldName.pattern,
                            message: formFieldName.errorMessage,
                        },
                        {
                            validator: (_, value) =>
                                isDuplicatedNameValidator(existingConfigs!, value || '', 'Configuration'),
                        },
                    ]}
                >
                    <Input placeholder="E.g. My conf v1" />
                </Form.Item>
                <Form.Item
                    name="list"
                    label=""
                    rules={[
                        {
                            required: true,
                            message: formFieldRequired.errorMessage,
                        },
                    ]}
                >
                    <DataSourceTypesMultiSelect
                        width={500}
                        onChange={(event) => {
                            setShowMultipleDSWarning(event.length > 1);
                            form.setFieldValue('list', event);
                        }}
                    />
                </Form.Item>
            </Form>
            {showMultipleDSWarning && (
                <div className="multiple-ds-warning">
                    <UiIcon name="redWarning" />
                    WARNING! You are trying to create a Node that will receive data from multiple data sources. Proceed?
                </div>
            )}
        </UiModal>
    );
};

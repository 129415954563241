import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';

import { useGetConfigQuery } from 'api/configApi';
import { deTokApi, useDetokLazyQuery } from 'api/deTokApi';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';

export const useDetok = (
    tokenizedContent: string
): [isDetokenized: boolean, content: string, toggleDetok: () => void] => {
    const dispatch = useDispatch();
    const [isDetokenized, setIsDetokenized] = useState<boolean>(false);
    const { data: config } = useGetConfigQuery();

    const [trigger, { isLoading, isError, data: detokenizedContent, error }] = useDetokLazyQuery();

    useEffect(() => {
        if (isError) {
            errorMessage(
                (error as { status: number; data: unknown })?.status === 429
                    ? 'Error: Exceeded the number of de-tokenization requests per second'
                    : extractErrorMessage(error)
            );
            setIsDetokenized(false);
        }
    }, [isError]);

    return [
        isDetokenized,
        isDetokenized && detokenizedContent ? detokenizedContent : tokenizedContent,
        () => {
            !isLoading &&
                setIsDetokenized((prevIsDetokenized) => {
                    if (prevIsDetokenized) {
                        dispatch(
                            deTokApi.util.updateQueryData(
                                'detokenize',
                                { tokenizedContent, apiKey: config!.detok_ratelimit_apikey },
                                () => undefined
                            ) as unknown as AnyAction
                        );
                    } else {
                        trigger({ tokenizedContent });
                    }

                    return !prevIsDetokenized;
                });
        },
    ];
};

import { useCallback, useEffect, useState } from 'react';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { QueryRow } from './QueryRow/QueryRow';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { UiButton } from 'sharedComponents/button/Button';
import { IQueryCondition, IQueryRow, IQueryRowValidation, IQueryValidation } from 'interfaces/query.interface';
import { TimestampQuery } from 'features/discovery/shared/discoveryInterfaces';

import './QueryBuilder.scss';

interface IProps {
    onChange: Function;
    endpointData: { endpoints: IEndpointDisplay[]; endpointsIds: string[] };
    timeRange: TimestampQuery;
    onClearAll: Function;
    validation?: IQueryValidation;
}

export function validateRow(row: IQueryRow): IQueryRowValidation {
    return {
        endpoint: row.defaultValue ? null : 'This field is required',
        conditions: row.conditions?.length
            ? row.conditions.map((condition: IQueryCondition) =>
                  condition.id || condition.id === 0 ? null : 'This field is required'
              )
            : [],
    };
}

export function isValidRow(rowValidation: IQueryRowValidation): boolean {
    return (
        !rowValidation?.endpoint &&
        rowValidation?.conditions.filter((condition: string | null) => condition).length === 0
    );
}

export const QueryBuilder = (props: IProps) => {
    const [queryItems, setQueryItems] = useState<IQueryRow[]>(
        props.endpointData.endpointsIds?.length
            ? [{ conditions: [] }]
            : [{ conditions: [], defaultValue: 'any_endpoint' }]
    );
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [validation, setValidation] = useState(
        props.validation || { rows: [{ endpoint: null, conditions: [] }], isValid: true }
    );

    useEffect(() => {
        props.onChange(queryItems);
    }, []);

    useEffect(() => {
        props.validation && setValidation(props.validation);
    }, [props.validation]);

    const addQueryCondition = useCallback(
        (index: any) => {
            const rowValidation = validateRow(queryItems[index]);

            if (!isValidRow(rowValidation)) {
                setValidation((prev: IQueryValidation) => {
                    const newValidation = { ...prev };
                    newValidation.rows[index] = rowValidation;
                    return newValidation;
                });
                return;
            }

            const newQueryItems = [...queryItems];
            newQueryItems[index].conditions = [...newQueryItems[index].conditions, {}];
            setQueryItems(newQueryItems);
            props.onChange(newQueryItems);
        },
        [queryItems]
    );

    const resetConditions = (queryItem: IQueryRow) => {
        queryItem.conditions = [];
    };

    const selectedAutoComplete = useCallback(
        (value: string, index: number) => {
            const newQueryItems = [...queryItems];
            const queryItem = { ...newQueryItems[index] };

            // TODO : don't reset conditions if chaging to 'any_endpoint'
            resetConditions(queryItem);
            queryItem.defaultValue = value;
            const currEndpoint: IEndpointDisplay | undefined = props.endpointData.endpoints.find(
                (endpoint) => endpoint.id === value
            );
            if (currEndpoint) {
                queryItem.endpointPath = currEndpoint.endpoint_path;
                queryItem.endpointMethod = currEndpoint.method;
                queryItem.endpointServiceName = currEndpoint.service_name;
            }
            newQueryItems[index] = queryItem;
            setQueryItems(newQueryItems);

            const rowValidation = validateRow(newQueryItems[index]);
            setValidation((prev: IQueryValidation) => {
                const newValidation = { ...prev };
                newValidation.rows[index] = rowValidation;
                return newValidation;
            });

            props.onChange(newQueryItems);
        },
        [queryItems]
    );

    // Build / Add to conditions
    const conditionChanged = useCallback(
        (value: any, colIndex: number, rowIndex: number) => {
            const newQueryItems = [...queryItems];
            const queryItem = JSON.parse(JSON.stringify(newQueryItems[rowIndex]));
            const conditions = queryItem.conditions || [];
            conditions[colIndex] = { ...conditions[colIndex], ...value };
            newQueryItems[rowIndex].conditions = conditions;
            setQueryItems(newQueryItems);

            const rowValidation = validateRow(newQueryItems[rowIndex]);
            setValidation((prev: IQueryValidation) => {
                const newValidation = { ...prev };
                newValidation.rows[rowIndex] = rowValidation;
                return newValidation;
            });

            props.onChange(newQueryItems);
        },
        [queryItems]
    );

    useEffect(() => {
        let newRows: any[] = [];
        if (props.endpointData.endpointsIds.length) {
            props.endpointData.endpointsIds.forEach((endpointId) => {
                const queryItem: any = {};
                queryItem.defaultValue = endpointId;
                queryItem.conditions = [];
                const currEndpoint: IEndpointDisplay | undefined = props.endpointData.endpoints.find(
                    (endpoint) => endpoint.id === endpointId
                );
                if (currEndpoint) {
                    queryItem.endpointPath = currEndpoint.endpoint_path;
                    queryItem.endpointMethod = currEndpoint.method;
                    queryItem.endpointServiceName = currEndpoint.service_name;
                }
                newRows.push(queryItem);
            });
        }

        if (newRows.length) {
            setQueryItems(newRows);
            props.onChange(newRows);
        }
    }, [props.endpointData]);

    const resetQuery = () => {
        const newQuery = [{ conditions: [] }];
        props.onChange(newQuery);
        props.onClearAll();
        setQueryItems(newQuery);
    };

    const onConditionRemove = useCallback(
        (colIndex: number, rowIndex: number) => {
            const newQueryItems = [...queryItems];
            newQueryItems[rowIndex].conditions.splice(colIndex, 1);
            setQueryItems(newQueryItems);

            const rowValidation = validateRow(newQueryItems[rowIndex]);
            setValidation((prev: IQueryValidation) => {
                const newValidation = { ...prev };
                newValidation.rows[rowIndex] = rowValidation;
                return newValidation;
            });

            props.onChange(newQueryItems);
        },
        [queryItems]
    );

    const onHoverChange = useCallback((e: any, val: any, index = -1) => {
        e.stopPropagation();
        setHoverIndex(index);
    }, []);

    const removeRow = (index: number) => {
        const newQueryItems = [...queryItems];
        newQueryItems.splice(index, 1);

        setValidation((prev: IQueryValidation) => {
            prev.rows.splice(index, 1);
            return prev;
        });

        props.onChange(newQueryItems);
        setQueryItems(newQueryItems);
    };

    return (
        <div className="query-builder">
            <div className="query-builder-heading">
                <div className="box-title">Build Your Query</div>
                <div className="clear-text-button">
                    <UiButton type="text" text="clear all" onClick={resetQuery} />
                </div>
            </div>
            <div className="query-builder-content box">
                <div className="query-elements">
                    {queryItems.map((item, index, arr) => (
                        <div
                            className={`query-element ${hoverIndex === index ? 'hover-query-row' : ''}`}
                            key={index}
                            onMouseOver={(e) => onHoverChange(e, true, index)}
                            onMouseLeave={(e) => onHoverChange(e, false)}
                        >
                            {arr.length !== 1 && (
                                <span className="remove-row">
                                    <UiIcon name="remove" onClick={(e: any) => removeRow(index)} />
                                </span>
                            )}
                            <QueryRow
                                onSelectedAutoComplete={(value: any) => selectedAutoComplete(value, index)}
                                onConditionsChanged={(value: any, colIndex: number) =>
                                    conditionChanged(value, colIndex, index)
                                }
                                onAddCondition={(e: any) => addQueryCondition(index)}
                                key={index}
                                queryRowObj={item}
                                endpointsData={props.endpointData.endpoints}
                                onConditionRemove={(colIndex: number) => onConditionRemove(colIndex, index)}
                                defaultValue={item.defaultValue}
                                title={index ? 'Followed by' : 'Endpoint'}
                                timeRange={props.timeRange}
                                validation={validation?.rows[index]}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

import { Select } from 'antd';

import { useEntity } from 'services/data/hooks';
import { EntityName } from 'services/data/entityMap';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';
import { sortAlphabetically } from 'general/utils';

import './AlertCategoryMultiSelect.scss';

const { Option } = Select;

interface IAlertCategoryMultiSelectProps {
    label?: string;
    value: string[];
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertCategoryMultiSelect = (props: IAlertCategoryMultiSelectProps) => {
    const [alertCategoryList] = useEntity<{ items: {}[] }>(EntityName.AlertCategory);

    return alertCategoryList ? (
        <div className="alert-category-multi-select">
            <div className="acms-label">{props.label || 'Alert Category'}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                onChange={(event: any) => props.onChange(event)}
            >
                {alertCategoryList.items.sort(sortAlphabetically('category')).map((alertCategory: any) => (
                    <Option key={alertCategory.category} value={alertCategory.category}>
                        {alertCategory.category}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    ) : null;
};

import React, { useCallback, useEffect, useState } from 'react';
import { Tag, AutoComplete } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './UiLabel.scss';

interface ITagProps {
    text: string;
    isClosable?: boolean;
    onCloseHandler?: any;
    newLabel?: boolean;
    moreLabel?: boolean;
    newTagOptions?: { label?: string | undefined; value: any }[];
    addTag?: (label: string) => any;
    maxWidth?: number;
}

export const UiLabel = (props: ITagProps) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [style, setStyle] = useState<any>();

    const onAddTagPressed = () => {
        setShowInput(!showInput);
    };

    const keyDownHandler = (e: any) => {
        if (e.key === 'Enter') {
            props.addTag && props.addTag(inputValue);
            resetState();
        }
    };

    const selectHandler = (e: any): void => {
        props.addTag && props.addTag(e);
    };

    const resetState = () => {
        setShowInput(false);
        setInputValue('');
    };

    const onTagClosedHandler = (e: any) => {
        e.preventDefault();
        props.onCloseHandler();
    };

    const getStyle = useCallback(() => {
        let styleObj: { [key: string]: string } = {};
        if (props.maxWidth) {
            styleObj = {
                ...styleObj,
                maxWidth: `${props.maxWidth}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        }
        if (props.moreLabel) {
            styleObj.opacity = '0.8';
            styleObj.borderStyle = 'dashed';
        }
        return styleObj;
    }, [props.maxWidth]);

    useEffect(() => {
        setStyle(getStyle());
    }, [props.text, props.moreLabel, getStyle]);

    return (
        <>
            {!props.newLabel && (
                <Tag onClose={onTagClosedHandler} closable={props.isClosable} style={style}>
                    {props.text}
                </Tag>
            )}
            {props.newLabel &&
                (!showInput ? (
                    <Tag className="new-tag" onClick={onAddTagPressed}>
                        <PlusOutlined /> New Label
                    </Tag>
                ) : (
                    <AutoComplete
                        className="add-tag"
                        autoFocus={true}
                        onInputKeyDown={(e) => keyDownHandler(e)}
                        onSelect={(e) => {
                            selectHandler(e);
                        }}
                        onChange={(e, option: any) => {
                            setInputValue(option?.label || e);
                        }}
                        onBlur={resetState}
                        dropdownStyle={{ maxWidth: 200, minWidth: 200 }}
                        filterOption={(value, option) =>
                            option?.label?.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1
                        }
                        options={props.newTagOptions}
                    />
                ))}
        </>
    );
};

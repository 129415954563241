import { ICall, IFinding, IGroupedFinding, IScan } from 'api/apiTestingApi';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { UiButton } from 'sharedComponents/button/Button';

import './FindingDetailsOffendingCall.scss';

export interface IFindingDetailsOffendingCall {
    offendingCall: ICall;
    offendingCallSeverity: IGroupedFinding['severity'];
    scanId: IScan['id'];
    groupedFindingId: IGroupedFinding['id'];
    findingId: IFinding['id'];
    onView: () => void;
}

export const FindingDetailsOffendingCall = ({
    offendingCall,
    offendingCallSeverity,
    onView,
}: IFindingDetailsOffendingCall) => {
    return (
        <div className="FindingDetailsOffendingCall">
            <UiIcon name={`alert${offendingCallSeverity}`} />
            <ApiLabel apiType={[offendingCall.method]} place="top" apiName={offendingCall.url} />
            <UiButton type="secondary" text="View call" onClick={onView} />
        </div>
    );
};

import { UiAutoComplete } from 'sharedComponents/UiAutoComplete/UiAutoComplete';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { IApiCallParameter } from 'interfaces/apiCallParameter.interface';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { IQueryCondition } from 'interfaces/query.interface';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';
import { QueryOperatorsDropdown } from 'features/query/QueryBuilder/QueryRow/QueryCondition/QueryOperatorsDropdown';

import './QueryCondition.scss';

interface IQueryConditionProps {
    conditions?: IQueryCondition[];
    conditionsData: [IApiCallParameter[], any];
    onChange: Function;
    loading?: boolean;
    onConditionRemove: Function;
    validation?: (string | null)[];
}

export const QueryCondition = (props: IQueryConditionProps) => {
    const groupedAttributeOptions = props.conditionsData[1];
    const flatAttributeOptions = props.conditionsData[0]; // raw response from api request

    const onOptionSelect = (id: any, index: number) => {
        resetValue(index);
        let attributeOptions = {};
        if (!flatAttributeOptions[id]) {
            attributeOptions = {
                name: id,
                in: 'Log',
                partOf: 'Attributes',
                value_type: 'String',
            };
        }
        props.onChange(
            {
                id,
                index,
                name: flatAttributeOptions?.[id]?.name,
                in: flatAttributeOptions?.[id]?.in,
                partOf: flatAttributeOptions?.[id]?.part_of,
                value_type: flatAttributeOptions?.[id]?.value_type,
                ...attributeOptions,
            },
            index
        );
    };

    const onOperatorChanged = (e: any, index: number) => {
        props.onChange({ operator: e }, index);
    };

    const onValueChanged = (e: any, index: number) => {
        props.onChange({ value: e.target.value }, index);
    };

    const onMultiChanged = (e: any, index: number) => {
        props.onChange({ value: e }, index);
    };

    const resetValue = (index: number) => {
        if (props.conditions && props.conditions[index]) {
            props.conditions[index] = {
                partOf: undefined,
                in: undefined,
                id: undefined,
                operator: undefined,
                value: '',
            };
        }
    };

    return (
        <div className="query-conditions-container">
            {props.conditions?.map((item: any, index: number) => (
                <div className="condition-element" key={index}>
                    <div className="condition-controls">
                        <span className="remove-icon">
                            <UiIcon name="remove" onClick={(e: any) => props.onConditionRemove(index)} />
                        </span>
                        <div className="condition-control">
                            <UiAutoComplete
                                options={groupedAttributeOptions}
                                placeholder={'Parameter'}
                                width={180}
                                loading={props.loading}
                                value={item.id}
                                errorMessage={props.validation?.[index]}
                                onOptionSelect={(e: any) => onOptionSelect(e, index)}
                                enableInputFreeText
                            />
                        </div>
                        <div className="condition-control">
                            <QueryOperatorsDropdown {...{ item, index, onOperatorChanged }} />
                        </div>
                        <div className="condition-control">
                            {item.operator === 'OneOf' || item.operator === 'NotOneOf' ? (
                                <UiMultiSelect
                                    width={180}
                                    onChange={(e: any) => onMultiChanged(e, index)}
                                    placeholder={'Value'}
                                />
                            ) : (
                                <UiInput
                                    type={item.value_type}
                                    value={item.value}
                                    width={180}
                                    maxLength={1000}
                                    disabled={
                                        (!item.id && item.id !== 0) ||
                                        item.operator === 'Exists' ||
                                        item.operator === 'NotExists'
                                    }
                                    placeholder={'Value'}
                                    onChange={(e: any) => onValueChanged(e, index)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

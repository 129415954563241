import { Content } from 'antd/es/layout/layout';
import { BreadCrumb, INavigationLinks } from 'sharedComponents/breadcrumb/BreadCrumb';
import { Layout } from 'antd';

import './ResourceNotFound.scss';

export interface IResourceNotFoundProps {
    breadcrumb: INavigationLinks[];
    selectedRouteName: string;
}

export const ResourceNotFound = ({ breadcrumb, selectedRouteName }: IResourceNotFoundProps) => {
    return (
        <Layout className="ResourceNotFound" style={{ padding: '30px', height: '100%' }}>
            <div>
                <BreadCrumb
                    isPassParams={true}
                    breadcrumbList={breadcrumb}
                    selectedRoute={selectedRouteName}
                    showLastItem={false}
                />
            </div>
            <Content>
                <div className="ResourceNotFoundContent">
                    <div className="title">Resource not found</div>
                    <span className="text">The resource you’re looking for does not exist or has been deleted.</span>
                </div>
            </Content>
        </Layout>
    );
};

import { CellRenderLink } from 'sharedComponents/ui-ag-grid/customCellRenderers';

const commonTableProperties = {
    type: 'basic',
    flex: 1,
    sortable: false,
};

export const authConfigTableCols = (tableMenuItems: Function, authConfigBaseUrl: string) => [
    {
        ...commonTableProperties,
        headerName: 'Name',
        field: 'name',
        flex: 2,
        cellRenderer: CellRenderLink,
        cellRendererParams: ({ data }: any) => ({ path: `${authConfigBaseUrl}/${data?.id}/edit`, text: data.name }),
    },
    {
        ...commonTableProperties,
        headerName: 'Created At',
        field: 'created_at',
        cellRenderer: 'cellRenderTimeStamp',
    },
    {
        ...commonTableProperties,
        headerName: 'Created By',
        field: 'created_by',
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified',
        field: 'updated_at',
        cellRenderer: 'cellRenderTimeStamp',
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified By',
        field: 'updated_by',
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: '',
        field: 'overlay',
        resizable: false,
        flex: 0,
        width: 60,
        cellRenderer: 'cellRenderGenericMore',
        cellRendererParams: tableMenuItems,
    },
];

import { UiIcon } from '../icon/UiIcon';
import { Dropdown, MenuProps } from 'antd';

import './UiOverlayMenu.scss';

export interface IOverlayMenu {
    menuItems: {
        label: string | JSX.Element;
        onClick?: Function;
        disabled?: boolean;
        icon?: JSX.Element;
        tooltip?: string;
    }[];
    disabled?: boolean;
    icon?: JSX.Element;
    handleClickMenu?: Function;
    menuId?: string;
}

export const UiOverlayMenu = ({ menuItems, disabled, icon, handleClickMenu, menuId }: IOverlayMenu) => {
    const items: MenuProps['items'] = menuItems.map((menuItem: any) => {
        if (menuItem.label === 'divider') {
            return { type: 'divider' };
        } else {
            return {
                ...menuItem,
                label: <div title={menuItem.disabled ? menuItem.tooltip : ''}>{menuItem.label}</div>,
                key: menuItem.label + menuId,
            };
        }
    });

    return (
        <div
            className={`overlay-menu-container ${disabled || !menuItems?.length ? 'disabled' : ''}`}
            onClick={(e) => {
                handleClickMenu && handleClickMenu();
                e.stopPropagation();
            }}
        >
            <Dropdown
                className={'overlay-dropdown'}
                menu={{ items }}
                trigger={['click']}
                disabled={disabled || !menuItems?.length}
                destroyPopupOnHide
            >
                {icon || <UiIcon name="more" role="button" />}
            </Dropdown>
        </div>
    );
};

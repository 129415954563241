import React from 'react';
import { useParams } from 'react-router-dom';

import { collectorStateMachine, CollectorStates, ICollector, updateCollector } from '../shared/collectorApis';
import { errorMessage } from 'general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { UiModal } from 'sharedComponents/UiModal/UiModal';

export interface IDeleteOrUndeleteModalProps {
    onClose: () => void;
    collectorToDeleteOrUndelete: ICollector;
    setUpdateListDelay: (delay: number) => void;
}

export const CollectorDeleteOrUndeleteModal = ({
    collectorToDeleteOrUndelete: collector,
    onClose,
    setUpdateListDelay,
}: IDeleteOrUndeleteModalProps) => {
    const { activeOrg } = useParams<ISettingsPathParams>();

    const deleteMode = collectorStateMachine[collector.state]?.isDeletable || !collector.isHeartbeatActive;
    const actionText = deleteMode ? 'Delete' : 'Undelete';

    const deleteOrUndeleteCollector = async (collector: ICollector) => {
        try {
            const state = collector.isHeartbeatActive
                ? deleteMode
                    ? CollectorStates.pending_deleted
                    : CollectorStates.pending_disabled
                : CollectorStates.pending_deleted;
            await updateCollector(activeOrg, collector.sourceId, collector.id, {
                state,
            });

            setUpdateListDelay(1000);
        } catch (e) {
            errorMessage(`Error: cannot ${actionText} node`);
        }
    };

    return (
        <UiModal
            onCancel={onClose}
            acceptButton={{
                text: actionText,
                onClick: () => {
                    deleteOrUndeleteCollector(collector);
                    onClose();
                },
            }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            title={`${actionText} Node?`}
            icon={deleteMode ? 'trash2' : ''}
        >{`${actionText} the node '${collector.name}'?`}</UiModal>
    );
};

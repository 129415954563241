import { useState } from 'react';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { UiOverlayMenu } from 'sharedComponents/UiOverlayMenu/UiOverlayMenu';

import './ContainsTextPredicateInput.scss';

interface IContainsTextPredicateInputProps {
    label: string;
    value: {
        contains: boolean; // true for "contains", false for "does not contain"
        text: string;
    };
    onChange: (event: { contains: boolean; text: string }) => any;
}

export const ContainsTextPredicateInput = (props: IContainsTextPredicateInputProps) => {
    const [output, setOutput] = useState({
        contains: props.value.contains === undefined ? true : props.value.contains,
        text: props.value.text || '',
    });

    function getMenuItems() {
        return [
            {
                label: 'Contains',
                onClick: () => {
                    const newState = {
                        ...output,
                        contains: true,
                    };
                    setOutput(newState);
                    props.onChange(newState);
                },
            },
            {
                label: 'Does not contain',
                onClick: () => {
                    const newState = {
                        ...output,
                        contains: false,
                    };
                    setOutput(newState);
                    props.onChange(newState);
                },
            },
        ];
    }

    function onChange(event: any) {
        const newState = {
            ...output,
            text: event.target.value,
        };
        setOutput(newState);
        props.onChange(newState);
    }

    return (
        <div className="contains-text-predicate-input">
            <div className="ctpi-label">{props.label}</div>
            <div className="ctpi-predicate">
                <UiOverlayMenu
                    menuItems={getMenuItems()}
                    icon={
                        <span
                            style={{
                                marginRight: '10px',
                                color: '#436ace',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {output.contains ? 'Contains' : 'Does not contain'}
                        </span>
                    }
                />
                <UiInput width={435} defaultValue={output.text} onBlur={onChange} />
            </div>
        </div>
    );
};

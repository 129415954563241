import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import { ENDPOINTS_RESULT_LIMIT, IEndpointsDataState } from 'features/query/Query';
import { groupEndpointsByService } from 'general/utils';
import { useOnEscOrClickOutside } from 'hooks/useOnEscOrClickOutside';
import { BreadCrumb, INavigationLinks } from 'sharedComponents/breadcrumb/BreadCrumb';
import { UiAutoComplete } from 'sharedComponents/UiAutoComplete/UiAutoComplete';
import { getTenantEndpoints } from 'features/discovery/shared/discoveryApisLegacy';
import { IEndpoint } from 'features/discovery/shared/discoveryInterfaces';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { AUTO_COMPLETE_WIDTH } from 'interfaces/query.interface';

import './EndpointRedirect.scss';

interface IEndpointRedirectProps {
    queryParams: any;
    pathParams: any;
    timeRange: TDatetimeRange;
    title: string;
    isEndpointSelect?: boolean;
    breadcrumb?: INavigationLinks[];
    selectedRouteName?: string;
}

export const EndpointRedirect = (props: IEndpointRedirectProps) => {
    const { queryParams, pathParams, timeRange, title } = props;
    const [options, setOptions] = useState<any[]>([]);
    const [endpointsData, setEndpointsData] = useState<IEndpointsDataState>({
        endpoints: [],
        endpointsIds: [],
    });
    const [openEndpointSelect, setOpenEndpointSelect] = useState<boolean>(false);
    const endpointsIds: any[] = queryParams.get('endpointIds') ? JSON.parse(queryParams.getAll('endpointIds')) : [];
    const activeOrganization = pathParams.activeOrgParam;

    const [selectedEndPoint, setSelectedEndpoint] = useState<any>();
    const onOutsideClick = () => {
        setOpenEndpointSelect(false);
    };
    const wrapperRef = useRef<HTMLDivElement>(null);
    const componentClass = 'query-endpoint-autocomplete';
    useOnEscOrClickOutside(wrapperRef, onOutsideClick, componentClass);

    useEffect(() => {
        setOptions(groupEndpointsByService(endpointsData.endpoints));
    }, [endpointsData]);

    useEffect(() => {
        if (!activeOrganization) {
            return;
        }
        let offset = 0;
        let totalEndpointsToFetch: number = 0;
        getTenantEndpoints(
            activeOrganization,
            timeRange[0].format('X'),
            timeRange[1].format('X'),
            ENDPOINTS_RESULT_LIMIT.toString(),
            offset.toString()
        ).then((res: any) => {
            const endpoints: IEndpoint[] = res.data.items;
            totalEndpointsToFetch = res.data.total;
            let displayedEndpoints: IEndpointDisplay[] = [];
            endpoints.forEach((endpoint) => {
                displayedEndpoints.push({
                    id: endpoint.id,
                    method: endpoint.method,
                    endpoint_path: endpoint.endpoint_path,
                    service_name: endpoint.service_name,
                    pending_deletion: endpoint.pending_deletion,
                    deleted_by: endpoint.deleted_by,
                });
            });
            setEndpointsData({ endpoints: displayedEndpoints, endpointsIds });
            if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                getMoreEndpoints();
            }
        });

        function getMoreEndpoints() {
            offset += ENDPOINTS_RESULT_LIMIT;
            getTenantEndpoints(
                activeOrganization,
                timeRange[0].format('X'),
                timeRange[1].format('X'),
                ENDPOINTS_RESULT_LIMIT.toString(),
                offset.toString()
            )
                .then((res) => {
                    setEndpointsData((prevData: IEndpointsDataState) => ({
                        ...prevData,
                        endpoints: [...prevData.endpoints, ...res.data.items],
                    }));

                    if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                        getMoreEndpoints();
                    }
                })
                .catch((err) => {
                    console.log('error fetching endpoints', err);
                });
        }
    }, [timeRange]);

    const endpointSelectHandler = (value: any) => {
        setSelectedEndpoint(null);
        const endpoint = endpointsData.endpoints.find((e) => e.id === value);
        if (!endpoint?.pending_deletion) {
            setOpenEndpointSelect(false);
            window.open(
                `/${activeOrganization}/discovery/services/${encodeURIComponent(
                    endpoint?.service_name as string
                )}/endpoints/${endpoint?.id}?from_timestamp=${timeRange[0].format(
                    'X'
                )}&to_timestamp=${timeRange[1].format('X')}`,
                '_blank'
            );
        }
    };

    const onShowEndpoints = () => {
        setOpenEndpointSelect(!openEndpointSelect);
    };

    return (
        <div className="endpoint-redirect" ref={wrapperRef}>
            {props.breadcrumb && props.selectedRouteName && (
                <div className="breadcrumb-with-endpoints">
                    <BreadCrumb
                        isPassParams={true}
                        breadcrumbList={props.breadcrumb}
                        selectedRoute={props.selectedRouteName}
                    />
                    {props.isEndpointSelect && (
                        <div className={`endpoint-select-container`}>
                            <SearchOutlined className="search-endpoint-breadcrumb" onClick={onShowEndpoints} />
                            <div
                                className={`endpoint-redirect-control ${
                                    !openEndpointSelect ? 'hide-endpoint-select' : 'visible-endpoint-select'
                                }`}
                            >
                                {openEndpointSelect && (
                                    <UiAutoComplete
                                        options={options}
                                        width={AUTO_COMPLETE_WIDTH}
                                        value={selectedEndPoint}
                                        dropDownOpen={() => {}}
                                        className={componentClass}
                                        onOptionSelect={endpointSelectHandler}
                                        isAlwaysOpen={openEndpointSelect}
                                        autoFocus
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <span
                className="endpoint-redirect-title"
                style={
                    props.isEndpointSelect && !props.breadcrumb && !props.selectedRouteName ? { display: 'inline' } : {}
                }
            >
                {title}
            </span>
            {props.isEndpointSelect && !props.breadcrumb && !props.selectedRouteName && (
                <div className={`endpoint-select-container`}>
                    <SearchOutlined className="search-endpoint" onClick={onShowEndpoints} />
                    <div
                        className={`endpoint-redirect-control ${
                            !openEndpointSelect ? 'hide-endpoint-select' : 'visible-endpoint-select'
                        }`}
                    >
                        {openEndpointSelect ? (
                            <UiAutoComplete
                                options={options}
                                width={AUTO_COMPLETE_WIDTH}
                                value={selectedEndPoint}
                                dropDownOpen={() => {}}
                                className={`query-endpoint-autocomplete`}
                                onOptionSelect={endpointSelectHandler}
                                isAlwaysOpen={openEndpointSelect}
                                autoFocus
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

import React from 'react';
import './UiCheckbox.scss';
import { Checkbox } from 'antd';

interface IChekcboxProps {
    onChange: any;
    isDisabled?: boolean;
    label?: string;
    value?: boolean;
}

export const UiChekcbox = (props: IChekcboxProps) => {
    return (
        <Checkbox defaultChecked={props.value} onChange={props.onChange} disabled={props.isDisabled}>
            {props.label}
        </Checkbox>
    );
};

import { TimeDisplayResEnum } from 'general/utils';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ExclusionRuleOption } from 'api/exclusionRuleApi';

const commonTableProperties = {
    type: 'basic',
    flex: 0.5,
    sortable: false,
};

export const getEndpointFilterListCols = (onRowToggleHandler: Function, tableMenuItems: Function) => [
    {
        ...commonTableProperties,
        headerName: 'Status',
        flex: 0,
        width: 80,
        field: 'active',
        cellRenderer: 'cellRenderToggle',
        headerTooltip: 'Disabled/Enabled',
        cellRendererParams: (params: ICellRendererParams) => ({
            disabled: !(
                params.data.filter_option === ExclusionRuleOption.PATH ||
                params.data.filter_option === ExclusionRuleOption.ATTRIBUTE
            ),
            onRowToggleHandler,
        }),
    },
    {
        ...commonTableProperties,
        headerName: 'Condition',
        field: 'condition',
        flex: 2,
        cellRenderer: 'endpointFilterRuleCondCellRenderer',
        headerTooltip: 'Rule match conditions',
    },
    {
        ...commonTableProperties,
        headerName: 'Description',
        field: 'description',
        flex: 1,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        ...commonTableProperties,
        headerName: 'Created At',
        field: 'created_at',
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            timeResolution: TimeDisplayResEnum.MIN,
        },
        headerTooltip: 'Rule’s last modification timestamp',
    },
    {
        ...commonTableProperties,
        headerName: 'Author',
        field: 'creating_user',
        cellRenderer: 'cellRenderVerticalCenter',
        headerTooltip: 'Rule creator',
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified',
        field: 'updated_at',
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            timeResolution: TimeDisplayResEnum.MIN,
        },
        headerTooltip: 'Rule’s last modification timestamp',
    },
    {
        ...commonTableProperties,
        headerName: 'Last Modified By',
        field: 'last_updated_user',
        cellRenderer: 'cellRenderVerticalCenter',
        headerTooltip: 'Last user to modify rule',
    },
    {
        ...commonTableProperties,
        headerName: '',
        field: 'overlay',
        resizable: false,
        flex: 0,
        width: 60,
        cellRenderer: 'cellRenderGenericMore',
        cellRendererParams: tableMenuItems,
    },
];

export interface ILabel {
    text: string,
    background_color: string,
    font_color: string
}

export interface LabelValue {
    label: string;
    value: string;
}

export interface IBaseLabel {
    label: string;
    is_automated: boolean;
}

export interface IServiceLabel extends IBaseLabel {
}

export interface IEndpointLabel extends IBaseLabel {
}

export enum LabelSuppressPeriod {
    'One day' = 1,
    'Seven days' = 7,
    '30 Days' = 30,
    'Forever' = -1,
}

export type LabelSuppressPeriodDisplay = keyof typeof LabelSuppressPeriod;



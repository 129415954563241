import { createSelector } from '@reduxjs/toolkit';

import { IServerQueryResponse, tenantApi } from 'api/baseServerApi';
import { RootState } from 'general/store';
import { errorMessage } from 'general/toast-service';
import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export enum OperationValueEnum {
    Name = 'Name',
    Extract = 'Extract',
}

export interface IServiceMappingItem {
    id: string;
    tenant_key: string;
    index: number;
    active: boolean;
    pattern: string;
    operation_type: string;
    operation_value: OperationValueEnum;
    description: string;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    is_editable: boolean;
}

export interface ICreateServiceMappingItemBody {
    pattern: string;
    operation_type: string; // Valid options are 'Name' or 'Extract'
    operation_value: string; // on operation_type = 'Extract' duplicate the pattern field value to this value as well
    description?: string;
}

export interface IUpdateServiceMappingItemBody extends Partial<ICreateServiceMappingItemBody> {
    index?: string;
    active?: boolean;
}

const updateAddResponseError = ({ status, data }: BaseQueryError<any>) => {
    if (status === 400) {
        errorMessage(data?.detail || 'Bad request');
    }
    if (status === 422) {
        errorMessage('Validation Error');
    }
};

export const serviceMappingApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getServiceMappingList: builder.query<IServerQueryResponse<IServiceMappingItem>, void>({
            query: () => ({
                url: `discovery/service_mapping_rules`,
            }),
            transformResponse: (response: IServerQueryResponse<IServiceMappingItem>) => ({
                ...response,
                items: response.items.map((rule) =>
                    rule.pattern === 'Default Rule'
                        ? { ...rule, active: true, created_at: '', updated_at: '', hide: true }
                        : rule
                ),
            }),
        }),
        getServiceMappingItem: builder.query<IServiceMappingItem, { serviceMappingId: string }>({
            query: ({ serviceMappingId }) => ({
                url: `discovery/service_mapping_rules/${serviceMappingId}`,
            }),
        }),
        createServiceMappingItem: builder.query<void, { body: ICreateServiceMappingItemBody }>({
            query: ({ body }) => ({
                url: `discovery/service_mapping_rules`,
                method: 'POST',
                body,
            }),
            transformErrorResponse: (response: BaseQueryError<any>) => {
                updateAddResponseError(response);
            },
        }),
        deleteServiceMappingItem: builder.query<void, { serviceMappingId: string }>({
            query: ({ serviceMappingId }) => ({
                url: `discovery/service_mapping_rules/${serviceMappingId}`,
                method: 'DELETE',
            }),
        }),
        updateServiceMappingItem: builder.query<
            void,
            { serviceMappingId: string; body: IUpdateServiceMappingItemBody }
        >({
            query: ({ serviceMappingId, body }) => ({
                url: `discovery/service_mapping_rules/${serviceMappingId}`,
                method: 'PATCH',
                body,
            }),
            transformErrorResponse: (response: BaseQueryError<any>) => {
                updateAddResponseError(response);
            },
        }),
    }),
    overrideExisting: false,
});

export const useServiceMappingListQuery = serviceMappingApi.endpoints.getServiceMappingList.useQuery;
export const useServiceMappingItemQuery = serviceMappingApi.endpoints.getServiceMappingItem.useQuery;
export const useCreateServiceMappingItemMutation = serviceMappingApi.endpoints.createServiceMappingItem.useLazyQuery;
export const useDeleteServiceMappingItemMutation = serviceMappingApi.endpoints.deleteServiceMappingItem.useLazyQuery;
export const useUpdateServiceMappingItemMutation = serviceMappingApi.endpoints.updateServiceMappingItem.useLazyQuery;

export const selectServiceMappingSlice = serviceMappingApi.endpoints.getServiceMappingList.select();
export const selectServiceMappingList = createSelector(selectServiceMappingSlice, ({ data }) => data?.items);
export const selectServiceMappingRuleById = (state: RootState, ruleId: string) =>
    createSelector(selectServiceMappingList, (list) => list?.filter((rule) => rule.id === ruleId));

import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { IGroupedFinding } from 'api/apiTestingApi';

import './FindingDetailsEndpoint.scss';

interface IFindingDetailsEndpointProps {
    method: IGroupedFinding['method'];
    endpoint: IGroupedFinding['endpoint'];
}

export const FindingDetailsEndpoint = ({ method, endpoint }: IFindingDetailsEndpointProps) => {
    return (
        <div className="FindingDetailsEndpoint">
            <div className="finding-details-container-title">Endpoint</div>
            <div className="finding-details-container-content">
                <ApiLabel apiType={[method]} place="top" apiName={endpoint} />
            </div>
        </div>
    );
};

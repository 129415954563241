import { Progress } from 'antd';

import './UiProgress.scss';

interface IProgressProps {
    percentage: number | string;
    extraInfo?: any;
    className?: string;
}

export const UiProgress = (props: IProgressProps) => {
    return (
        <div className="ui-progress">
            <Progress percent={+props.percentage} showInfo={!props.extraInfo} className={props.className} />
            <div className="extra-info">{props.extraInfo}</div>
        </div>
    );
};

export const defaultGraphColors = [
    '#2D57C2',
    '#C4D5FF',
    '#816DF9',
    '#B99CF8',
    '#A4B2C3',
    '#565B75',
    '#27aeef',
    '#b33dc6',
    '#e60049',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
    '#e60049',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
    '#ea5545',
    '#f46a9b',
    '#ef9b20',
    '#edbf33',
    '#ede15b',
    '#bdcf32',
    '#87bc45',
];

import { Button } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { httpGet } from 'general/http-service';
import { valueEmptyState, ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { IScan } from 'api/apiTestingApi';
import { copyToClipboard, extractErrorMessage, fullDateStringFormat } from 'general/utils';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { errorMessage } from 'general/toast-service';
import { AuthConfigModal } from './AuthConfigModal/AuthConfigModal';
import { TestSuiteModal } from './TestSuiteModal/TestSuiteModal';

import './ScanExtraDetails.scss';

interface IScanExtraDetailsProps {
    scan?: IScan;
}

export const getCopyToClipboardIcon = (copyValue: string = '') => (
    <Button
        type="link"
        icon={<UiIcon name="copy" style={{ width: '40px' }} />}
        onClick={() => copyToClipboard(copyValue)}
        className="copy-button"
    />
);

export const ScanExtraDetails = ({ scan }: IScanExtraDetailsProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { suite, oas_file_name, oas_file_id, auth_config_name, created_at, started_at, ended_at, queued_at } =
        scan || {};
    const [isTestSuiteModalOpen, setIsTestSuiteModalOpen] = useState<boolean>(false);
    const [isAuthConfigModalOpen, setIsAuthConfigModalOpen] = useState<boolean>(false);
    const [loadingSwagger, setLoadingSwagger] = useState<boolean>(false);

    const handleDownloadSwagger = async () => {
        try {
            setLoadingSwagger(true);
            const response = await httpGet(`organizations/${currentTenantKey}/testing/oas/${oas_file_id}`);
            const blob = new Blob([response.data.data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, oas_file_name);
        } catch (error: unknown) {
            errorMessage(extractErrorMessage(error));
        } finally {
            setLoadingSwagger(false);
        }
    };

    const DownloadSwagger = () =>
        oas_file_name && oas_file_id ? (
            <Button
                loading={loadingSwagger}
                style={{ display: 'flex', alignItems: 'center' }}
                type="text"
                icon={<UiIcon name="download" />}
                onClick={handleDownloadSwagger}
            >
                {oas_file_name}
            </Button>
        ) : (
            valueEmptyState
        );

    const TestSuite = () =>
        suite?.name && suite?.test_count ? (
            <div>
                <Button type="text" onClick={() => setIsTestSuiteModalOpen(true)}>
                    {suite?.name}
                </Button>
                <span> ({suite?.test_count} tests)</span>
            </div>
        ) : (
            valueEmptyState
        );

    const AuthConfig = () =>
        auth_config_name ? (
            <Button type="text" onClick={() => setIsAuthConfigModalOpen(true)}>
                {auth_config_name}
            </Button>
        ) : (
            valueEmptyState
        );

    return (
        <div className="ScanExtraDetails">
            <ValueWithLabel label="target oas" value={<DownloadSwagger />} />
            <ValueWithLabel label="test suite" value={<TestSuite />} />
            <ValueWithLabel label="auth config" value={<AuthConfig />} />
            <ValueWithLabel label="creation time" value={created_at && fullDateStringFormat(created_at, false)} />
            <ValueWithLabel label="start time" value={started_at && fullDateStringFormat(started_at, false)} />
            <ValueWithLabel label="end time" value={ended_at && fullDateStringFormat(ended_at, false)} />
            {isTestSuiteModalOpen && (
                <TestSuiteModal
                    scan={scan}
                    onClose={() => setIsTestSuiteModalOpen(false)}
                    getCopyToClipboardIcon={getCopyToClipboardIcon}
                />
            )}
            {isAuthConfigModalOpen && (
                <AuthConfigModal
                    scan={scan}
                    onClose={() => setIsAuthConfigModalOpen(false)}
                    getCopyToClipboardIcon={getCopyToClipboardIcon}
                />
            )}
        </div>
    );
};

import { tenantApi } from './baseServerApi';
import { ICallAttributesQuery, ICallsQuery } from 'interfaces/query.interface';
import { IApiCall } from 'interfaces/apiCall.interface';

export interface IQueryInitiationResponse {
    query_execution_id: string;
    query_execution_id_total: string;
}

export const queryPageApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        initiateCallsQuery: builder.query<IQueryInitiationResponse, ICallsQuery>({
            query: (body: any) => ({
                url: 'calls/queries',
                method: 'POST',
                body,
            }),
        }),
        getCallsWithExecId: builder.query<Omit<IApiCall, 'attributes'>[], string>({
            query: (execId: string) => ({
                url: `calls/queries/${execId}`,
            }),
        }),
        getTotalNumCallsWithExecId: builder.query<{ total: number }, string>({
            query: (execId: IQueryInitiationResponse['query_execution_id']) => ({
                url: `queries/${execId}/total`,
            }),
        }),
        initiateCallAttributesQuery: builder.query<IQueryInitiationResponse, ICallAttributesQuery>({
            query: (body: any) => ({
                url: 'calls/attributes/queries',
                method: 'POST',
                body,
            }),
        }),
        getCallAttributesWithExecId: builder.query<
            { items: Pick<IApiCall, 'id' | 'attributes'>[]; count: number },
            string
        >({
            query: (execId: IQueryInitiationResponse['query_execution_id']) => ({
                url: `calls/attributes/queries/${execId}`,
            }),
        }),
    }),
});

export const {
    useLazyGetCallsWithExecIdQuery,
    useLazyGetTotalNumCallsWithExecIdQuery,
    useLazyInitiateCallsQueryQuery,
    useLazyInitiateCallAttributesQueryQuery,
    useLazyGetCallAttributesWithExecIdQuery,
} = queryPageApi;

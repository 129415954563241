import React, { useEffect, useState } from 'react';
import { IAlert } from '../../../../interfaces/alert.interface';
import { AlertItem } from '../../../dashboards/OverviewDashboard/RecentOpenAlertsWidget/alert-item/AlertItem';
import { UiButton } from '../../../../sharedComponents/button/Button';

import './AlertsCarousel.scss';

interface IAlertsCarouselProps {
    alertsData: IAlert[];
    reloadAlertsCB: Function;
    alertStatusChanged?: (isOpened: boolean) => void;
}

export const AlertsCarousel = ({ alertsData, reloadAlertsCB, alertStatusChanged }: IAlertsCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const changeAlert = (val: number) => {
        setCurrentIndex((prevIndex) => prevIndex + val);
    };

    useEffect(() => {
        if (alertsData.length <= currentIndex) {
            setCurrentIndex(alertsData.length - 1);
        }
    }, [alertsData.length]);

    return alertsData.length > 0 ? (
        <div className="alerts-carousel">
            <div className="header">
                <div className="carousel-title box-title">
                    Alerts{' ' + (currentIndex + 1 + '/' + alertsData.length)}
                </div>
                <div className="controls">
                    <UiButton
                        onClick={() => changeAlert(-1)}
                        disabled={currentIndex === 0}
                        className={'text-with-icon'}
                        type={'text'}
                        text={'Prev'}
                        icon={'leftArrowSmall'}
                    />
                    <UiButton
                        onClick={() => changeAlert(1)}
                        disabled={currentIndex === alertsData.length - 1}
                        className={'text-with-icon reverse'}
                        type={'text'}
                        text={'Next'}
                        icon={'rightArrowSmall'}
                    />
                </div>
            </div>
            {alertsData[currentIndex] && (
                <AlertItem
                    alertData={alertsData[currentIndex]}
                    stretchHeight
                    showGoToEndpointOption={false}
                    reloadAlertsCB={reloadAlertsCB}
                    alertStatusChanged={alertStatusChanged}
                />
            )}
        </div>
    ) : null;
};

import { useEffect, useState } from 'react';

import { UiButton } from 'sharedComponents/button/Button';

import './UiPagination.scss';

export interface IUiPaginationProps {
    initialPage: number;
    totalPageCount: number;
    onPageChange: (page: number) => void;
}

export const UiPagination = ({ initialPage, totalPageCount, onPageChange }: IUiPaginationProps) => {
    const [currentPage, setCurrentPage] = useState<number>(initialPage);

    useEffect(() => {
        setCurrentPage(initialPage);
    }, [initialPage]);

    const changePage = (page: number) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    return (
        <div className="UiPagination">
            <UiButton
                onClick={() => changePage(currentPage - 1)}
                disabled={currentPage === 1}
                type="text"
                text="Prev"
                icon="leftArrowSmall"
            />
            <span className="uip-text">{`${currentPage} of ${totalPageCount}`}</span>
            <UiButton
                className="text-with-icon reverse"
                onClick={() => changePage(currentPage + 1)}
                disabled={currentPage === totalPageCount}
                type="text"
                text="Next"
                icon="rightArrowSmall"
            />
        </div>
    );
};

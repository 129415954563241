import React from 'react';

import { UiTooltip } from '../UiTooltip/UiTooltip';

import './Card.scss';

export interface ICardProps {
    title: string;
    children: React.ReactNode;
    menu?: React.ReactNode;
    subtitle?: React.ReactNode;
    isDisabled?: boolean;
}

export const Card = ({ children, title, menu, subtitle, isDisabled }: ICardProps) => {
    return (
        <div className={`Card ${isDisabled ? 'card-disabled-background' : 'card-background'}`}>
            <div className="header">
                <div className="leftHeader ellipsis">
                    <UiTooltip title={title}>
                        <span className={`title ${isDisabled && 'disabled-title'} ellipsis`}>{title}</span>
                    </UiTooltip>
                    {subtitle}
                </div>
                {menu}
            </div>
            <div className="divider" />
            <div className="section">{children}</div>
        </div>
    );
};

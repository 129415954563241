import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
// import { RumRoute as Route } from '@datadog/rum-react-integration';

import { NoTenant } from 'features/NoTenant/NoTenant';
import { FourOFour } from 'features/404/404';
import { User } from 'features/user/User';
import { Alerts } from 'features/alerts/Alerts';
import { Query } from 'features/query/Query';
import { Discovery } from 'features/discovery/Discovery';
import Spinner from 'sharedComponents/spinner/Spinner';
import { selectTenantList, useGetTenantsListQuery } from 'api/tenantListApi';
import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { fourOFour } from 'api/listeners/initAppState';
import { startDDRumTracking } from 'analyticsUtils';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';
import { selectDashboardsSlice } from 'api/slices/dashboardsSlice';
import { Dashboards } from 'features/dashboards/Dashboards';
import { useGetConfigLazyQuery } from 'api/configApi';
import { ApitTestingRoutes } from 'features/apiTesting/ApitTestingRoutes';
import { Settings } from 'features/settings/Settings';

export const AppRoutes = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const tenantList = useSelector(selectTenantList);
    const { isError: isTenantListError } = useGetTenantsListQuery();
    const { data: user } = useGetUserSessionTokenPayloadQuery();
    const { activeDashboardId } = useSelector(selectDashboardsSlice);
    const [triggerConfigQuery, { data: config }] = useGetConfigLazyQuery();

    useEffect(() => {
        config &&
            currentTenantKey &&
            user?.email &&
            !!featureflagMap?.ddRumTracking &&
            startDDRumTracking(currentTenantKey);
    }, [currentTenantKey, user?.email, config]);

    useEffect(() => {
        currentTenantKey && !config && triggerConfigQuery();
    }, [currentTenantKey, config]);

    if (!user) {
        return <Spinner show />;
    }

    if (isTenantListError || (tenantList && Object.keys(tenantList).length === 0)) {
        return <NoTenant />;
    }

    if (!currentTenantKey) {
        return <Spinner show />;
    }

    if (currentTenantKey === fourOFour) {
        return <FourOFour />;
    }

    if (!config) {
        return <Spinner show />;
    }

    return (
        <Switch>
            <Route path={'/:activeOrgParam/user'}>
                <User />
            </Route>
            <Route path={'/:activeOrgParam/alerts/:activeFilterId'}>
                <Alerts />
            </Route>
            <Route path={'/:activeOrgParam/alerts'}>
                <Alerts />
            </Route>
            <Route path="/:activeOrgParam/query">
                <Query />
            </Route>
            <Route path="/:activeOrgParam/discovery/services">
                <Discovery />
            </Route>
            {
                // TODO: Remove when api-testing is open for all
                featureflagMap['apiTesting'] && (
                    <Route path="/:activeOrg/api-testing">
                        <ApitTestingRoutes />
                    </Route>
                )
            }
            <Route path="/:activeOrgParam/entity/:entityType/event/:eventType/:eventId/:eventTimestamp">
                <User />
            </Route>
            <Route path="/:activeOrg/settings">
                <Settings />
            </Route>
            <Route path="/:activeOrgParam/dashboards/:activeDashboardId">
                <Dashboards />
            </Route>
            <Route path="/:activeOrgParam/dashboards/">
                <Redirect to={`/${currentTenantKey}/dashboards/${activeDashboardId}`} />
            </Route>
            <Route path="/:activeOrgParam/home">
                <Redirect to={`/${currentTenantKey}/dashboards/${activeDashboardId}`} />
            </Route>
            <Route path="/:activeOrgParam">
                <Redirect to={`/${currentTenantKey}/dashboards/${activeDashboardId}`} />
            </Route>
        </Switch>
    );
};

import { ICellRendererParams } from '@ag-grid-community/core';
import { OperationValueEnum } from 'api/ServiceMappingApi';

import './CellRendererCustomMappingRule.scss';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';

const DEFAULT_RULE_PATTERN = 'Default Rule';

function getRuleTypePrettyPrint(isTypeName: boolean): 'Match URL' | 'Match And Extract URL' {
    return isTypeName ? 'Match URL' : 'Match And Extract URL';
}

function isDefaultRule(pattern: string) {
    return pattern === DEFAULT_RULE_PATTERN;
}

export const CellRendererCustomMappingRule = ({ data }: ICellRendererParams) => {
    const { operation_type, operation_value, pattern } = data;
    const isTypeName = operation_type === OperationValueEnum.Name;

    const getTooltipContent = (): string => {
        if (isDefaultRule(pattern)) {
            return DEFAULT_RULE_PATTERN;
        }

        const svcNameIfRelevant = isTypeName ? `, Name "${operation_value}"` : '';

        return getRuleTypePrettyPrint(isTypeName) + ` /${pattern}/` + svcNameIfRelevant;
    };

    return (
        <div className="custom-cell-renderer CellRendererCustomMappingRule">
            <UiTooltip title={getTooltipContent()} overlayClassName="pre-wrap" placement={'top'}>
                {!isDefaultRule(pattern) && <span className="type-text">{getRuleTypePrettyPrint(isTypeName)}</span>}
                <span>{pattern}</span>
                {isTypeName && (
                    <>
                        <span className="name-text"> Name </span>
                        <span> {operation_value} </span>
                    </>
                )}
            </UiTooltip>
        </div>
    );
};

import React, { useEffect } from 'react';
import { Checkbox, Divider } from 'antd';

import { AppDispatch } from 'general/store';
import { useDispatch, useSelector } from 'react-redux';
import { checkboxShowServicesWithoutActivityToggled, selectDiscoveryPagesSlice } from 'api/slices/discoveryPagesSlice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { useDownloadSwagger } from 'hooks/useDownloadSwagger';
import { getPluralSuffix } from 'general/utils';

import './ServicesTableActions.scss';

export interface IServicesTableActionsProps {
    setFilters: Function;
    selectedServices: string[];
    pathParams: { activeOrgParam: string };
    timeRangeFromInput: TDatetimeRange;
}

export default function ServicesTableActions({
    setFilters,
    selectedServices,
    pathParams,
    timeRangeFromInput,
}: IServicesTableActionsProps) {
    const rtkDispatch = useDispatch<AppDispatch>();
    const { showServicesWithNoActivity } = useSelector(selectDiscoveryPagesSlice);
    const activeTenant = pathParams.activeOrgParam;
    const downloadSwagger = useDownloadSwagger({
        serviceNames: selectedServices,
        tenant: activeTenant,
        fromTimestamp: timeRangeFromInput[0].unix(),
        toTimestamp: timeRangeFromInput[1].unix(),
    });

    useEffect(() => {
        if (showServicesWithNoActivity) {
            setFilters([]);
        } else {
            setFilters([[{ name: 'calls_count', operator: 'ne', value: 0 }]]);
        }
    }, [showServicesWithNoActivity]);

    return (
        <div className="ServicesTableActions">
            {selectedServices.length > 0 && (
                <div className="table-actions">
                    <span className="selected-service-action">
                        {`${selectedServices.length} SERVICE${getPluralSuffix('S', selectedServices.length)} SELECTED`}
                    </span>
                    <span onClick={() => downloadSwagger()} className="download-oas-action">
                        <UiIcon name="download" className="download-icon" isNotDefaultStyle />
                        {`Download OAS File${getPluralSuffix('s', selectedServices.length)}`}
                    </span>
                    <Divider type="vertical" className="vertical-divider" />
                </div>
            )}
            <div className="show-hide-by-activity">
                <Checkbox
                    checked={showServicesWithNoActivity}
                    onChange={(e: CheckboxChangeEvent) => {
                        rtkDispatch(checkboxShowServicesWithoutActivityToggled(e.target.checked));
                    }}
                >
                    Show services with no activity
                </Checkbox>
            </div>
        </div>
    );
}

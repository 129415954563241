import { useState } from 'react';

import { EntityMultiSelect, EntityMultiSelectState } from './EntityMultiSelect';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './EntityMultiSelect.scss';

export interface EntityMultiSelectListProps {
    initialState: EntityMultiSelectState[];
    onChange: (event: EntityMultiSelectState[]) => void;
}

export const EntityMultiSelectList = (props: EntityMultiSelectListProps) => {
    const [componentState, setComponentState] = useState<EntityMultiSelectListProps['initialState']>(
        props.initialState
    );

    function onChange(event: EntityMultiSelectState, index: number) {
        const newState = [...componentState];
        newState.splice(index, 1, event);
        setComponentState(newState);
        props.onChange(newState);
    }

    function remove(index: number) {
        const newState = [...componentState];
        newState.splice(index, 1);
        setComponentState(newState);
        props.onChange(newState);
    }

    function add() {
        const newState = componentState.concat([{ entity: { type: '', id: [] } }]);
        setComponentState(newState);
    }

    return (
        <div className="entity-multi-select-list">
            <div className="ems-label">Associated Entity</div>
            {componentState.map((entityMultiSelectState, i) => (
                <div key={`${i}${entityMultiSelectState.entity.type}`}>
                    {i > 0 ? (
                        <div className="ems-separator">
                            AND<div className="ems-separator-line"></div>
                        </div>
                    ) : null}
                    <div
                        style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '15px' }}
                    >
                        <EntityMultiSelect
                            initialState={entityMultiSelectState}
                            onChange={(event) => onChange(event, i)}
                        />
                        <span style={{ marginLeft: '15px' }}>
                            <UiIcon name="remove" onClick={() => remove(i)} />
                        </span>
                    </div>
                </div>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => add()}>
                <UiIcon name="add" />
                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add Entity</span>
            </div>
        </div>
    );
};

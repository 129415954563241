import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { IScan } from 'api/apiTestingApi';
import { ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { AuthConfigModal } from 'features/apiTesting/scans/ScanDetails/ScanExtraDetails/AuthConfigModal/AuthConfigModal';
import { getCopyToClipboardIcon } from 'features/apiTesting/scans/ScanDetails/ScanExtraDetails/ScanExtraDetails';
import { fullDateStringFormat } from 'general/utils';

interface IFindingDetailsScanProps {
    scan: IScan;
}

export const FindingDetailsScan = ({ scan }: IFindingDetailsScanProps) => {
    const [isAuthConfigModalOpen, setIsAuthConfigModalOpen] = useState<boolean>(false);
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId } = useParams<IApitRouteParams>();

    return (
        <div className="FindingDetailsScan">
            <ValueWithLabel
                label="scan"
                value={
                    <Link to={`/${currentTenantKey}/api-testing/applications/${appId}/scans/${scan.id}`}>
                        {`Scan ${scan.id}`}
                    </Link>
                }
            />
            <ValueWithLabel label="scan date" value={fullDateStringFormat(scan.ended_at, false)} />
            <ValueWithLabel
                label="auth config"
                value={
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            setIsAuthConfigModalOpen(true);
                        }}
                    >
                        {scan.auth_config_name}
                    </a>
                }
            />
            <ValueWithLabel label="triggered by" value={scan.triggered_by} />
            {isAuthConfigModalOpen && (
                <AuthConfigModal
                    scan={scan}
                    onClose={() => setIsAuthConfigModalOpen(false)}
                    getCopyToClipboardIcon={getCopyToClipboardIcon}
                />
            )}
        </div>
    );
};

import { Select } from 'antd';

import { useEntity } from 'services/data/hooks';
import { EntityName } from 'services/data/entityMap';
import { AlertSeverityEnum } from 'features/alerts/Alerts';

import './AlertSeveirtySelect.scss';

interface IAlertSeverityMultiSelectProps {
    label?: string;
    value: string;
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertSeveritySelect = (props: IAlertSeverityMultiSelectProps) => {
    const [alertSeverityList] = useEntity<{ items: { severity: AlertSeverityEnum }[] }>(EntityName.AlertSeverity);

    return alertSeverityList?.items ? (
        <div className="alert-severity-select">
            {props.label && <div className="ass-label">{props.label || 'Alert Severity'}</div>}
            <Select
                style={{ minWidth: props.width }}
                options={alertSeverityList.items.map(({ severity }) => ({ label: severity, value: severity }))}
                value={props.value}
                onChange={(event: any) => props.onChange(event)}
            />
        </div>
    ) : null;
};

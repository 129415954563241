import { ReactPortal, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import { ApitEnum, selectApitSlice, updateAppsOrAuthConfigsTabId } from 'api/slices/apitSlice';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { UiTabsNav } from 'sharedComponents/UiTabsNav/UiTabsNav';
import { API_TESTING_PATH, IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { ApplicationList } from 'features/apiTesting/applications/ApplicationList/ApplicationList';
import { AuthConfigList } from './authConfigs/authConfigList/AuthConfigList';

import './AppsOrAuthConfigs.scss';

const API_TESTING_APPS_OR_AUTH_CTA_BUTTON_PORTAL_ID = 'api-testing-apps-or-auth-cta-button-portal';
export const renderAppsOrAuthCtaButton = (buttonElm: JSX.Element): ReactPortal =>
    createPortal(
        buttonElm,
        document.getElementById(API_TESTING_APPS_OR_AUTH_CTA_BUTTON_PORTAL_ID) || document.createElement('div')
    );

export const AppsOrAuthConfigs = () => {
    const params = useParams<IApitRouteParams>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { appsOrAuthConfigsTabId } = useSelector(selectApitSlice);
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    useEffect(() => {
        if (params.appsOrAuthConfigsTabId && params.appsOrAuthConfigsTabId.length > 0) {
            dispatch(
                updateAppsOrAuthConfigsTabId({ appsOrAuthConfigsTabId: params.appsOrAuthConfigsTabId as ApitEnum })
            );
        }
    }, [params.appsOrAuthConfigsTabId]);

    const handleTabSelected = useCallback(
        (key: string | number) => {
            dispatch(updateAppsOrAuthConfigsTabId({ appsOrAuthConfigsTabId: key as ApitEnum }));
            history.push(`/${currentTenantKey}/${API_TESTING_PATH}/${key}`);
        },
        [dispatch, history, currentTenantKey]
    );

    return (
        <div className="AppsOrAuthConfigs">
            <div className="apit-main-page-header">
                <span className="apit-main-page-header-title">API Testing</span>
                <div id={API_TESTING_APPS_OR_AUTH_CTA_BUTTON_PORTAL_ID}></div>
            </div>
            <div className="apit-main-page-content">
                <UiTabsNav
                    className="tabs-nav"
                    items={[
                        { key: ApitEnum.APPLICATIONS, label: 'Applications' },
                        { key: ApitEnum.AUTH_CONFIGURATIONS, label: 'Auth Configurations' },
                    ]}
                    onTabSelected={handleTabSelected}
                    activeKey={appsOrAuthConfigsTabId}
                />
                {appsOrAuthConfigsTabId === ApitEnum.APPLICATIONS && <ApplicationList />}
                {appsOrAuthConfigsTabId === ApitEnum.AUTH_CONFIGURATIONS && <AuthConfigList />}
            </div>
        </div>
    );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { removeTrailingSlashes } from 'general/utils';
import { IDataSource } from '../shared/dataSourceApis';
import { Card } from 'sharedComponents/Card/Card';
import { gridCellGenerator } from 'sharedComponents/Card/gridCellGenerator';

import './DataSourceCard.scss';

export interface IConfigCardProps {
    menu: React.ReactNode;
    ds: IDataSource;
    handleClick?: () => void;
}

export const DataSourceCard = ({ menu, ds: { id, name, numberOfCollectors } }: IConfigCardProps) => {
    const history = useHistory();

    return (
        <div
            className="between-card"
            onClick={() => history.push(`${removeTrailingSlashes(history.location.pathname)}/${id}`)}
        >
            <Card title={name} menu={menu}>
                <div className="data-source-grid">
                    {gridCellGenerator('Neosec Nodes', numberOfCollectors)}
                    {/*{gridCellGenerator('Sensors',numberOfSensors )}*/}
                </div>
            </Card>
        </div>
    );
};

import { MouseEventHandler } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Text,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { RenderCustomizedLegend } from 'sharedComponents/UiBarChart/RenderCustomizedLegend';
import { defaultGraphColors } from 'styles/consts';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { formatNumberHumanReadable } from 'general/utils';

import '../UiChartLegend/UiChartLegend.scss';
import '../../styles/_fonts.scss';

export interface IUiBarChartProps {
    graphKeys: string[];
    graphData: any[];
    isStacked: boolean;
    customYAxis?: JSX.Element;
    customBars?: (key: string, index: number) => JSX.Element[] | undefined;
    customTooltip?: (props: TooltipProps<ValueType, NameType>) => JSX.Element | null;
    legend?: (payload: any) => JSX.Element;
    colorsMap?: Record<string, string>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const generateColorsForKeys = (keys: string[]) => {
    const setKeys = [...new Set(keys)];
    return setKeys.reduce((acc, key, i) => {
        acc[key] = defaultGraphColors[i] || defaultGraphColors[Math.floor(Math.random() * defaultGraphColors.length)];
        return acc;
    }, {} as Record<string, string>);
};

const CustomizedAxisTick = ({ x, y, payload }: any) => (
    <UiTooltip title={payload.value} disableCopyButton>
        <Text
            x={x}
            y={y}
            width={70}
            textAnchor="middle"
            verticalAnchor="start"
            fontSize={10}
            breakAll
            maxLines={2}
            style={{ fontWeight: 400, textTransform: 'lowercase' }}
        >
            {payload.value}
        </Text>
    </UiTooltip>
);

export const UiBarChart = ({
    graphKeys,
    graphData,
    customYAxis,
    legend,
    colorsMap,
    onClick,
    customBars,
    customTooltip,
    isStacked,
    ...restBarChartProps
}: IUiBarChartProps & CategoricalChartProps) => {
    const colorKeys = colorsMap || generateColorsForKeys(graphKeys);
    const CustomTooltip = customTooltip;

    return (
        <ResponsiveContainer>
            <BarChart data={graphData} stackOffset="sign" {...restBarChartProps} barGap={isStacked ? 5 : 40}>
                <CartesianGrid vertical={false} stroke={`var(--tabs-box-shadow)`} />
                <Tooltip cursor={{ fill: 'none' }} content={CustomTooltip && <CustomTooltip />} />
                <ReferenceLine y={0} />
                <Legend iconType="circle" iconSize={5} content={legend || <RenderCustomizedLegend />} />
                {customYAxis || <YAxis tickFormatter={formatNumberHumanReadable} />}
                {graphKeys.map((key, index) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        barSize={-35}
                        stackId={isStacked ? 'stack' : undefined}
                        fill={colorKeys[key]}
                        radius={2}
                        stroke="white"
                        strokeWidth={1}
                        onClick={onClick}
                        cursor={onClick && 'pointer'}
                        children={customBars?.(key, index)}
                    />
                ))}
                <XAxis dataKey="name" interval={0} height={50} tick={<CustomizedAxisTick />} />
            </BarChart>
        </ResponsiveContainer>
    );
};

import xs, {Listener, Producer, Stream, Subscription} from "xstream";

export type DialogConfig = Config | null;

export type DialogServiceSubscription = Subscription;

interface Config{
    title: string;
    text: string;
    icon: string;
    resolveTitle?:string;
    rejectTitle?:string;
    resolve?: Function;
    reject?: Function;
    height?: number;
}

class DialogService {
    private listener: Listener<DialogConfig> = {} as Listener<DialogConfig>;
    private stream: Stream<DialogConfig>;

    constructor() {
        const producer: Producer<DialogConfig> = {
            start: (listener) => {
                console.log('Starting Dialog service producer');
                this.listener = listener;
            },
            stop: () => console.log('Stopping Dialog service producer')
        };
        this.stream = xs.create(producer);
    }

    subscribe(next: (dialogConfig: DialogConfig) => any, error: (error: any) => any): DialogServiceSubscription {
        return this.stream.subscribe({ next, error });
    }

    open(dialogConfig: DialogConfig): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.listener.next({
                ...dialogConfig,
                resolve,
                reject
            } as DialogConfig);
        });
    }

    close() {
        this.listener.next(null);
    }

    stop() {
        this.listener.complete();
    }
}

const dialogService = new DialogService();

export const subscribe = dialogService.subscribe.bind(dialogService);
export const openDialog = dialogService.open.bind(dialogService);
export const closeDialog = dialogService.close.bind(dialogService);

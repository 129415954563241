import React from 'react';

import {
    bulletSpaceMarkup,
    convertTimestampToTimeAgo,
    fullDateStringFormat,
    getDiffFromNowInDays,
} from 'general/utils';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { maxDiffDaysTimeline } from 'features/user/user-summary/EntitySummary';

import 'features/user/business-impact/generic-details/GenericDetails.scss';

interface IGenericDetails {
    dataObject: any;
    dataKeys: { key: string; header: string }[];
}

export const GenericDetails = ({ dataObject, dataKeys }: IGenericDetails) => {
    const getValue = (item: any) => {
        if (item.type === 'readableTime') {
            return (
                <UiTooltip
                    title={
                        getDiffFromNowInDays(dataObject[item.key]) > maxDiffDaysTimeline
                            ? ''
                            : fullDateStringFormat(dataObject[item.key])
                    }
                >
                    {convertTimestampToTimeAgo(dataObject[item.key], maxDiffDaysTimeline)}
                </UiTooltip>
            );
        } else return dataObject[item.key];
    };

    const getDefaultValues = () => {
        return dataKeys.map((item, index) => {
            const isDescription = item.key === 'description';
            return (
                <div
                    className={`item item-${index} ${isDescription ? 'description pre-wrap' : ''}`}
                    key={`${item.key}-${index}`}
                >
                    <UiTitle title={item.header} type={'medium'} />
                    <div className="text-container">
                        {isDescription ? bulletSpaceMarkup(dataObject[item.key], true) : getValue(item)}
                    </div>
                </div>
            );
        });
    };

    return <div className="GenericDetails">{getDefaultValues()}</div>;
};

import { useEntity } from 'services/data/hooks';
import { EntityName } from 'services/data/entityMap';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';
import { sortBySeverity } from 'general/utils';
import { AlertSeverityEnum } from 'features/alerts/Alerts';

import './AlertSeverityMultiSelect.scss';

interface IAlertSeverityMultiSelectProps {
    label?: string;
    value: string[];
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertSeverityMultiSelect = (props: IAlertSeverityMultiSelectProps) => {
    const [alertSeverityList] = useEntity<{ items: { severity: AlertSeverityEnum }[] }>(EntityName.AlertSeverity);
    return alertSeverityList?.items ? (
        <div className="alert-severity-multi-select">
            <div className="asms-label">{props.label || 'Alert Severity'}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                value={props.value}
                options={alertSeverityList.items
                    .sort(sortBySeverity('severity'))
                    .map(({ severity }) => ({ label: severity, value: severity }))}
                onChange={(event: any) => props.onChange(event)}
            />
        </div>
    ) : null;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import type { MenuProps } from 'antd/es/menu';

import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { selectDashboardsSlice } from 'api/slices/dashboardsSlice';

import 'sharedComponents/NavBar/NavigationMenu/NavigationMenu.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    handleClick: any;
    current: string;
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

export const NavigationMenu = ({ handleClick, current }: IProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { activeDashboardId } = useSelector(selectDashboardsSlice);
    const featureFlagMap = useSelector(selectFeatureFlagMap);

    const items = [
        getItem(
            <NavLink to={`/${currentTenantKey}/dashboards/${activeDashboardId}`}>Dashboards</NavLink>,
            'Dashboards'
        ),
        getItem(<NavLink to={'/' + currentTenantKey + '/alerts/'}>Alerts</NavLink>, 'Alerts'),
        getItem(<NavLink to={'/' + currentTenantKey + '/query/'}>Query</NavLink>, 'Query'),
        getItem(<NavLink to={'/' + currentTenantKey + '/discovery/services/'}>Discovery</NavLink>, 'Discovery'),
        // TODO: Remove when api-testing is open for all
        !!featureFlagMap?.apiTesting
            ? getItem(<NavLink to={'/' + currentTenantKey + '/api-testing/'}>Testing</NavLink>, 'api-testing')
            : null,
    ];

    return (
        <div className="navigation-menu">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={items} />
        </div>
    );
};

import React, { ReactNode } from 'react';

import { UiIcon } from 'sharedComponents/icon/UiIcon';

import './ValueWithLabel.scss';

export const valueEmptyState = <div>-</div>;

export const ValueWithIcon = ({ value, iconName }: any) => {
    if (!value || value === 0) return <>-</>;
    return (
        <div style={{ display: 'flex' }}>
            <UiIcon style={{ marginRight: '10px' }} name={iconName} />
            <span>{value || '-'}</span>
        </div>
    );
};

export const ValueWithLabel = ({ label, value }: { label: string; value?: ReactNode }) => {
    return (
        <div className="ValueWithLabel">
            <div className="label">{label}</div>
            {value || valueEmptyState}
        </div>
    );
};

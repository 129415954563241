import { UiModal } from 'sharedComponents/UiModal/UiModal';

export interface IChangeTemplateConfirmModalProps {
    onClose: () => void;
    onReject: () => void;
    changeTemplate: () => void;
}

export const ChangeTemplateConfirmModal = ({ onClose, onReject, changeTemplate }: IChangeTemplateConfirmModalProps) => {
    return (
        <UiModal
            onCancel={onClose}
            acceptButton={{ text: 'Ok', onClick: changeTemplate }}
            rejectButton={{
                text: 'Cancel',
                onClick: () => {
                    onReject();
                    onClose();
                },
            }}
            title="Change type?"
            icon="restoreBig"
        >
            Are you sure you want to change your template? Any changes made will be lost.
        </UiModal>
    );
};

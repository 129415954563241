import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { fullDateStringFormat } from 'general/utils';
import { IScan } from 'api/apiTestingApi';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';

interface IFindingDetailsScanLinkProps {
    scan: IScan;
}

export const FindingDetailsScanLink = ({ scan }: IFindingDetailsScanLinkProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId } = useParams<IApitRouteParams>();

    return (
        <div className="FindingDetailsScanLink">
            <span>{fullDateStringFormat(scan.ended_at, false)} </span>
            <Link to={`/${currentTenantKey}/api-testing/applications/${appId}/scans/${scan.id}`}>
                {`(Scan ${scan.id})`}
            </Link>
        </div>
    );
};

import { useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { GridApi } from '@ag-grid-community/core';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { useDownloadSwagger } from 'hooks/useDownloadSwagger';
import {
    ExclusionRuleOrDeleteEndpointsWizard,
    WizardModeOptions,
} from 'sharedComponents/exclusionRuleOrDeleteEndpointsWizard/ExclusionRuleOrDeleteEndpointsWizard';

import './ActionsDropdown.scss';
import { useSelector } from 'react-redux';
import { selectFeatureFlagMap } from 'api/slices/appInfoSlice';

interface IActionsDropdownProps {
    pathParams: {
        activeOrgParam: string;
        encodedServiceName: string;
        timeRangeFromInput: TDatetimeRange;
    };
    timeRangeFromInput: TDatetimeRange;
    gridApi?: GridApi;
}

export const ActionsDropdown = ({ pathParams, timeRangeFromInput, gridApi }: IActionsDropdownProps) => {
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const activeTenant = pathParams.activeOrgParam;
    const serviceName = decodeURIComponent(pathParams.encodedServiceName || '');
    const downloadSwagger = useDownloadSwagger({
        serviceNames: [serviceName],
        tenant: activeTenant,
        fromTimestamp: timeRangeFromInput[0].unix(),
        toTimestamp: timeRangeFromInput[1].unix(),
    });
    const [isDeleteEndpointModalVisible, setIsDeleteEndpointModalVisible] = useState<boolean>();

    const downloadSwaggerAction = {
        key: 'downloadSwagger',
        label: (
            <div className="services-action-item" onClick={() => downloadSwagger()}>
                <div className="item-icon">
                    <UiIcon size={30} name="actionMenuDownload" />
                </div>
                <span className="item-text">Download OAS File</span>
            </div>
        ),
    };

    const exportExcelAction = {
        key: 'exportExcel',
        label: (
            <div
                className="services-action-item"
                onClick={() => {
                    gridApi?.exportDataAsExcel();
                }}
            >
                <div className="item-icon">
                    <UiIcon size={30} name="actionMenuDownload" />
                </div>
                <span className="item-text">Export to Excel</span>
            </div>
        ),
    };
    const items: MenuProps['items'] = [
        ...(featureflagMap.excelExport ? [exportExcelAction] : []),
        ...(serviceName ? [downloadSwaggerAction] : []),
        {
            key: 'deleteEndpoints',
            label: (
                <div className="services-action-item" onClick={() => setIsDeleteEndpointModalVisible(true)}>
                    <div className="item-icon">
                        <UiIcon size={30} name="actionMenuDelete" />
                    </div>
                    <span className="item-text">Delete Endpoints (Regex)</span>
                </div>
            ),
        },
    ];

    return (
        <div className="ActionsDropdown">
            <Dropdown
                overlayClassName="actions-dropdown-overlay"
                trigger={['click']}
                placement="bottomRight"
                menu={{ items }}
            >
                <div className="dropdown-container">
                    <span>Actions</span>
                    <UiIcon name="downArrowWhite" />
                </div>
            </Dropdown>
            {isDeleteEndpointModalVisible && (
                <ExclusionRuleOrDeleteEndpointsWizard
                    mode={serviceName ? WizardModeOptions.DeleteEpService : WizardModeOptions.DeleteEpServices}
                    title="Delete Endpoints"
                    onCancel={() => setIsDeleteEndpointModalVisible(false)}
                    onFinish={() => {
                        gridApi?.refreshServerSide();
                        setIsDeleteEndpointModalVisible(false);
                    }}
                />
            )}
        </div>
    );
};

import { Divider, Dropdown, MenuProps } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

import { EventTypesEnum } from 'enums/eventTypes.enum';
import { dateStringFormat, isObjectNotEmpty, patchAlert, TimeDisplayResEnum, timeStringFormat } from 'general/utils';
import { IAlert } from 'interfaces/alert.interface';
import { IApiCall } from 'interfaces/apiCall.interface';
import { AddExceptionModal } from './add-exception/AddExceptionModal';
import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { LabelList } from 'sharedComponents/LabelList/LabelList';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { IEndpointLabel, IServiceLabel } from 'interfaces/labels.interface';
import { CommentAddPopover } from 'sharedComponents/CommentAddPopover/CommentAddPopover';
import { CommentCount } from 'sharedComponents/CommentCount/CommentCount';
import { AlertStatusEnum } from 'features/alerts/Alerts';
import { ITenant } from 'api/tenantListApi';
import { useSelector } from 'react-redux';
import { selectCurrentTenantDetails } from 'api/slices/appInfoSlice';

import './SummaryDetails.scss';

interface ISummaryProps {
    eventData: IAlert | IApiCall;
    eventType: EventTypesEnum;
    labels?: IServiceLabel[] | IEndpointLabel[];
    setExpanded: (value: boolean) => void;
    expanded: boolean;
    commentsCount: number;
}

export const SummaryDetails = ({ eventData, eventType, setExpanded, expanded, commentsCount }: ISummaryProps) => {
    const currentTenant = useSelector(selectCurrentTenantDetails);
    const [showModal, setShowModal] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const [alertStatus, setAlertStatus] = useState<AlertStatusEnum>(eventData.status as AlertStatusEnum);
    const toggleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);
    const isAlert = eventType === EventTypesEnum.Alert;
    const labels: any = eventData?.endpoint_labels || [];
    const { method, endpoint_path } = eventData as IApiCall;
    const title = method ? <ApiLabel apiType={[method]} place="top" apiName={endpoint_path} /> : eventData?.name;

    const alertIcon = () => {
        if (eventData && !isObjectNotEmpty(eventData) && 'severity' in eventData) {
            const alertSeverity = (eventData as IAlert).severity;

            return (
                <UiTooltip title={alertSeverity}>
                    <span className={'severity-icon'}>
                        <UiIcon name={`alert${alertSeverity}`} />
                    </span>
                </UiTooltip>
            );
        }
    };

    const changeAlertStatusHandler = () => {
        setIsReloading(true);
        const newAlertState = alertStatus === AlertStatusEnum.Open ? AlertStatusEnum.Closed : AlertStatusEnum.Open;
        patchAlert('status', currentTenant as ITenant, eventData.id, newAlertState)
            .then((res) => {
                setAlertStatus(newAlertState);
                setIsReloading(false);
                popoverRef?.current?.click();
                return res;
            })
            .catch((err) => console.error(err));
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            disabled: (eventData as IAlert).comments_count >= 200,
            label: (
                <div
                    className="summary-details-action-item"
                    onClick={() => {
                        popoverRef?.current?.click();
                    }}
                >
                    <div className="icon-container">
                        <UiIcon size={30} name="addComment" />
                    </div>
                    <span className="item-text">Add comment</span>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    onClick={changeAlertStatusHandler}
                    className={`summary-details-action-item close-alert-item ${isReloading ? 'disabled' : ''}`}
                >
                    <div className="icon-container">
                        <UiIcon size={14} name={alertStatus === 'Open' ? 'closeItem' : 'reOpenItem'} />
                    </div>
                    <span className="item-text">{alertStatus === 'Open' ? 'Close Alert' : 'Open Alert'}</span>
                </div>
            ),
        },
    ];

    return (
        <div className="summary-container">
            <div className="left-side">
                <div className="title-header">
                    <div className="title">
                        <UiTitle title={title} type="large" />
                        {alertIcon()}
                    </div>
                    {isObjectNotEmpty(eventData) && (
                        <div className="date-wrapper">
                            <UiTitle title={dateStringFormat(eventData.timestamp)} type="medium" />
                            <Divider className="date-divider" type="vertical" />
                            <UiTitle
                                title={timeStringFormat(eventData.timestamp, TimeDisplayResEnum.MS)}
                                type="medium"
                            />
                            {labels.length > 0 && <Divider className="date-divider" type="vertical" />}
                            <LabelList labels={labels || []} />
                        </div>
                    )}
                </div>
            </div>

            <div className="right-side">
                {isAlert && (
                    <div className="status-ctrl">
                        <UiTitle type="small" title="STATUS:" />
                        <div>
                            <UiIcon name={alertStatus === 'Open' ? 'Active' : 'Resolved'} />
                            <span className="status-label">{alertStatus}</span>
                        </div>
                        <div
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className="comment-count"
                        >
                            <CommentCount value={commentsCount} />
                        </div>
                        <div className="status-btn-container">
                            <Dropdown
                                className="alert-dropdown"
                                overlayClassName="UiDropdownOverlay"
                                trigger={['click']}
                                placement="bottomRight"
                                menu={{ items }}
                            >
                                <div className="dropdown-container">
                                    <span>Actions</span>
                                    <UiIcon name="downArrowWhite" />
                                </div>
                            </Dropdown>
                            <CommentAddPopover alert={eventData as IAlert}>
                                <div ref={popoverRef}></div>
                            </CommentAddPopover>
                        </div>
                    </div>
                )}
            </div>
            <AddExceptionModal isVisible={showModal} toggleModal={toggleModal} />
        </div>
    );
};

import { AxiosResponse } from 'axios';

import { IServerQueryResponse } from 'api/baseServerApi';
import { ENDPOINTS_RESULT_LIMIT } from 'features/query/Query';
import { httpDelete, httpGet, httpPatch, httpPOST, PAGE_SIZE_LIMIT, recursiveHttpGet } from 'general/http-service';
import { recursiveHttpPost } from 'general/http-service-utils';
import { IConfigRes } from 'general/organization-service';
import { errorMessage } from 'general/toast-service';
import { getTimeRangeQueryParams, shiftDatetimeRangeToUTC, urlEncode } from 'general/utils';
import { IDiscoveryService } from 'interfaces/service.interface';
import { IFilter } from 'interfaces/filter.interface';
import { IAlert } from 'interfaces/alert.interface';
import { IDiscoveryEndpointResponse, IEndpointPathParameter } from 'interfaces/endpoint.interface';
import { LabelSuppressPeriod } from 'interfaces/labels.interface';
import { IEndpointFilterOptions, IEndpointOptionalFields } from './discoveryInterfaces';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { IAttribute } from 'interfaces/query.interface';

export const QUERY_RESULTS_LIMIT = 100;

export function getTotalRequests(
    timeRange: TDatetimeRange,
    activeOrg: string,
    base64EncodedServiceName: string,
    endpointId?: string
) {
    const queryParams = getTimeRangeQueryParams(timeRange);
    let baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/total_requests?${queryParams}`;
    if (endpointId) {
        baseUrl += '&endpoint_id=' + endpointId;
    }
    return httpGet(baseUrl);
}

export function getSeriesRequests(
    timeRange: TDatetimeRange,
    activeOrg: string,
    base64EncodedServiceName: string,
    endpointId?: string
) {
    const queryParams = getTimeRangeQueryParams(shiftDatetimeRangeToUTC(timeRange));
    let baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/requests_over_time?${queryParams}`;
    if (endpointId) {
        baseUrl += '&endpoint_id=' + endpointId;
    }
    return httpGet(baseUrl);
}

export function getSortQueryString(sortParams?: string): string {
    return sortParams ? sortParams.split('=')[1] : 'desc(name)';
}

export function getSortEndpointQueryString(sortParams?: string): string {
    return sortParams ? sortParams.split('=')[1] : 'desc(endpoint_path)';
}

export function getTableDataServices(
    activeOrg: string,
    limit: number,
    filters: IFilter[],
    timeRangeFromInput: TDatetimeRange
) {
    const baseUrl = `organizations/${activeOrg}/discovery/services/query`;
    return function (start: number, end: number, sortParams: string | undefined) {
        const queryObj = {
            from_timestamp: timeRangeFromInput[0].format('X'),
            to_timestamp: timeRangeFromInput[1].format('X'),
            offset: start,
            limit: limit,
            sort_by: getSortQueryString(sortParams),
            filters: filters,
        };
        return httpPOST(baseUrl, queryObj).catch(() => errorMessage('Error fetching Data')) as Promise<
            AxiosResponse<IServerQueryResponse<IDiscoveryService>>
        >;
    };
}

export function queryServices(
    timeRange: TDatetimeRange,
    activeOrg: string,
    serviceName: string
): Promise<IDiscoveryService[]> {
    const baseUrl = `organizations/${activeOrg}/discovery/services/query`;
    const queryObj = {
        from_timestamp: timeRange[0].format('X'),
        to_timestamp: timeRange[1].format('X'),
        offset: 0,
        limit: 1,
        sort_by: 'asc(name)',
        filters: [[{ name: 'name', operator: 'eq', value: serviceName }]],
    };

    return httpPOST(baseUrl, queryObj).then(
        (res: AxiosResponse<IServerQueryResponse<IDiscoveryService>>) => res.data.items
    );
}

export function getTableData(
    activeOrg: string,
    limit: number,
    filters: IFilter[] | IFilter[][],
    timeRangeFromInput: TDatetimeRange,
    base64EncodedServiceName: string
) {
    const baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/endpoints/query`;

    return function (start: number, end: number, sortParams?: string) {
        const queryObj = {
            from_timestamp: timeRangeFromInput[0].format('X'),
            to_timestamp: timeRangeFromInput[1].format('X'),
            offset: start,
            limit,
            sort_by: getSortEndpointQueryString(sortParams),
            filters,
        };
        return httpPOST(baseUrl, queryObj).catch(() => errorMessage('Error fetching Data')) as Promise<
            AxiosResponse<IServerQueryResponse<IDiscoveryEndpointResponse>>
        >;
    };
}

export function getConfig(activeOrg: string): Promise<IConfigRes> {
    return httpGet(`organizations/${activeOrg}/config`).then((res) => res.data);
}

export function queryEndpoints(
    timeRange: TDatetimeRange,
    activeOrg: string,
    base64EncodedServiceName: string,
    endpointId: string
): Promise<IDiscoveryEndpointResponse[]> {
    const baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/endpoints/query`;
    const queryObj = {
        from_timestamp: timeRange[0].format('X'),
        to_timestamp: timeRange[1].format('X'),
        offset: 0,
        limit: 1,
        sort_by: 'desc(endpoint_path)',
        filters: [[{ name: 'id', operator: 'eq', value: endpointId }]],
    };
    return httpPOST(baseUrl, queryObj).then(
        (res: AxiosResponse<IServerQueryResponse<IDiscoveryEndpointResponse>>) => res.data.items
    );
}

export interface IHideShowEndpoint {
    id: string;
    hide: boolean;
}

export const hideShowEndpointApi = (
    activeOrg: string,
    base64EncodedServiceName: string,
    items: IHideShowEndpoint[]
): Promise<AxiosResponse> => {
    const baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/endpoints`;
    return httpPatch(baseUrl, { items });
};

export const getFilterValuesSet = (activeOrg: string, base64EncodedServiceName: string): Promise<AxiosResponse> => {
    const baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/values_set`;
    return httpGet(baseUrl);
};

export function fetchEndpointAttributes(
    org: string,
    serviceName: string,
    endpointId: string,
    from_timestamp: string,
    to_timestamp: string
): Promise<{ items: IAttribute[] }> {
    const promise = new Promise((resolve, reject) => {
        recursiveHttpGet(
            `organizations/${org}/discovery/services/${urlEncode(
                serviceName
            )}/endpoints/${endpointId}/attributes?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`,
            0,
            PAGE_SIZE_LIMIT,
            [],
            resolve,
            reject,
            5000
        );
    });
    return promise.then((items) => ({ items: items as IAttribute[] }));
}

export function fetchEndpointAlerts(
    org: string,
    serviceName: string,
    endpointId: string,
    from_timestamp: string,
    to_timestamp: string,
    isTimeFiltered?: boolean
): Promise<{ items: IAlert[] }> {
    const promise = new Promise((resolve, reject) => {
        recursiveHttpGet(
            `organizations/${org}/discovery/services/${urlEncode(
                serviceName
            )}/endpoints/${endpointId}/alerts?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}${
                isTimeFiltered ? '&new_discovery=True' : ''
            }`,
            0,
            PAGE_SIZE_LIMIT,
            [],
            resolve,
            reject
        );
    });
    return promise.then((items) => ({ items: items as IAlert[] }));
}

export const setAttributes = (
    activeOrg: string,
    parameters: IEndpointPathParameter,
    attributeIdList: string[],
    newParameterName: string
): Promise<AxiosResponse> => {
    return httpPatch(`organizations/${activeOrg}/discovery/services/${parameters.serviceName}/attributes`, {
        items: attributeIdList.map((attributeId: string) => {
            return {
                attribute_id: attributeId,
                attribute_name: newParameterName,
            };
        }),
    });
};

export function queryAllEndpoints<T>(
    activeOrg: string,
    limit: number,
    filters: IFilter[],
    timeRangeFromInput: TDatetimeRange,
    base64EncodedServiceName: string,
    queryParams: string = '',
    onSuccess: (params: T[]) => void,
    sortParams?: string
) {
    const baseUrl = `organizations/${activeOrg}/discovery/services/${base64EncodedServiceName}/endpoints/query${queryParams}`;

    const queryObj = {
        from_timestamp: timeRangeFromInput[0].format('X'),
        to_timestamp: timeRangeFromInput[1].format('X'),
        offset: 0,
        limit: limit,
        sort_by: getSortEndpointQueryString(sortParams),
        filters: filters,
    };

    return recursiveHttpPost(baseUrl, queryObj, [], onSuccess, () => errorMessage('Error fetching Data'));
}

export const getTenantEndpoints = (
    activeOrganization: string,
    fromTimestamp: string,
    toTimestamp: string,
    limit: string = ENDPOINTS_RESULT_LIMIT.toString(),
    offset: string = '0',
    sortBy: string = 'desc(endpoint_path)',
    filter?: IEndpointFilterOptions,
    additionalFields?: Array<IEndpointOptionalFields>
) => {
    const additionalFieldsStr = additionalFields?.length
        ? `?additional_fields=${additionalFields.join('&additional_fields=')}`
        : '';

    const endpointUrl = `organizations/${activeOrganization}/discovery/endpoints${additionalFieldsStr}`;

    const params: { [key: string]: string } = {
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
        limit,
        offset,
        sort_by: sortBy,
    };

    if (filter) {
        Object.assign(params, filter);
    }

    return httpGet(endpointUrl, params);
};

export const createEndpointLabel = (
    tenant: string,
    serviceName: string,
    endpointId: string,
    label: string
): Promise<any> => {
    return httpPOST(
        `organizations/${tenant}/discovery/services/${urlEncode(serviceName)}/endpoints/${endpointId}/labels`,
        {
            label,
        }
    );
};

export const deleteEndpointLabel = (
    tenant: string,
    serviceName: string,
    endpointId: string,
    labelId: string
): Promise<any> => {
    return httpDelete(
        `organizations/${tenant}/discovery/services/${urlEncode(
            serviceName
        )}/endpoints/${endpointId}/labels/${urlEncode(labelId)}`
    );
};

export const suppressEndpointLabel = (
    tenant: string,
    serviceName: string,
    endpointId: string,
    labelId: string,
    period: LabelSuppressPeriod
) => {
    return httpPatch(
        `organizations/${tenant}/discovery/services/${urlEncode(
            serviceName
        )}/endpoints/${endpointId}/labels/${urlEncode(labelId)}`,
        { suppress_by: period }
    );
};

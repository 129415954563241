import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { useDetok } from 'hooks/useDetok';
import { selectFeatureFlagMap } from 'api/slices/appInfoSlice';

import './Detokenizable.scss';

interface IDetokenizableProps {
    tokenizedContent: string;
    renderTooltip: (content: string) => JSX.Element | string;
    renderContent: (content: string) => JSX.Element | string;
    className?: string;
}

export const Detokenizable = ({ className, tokenizedContent, renderTooltip, renderContent }: IDetokenizableProps) => {
    const featureFlagMap = useSelector(selectFeatureFlagMap);
    const [isDetokenized, content, toggleDetok] = useDetok(tokenizedContent);

    const getFooter = () => {
        if (!featureFlagMap?.detokenization) return;

        return (
            <Button type="text" icon={<UiIcon name="detokenize" />} onClick={toggleDetok}>
                {isDetokenized ? 'Re-tokenize' : 'De-tokenize'}
            </Button>
        );
    };

    return (
        <div className={`Detokenizable ${isDetokenized ? 'detokenized' : ''} ${className || ''}`}>
            <UiTooltip title={renderTooltip(content)} footer={getFooter()} placement="topLeft">
                {renderContent(content)}
            </UiTooltip>
        </div>
    );
};

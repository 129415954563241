import { Divider, Space } from 'antd';

import { useGetTopAlertingUsersQuery } from 'api/overviewDashboardApi';
import { fullDateStringFormat } from 'general/utils';
import { IEntityInfo, IUser } from 'interfaces/user.interface';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { getLocationLine } from 'features/user/user-summary/EntitySummary';

import 'features/dashboards/OverviewDashboard/TopAlertingUsers/TopAlertingUsers.scss';

interface IAlertingUsersData {
    userName: JSX.Element;
    alerts: JSX.Element;
    activeSince: string;
    location?: string | JSX.Element;
    entityInfo?: IEntityInfo;
}

const USERS_HEADERS = [
    {
        title: 'user',
        icon: '',
    },
    {
        title: 'alerts',
        icon: 'bell16',
    },
    {
        title: 'location',
        icon: 'location16',
    },
    {
        title: 'active since',
        icon: 'calendar16',
    },
] as const;

export const TopAlertingUsers = () => {
    const { data, isLoading } = useGetTopAlertingUsersQuery();

    function enrichWithLocation(element: IAlertingUsersData): IAlertingUsersData {
        if (element.entityInfo) {
            const locationLine = getLocationLine(element.entityInfo);
            return {
                ...element,
                location: (
                    <UiTooltip title={locationLine}>
                        <div>{locationLine}</div>
                    </UiTooltip>
                ),
            };
        }
        return element;
    }

    function prepareUsersDisplayData(userData: IUser[]): IAlertingUsersData[] {
        return userData
            .map((userInfo): IAlertingUsersData => {
                const alerts = (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {userInfo.open_alerts_max_severity && (
                            <UiIcon name={`alert${userInfo.open_alerts_max_severity}`} />
                        )}
                        <span style={{ marginLeft: '6px' }}>{userInfo.open_alerts}</span>
                    </div>
                );

                return {
                    userName: (
                        <UiTooltip title={userInfo.id}>
                            <div className="user-name-text no-wrap">{userInfo.id}</div>
                        </UiTooltip>
                    ),
                    alerts,
                    activeSince: fullDateStringFormat(userInfo.first_activity_time, false),
                    entityInfo: userInfo.entity_info,
                };
            })
            .map(enrichWithLocation);
    }

    function getSkeleton(): JSX.Element {
        return (
            <div className="skeleton">
                <Space
                    direction="vertical"
                    split={<Divider type="horizontal" style={{ margin: '0' }} />}
                    className="skeleton-rows"
                >
                    <div className="skeleton-table-header skeleton-table-row">
                        <div />
                        {[1, 2, 3, 4].map((i) => (
                            <div key={'header-col-' + i}>
                                <div className="skeleton-item" />
                                <div className="skeleton-item" />
                            </div>
                        ))}
                    </div>
                    {[1, 2, 3, 4].map((i) => (
                        <div className="skeleton-table-row" key={'row-' + i}>
                            {[1, 2, 3, 4, 5].map((j) => (
                                <div className="skeleton-item" key={'row-' + i + '-col-' + j} />
                            ))}
                        </div>
                    ))}
                </Space>
            </div>
        );
    }

    const headerDisplay = USERS_HEADERS.map((item: { icon: string; title: string }) => {
        const headerClassName = item.title.replace(' ', '-').replace('.', '');
        return (
            <div className={`header-col header-col-${headerClassName}`} key={item.title}>
                <div className="header-col-inner">
                    <UiIcon name={item.icon} />
                    <div className="title">{item.title}</div>
                </div>
            </div>
        );
    });

    return (
        <div className="TopAlertingUsers">
            <div className="widget-header">
                <UiTitle title="Top Alerting Users" type="x-large" />
            </div>
            <div className="widget-content">
                {data?.items.length ? (
                    <>
                        <div className="users-table-header">{headerDisplay}</div>
                        <div className="users-table-body">
                            {prepareUsersDisplayData(data.items)?.map((item: IAlertingUsersData, idx) => {
                                return (
                                    <div className="row" key={item.userName?.props?.title || idx}>
                                        <div className="user-name cell">{item.userName}</div>
                                        <div className="alerts cell">{item.alerts}</div>
                                        <div className="location cell">{item.location}</div>
                                        <div className="active cell">{item.activeSince}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : isLoading ? (
                    getSkeleton()
                ) : (
                    <span className="info-message">No alert data available yet</span>
                )}
            </div>
        </div>
    );
};

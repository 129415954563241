import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { ColDef, GridApi, SetFilterValuesFuncParams } from '@ag-grid-community/core';

import { BASIC_COLUMN_DEFINITION, IProps as UISSRMProps } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { SMALL_COLUMN, TINY_COLUMN } from 'sharedComponents/ui-ag-grid/agGridConstants';
import { IEndpointPathParameter } from 'interfaces/endpoint.interface';
import { endpointTableMenu } from './endpointTableMenu';
import { EditEndpointParameterNameModal } from '../../EditEndpointParameterNameModal/EditEndpointParameterNameModal';
import { getFilterValuesSet } from 'features/discovery/shared/discoveryApisLegacy';
import { RiskTooltip } from '../../RiskTooltip/RiskTooltip';
import { FilterableTable } from '../../FilterableTable/FilterableTable';
import { TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { IEndpointDeletionRequest } from 'api/exclusionRuleApi';

import './EndpointFilterableTable.scss';

interface IEndpointTableProps {
    tableActions?: ReactNode;
    newCustomTitle?: Function;
    getData: UISSRMProps['getData'];
    activeOrg: string;
    onFilterChange: Function;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount?: Function;
    onSelectionChanged?: Function;
    base64EncodedServiceName: string;
    setGetGridApi?: (gridApi: GridApi) => void;
    gridApi?: GridApi;
    timeRangeFromInput: TDatetimeRange;
    onTableDataUpdate: () => void;
    setIsUpdateHiddenCount: Function;
    deleteEndpoints: (endpointDeletionRequest: IEndpointDeletionRequest) => Promise<void>;
}

export const EndpointFilterableTable = (props: IEndpointTableProps) => {
    const {
        tableActions,
        getData,
        activeOrg,
        onFilterChange,
        paginationPageSize,
        pagination,
        onSelectionChanged,
        base64EncodedServiceName,
        setGetGridApi,
        onTableDataUpdate,
        timeRangeFromInput,
        setIsUpdateHiddenCount,
        newCustomTitle,
        deleteEndpoints,
        gridApi,
    } = props;
    const history = useHistory();

    const [modalData, setModalData] = useState<IEndpointPathParameter | null>(null);

    const columnDef: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'selection',
            flex: 0,
            maxWidth: TINY_COLUMN,
            sortable: false,
            checkboxSelection: (params) => !params.data?.pending_deletion,
            filter: false,
            filterParams: null,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Name',
            field: 'endpoint_path',
            filterParams: {
                values: (params: SetFilterValuesFuncParams) => {
                    getFilterValuesSet(activeOrg, base64EncodedServiceName).then(({ data }) => {
                        params.success(data?.endpoint_path);
                    });
                },
                refreshValuesOnOpen: true,
            },
            filter: 'agSetColumnFilter',
            cellRenderer: 'redirectableTitle',
            flex: 2.5,
            headerComponentParams: {
                headerTooltipContent: 'Endpoint name',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk',
            field: 'risk_score',
            cellRenderer: 'cellProgress',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: <RiskTooltip />,
                disableCopyButton: true,
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk Change',
            field: 'risk_change',
            cellRenderer: 'riskChangeRender',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Difference in cumulative risk between start and end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Labels',
            field: 'labels',
            cellRenderer: 'labelListRender',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params: SetFilterValuesFuncParams) => {
                    getFilterValuesSet(activeOrg, base64EncodedServiceName).then(({ data }) => {
                        params.success(data?.labels);
                    });
                },
                refreshValuesOnOpen: false,
            },
            type: 'agSetFilter',
            flex: 1.5,
            headerComponentParams: {
                headerTooltipContent: 'All endpoint labels',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Posture',
            field: 'posture_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open posture alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Runtime',
            field: 'behavior_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open behavior or runtime alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Calls',
            field: 'calls_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of requests to service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '4XX',
            field: 'errors_4xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of client-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '5XX',
            field: 'errors_5xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of server-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'First seen',
            field: 'first_seen',
            flex: 1.5,
            cellRenderer: 'dateRender',
            headerComponentParams: {
                headerTooltipContent: 'First traffic to the endpoint',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Last seen',
            field: 'last_seen',
            flex: 1.5,
            cellRenderer: 'dateRender',
            initialSort: 'desc',
            headerComponentParams: {
                headerTooltipContent: 'Last traffic to the endpoint',
            },
        },
    ];

    const menuCol = {
        ...BASIC_COLUMN_DEFINITION,
        headerName: '',
        field: 'more',
        maxWidth: SMALL_COLUMN,
        flex: 0.5,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: endpointTableMenu(
            setModalData,
            history,
            base64EncodedServiceName,
            deleteEndpoints,
            gridApi
        ),
        suppressMenu: true,
        suppressMovable: true,
        filter: false,
        filterParams: null,
        sortable: false,
        resizable: false,
    };

    columnDef.push(menuCol);

    const gridOptions = {
        rowClassRules: {
            'disabled-row': (params: any) => params.data?.pending_deletion,
        },
        suppressRowClickSelection: false,
    };

    return (
        <div
            className="EndpointFilterableTable"
            onWheel={(e) => {
                e.deltaY < 0 && e.stopPropagation();
            }}
        >
            <FilterableTable
                tableActions={tableActions}
                columns={columnDef}
                options={gridOptions}
                getData={getData}
                onFilterChange={onFilterChange}
                paginationPageSize={paginationPageSize!}
                pagination={pagination!}
                onSelectionChanged={onSelectionChanged!}
                setRowsCount={props.setRowsCount!}
                redirectOption={'ENDPOINT'}
                setGetGridApi={setGetGridApi!}
                newCustomTitle={newCustomTitle!}
            />
            {modalData && (
                <EditEndpointParameterNameModal
                    activeOrg={activeOrg}
                    parameters={modalData}
                    onModalSuccess={onTableDataUpdate}
                    onModalClose={() => setModalData(null)}
                    timeRangeFromInput={timeRangeFromInput}
                />
            )}
        </div>
    );
};

import { Surface, Symbols } from 'recharts';

import { UiTitle } from '../UiTitle/UiTitle';
import { UiTooltip } from '../UiTooltip/UiTooltip';

export const legendTexticon = (legends: any) =>
    legends.map((entry: any) => {
        const { dataKey, color } = entry;
        return (
            <span style={{ display: 'inline-block' }} key={dataKey}>
                <Surface width={13} height={10}>
                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                </Surface>
                <span style={{ paddingRight: '16px' }}>{dataKey}</span>
            </span>
        );
    });

export const RenderCustomizedLegend = ({ payload }: any) => {
    const numOfLabels = payload.length;
    const numberOfDisplayedItems = 10;
    return (
        <div className="customized-legend">
            <UiTitle title="legend" type="small" />
            {legendTexticon([...payload].splice(0, numberOfDisplayedItems))}
            {numOfLabels > numberOfDisplayedItems && (
                <UiTooltip title={legendTexticon([...payload].splice(numberOfDisplayedItems, numOfLabels))}>
                    +{numOfLabels - numberOfDisplayedItems} more
                </UiTooltip>
            )}
        </div>
    );
};

import React from 'react';
import { Switch } from 'antd';

import './Switch.scss';

interface ISwitchProps {
    text?: string;
    onChange?: any;
    defaultChecked?: boolean;
    disabled?: boolean;
}

export const UiSwitch = ({ onChange, defaultChecked, text, disabled }: ISwitchProps) => {
    return (
        <span className="ui-switch">
            <Switch size="small" defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} />
            <span className="switch-text">{text}</span>
        </span>
    );
};

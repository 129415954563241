import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

import {
    IApplicationGroupedFinding,
    useGetApplicationQuery,
    useGetGroupedFindingQuery,
    useGetScanQuery,
} from 'api/apiTestingApi';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import { BreadCrumb } from 'sharedComponents/breadcrumb/BreadCrumb';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import Spinner from 'sharedComponents/spinner/Spinner';
import { Severity } from 'sharedComponents/Severity/Severity';
import { FindingDetailsEndpoint } from 'features/apiTesting/findings/FindingDetails/FindingDetailsEndpoint/FindingDetailsEndpoint';
import { FindingDetailsFinding } from 'features/apiTesting/findings/FindingDetails/FindingDetailsFinding/FindingDetailsFinding';
import { FindingDetailsEvidence } from 'features/apiTesting/findings/FindingDetails/FindingDetailsEvidence/FindingDetailsEvidence';

import './FindingDetails.scss';

export const FindingDetails = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appId, scanId, findingId } = useParams<IApitRouteParams>();
    const { data: application, isLoading: isApplicationLoading } = useGetApplicationQuery({ appId });
    const { data: groupedFinding, isLoading: isGroupedFindingLoading } = useGetGroupedFindingQuery({
        appId,
        scanId,
        groupedFindingId: findingId,
    });
    const { data: scan, isLoading: isScanLoading } = useGetScanQuery(scanId ? { scanId, appId } : skipToken);

    const breadCrumbList = useMemo(() => {
        return [
            {
                url: `/${currentTenantKey}/api-testing/applications`,
                text: 'Applications',
            },
            {
                url: `/${currentTenantKey}/api-testing/applications/${appId}/findings`,
                text: application?.name || '',
            },
            ...(scanId
                ? [
                      {
                          url: `/${currentTenantKey}/api-testing/applications/${appId}/scans/${scanId}`,
                          text: `Scan ${scanId}`,
                      },
                  ]
                : []),
            {
                url: `/${currentTenantKey}/api-testing/applications/${appId}${scanId ? `/scans` : ''}/findings/${
                    groupedFinding?.id
                }`,
                text: `${groupedFinding?.id} - ${groupedFinding?.name}`,
            },
        ];
    }, [application?.id, scanId, findingId, currentTenantKey, groupedFinding?.id]);

    if (isApplicationLoading || isGroupedFindingLoading || isScanLoading) {
        return (
            <div className="FindingDetails">
                <Spinner show />
            </div>
        );
    }

    if (!groupedFinding) {
        // TODO: Empty state
        return <></>;
    }

    return (
        <div className="FindingDetails">
            <BreadCrumb
                isPassParams
                breadcrumbList={breadCrumbList}
                selectedRoute={`${groupedFinding?.id} - ${groupedFinding?.name}`}
            />
            <div className="finding-details-header">
                <span className="finding-details-name">{groupedFinding.name}</span>
                <Severity severity={groupedFinding.severity} />
            </div>
            <div className="finding-details-layout">
                <div className="finding-details-layout-left">
                    <FindingDetailsEndpoint method={groupedFinding.method} endpoint={groupedFinding.endpoint} />
                    <FindingDetailsFinding {...{ groupedFinding, scan }} />
                </div>
                <div className="finding-details-layout-right">
                    <FindingDetailsEvidence
                        {...{
                            groupedFinding,
                            scan: scanId ? scan! : (groupedFinding as IApplicationGroupedFinding).last_scan,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
    updateCollectorConfig,
    ICollectorConfigurationConfigFile,
    createCollectorConfig,
} from '../../shared/collectorConfigurationApis';
import { errorMessage, successMessage } from 'general/toast-service';
import { ISettingsPathParams } from '../../../../Settings';
import { UiModal } from 'sharedComponents/UiModal/UiModal';

export interface IConfirmSaveConfigFiles {
    currentConfigFiles?: ICollectorConfigurationConfigFile[];
    name: string;
    dataSourceFilenames: string[];
    universalConfigTemplateVersion: string;
}

export interface IConfigurationSaveModalProps {
    onClose: (redirectBack: boolean) => void;
    confirmSaveConfigFiles: IConfirmSaveConfigFiles;
    configId?: string;
}

export const CollectorConfigurationSaveConfirmationModal = ({
    onClose,
    confirmSaveConfigFiles,
    configId,
}: IConfigurationSaveModalProps) => {
    const history = useHistory();
    const { activeOrg } = useParams<ISettingsPathParams>();
    const { currentConfigFiles, name, dataSourceFilenames, universalConfigTemplateVersion } = confirmSaveConfigFiles;

    const modifiedFiles = currentConfigFiles?.reduce(
        (acc: ICollectorConfigurationConfigFile[], { modified, fileContent, fileName, index, id }) => {
            modified && acc.push({ fileContent, fileName, index, id });
            return acc;
        },
        []
    );

    const handleSave = useCallback(async () => {
        try {
            if (configId && modifiedFiles) {
                await updateCollectorConfig(activeOrg, configId, name, modifiedFiles);
                history.push({ pathname: `/${activeOrg}/settings/node-configurations` });
                successMessage(`Node Configuration '${name}' updated`);
            } else {
                const formattedConfigFiles = currentConfigFiles?.map(({ fileContent, fileName, index }) => ({
                    fileContent,
                    fileName,
                    index,
                }));
                await createCollectorConfig(
                    activeOrg,
                    name,
                    dataSourceFilenames,
                    universalConfigTemplateVersion,
                    formattedConfigFiles
                );
                history.push({ pathname: `/${activeOrg}/settings/node-configurations` });
                successMessage(`Node Configuration '${name}' created`);
            }
        } catch (e) {
            errorMessage(`Could not save Node Configuration`);
        }
    }, []);

    return (
        <UiModal
            onCancel={() => onClose(false)}
            acceptButton={{ text: 'Save', onClick: handleSave }}
            rejectButton={{ text: 'Cancel', onClick: () => onClose(false) }}
            title="Save Node Configuration?"
            icon="save"
        >
            <div>
                {modifiedFiles && modifiedFiles.length > 0 && (
                    <>
                        <div>The following files will be affected:</div>
                        <ul>
                            {modifiedFiles?.map(({ fileName }) => (
                                <li>{fileName}</li>
                            ))}
                        </ul>
                    </>
                )}
                <div>{configId ? 'Save changes to' : 'Create new'} Node Configuration</div>
                <div>`{name}`?</div>
            </div>
        </UiModal>
    );
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import {
    CellRenderActionDescription,
    CellRenderDetokenizable,
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { CustomTooltip } from 'sharedComponents/ui-ag-grid/customToolTip';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { extractErrorMessage, stringifyRulePredicate, TimeDisplayResEnum } from 'general/utils';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { openDialog } from 'services/dialog/dialog-service';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { IEntity, useGetEntitiesQuery } from 'api/entitiesApi';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import {
    IAutomatedActionRule,
    IAvailableAction,
    useDeleteAutomatedActionRuleMutation,
    useGetAutomatedActionRulesQuery,
    useGetAvailableActionsQuery,
    usePatchAutomatedActionRuleMutation,
} from 'api/automatedActionRulesApi';
import { errorMessage } from 'general/toast-service';

import './AutomatedActionRuleList.scss';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        cellRenderDetokenizable: CellRenderDetokenizable,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': (params: any) => params.data?.status === false,
    },
};

const getTableItems = (rules: IAutomatedActionRule[], entities: IEntity[], availableActions: IAvailableAction[]) => {
    return rules.map((rule) => {
        return {
            id: rule.id,
            status: rule.status === 'enabled',
            name: rule.name || 'no name provided',
            match: stringifyRulePredicate(rule.predicate, entities?.filter((e) => !e.active).map((e) => e.name) || []),
            action: availableActions.find((action) => action.id === rule.automated_action_id),
            automated_action_id: rule.automated_action_id,
            hit_count: rule.hit_count,
            createdAt: rule.created_at,
            author: rule.created_by,
            lastModified: rule.updated_at,
            LastModifiedBy: rule.updated_by,
        };
    });
};

export const AutomatedActionRuleList = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string };
    const activeOrg = params.activeOrg;
    const [tableData, setTableData] = useState<any[]>([]);
    const { data: entitiesResponse, error: entitiesError } = useGetEntitiesQuery({ includeHidden: true });
    const { data: availableActionsResponse, error: availableActionsError } = useGetAvailableActionsQuery();
    const { data: rulesResponse, error: rulesError } = useGetAutomatedActionRulesQuery();
    const [patchRule] = usePatchAutomatedActionRuleMutation();
    const [deleteRule] = useDeleteAutomatedActionRuleMutation();

    const columnsDefs = [
        {
            headerName: 'Status',
            field: 'status',
            type: 'basic',
            width: 100,
            sortable: false,
            cellRenderer: 'cellRenderToggle',
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: { data: IAutomatedActionRule }) => {
                return {
                    onRowToggleHandler: (active: boolean) => onRowToggleHandler(params.data.id, active),
                };
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action name',
        },
        {
            headerName: 'Match',
            field: 'match',
            type: 'basic',
            flex: 2,
            cellRenderer: 'cellRenderDetokenizable',
            sortable: false,
            headerTooltip: 'Action match conditions',
        },
        {
            headerName: 'Action',
            field: 'action',
            type: 'basic',
            flex: 2,
            cellRenderer: CellRenderActionDescription,
            cellRendererParams: (params: { value: IAvailableAction }) => ({
                action: params.value,
            }),
            headerTooltip: 'Action to take on rule match',
        },
        {
            headerName: 'Hits',
            field: 'hit_count',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'The number of rule hits',
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'author',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action creator',
        },
        {
            headerName: 'Last Modified',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'LastModifiedBy',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            resizable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: { data: IAutomatedActionRule }) => {
                const menuItems = [
                    {
                        label: `Edit Action`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/automated-actions/${params.data.id}`);
                        },
                    },
                    {
                        label: `Delete Action`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete Rule ${params.data.name}?`,
                                icon: 'trash2',
                                title: 'Delete Rule',
                            }).then(() => deleteRuleHandler(params.data.id));
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    useEffect(() => {
        if (rulesResponse && entitiesResponse && availableActionsResponse) {
            setTableData(getTableItems(rulesResponse.items, entitiesResponse.items, availableActionsResponse.items));
        }
    }, [rulesResponse, entitiesResponse, availableActionsResponse]);

    useEffect(() => {
        if (rulesError || availableActionsError || entitiesError) {
            errorMessage(extractErrorMessage(rulesError || availableActionsError || entitiesError));
        }
    }, [rulesError, availableActionsError, entitiesError]);

    const deleteRuleHandler = (id: string) => {
        deleteRule({ id })
            .unwrap()
            .catch((error) => errorMessage(extractErrorMessage(error)));
    };

    const onRowToggleHandler = (id: string, active: boolean) => {
        patchRule({ id, body: { status: active ? 'enabled' : 'disabled' } })
            .unwrap()
            .catch((error) => errorMessage(extractErrorMessage(error)));
    };

    return (
        <div className="suppression-rules-container">
            <SettingsSectionHeader
                title="Automated Actions"
                urlPath="settings/automated-actions/add"
                buttonText="Add Action"
            />
            <div className="suppression-rules-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={tableData}
                    showRowCount={true}
                    columns={columnsDefs}
                    rowCountTitle="Automated Actions"
                />
            </div>
        </div>
    );
};

const style = `
#container {
  display: flex;
  align-items : baseline;
  overflow: hidden;
  user-select: none;
}
#first-part, #second-part {
  overflow: hidden;
  overflow-wrap: break-word; 
}
#second-part {
  direction: rtl;
  align-self: flex-end;
}
`;

const template = `
<div id="container">
    <span id="first-part"></span>
    <span id="separator">...</span>
    <span id="second-part"></span>
</div>
`;

const tpl = document.createElement('template');
tpl.innerHTML = `<style>${style}</style>${template}`;

export class Truncate extends HTMLElement {
    constructor() {
        super();
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(tpl.content.cloneNode(true));
    }

    static get observedAttributes() {
        return ['chars', 'content'];
    }

    attributeChangedCallback() {
        const content = this.getAttribute('content') as string;
        const chars = this.getAttribute('chars') as unknown as number;
        const containerEl = this.shadowRoot?.getElementById('container') as HTMLElement;

        if (content && chars) {
            const firstPartEl = this.shadowRoot?.getElementById('first-part') as HTMLElement;
            const separatorEl = this.shadowRoot?.getElementById('separator') as HTMLElement;
            const secondPartEl = this.shadowRoot?.getElementById('second-part') as HTMLElement;
            containerEl.style.display = 'flex';

            if (content.length <= chars) {
                separatorEl.style.display = 'none';
                secondPartEl.style.display = 'none';
                firstPartEl.innerHTML = content;
            } else {
                const firstPart = content.substr(0, chars / 2);
                const secondPart = content.substr(content.length - chars / 2, content.length);
                separatorEl.style.display = 'flex';
                secondPartEl.style.display = 'flex';
                firstPartEl.innerHTML = firstPart;
                secondPartEl.innerHTML = secondPart;
            }
        } else {
            containerEl.style.display = 'none';
        }
    }
}

customElements.define('ns-truncate', Truncate);

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'ns-truncate': { chars: number; content: string };
        }
    }
}

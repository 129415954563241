import { IServerQueryResponse, tenantApi } from 'api/baseServerApi';
import { fetchAll } from 'api/apiUtils';
import { ISuppressionRule, ISuppressionRuleForm } from 'interfaces/throttle.interface';

export const throttlingRulesApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getThrottlingRules: builder.query<IServerQueryResponse<ISuppressionRule>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<ISuppressionRule>('throttling_rules', 1000, baseQuery),
            providesTags: ['throttlingRules'],
        }),
        postThrottlingRule: builder.mutation<void, { body: ISuppressionRuleForm }>({
            query: ({ body }) => ({
                url: 'throttling_rules',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['throttlingRules'],
        }),
        patchThrottlingRule: builder.mutation<
            void,
            {
                id: ISuppressionRule['id'];
                body: Partial<ISuppressionRuleForm>;
            }
        >({
            query: ({ id, body }) => ({
                url: `throttling_rules/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['throttlingRules'],
        }),
        deleteThrottlingRule: builder.mutation<void, { id: ISuppressionRule['id'] }>({
            query: ({ id }) => ({
                url: `throttling_rules/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['throttlingRules'],
        }),
    }),
});

export const useGetThrottlingRulesQuery = throttlingRulesApi.endpoints.getThrottlingRules.useQuery;
export const usePostThrottlingRuleMutation = throttlingRulesApi.endpoints.postThrottlingRule.useMutation;
export const usePatchThrottlingRuleMutation = throttlingRulesApi.endpoints.patchThrottlingRule.useMutation;
export const useDeleteThrottlingRuleMutation = throttlingRulesApi.endpoints.deleteThrottlingRule.useMutation;

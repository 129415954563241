import { TotalReqPieChart } from './total-req-pie-chart/TotalReqPieChart';
import { SeriesLineChart } from './series-line-chart/SeriesLineChart';

import { IEntityChartSeries, IEntityChartTotals } from 'interfaces/user.interface';
import { ISeriesData } from 'interfaces/status.interface';

import './EntityRequestChart.scss';

interface IProps {
    chartsData: { totals: IEntityChartTotals; series: IEntityChartSeries } | null;
    customHeight?: number;
    isDark?: boolean;
}

const chartColors = ['#17B0E0', '#B6F3B4', '#5779D1', '#F66060', '#FFA52B', '#A4B2C3'];
const chartColorsLight = ['#1D8CB6', '#49CC90', '#606999', '#F66060', '#FFA52B', '#A4B2C3'];

export function EntityRequestCharts({ chartsData, customHeight, isDark = false }: IProps) {
    const totalsData = chartsData?.totals;
    const seriesData = chartsData?.series?.timestamps.map((timeStamp: string, i) => {
        const data = chartsData?.series?.legend.reduce(
            (acc, legendItem, j) => ({
                ...acc,
                [legendItem]: chartsData?.series?.data[i][j],
            }),
            {}
        );

        return {
            name: timeStamp,
            ...data,
        } as ISeriesData;
    });

    return (
        <div
            className={`alert-charts-container ${isDark ? 'dark-chart' : 'light-chart'}`}
            style={{ height: `${customHeight !== undefined ? customHeight + 'px' : '100%'}`, transition: '0.7s all' }}
        >
            <div className="totals-container">
                {chartsData && (
                    <TotalReqPieChart
                        isDark={isDark}
                        totalsData={totalsData}
                        colors={isDark ? chartColors : chartColorsLight}
                    />
                )}
            </div>
            <div className="seriesContainer">
                {chartsData && (
                    <SeriesLineChart seriesData={seriesData} colors={isDark ? chartColors : chartColorsLight} />
                )}
            </div>
        </div>
    );
}

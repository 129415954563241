import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { AxiosResponse } from 'axios';

import { API_TESTING_PATH } from 'features/apiTesting/ApitTestingRoutes';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import {
    CellRenderRangeSensitiveMore,
    CellRenderVerticalCenter,
    CustomNoRowsOverlay,
    TableColumnHeader,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { applicationTableColumnDefinitions } from 'features/apiTesting/applications/ApplicationList/applicationTableCols';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import CustomDateFilter from 'sharedComponents/ui-ag-grid/customDateFilter';
import { IApplication } from 'api/apiTestingApi';
import { AddEditApplicationModal } from 'features/apiTesting/applications/ApplicationList/AddEditApplicationModal/AddEditApplicationModal';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { selectApitSlice } from 'api/slices/apitSlice';
import { DeleteApplicationModal } from 'features/apiTesting/applications/ApplicationList/DeleteApplicationModal/DeleteApplicationModal';
import { renderAppsOrAuthCtaButton } from 'features/apiTesting/AppsOrAuthConfigs';
import { UiButton } from 'sharedComponents/button/Button';
import { IServerQueryResponse } from 'api/baseServerApi';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';

import './ApplicationList.scss';

export const ApplicationList = () => {
    const history = useHistory();
    const location = useLocation();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { appsOrAuthConfigsTabId } = useSelector(selectApitSlice);
    const [applicationToEditDelete, setApplicationToEditDelete] = useState<IApplication>();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const applicationsBaseUrl = `/${currentTenantKey}/${API_TESTING_PATH}/${appsOrAuthConfigsTabId}`;

    const [gridApi, setGridApi] = useState<GridApi>();
    const onGridReady = useCallback((params: { api: GridApi; columnApi: ColumnApi }) => {
        !gridApi && setGridApi(params.api);
    }, []);

    const newApplicationButtonProps = {
        type: 'primary',
        text: 'New Application',
        onClick: () => history.push(`${applicationsBaseUrl}/add`),
    };

    const applicationTableMenu = (application: IApplication) => {
        return [
            {
                icon: UiIcon({ name: 'show' }),
                label: 'View application',
                onClick: () => {
                    history.push(`${applicationsBaseUrl}/${application.id}/findings`);
                },
            },
            {
                icon: UiIcon({ name: 'pencil' }),
                label: 'Edit Name',
                onClick: () => {
                    setApplicationToEditDelete(application);
                    history.push(`${applicationsBaseUrl}/${application.id}/edit`);
                },
            },
            {
                icon: UiIcon({ name: 'trash' }),
                label: 'Delete',
                onClick: () => {
                    setApplicationToEditDelete(application);
                    setIsDeleteModalOpen(true);
                },
            },
        ];
    };
    const tableCols = useMemo(
        () => applicationTableColumnDefinitions(applicationTableMenu, applicationsBaseUrl),
        [applicationTableMenu]
    );

    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
    useEffect(() => {
        setIsAddEditModalOpen(location.pathname.includes('/add') || location.pathname.includes('/edit'));
    }, [location]);

    const handleAddEditModalClose = useCallback(() => {
        history.goBack();
        setIsAddEditModalOpen(false);
        setApplicationToEditDelete(undefined);
    }, []);

    const handleAddEditModalAction = () => {
        gridApi?.refreshServerSide({});
        handleAddEditModalClose();
    };

    const handleDeleteModalClose = useCallback(() => {
        setIsDeleteModalOpen(false);
        setApplicationToEditDelete(undefined);
    }, []);

    const handleDeleteModalAction = () => {
        gridApi?.refreshServerSide({});
        handleDeleteModalClose();
    };

    const getData = useCallback(
        async (
            start: number,
            end: number,
            sortParams?: string
        ): Promise<AxiosResponse<IServerQueryResponse<IApplication>>> => {
            const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(id)';
            return httpPOST(`organizations/${currentTenantKey}/testing/applications/query`, {
                undefined,
                sort_by,
                offset: start,
                limit: 100,
            }).catch((error) => {
                return errorMessage(`${error.response?.data?.detail || 'Unexpected error while fetching data'}`);
            });
        },
        [currentTenantKey]
    );

    return (
        <div className="ApplicationList">
            <UiAgGridSSRM
                isHideRowsCount
                columns={tableCols}
                refetchInterval={30000}
                options={{
                    columnTypes: {
                        basic: BASIC_AGGRID_COL_TYPE,
                        agSetFilter: agSetFilter,
                        agTextFilter: agTextFilter,
                    },
                    components: {
                        agColumnHeader: TableColumnHeader,
                        moreButtonRender: CellRenderRangeSensitiveMore,
                        cellRenderVerticalCenter: CellRenderVerticalCenter,
                        customNoRowsOverlay: CustomNoRowsOverlay,
                        customDateFilter: CustomDateFilter,
                    },
                }}
                getData={getData}
                dataMappingFunction={(data: any) => data}
                onGridReady={onGridReady}
            />
            {isAddEditModalOpen && (
                <AddEditApplicationModal
                    onCreateUpdate={handleAddEditModalAction}
                    onClose={handleAddEditModalClose}
                    name={applicationToEditDelete?.name}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteApplicationModal
                    onDelete={handleDeleteModalAction}
                    onClose={handleDeleteModalClose}
                    id={applicationToEditDelete!.id}
                />
            )}
            {renderAppsOrAuthCtaButton(<UiButton {...newApplicationButtonProps} />)}
        </div>
    );
};

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { IServerQueryResponse } from './baseServerApi';

export const fetchAll = async <T>(
    uriPath: string,
    pageSize: number,
    baseQuery: Function,
    maxNumOfItemsToFetch?: number
): Promise<{ data: IServerQueryResponse<T> } | { error: FetchBaseQueryError }> => {
    const fullResult: Partial<IServerQueryResponse<T>> = {
        count: 0,
        total: undefined,
        items: [],
    };
    let offset: number = 0;
    let uriPathWithSuffix = uriPath;

    if (!uriPathWithSuffix.includes('?')) {
        uriPathWithSuffix += '?';
    }

    try {
        while (
            (fullResult.total === undefined || fullResult.total > fullResult.count!) &&
            (!maxNumOfItemsToFetch || fullResult.items!.length < maxNumOfItemsToFetch)
        ) {
            const { data } = await baseQuery(uriPathWithSuffix + `&offset=${offset}&limit=${pageSize}`);
            fullResult.items?.push(...data.items);
            fullResult.count += data.count;
            fullResult.total = data.total;
            offset += pageSize;
        }
        if (maxNumOfItemsToFetch) {
            const trimmedItems = fullResult.items?.slice(0, maxNumOfItemsToFetch);
            const trimmedFullResult: Partial<IServerQueryResponse<T>> = {
                count: trimmedItems!.length,
                total: trimmedItems!.length,
                items: trimmedItems,
            };
            return { data: trimmedFullResult as IServerQueryResponse<T> };
        } else {
            return { data: fullResult as IServerQueryResponse<T> };
        }
    } catch (e) {
        return { error: e as FetchBaseQueryError };
    }
};

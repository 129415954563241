import React from 'react';
import 'sharedComponents/spinner/Spinner.scss';
import { Spin, Alert } from 'antd';

export interface ISpinnerProps {
    show: boolean;
    size?: 'small' | 'default' | 'large';
    paddingBottom?: number;
    paddingTop?: number;
}

const Spinner = ({ show, size, paddingTop, paddingBottom }: ISpinnerProps) => {
    return show ? (
        <div className="Spinner">
            <div
                className="spinner-container"
                style={{ paddingBottom: `${paddingBottom}px`, paddingTop: `${paddingTop}px` }}
            >
                <Spin wrapperClassName="ui-spinner" className="spinner" tip="Loading..." size={size || 'default'} />
            </div>
        </div>
    ) : null;
};

export default Spinner;

import React from 'react';
import { message } from 'antd';

export interface IMessageConfigOptions {
    icon?: React.ReactNode;
    duration?: number;
    key?: string | number;
}

export const defaultMessageConfig: IMessageConfigOptions = {
    icon: <></>,
};

const successMessage = (text: string, configOverride?: IMessageConfigOptions) => {
    return message.success({
        ...defaultMessageConfig,
        duration: 3,
        ...configOverride,
        className: 'success',
        content: text,
    });
};

const infoMessage = (text: string, configOverride?: IMessageConfigOptions) => {
    return message.info({
        ...defaultMessageConfig,
        duration: 3,
        ...configOverride,
        className: 'info',
        content: text,
    });
};

const warningMessage = (text: string, configOverride?: IMessageConfigOptions) => {
    return message.warn({
        ...defaultMessageConfig,
        duration: 6,
        ...configOverride,
        className: 'warning',
        content: text,
    });
};

const errorMessage = (text: string, configOverride?: IMessageConfigOptions) => {
    return message.error({
        ...defaultMessageConfig,
        duration: 10,
        ...configOverride,
        className: 'error',
        content: text,
    });
};

const withSpinner = (text: string) => {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    setTimeout(() => {
        message.success({ content: text, key, duration: 2 });
    }, 1000);
};

const setMessageBg = (color: string) => {
    const messageBody = document.querySelector('.ant-message-notice-content') as HTMLElement;
    if (messageBody) {
        messageBody.style.backgroundColor = color;
    }
};

export { successMessage, infoMessage, errorMessage, warningMessage, withSpinner };

import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getJWTToken } from 'general/auth-service';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'general/store';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

export interface IServerQueryResponse<T> {
    count: number;
    items: T[];
    total: number;
}

export enum apiTags {
    endpointExclusionRules = 'endpointExclusionRules',
}

const rawBaseQuery = (url: string = '') =>
    fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}${url}`,
        prepareHeaders: async (headers) => {
            try {
                const JWT = await getJWTToken();
                headers.set('Authorization', `Bearer ${JWT}`);
            } catch (err) {
                console.log(err);
            }
            return headers;
        },
    });

const commonServerOptions = (url: string = '') => ({
    baseQuery: rawBaseQuery(url),
    endpoints: () => ({}),
});
export const serverApi = createApi({
    reducerPath: 'serverApi',
    ...commonServerOptions(),
});

export const cmsApi = createApi({
    reducerPath: 'cmsApi',
    ...commonServerOptions(`cms/`),
});

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const tenantKey = selectCurrentTenantKey(api.getState() as RootState);

    if (!tenantKey) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'No tenantKey',
            },
        };
    }

    const urlEnd = typeof args === 'string' ? args : args.url;
    // construct a dynamically generated portion of the url
    const adjustedUrl = `${tenantKey}/${urlEnd}`;
    const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery('organizations/')(adjustedArgs, api, extraOptions);
};

export const tenantApi = createApi({
    reducerPath: 'tenantApi',
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
    tagTypes: [
        'alertComments',
        'testingScans',
        'akamaiConfigurationList',
        'serviceLabels',
        'entities',
        'automatedActionRules',
        'throttlingRules',
        'apiTestingScans',
        'apiTestingApplications',
        'apiTestingAuthConfigs',
        apiTags.endpointExclusionRules,
    ],
});

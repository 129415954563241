import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Divider, Input } from 'antd';

import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { httpGet } from 'general/http-service';
import { ISettingsPathParams } from 'features/settings/Settings';

import 'sharedComponents/TroubleshootingModal/TroubleshootingModal.scss';

export enum TroubleshootingContext {
    collector = 'collector',
    sensor = 'sensor',
}

export interface ITroubleshootingModalProps {
    troubleshootContext: TroubleshootingContext;
    onDismiss: () => void;
}

interface ITroubleshootingItem {
    order: number;
    question: string;
    answer: string;
}

interface ITroubleshootingData {
    header: string;
    content: string;
}

export default function TroubleshootingModal({ troubleshootContext, onDismiss }: ITroubleshootingModalProps) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [data, setData] = useState<ITroubleshootingItem[]>([]);
    const [filteredTroubleshootInfo, setFilteredTroubleshootInfo] = useState<ITroubleshootingData[]>([]);

    const { activeOrg } = useParams<ISettingsPathParams>();

    useEffect(() => {
        const getInitialData: () => void = async () => {
            const getTroubleshootingItems: () => Promise<ITroubleshootingItem[]> = async () => {
                return httpGet(
                    `organizations/${activeOrg}/cms/troubleshoot/${TroubleshootingContext[troubleshootContext]}`
                ).then((res) => res.data);
            };
            const troubleshootingRes: ITroubleshootingItem[] = await getTroubleshootingItems();
            setData(troubleshootingRes);
        };
        getInitialData();
    }, [activeOrg, troubleshootContext]);

    useEffect(() => {
        setFilteredTroubleshootInfo(
            (searchValue
                ? data.filter(
                      ({ question, answer }) =>
                          question.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                          answer.toLowerCase().includes(searchValue.trim().toLowerCase())
                  )
                : data
            )
                .sort(({ order: a }, { order: b }) => a - b)
                .map(({ question, answer }) => ({ header: question, content: answer }))
        );
    }, [data, searchValue]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const getSearchbarSuffixElem = () => {
        return searchValue ? (
            <>
                <Divider type="vertical" />
                <UiIcon name="search" />
            </>
        ) : (
            <UiIcon name="search" />
        );
    };

    const getCollapseItems = () => {
        return filteredTroubleshootInfo.map(({ header, content }, i) => (
            <Collapse.Panel header={header} key={i}>
                {content}
            </Collapse.Panel>
        ));
    };

    const customIcon = (isActive: any) => (
        <span className="my-custom-icon">
            <UiIcon name={isActive ? 'upArrow' : 'downArrow'} />
        </span>
    );

    return (
        <UiModal
            isVisible
            isFormModal
            onCancel={onDismiss}
            acceptButton={{ text: 'Ok', onClick: onDismiss }}
            title={'Troubleshooting'}
            width={600}
            wrapClassName="TroubleshootingModal"
        >
            <div className="searchbar">
                <Input
                    placeholder="Search"
                    suffix={getSearchbarSuffixElem()}
                    allowClear
                    value={searchValue}
                    onChange={onSearch}
                />
            </div>
            <div className="collapse-container">
                <Collapse
                    expandIconPosition="right"
                    className="troubleshooting-collapse"
                    expandIcon={({ isActive }) => customIcon(isActive)}
                    accordion
                >
                    {getCollapseItems()}
                </Collapse>
            </div>
        </UiModal>
    );
}

import { ICollectorConfigurationConfigFile } from 'features/settings/components/collectorConfigurations/shared/collectorConfigurationApis';
import { cmsApi } from './baseServerApi';

export interface IDataSourceType {
    filename: string;
    displayName: string;
    port: number;
}

export interface IUniversalConfigTemplate {
    files: ICollectorConfigurationConfigFile[];
    universalConfigTemplateVersion: string;
}

export const nodeApi = cmsApi.injectEndpoints({
    endpoints: (builder) => ({
        getDataSourceTypes: builder.query<{ items: IDataSourceType[] }, void>({
            query: () => `data_source_types`,
        }),
        getCollectorConfigFiles: builder.query<IUniversalConfigTemplate, { dataSourceFilenames: string[] }>({
            query: ({ dataSourceFilenames }) => ({
                url: `universal_config_template`,
                method: 'POST',
                body: { dataSourceFilenames },
            }),
        }),
    }),
});

export const useGetDataSourceTypesQuery = nodeApi.endpoints.getDataSourceTypes.useQuery;
export const useGetCollectorConfigFilesQuery = nodeApi.endpoints.getCollectorConfigFiles.useQuery;

import { useLayoutEffect, useRef, useState } from 'react';

import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { ApiLabel } from 'sharedComponents/ApiLabel/ApiLabel';
import { UiProgress } from 'sharedComponents/UiProgress/UiProgress';

import './TopEndpoints.scss';

interface IEndpointData {
    auth_type: string;
    call_count: number;
    call_percentage: number;
    endpoint_path: string;
    first_seen: string;
    call_count_display?: string;
}

interface ITopEndpointsProps {
    endpointsData: IEndpointData[];
}

export const TopEndpoints = ({ endpointsData }: ITopEndpointsProps) => {
    const [serviceCharNo, setServiceCharNo] = useState<number>(0);
    let topEndpointsRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (topEndpointsRef?.current?.clientWidth) {
            setServiceCharNo(topEndpointsRef?.current?.clientWidth / 27);
        }
    }, [topEndpointsRef]);

    return (
        <div className="top-endpoints-container">
            <div className="inner-endpoint-container">
                {endpointsData.map((endpoint: any, index: number) => {
                    const displayKNotation = endpoint.call_count > 999999;

                    const call_count_display = displayKNotation
                        ? `${Math.round(endpoint.call_count / 1000).toLocaleString()}K`
                        : endpoint.call_count.toLocaleString();

                    const pct = endpoint.call_percentage.toFixed(2);

                    return (
                        <div className="endpoint-row" key={index} ref={topEndpointsRef}>
                            <span className="service-name ellipsis">
                                <UiTooltip title={endpoint.service_name}>
                                    <ns-truncate chars={serviceCharNo} content={endpoint.service_name} />
                                </UiTooltip>
                            </span>
                            <ApiLabel
                                apiType={[endpoint.method]}
                                apiName={endpoint.endpoint_path}
                                tooltip={endpoint.endpoint_path}
                            />
                            <UiProgress
                                percentage={pct}
                                className="endpoint"
                                extraInfo={call_count_display + ' (' + pct + '%)'}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

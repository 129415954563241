import { useState } from 'react';
import { Button } from 'antd';

import { UiIcon } from '../../../sharedComponents/icon/UiIcon';

import './ExportCsvButton.scss';

export interface IExportCsvButton {
    onClick: () => Promise<any>;
    disabled?: boolean;
}

export const ExportCsvButton = ({ onClick, disabled }: IExportCsvButton) => {
    const [loading, setLoading] = useState<boolean>(false);

    const click = () => {
        setLoading(true);
        onClick().finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="ExportCsvButton">
            <Button
                icon={<UiIcon name="swagger" isNotDefaultStyle />}
                type="primary"
                loading={loading}
                onClick={click}
                disabled={!!disabled}
            >
                CSV
            </Button>
        </div>
    );
};

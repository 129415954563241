import xs, { Listener, Producer, Stream, Subscription } from 'xstream';
import { httpGet, httpPatch, httpPOST } from '../../general/http-service';

export enum HTTPMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export type QueryParams = { [key: string]: string };

export class RESTQuery {
    entityUrl: string;
    method: HTTPMethod;
    params?: QueryParams;
    body?: Object;

    constructor(entityUrl: string, method: HTTPMethod, params = {}, body?: Object | undefined) {
        this.entityUrl = entityUrl;
        this.method = method;
        this.params = params;
        this.body = body;
    }
}

export type Entity = any;

export type Cache = Map<string, Map<string, Entity>>;

export type Query = RESTQuery;

export type DataServiceSubscription = Subscription;

class DataService {
    private cache: Cache = new Map();
    private listener: Listener<Cache> = {} as Listener<Cache>;
    private stream: Stream<Cache>;

    constructor() {
        const producer: Producer<Cache> = {
            start: (listener) => {
                console.log('Starting Data service producer');
                this.listener = listener;
            },
            stop: () => console.log('Stopping Data service producer'),
        };
        this.stream = xs.create(producer);
    }

    subscribe(next: (cache: Cache) => void, error: (error: any) => void): DataServiceSubscription {
        return this.stream.subscribe({ next, error });
    }

    query(query: Query) {
        if (query instanceof RESTQuery) {
            this.handleRESTQueryRequest(query)
                .then((updatedCache: Cache) => this.listener.next(updatedCache))
                .catch((error: any) => this.listener.error({ [JSON.stringify(query)]: error }));
        }
    }

    stop() {
        this.listener.complete();
    }

    private handleRESTQueryRequest(query: RESTQuery): Promise<Cache> {
        if (query.method === HTTPMethod.GET) {
            // const cachedQueryResult = this.cache.get(query.entityUrl)?.get(JSON.stringify(query.params));
            // return cachedQueryResult ? Promise.resolve(new Map(this.cache)) : this.executeRESTGet(query);
            return this.executeRESTGet(query);
        } else if (query.method === HTTPMethod.PATCH) {
            return this.handleCreate(query);
        } else if (query.method === HTTPMethod.POST) {
            return this.handleUpdate(query);
        }

        return Promise.resolve(this.cache);
    }

    private handleCreate(query: RESTQuery): Promise<Cache> {
        return httpPatch(query.entityUrl, query.body).then(() =>
            this.executeRESTGet(
                new RESTQuery(query.entityUrl, HTTPMethod.GET) // update corresponding entities
            )
        );
    }

    private handleUpdate(query: RESTQuery): Promise<Cache> {
        return httpPOST(query.entityUrl, query.body).then(() =>
            this.executeRESTGet(
                new RESTQuery(query.entityUrl, HTTPMethod.GET) // update corresponding entities
            )
        );
    }

    private executeRESTGet(query: RESTQuery): Promise<Cache> {
        const promise = httpGet(query.entityUrl, query.params);
        return promise.then((result) => this.cacheResult(result, query));
    }

    private cacheResult(result: any, query: Query): Cache {
        // const entityCache = this.cache.get(query.entityUrl);
        // if (!entityCache) {
        //     this.cache.set(query.entityUrl, new Map());
        // }
        // this.cache.get(query.entityUrl)?.set(JSON.stringify(query.params), result);
        // return new Map(this.cache);

        return new Map([[query.entityUrl, new Map([[JSON.stringify(query.params), result]])]]);
    }
}

const dataService = new DataService();
export const subscribe = dataService.subscribe.bind(dataService);
export const query = dataService.query.bind(dataService);

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Radio } from 'antd';
import { useSelector } from 'react-redux';

import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { formFieldRequired, isValidRegex } from 'general/forms';
import { rootState } from 'general/store';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import {
    OperationValueEnum,
    selectServiceMappingRuleById,
    useCreateServiceMappingItemMutation,
    useServiceMappingListQuery,
    useUpdateServiceMappingItemMutation,
} from 'api/ServiceMappingApi';
import { ISettingsPathParams } from 'features/settings/Settings';

import './ServiceMappingRuleAddEdit.scss';

export interface IServiceMappingRuleAddEditProps {
    onClose: (refreshNeeded?: boolean) => void;
}

interface IServiceMappingRuleAddEditParams extends ISettingsPathParams {
    serviceMappingRuleId?: string;
}

const TooltipInfoExtract = ({ operationType }: { operationType: OperationValueEnum }) => (
    <div>
        <ul>
            {operationType === 'Extract' && (
                <li>Service names are determined by the regex capturing group in parentheses.</li>
            )}
            <li>Service names cannot contain ‘, “, and ` or be longer than 128 characters.</li>
            {operationType === 'Extract' && (
                <li>
                    Illegal characters will be removed from the captured value, and it will be truncated to 128
                    characters if longer.
                </li>
            )}
        </ul>
    </div>
);

export const ServiceMappingRuleAddEdit = (props: IServiceMappingRuleAddEditProps) => {
    const { onClose } = props;
    const [triggerCreateServiceMappingItem] = useCreateServiceMappingItemMutation();
    const [triggerUpdateServiceMapping] = useUpdateServiceMappingItemMutation();
    const { refetch: refetchServiceMappingList } = useServiceMappingListQuery();
    const { serviceMappingRuleId } = useParams<IServiceMappingRuleAddEditParams>();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [operationType, setOperationType] = useState<string>(OperationValueEnum.Name);
    const [editInitLoad, setEditInitLoad] = useState<boolean>(true);
    // @ts-ignore
    const ruleDetails = useSelector(selectServiceMappingRuleById(rootState, serviceMappingRuleId));
    const [serviceMappingRuleForm] = Form.useForm();

    useEffect(() => {
        if (editInitLoad && serviceMappingRuleId && ruleDetails && ruleDetails?.[0]) {
            const { operation_type, pattern, operation_value, description } = ruleDetails?.[0];
            const type =
                operation_type === OperationValueEnum.Name ? OperationValueEnum.Name : OperationValueEnum.Extract;
            serviceMappingRuleForm.setFieldValue('operation_type', type);
            serviceMappingRuleForm.setFieldValue('pattern', pattern);
            serviceMappingRuleForm.setFieldValue('serviceName', operation_value);
            serviceMappingRuleForm.setFieldValue('description', description);
            setEditInitLoad(false);
            setOperationType(type);
        }
    }, [serviceMappingRuleId, ruleDetails]);

    const handleSave = async () => {
        const pattern = serviceMappingRuleForm.getFieldValue('pattern');
        const body = {
            pattern,
            operation_type: serviceMappingRuleForm.getFieldValue('operation_type'),
            operation_value:
                operationType === OperationValueEnum.Name
                    ? serviceMappingRuleForm.getFieldValue('serviceName')
                    : pattern,
            description: serviceMappingRuleForm.getFieldValue('description'),
        };

        if (serviceMappingRuleId) {
            await triggerUpdateServiceMapping({ serviceMappingId: serviceMappingRuleId, body });
        } else {
            await triggerCreateServiceMappingItem({ body });
        }
        serviceMappingRuleForm.resetFields();
        onClose();
        await refetchServiceMappingList();
    };

    const handleOperationTypeChange = () => {
        setOperationType(serviceMappingRuleForm.getFieldValue('operation_type'));
    };

    return (
        <UiModal
            className="ServiceMappingRuleAddEdit"
            title={`${serviceMappingRuleId ? 'Edit ' : 'Create '} Service Mapping Rule`}
            acceptButton={{ text: 'Save', onClick: handleSave, disabled: !isFormValid }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            onCancel={() => onClose()}
            width={700}
        >
            <div style={{ width: '100%' }}>
                <Form
                    form={serviceMappingRuleForm}
                    initialValues={{ operation_type: OperationValueEnum.Name }}
                    colon={false}
                    layout="vertical"
                    requiredMark={false}
                    onFieldsChange={(changedFields) => {
                        setIsFormValid(changedFields[0]?.errors?.length === 0);
                    }}
                >
                    <Form.Item
                        name="operation_type"
                        label="Assign service name: "
                        rules={[
                            {
                                required: true,
                                message: formFieldRequired.errorMessage,
                            },
                        ]}
                    >
                        <Radio.Group onChange={handleOperationTypeChange} value={OperationValueEnum.Name}>
                            <Radio value={OperationValueEnum.Name}>Name</Radio>
                            <Radio value={OperationValueEnum.Extract}>Extract name from URL</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="pattern"
                        label={`Match ${operationType === OperationValueEnum.Extract ? '& Extract' : ''} URL (Regex)`}
                        tooltip={
                            operationType === OperationValueEnum.Extract && (
                                <UiTooltip
                                    title={null}
                                    children={<TooltipInfoExtract operationType={operationType} />}
                                />
                            )
                        }
                        rules={[
                            {
                                required: true,
                                message: formFieldRequired.errorMessage,
                            },
                            {
                                max: 200,
                                message: 'The regex can be up to 200 characters long',
                            },
                            isValidRegex,
                        ]}
                    >
                        <UiInput placeholder="" />
                    </Form.Item>
                    {operationType === OperationValueEnum.Name && (
                        <Form.Item
                            name="serviceName"
                            label="Service Name"
                            tooltip={
                                <UiTooltip
                                    title={null}
                                    children={<TooltipInfoExtract operationType={operationType} />}
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: formFieldRequired.errorMessage,
                                },
                                {
                                    pattern: /^[^'"`]{1,128}$/,
                                    message:
                                        'Service name cannot contain the characters \', ", ` and can be up to 128 characters long',
                                },
                            ]}
                        >
                            <UiInput placeholder="My Service 1" maxLength={128} />
                        </Form.Item>
                    )}
                    <Form.Item name="description" label="Rule description">
                        <UiInput placeholder="Enter your description" maxLength={170} />
                    </Form.Item>
                </Form>
            </div>
        </UiModal>
    );
};

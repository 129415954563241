import { ContentType } from 'recharts/types/component/DefaultLegendContent';

import { roundToDigits } from 'general/utils';
import { UiPieChart } from 'sharedComponents/UiPieChart/UiPieChart';
import { LegendSubtotal } from 'features/user/entity-request-chart/total-req-pie-chart/LegendSubtotal';
import { IEntityChartTotals } from 'interfaces/user.interface';

import './TotalReqPieChart.scss';

interface IProps {
    totalsData: IEntityChartTotals | undefined;
    colors: string[];
    isDark?: boolean;
}

export function TotalReqPieChart({ totalsData, colors, isDark = false }: IProps) {
    const counts = totalsData?.countsByStatus;

    const getLegend: ContentType = () => {
        return totalsData?.countsByStatus.map((group, i) => {
            let returnedValue = totalsData?.total ? (group.value / totalsData?.total) * 100 : 0;
            return (
                <LegendSubtotal
                    isDark={isDark}
                    name={group.name}
                    subtotal={group.value}
                    color={colors[i]}
                    pct={roundToDigits(returnedValue, 1)}
                    key={group.name}
                />
            );
        });
    };

    return (
        <div className={`pie-chart-container ${isDark ? 'dark-chart' : 'light-chart'}`}>
            <div className="title">Total</div>
            <div className="content">
                <div className="chart">
                    <UiPieChart
                        data={counts || []}
                        colors={colors}
                        rechartsLegendProps={{ align: 'right', verticalAlign: 'middle', layout: 'vertical' }}
                        legendRenderer={getLegend}
                    />
                </div>
            </div>
        </div>
    );
}

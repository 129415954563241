import { useMemo, useState } from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { PolarViewBox } from 'recharts/types/util/types';

import { UiChartLegend } from '../UiChartLegend/UiChartLegend';
import { formatNumberWithSeparators } from 'general/utils';

export interface IUiPieChartProps {
    data: { name: string; value: number }[];
    colors: string[];
    centerLabel?: string;
    legendRenderer?: ContentType;
    onSegmentClick?: (segmentName: string) => void;
    isSegmentHoverEnabled?: boolean;
    isLegendEnabled?: boolean;
    rechartPieProps?: CategoricalChartProps;
    rechartsLegendProps?: any;
}

export const UiPieChart = ({
    data,
    colors,
    centerLabel,
    onSegmentClick,
    legendRenderer,
    isSegmentHoverEnabled = true,
    isLegendEnabled = true,
    rechartPieProps,
    rechartsLegendProps,
}: IUiPieChartProps) => {
    const [activeIndex, setActiveIndex] = useState<number>();
    const sumOfValues = useMemo(() => data.reduce((prev, { value }) => prev + value, 0), [data]);

    const handleSegmentClick = (segmentName: string) => {
        onSegmentClick?.(segmentName);
    };

    const onSegmentEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

        return startAngle !== endAngle ? (
            <>
                {/* First sector is the slice of the pie chart that darts out */}
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius + 5}
                    outerRadius={outerRadius + 5}
                    startAngle={startAngle - 1}
                    endAngle={endAngle + 1}
                    fill={fill}
                />
                {/* Second sector's job is to prevent jitter effect when hovering over margin between the sector above and other cells of the pie  */}
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius - 2}
                    outerRadius={outerRadius + 2}
                    startAngle={startAngle + 2}
                    endAngle={endAngle - 2}
                    fill="transparent"
                />
            </>
        ) : (
            <></>
        );
    };

    return (
        <ResponsiveContainer>
            <PieChart className="UiPieChart">
                <Pie
                    {...rechartPieProps}
                    data={data}
                    dataKey="value"
                    innerRadius="70%"
                    outerRadius="100%"
                    startAngle={90}
                    endAngle={-450}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    legendType="circle"
                    onMouseEnter={onSegmentEnter}
                    onMouseLeave={() => setActiveIndex(undefined)}
                >
                    <Label
                        content={(props) => {
                            const { cx } = props.viewBox as PolarViewBox;

                            return (
                                <g>
                                    <text x={cx} y="50%" textAnchor="middle" className="text-one">
                                        Total {centerLabel}
                                    </text>
                                    <text x={cx} y="50%" dy="1.5em" textAnchor="middle" className="text-two">
                                        {formatNumberWithSeparators.format(sumOfValues)}
                                    </text>
                                </g>
                            );
                        }}
                    />
                    {data?.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index]}
                            onClick={() => handleSegmentClick(entry.name)}
                        />
                    ))}
                </Pie>
                {isLegendEnabled && (
                    <Legend
                        {...rechartsLegendProps}
                        wrapperStyle={{
                            height: '80%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexDirection: 'column',
                            maxWidth: '50%',
                            whiteSpace: 'nowrap',
                        }}
                        formatter={(_, { payload, value }, index) => {
                            return (
                                <span
                                    className={`legend-item-text${
                                        activeIndex && isSegmentHoverEnabled
                                            ? activeIndex === index
                                                ? ' active'
                                                : ' inactive'
                                            : ''
                                    }`}
                                    onMouseEnter={() => setActiveIndex(index)}
                                    onMouseLeave={() => setActiveIndex(undefined)}
                                    onClick={() => {
                                        handleSegmentClick(value);
                                    }}
                                >
                                    <span className="legend-item-name">{value}</span>
                                    <span className="legend-item-value">{payload?.value}</span>
                                </span>
                            );
                        }}
                        content={legendRenderer || UiChartLegend}
                    />
                )}
            </PieChart>
        </ResponsiveContainer>
    );
};
//

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'general/store';

export const DEFAULT_STATE = { showServicesWithNoActivity: true };

export interface IDiscoveryPagesState {
    showServicesWithNoActivity: boolean;
}

export const discoveryPagesSlice = createSlice({
    name: 'discoveryPagesSlice',
    initialState: DEFAULT_STATE as IDiscoveryPagesState,
    reducers: {
        checkboxShowServicesWithoutActivityToggled: (
            state,
            action: PayloadAction<IDiscoveryPagesState['showServicesWithNoActivity']>
        ) => ({
            ...state,
            showServicesWithNoActivity: action.payload,
        }),
        updateDiscoveryPages: (state, action: PayloadAction<Partial<IDiscoveryPagesState>>) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const { updateDiscoveryPages, checkboxShowServicesWithoutActivityToggled } = discoveryPagesSlice.actions;

export const selectDiscoveryPagesSlice = (state: RootState) => state.discoveryPagesSlice;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectTenantList } from 'api/tenantListApi';
import { RootState } from 'general/store';
import { selectUser } from 'api/userApi';
import { selectConfig } from 'api/configApi';
import { featureFlagMap } from 'general/featureFlagMap';

export interface IAppInfoState {
    tenantKey: string;
    preventSaveStateToStorage: boolean;
}

export const appInfoSlice = createSlice({
    name: 'appInfoSlice',
    initialState: {} as IAppInfoState,
    reducers: {
        calcTenantKeyFromRouteOrStorage: () => {},
        loadAppStateFromStorage: (_, { payload }) => {},
        saveAppStateToStorage: () => {},
        currentTenantKeyChanged: (state, { payload: { tenantKey } }) => ({ ...state, tenantKey }),
        saveStateToStorageDisabled: (state, { payload: { preventSaveStateToStorage } }) => ({
            ...state,
            preventSaveStateToStorage,
        }),
    },
});

export const {
    calcTenantKeyFromRouteOrStorage,
    loadAppStateFromStorage,
    saveAppStateToStorage,
    currentTenantKeyChanged,
    saveStateToStorageDisabled,
} = appInfoSlice.actions;

export const selectAppInfoSlice = (state: RootState) => state.appInfoSlice;
export const selectCurrentTenantKey = createSelector(selectAppInfoSlice, ({ tenantKey }) => tenantKey);
export const selectCurrentTenantDetails = createSelector(
    selectTenantList,
    selectAppInfoSlice,
    (tenantList, { tenantKey }) => tenantList && tenantList[tenantKey]
);
export const selectPreventSaveStateToStorage = createSelector(
    selectAppInfoSlice,
    ({ preventSaveStateToStorage }) => preventSaveStateToStorage
);

export const selectFeatureFlagMap = createSelector(
    selectCurrentTenantKey,
    selectUser,
    selectConfig,
    (currentTenantKey, user, config) => featureFlagMap(currentTenantKey, user, config)
);

export const colDef = (openModal: Function) => [
    {
        headerName: 'Time',
        field: 'timestamp',
        type: 'basic',
        cellRenderer: 'cellRenderTimeStamp',
        cellRendererParams: {
            displaySingleLine: false,
        },
        flex: 1,
    },
    {
        headerName: 'Entity Type',
        field: 'entityType',
        type: 'basic',
        sortable: false,
        flex: 1.5,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        headerName: 'Entity ID',
        field: 'entityId',
        type: 'basic',
        sortable: false,
        cellRenderer: 'cellRenderDetokenizable',
        flex: 2,
    },
    {
        headerName: 'Endpoint',
        field: 'endpoint',
        type: 'basic',
        cellRenderer: 'endpointRenderUserTable',
        flex: 2,
    },
    {
        headerName: 'Status Code',
        field: 'status_code',
        type: 'basic',
        flex: 1,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        headerName: 'Source IP',
        field: 'caller_ip',
        type: 'basic',
        flex: 1,
        cellRenderer: 'cellRenderVerticalCenter',
    },
    {
        headerName: 'Labels',
        field: 'labels',
        type: 'basic',
        cellRenderer: 'labelListRender',
        sortable: false,
        flex: 2,
    },
    {
        headerName: 'Content',
        field: 'content',
        type: 'basic',
        sortable: false,
        cellRenderer: 'callContentRender',
    },
    {
        headerName: '',
        field: 'info',
        type: 'basic',
        cellRenderer: 'cellRenderInfo',
        cellRendererParams: {
            openModalCb: openModal,
        },
        sortable: false,
        resizable: false,
        flex: 1,
        maxWidth: 60,
    },
];

import { ReactNode, useState } from 'react';
import { Steps } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

import { UiModal } from '../UiModal/UiModal';
import { UiButton } from '../button/Button';

import './UiWizard.scss';

export interface IWizardStep {
    title: string;
    content: JSX.Element;
}

export interface IUiWizardProps extends ModalProps {
    wrapClassName: string;
    title: ReactNode;
    onCancel: () => void;
    /**
     * onNext callback returned promise should resolve to false if the wizard should **NOT** advance to next step, true otherwise
     *
     *  The current step number is passed and can be utilized if necessary
     **/
    onNext: (step?: number) => Promise<boolean>;
    onFinish: () => void;
    onBack?: (step: number) => void;
    steps: IWizardStep[];
}

export const UiWizard = ({
    wrapClassName,
    title,
    onCancel,
    onNext,
    onFinish,
    onBack,
    steps,
    ...rest
}: IUiWizardProps) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNextClick = async () => {
        (await onNext(currentStep)) && setCurrentStep((step) => (currentStep < steps.length ? step + 1 : step));
    };

    const handleConfirmClick = () => {
        onFinish();
    };

    const handleBackClick = () => {
        onBack?.(currentStep);
        setCurrentStep((step) => (step > 0 ? step - 1 : step));
    };

    return (
        <UiModal
            {...{ title, onCancel }}
            wrapClassName={`UiWizard ${wrapClassName}`.trim()}
            width={745}
            isFormModal
            isVisible
            acceptButton={{
                text: currentStep === steps.length - 1 ? 'Confirm' : 'Next',
                onClick: currentStep === steps.length - 1 ? handleConfirmClick : handleNextClick,
            }}
            rejectButton={{
                text: 'Cancel',
                onClick: onCancel,
            }}
            extraFooterContent={
                <div className="form-footer">
                    {currentStep > 0 ? <UiButton type="secondary" text="Back" onClick={handleBackClick} /> : <span />}
                </div>
            }
            {...rest}
        >
            <Steps current={currentStep} className="wizard-steps" items={steps} />
            {steps[currentStep].content}
        </UiModal>
    );
};

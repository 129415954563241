import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { Button, Drawer, Space } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { IServerQueryResponse } from 'api/baseServerApi';
import { AlertFilterForm } from './alertFilter/AlertFilterForm/AlertFilterForm';
import { AlertFilterList } from './alertFilter/AlertFilterList/AlertFilterList';
import { UiButton } from 'sharedComponents/button/Button';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import {
    CellExpandButton,
    CellRenderedCommentsCount,
    CellRenderEndpoint,
    CellRenderAlertMore,
    CellRenderLabelList,
    CellRenderSeverity,
    CellRenderStatus,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
    CellRenderDetokenizable,
    CustomNoRowsOverlay,
    DescriptionRender,
    ServiceFilterCellRenderer,
    TableColumnHeader,
    TableDetailCelRenderer,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import CustomDateFilter from 'sharedComponents/ui-ag-grid/customDateFilter';
import { IProps as UISSRMProps, UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { UiTooltip } from 'sharedComponents/UiTooltip/UiTooltip';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import {
    httpDelete,
    httpGet,
    httpPatch,
    httpPOST,
    httpPOSTBin,
    PAGE_SIZE_LIMIT,
    recursiveHttpGet,
} from 'general/http-service';
import { errorMessage, infoMessage } from 'general/toast-service';
import {
    extractErrorMessage,
    formatDateTimeString,
    goToEndpointInNewTab,
    goToSuppressInNewTab,
    investigateInNewTab,
    omit,
    patchAlert,
    sortEntitiesByPrecedence,
} from 'general/utils';
import { AlertTypeMap, AlertTypeReverseMap, IAlert } from 'interfaces/alert.interface';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { IBaseLabel, ILabel } from 'interfaces/labels.interface';
import { IService, IUniqueValue } from 'interfaces/service.interface';
import { openDialog } from 'services/dialog/dialog-service';
import { ExportCsvButton } from 'features/alerts/ExportCsvButton/ExportCsvButton';
import { IEntity } from 'api/entitiesApi';

import './Alerts.scss';

export enum AlertStatusEnum {
    Open = 'Open',
    Closed = 'Closed',
}

export enum AlertSeverityEnum {
    Critical = 5,
    High = 4,
    Medium = 3,
    Low = 2,
    Info = 1,
}

export interface ISaveButton {
    disabled: boolean;
    showDrawer: Function;
}

const gridOptions = {
    components: {
        agColumnHeader: TableColumnHeader,
        cellRenderTimeStamp: CellRenderTimeStamp,
        severityRender: CellRenderSeverity,
        labelListRender: CellRenderLabelList,
        statusRender: CellRenderStatus,
        endpointRender: CellRenderEndpoint,
        moreButtonRender: CellRenderAlertMore,
        expandButtonCellRenderer: CellExpandButton,
        descriptionRender: DescriptionRender,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderedCommentsCount: CellRenderedCommentsCount,
        customDateFilter: CustomDateFilter,
        customNoRowsOverlay: CustomNoRowsOverlay,
        tableDetailCelRenderer: TableDetailCelRenderer,
        cellRenderDetokenizable: CellRenderDetokenizable,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
        agSetFilter: agSetFilter,
        agTextFilter: agTextFilter,
    },
    getRowHeight: (params: any) => {
        if (!params.node.detail) return undefined;

        return params.data.comments_count ? 270 : 160;
    },
    detailRowAutoHeight: false,
};

export interface IAlertFilterSet {
    name: string;
    id?: string;
    filter_model: string;
    user_id?: string;
    ordered_index: number;
}

const CHARACTER_LIMIT = 40;

const CSV_EXPORT_ROW_LIMIT = 10000;

const alertFilterSetNameValidationMessage = {
    required: 'This field is required',
    long: `Tab name is limited to ${CHARACTER_LIMIT} characters`,
};

export const checkValidEndpointChange = (filtersModel: any): boolean => {
    return Object.keys(filtersModel).includes('endpoints') && filtersModel.endpoints.values.length === 0;
};

function getColumnsDefs(filterMetadata: IFilterMetadata): any[] {
    return [
        {
            headerName: 'Time',
            field: 'timestamp',
            type: 'agSetFilter',
            flex: 0.5,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                displaySingleLine: false,
            },
            sort: 'desc',
            filter: 'customDateFilter',
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'agSetFilter',
            flex: 0.5,
            cellRenderer: 'cellRenderVerticalCenter',
            filterParams: {
                ...agSetFilter.filterParams,
                cellRenderer: ServiceFilterCellRenderer,
                showTooltips: true,
                values: (params: any) => {
                    filterMetadata.alertNamesPromise.then((alertNames) => {
                        params.success([...new Set(alertNames)]);
                    });
                },
            },
        },
        {
            headerName: 'Category',
            field: 'category',
            flex: 0.5,
            type: 'agSetFilter',
            cellRenderer: 'cellRenderVerticalCenter',
            filterParams: {
                values: (params: any) => {
                    filterMetadata.alertCategoriesPromise.then((alertCategories) => {
                        params.success([...new Set(alertCategories)]);
                    });
                },
            },
        },
        {
            headerName: 'Type',
            field: 'alert_type',
            flex: 0.5,
            type: 'agSetFilter',
            cellRenderer: 'cellRenderVerticalCenter',
            filterParams: {
                values: ['Posture', 'Runtime'],
            },
        },
        {
            headerName: 'Severity',
            field: 'severity',
            type: 'agSetFilter',
            flex: 0.5,
            minWidth: 150,
            cellRenderer: 'severityRender',
            cellRendererParams: {
                alertSeveritiesPromise: filterMetadata.alertSeveritiesPromise,
            },
            comparator: (a: AlertSeverityEnum, b: AlertSeverityEnum) => {
                if (AlertSeverityEnum[a] === AlertSeverityEnum[b]) return 0;
                return AlertSeverityEnum[a] > AlertSeverityEnum[b] ? 1 : -1;
            },
            filterParams: {
                values: (params: any) => {
                    filterMetadata.alertSeveritiesPromise.then((severities) => {
                        params.success([...new Set(severities)]);
                    });
                },
            },
        },
        {
            headerName: 'Entity Type',
            field: 'entity_name',
            type: 'agSetFilter',
            flex: 0.5,
            cellRenderer: 'cellRenderVerticalCenter',
            filterParams: {
                values: (params: any) => {
                    filterMetadata.entitiesPromise.then((entities) => {
                        params.success([...new Set(entities)]);
                    });
                },
            },
        },
        {
            headerName: 'Entity ID',
            field: 'entity_value',
            type: 'agTextFilter',
            flex: 0.5,
            cellRenderer: 'cellRenderDetokenizable',
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            headerName: 'Status',
            field: 'status',
            type: 'agSetFilter',
            flex: 0.35,
            filterParams: {
                ...agSetFilter.filterParams,
                suppressMiniFilter: true,
                values: ['Open', 'Closed'],
            },
            cellRenderer: 'statusRender',
        },
        {
            headerName: 'Labels',
            field: 'labels',
            type: 'agSetFilter',
            flex: 0.75,
            cellRenderer: 'labelListRender',
            keyCreator: (params: any) =>
                params.value.map((label: ILabel) => {
                    return label.text;
                }),
            filterParams: {
                ...agSetFilter.filterParams,
                values: (params: any) => {
                    filterMetadata.labelsPromise.then((labels) => {
                        params.success([...new Set(labels)]);
                    });
                },
            },
            sortable: false,
        },
        {
            headerName: 'Service',
            field: 'services',
            flex: 0.75,
            type: 'agSetFilter',
            sortable: true,
            filterParams: {
                ...agSetFilter.filterParams,
                cellRenderer: ServiceFilterCellRenderer,
                showTooltips: true,
                values: (params: any) => {
                    filterMetadata.servicesPromise.then((service) => {
                        params.success([...new Set(service)]);
                    });
                },
            },
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Endpoint',
            field: 'endpoints',
            flex: 0.75,
            type: 'agSetFilter',
            cellRenderer: 'endpointRender',
            sortable: false,
            keyCreator: (params: any) =>
                params.value.map((endpoint: IEndpointDisplay) => {
                    return endpoint.endpoint_path;
                }),
            filterParams: {
                ...agSetFilter.filterParams,
                cellRenderer: ServiceFilterCellRenderer,
                showTooltips: true,
                values: (params: any) => {
                    filterMetadata.endpointsPromise.then((endpoints) => {
                        params.success([
                            ...new Set(endpoints.map((endpoint) => `${endpoint.method} ${endpoint.endpoint_path}`)),
                        ]);
                    });
                },
                comparator: (a: string, b: string) => {
                    const methodA = a.split(' ')[0];
                    const nameA = a.split(' ')[1];
                    const methodB = b.split(' ')[0];
                    const nameB = b.split(' ')[1];
                    return nameA.localeCompare(nameB) || methodA.localeCompare(methodB);
                },
            },
        },
        {
            headerName: 'Comments',
            field: 'comments_count',
            type: 'agTextFilter',
            filter: false,
            flex: 0.5,
            cellRenderer: 'cellRenderedCommentsCount',
        },
        {
            headerName: '',
            field: 'overlay',
            width: 60,
            cellRenderer: 'moreButtonRender',
            cellRendererParams: (params: any) => {
                const menuItems =
                    params.data?.entities
                        ?.filter(
                            (entity: { name: string; class: string; family: string; value: string }) => entity.value
                        )
                        .map((entity: { name: string; class: string; family: string; value: string }) => ({
                            label: `Investigate ${entity.name}`,
                            onClick: () => {
                                investigateInNewTab(
                                    process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                                    params.data.currOrg,
                                    EventTypesEnum.Alert,
                                    params.data.id,
                                    entity.name,
                                    entity.value,
                                    moment(params.data.timestamp).valueOf()
                                );
                            },
                        })) || [];

                if (menuItems.length > 0) {
                    menuItems.push({ label: 'divider' });
                }

                if (params.data.endpoints?.length) {
                    menuItems.push(
                        {
                            icon: <UiIcon name="goTo" />,
                            label: 'Go to Endpoint',
                            onClick: () => {
                                goToEndpointInNewTab(
                                    process.env.REACT_APP_REDIRECT_SIGN_IN,
                                    encodeURIComponent(params.data.endpoints[0].service_name),
                                    params.data.endpoints[0].id,
                                    '1Y',
                                    params.data.currOrg,
                                    [
                                        moment(params.data.timestamp).startOf('day').subtract(2, 'weeks').unix(),
                                        moment(params.data.timestamp).isSame(new Date(), 'date')
                                            ? // add(1, 'second') to account for the fact that alert timestamp is in milliseconds
                                              // which are omitted during conversion to seconds
                                              moment(params.data.timestamp).add(1, 'second').unix()
                                            : moment(params.data.timestamp).endOf('day').unix(),
                                    ]
                                );
                            },
                            disabled: false,
                        },
                        {
                            label: 'divider',
                        }
                    );
                }

                menuItems.push(
                    {
                        label: 'Suppress Alert',
                        onClick: () => {
                            goToSuppressInNewTab(
                                process.env.REACT_APP_REDIRECT_SIGN_IN,
                                params.data.id,
                                params.data.currOrg
                            );
                        },
                    },
                    {
                        label: 'divider',
                    },
                    {
                        id: 'close-open-alert',
                        label: `${params.data.status === 'Open' ? 'Close Alert' : 'Reopen Alert'}`,
                        resolveHandler: () => {
                            params.data.refreshDataCB();
                        },
                        clickHandler: () => {
                            return patchAlert(
                                'status',
                                params.data.currOrg,
                                params.data.id,
                                `${
                                    params.data.status === 'Open' ? AlertStatusEnum.Closed : AlertStatusEnum.Open
                                }` as AlertStatusEnum
                            );
                        },
                    }
                );
                return { menuItems };
            },
        },
        {
            headerName: '',
            width: 60,
            field: 'overlay',
            cellRenderer: 'expandButtonCellRenderer',
            suppressMenu: true,
            suppressMovable: true,
        },
    ];
}

interface IAlertTableItem {
    description: string;
    severity: string;
    entity_name: string;
    entity_value: string;
    status: AlertStatusEnum;
    labels: Pick<IBaseLabel, 'label'>[];
    endpoints: IEndpointDisplay[];
    services: string[];
    timestamp: string;
    overlay: any;
}

interface IAlertFilter {
    name:
        | 'alert_type'
        | 'timestamp'
        | 'name'
        | 'description'
        | 'labels'
        | 'severity'
        | 'endpoints[0].service_name'
        | 'endpoints[0].endpoint_path'
        | 'endpoints[0].method'
        | 'entities[0].value'
        | 'entities[0].name';
    operator: 'Ge' | 'Lt' | 'Contains' | 'NotContains' | 'Eq' | 'Has';
    value?: 'APIAlert';
}

function getAlertFilters(filterModel: any): IAlertFilter[][] {
    let newAlertFilters: IAlertFilter[][] = [];

    for (let key in filterModel) {
        if (filterModel[key].filterType === 'customDateFilter' || key === 'labels') {
            newAlertFilters = newAlertFilters.concat(getAlertFilter(key, filterModel[key]));
        } else {
            newAlertFilters.push(getAlertFilter(key, filterModel[key]) as IAlertFilter[]);
        }
    }

    return newAlertFilters;
}

function getAlertFilter(key: string, filterModelItem: any): IAlertFilter[] | IAlertFilter[][] {
    if (key === 'endpoints') {
        return filterModelItem.values.map((value: string) => {
            return {
                name: 'endpoint',
                operator: 'Eq',
                value,
            };
        });
    }

    if (key === 'labels') {
        return filterModelItem.values.map((label: string) => [
            {
                name: 'labels',
                operator: 'Has',
                value: label,
            },
        ]);
    }

    if (key === 'alert_type') {
        return filterModelItem.values.map((value: string) => ({
            name: key,
            operator: 'Eq',
            value: AlertTypeReverseMap.get(value),
        }));
    }

    if (filterModelItem.filterType === 'set') {
        let name = key;

        if (key === 'entity_name') {
            name = 'entities[0].name';
        }

        if (key === 'services') {
            name = 'endpoints[0].service_name';
        }
        if (filterModelItem.values) {
            return filterModelItem.values.map((value: string) => ({
                name,
                operator: 'Eq',
                value,
            }));
        }
    }

    if (filterModelItem.filterType === 'text') {
        let name = key;

        if (key === 'entity_value') {
            name = 'entities[0].value';
        }

        return [
            {
                name: (name as 'entities[0].value') || 'description',
                operator: filterModelItem.type === 'notContains' ? 'NotContains' : 'Contains',
                value: filterModelItem.filter,
            },
        ];
    }

    if (filterModelItem?.filterType === 'customDateFilter') {
        return [
            [
                {
                    name: 'timestamp',
                    operator: 'Ge',
                    value: filterModelItem.value.filterDateRange[0],
                },
            ],
            [
                {
                    name: 'timestamp',
                    operator: 'Lt',
                    value: filterModelItem.value.filterDateRange[1],
                },
            ],
        ];
    }

    return [];
}

function getSortQueryString(sortParams: string | undefined): string {
    const sortString = sortParams ? sortParams.split('=')[1] : 'desc(timestamp)';

    // turn desc(entity_value) into desc(entities[0].value) because that is our API
    const field = sortString.split('(')[1].slice(0, -1);
    if (field === 'entity_name' || field === 'entity_value') {
        const predicate = sortString.split('(')[0];
        return `${predicate}(entities[0].${field.split('_')[1]})`;
    }
    return sortString;
}

function getAlertDataCallback(
    activeOrg: string,
    limit: number,
    filterModel: any,
    setTotalAlertCount: (count: number) => any
) {
    const baseUrl = `organizations/${activeOrg}/alerts/query`;
    return function (start: number, end: number, sortParams: string | undefined) {
        const queryObj = {
            alert_filters: getAlertFilters(filterModel),
            sort_by: getSortQueryString(sortParams),
            offset: start,
            // limit: PAGE_SIZE_LIMIT,
            limit: 100,
        };

        return httpPOST(baseUrl, queryObj).then((response: AxiosResponse<IServerQueryResponse<IAlert>>) => {
            setTotalAlertCount(response.data.total);
            return response;
        });
    };
}

function alertToTableRow(activeOrg: string, refreshDataCB: Function) {
    return function (alertsData: IAlert[]): IAlertTableItem[] {
        return alertsData.map((item: IAlert) => {
            const itemEntitiesOrdered = sortEntitiesByPrecedence(item.entities);
            return {
                ...item,
                currOrg: { key: activeOrg },
                alert_type: AlertTypeMap.get(item.alert_type),
                entity_name: itemEntitiesOrdered[0]?.name ?? '',
                entity_value: itemEntitiesOrdered[0]?.value ?? '',
                status: AlertStatusEnum[item.status as AlertStatusEnum],
                labels: item.labels?.map((label) => ({ label })),
                services: item.endpoints.map((endpoint: IEndpointDisplay) => endpoint.service_name),
                overlay: item,
                entities: itemEntitiesOrdered,
                refreshDataCB,
            };
        });
    };
}

export interface IFilterMetadata {
    alertNamesPromise: Promise<string[]>;
    alertCategoriesPromise: Promise<string[]>;
    alertSeveritiesPromise: Promise<string[]>;
    labelsPromise: Promise<string[]>;
    entitiesPromise: Promise<IEntity[]>;
    servicesPromise: Promise<IService[]>;
    endpointsPromise: Promise<IEndpointDisplay[]>;
}

function fetchAlertFilterSetList(org: string): Promise<IAlertFilterSet[]> {
    return httpGet(`organizations/${org}/alert_filter_set`).then((res) => res.data.items);
}

function fetchAlertFilterSet(org: string, id: string): Promise<IAlertFilterSet> {
    return httpGet(`organizations/${org}/alert_filter_set/${id}`).then((res) => res.data);
}

function createAlertFilterSet(org: string, alertFilterSet: IAlertFilterSet): Promise<AxiosResponse> {
    return httpPOST(`organizations/${org}/alert_filter_set`, alertFilterSet);
}

function updateAlertFilterSet(org: string, data: Partial<IAlertFilterSet>): Promise<AxiosResponse> {
    return httpPatch(`organizations/${org}/alert_filter_set/${data.id}`, omit(['id'], data));
}

export const Alerts = () => {
    const tableWrapper = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const [alertDataCallback, setAlertDataCallback] = useState<UISSRMProps['getData']>();
    const [alertFilters, setAlertFilters] = useState<object>();
    const params = useParams() as { activeOrgParam: string; activeFilterId: string };
    const queryParams = new URLSearchParams(useLocation().search);
    const severityParam = queryParams.get('severity');
    const labelParam = queryParams.get('label');
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();

    const unblock = useRef<Function>();

    const [visible, setVisible] = useState<boolean>(false);
    const [alertFilterName, setAlertFilterName] = useState<string>('');
    const [inputValidationMessage, setInputValidationMessage] = useState<string>('');
    const [inputValidationFlag, setInputValidationFlag] = useState<boolean>(false);
    const [filterMetadata, setFilterMetadata] = useState<IFilterMetadata>();
    const [unSavedModalFlag, setUnSavedModalFlag] = useState<boolean>(false);
    const [alertFilterSetList, setAlertFilterSetList] = useState<IAlertFilterSet[]>();
    const [alertFilterSet, setAlertFilterSet] = useState<IAlertFilterSet>();
    const [alertFilterSetBusy, setAlertFilterSetBusy] = useState<boolean>(false);
    const [totalAlertCount, setTotalAlertCount] = useState<number>(0);

    useEffect(() => {
        const activeOrg = params.activeOrgParam;
        if (!params.activeFilterId) {
            changeSelectedFilterTag('All');
        }
        const entitiesPromise = httpGet(`organizations/${activeOrg}/entities`).then((response) =>
            response?.data?.items?.map((item: any) => item.name)
        );
        const labelsPromise = httpGet(`organizations/${activeOrg}/config`).then((response) => response?.data?.labels);
        const alertSeveritiesPromise = httpGet(`organizations/${activeOrg}/alert_severities`).then((response) =>
            response?.data?.items?.map((item: { severity: string }) => item.severity)
        );

        let servicesResolve: Function,
            endpointsResolve: Function,
            alertNamesResolve: Function,
            alertCategoriesResolve: Function;

        const servicesPromise: Promise<IService[]> = new Promise((resolve) => (servicesResolve = resolve)),
            endpointsPromise: Promise<IEndpointDisplay[]> = new Promise((resolve) => (endpointsResolve = resolve)),
            alertNamesPromise: Promise<string[]> = new Promise((resolve) => (alertNamesResolve = resolve)),
            alertCategoriesPromise: Promise<string[]> = new Promise((resolve) => (alertCategoriesResolve = resolve));

        httpGet(
            `organizations/${activeOrg}/alerts/unique_values?column=endpoints.service_name&column=name&column=severity&column=category`
        )
            .then(({ data }) => {
                servicesResolve(
                    data.items.find((item: IUniqueValue) => item.column_name === 'endpoints.service_name').values || []
                );
                alertNamesResolve(data.items.find((item: IUniqueValue) => item.column_name === 'name').values || []);
                alertCategoriesResolve(
                    data.items.find((item: IUniqueValue) => item.column_name === 'category').values || []
                );
            })
            .catch((error: any) => console.error(error));

        recursiveHttpGet(
            `organizations/${activeOrg}/discovery/endpoints?limit=${PAGE_SIZE_LIMIT}&alerted=true&additional_fields=max_severity&from_timestamp=${moment()
                .subtract(1, 'years')
                .format('X')}&to_timestamp=${moment().format('X')}`,
            0,
            PAGE_SIZE_LIMIT,
            [],
            (endpoints: IEndpointDisplay[]) => {
                endpointsResolve(endpoints);
            },
            (error: any) => console.error(error)
        );

        setFilterMetadata({
            alertNamesPromise,
            alertCategoriesPromise,
            alertSeveritiesPromise,
            entitiesPromise,
            servicesPromise,
            endpointsPromise,
            labelsPromise,
        });
    }, []);

    useEffect(() => {
        unblock.current?.();

        const blocker = (location: any) => {
            if (unSavedModalFlag) {
                openDialog({
                    text: `Discard unsaved filter changes?`,
                    icon: 'trash2',
                    title: 'Discard Changes',
                })
                    .then(() => {
                        unblock.current?.();
                        history.push(location);
                    })
                    .catch(() => {});

                return false as false;
            }
        };
        unblock.current = history.block(blocker);

        return () => {
            unblock.current?.();
        };
    }, [unSavedModalFlag]);

    useEffect(() => {
        fetchAlertFilterSetList(params.activeOrgParam).then((afsl) => setAlertFilterSetList(afsl));
    }, [params.activeOrgParam]);

    useEffect(() => {
        (async function () {
            if (alertFilterSetList) {
                let selectedAlertFilterSet = [
                    { id: 'All', name: 'All Alerts', filter_model: '{}' } as IAlertFilterSet,
                    ...alertFilterSetList,
                ]?.find((afs) => afs.id === params.activeFilterId);

                if (!selectedAlertFilterSet) {
                    try {
                        const sharedAlertFilterSet = await fetchAlertFilterSet(
                            params.activeOrgParam,
                            params.activeFilterId
                        );
                        selectedAlertFilterSet = {
                            ...alertFilterSetList[0],
                            filter_model: sharedAlertFilterSet.filter_model,
                        };
                    } catch (e) {
                        selectedAlertFilterSet = alertFilterSetList[0];
                    }
                }

                if (severityParam || labelParam) {
                    setAlertFilters({
                        ...JSON.parse(selectedAlertFilterSet.filter_model),
                        severity: { values: [severityParam], filterType: 'set' },
                        labels: { values: [labelParam], filterType: 'set' },
                    });
                } else {
                    setAlertFilters(JSON.parse(selectedAlertFilterSet?.filter_model as string));
                }

                setAlertFilterSet(selectedAlertFilterSet);
                setAlertFilterName(
                    selectedAlertFilterSet?.name === 'All Alerts' ? '' : (selectedAlertFilterSet?.name as string)
                );
            }
        })();
    }, [alertFilterSetList, params.activeFilterId]);

    useEffect(() => {
        alertFilters &&
            setAlertDataCallback(() =>
                getAlertDataCallback(params.activeOrgParam, PAGE_SIZE_LIMIT, alertFilters, setTotalAlertCount)
            );
    }, [params.activeOrgParam, PAGE_SIZE_LIMIT, alertFilters]);

    const onGridReady = useCallback(
        (params: { api: GridApi; columnApi: ColumnApi }) => {
            if (!gridColumnApi) {
                setGridColumnApi(params.columnApi);
            }
        },
        [gridColumnApi]
    );

    const showDrawer = () => {
        setVisible(true);
    };

    const onCloseDrawer = () => {
        setVisible(false);
    };

    const onCsvExport = async () => {
        try {
            const sortedColumn = gridColumnApi?.getColumnState().filter((s) => s.sort !== null)[0];
            const sortString = `sort_by=${sortedColumn?.sort}(${sortedColumn?.colId})`;
            const baseUrl = `organizations/${params.activeOrgParam}/alerts/export_to_csv`;

            const queryObj = {
                alert_filters: getAlertFilters(alertFilters),
                sort_by: getSortQueryString(sortString),
            };

            const response = await httpPOSTBin(baseUrl, queryObj);
            const dateTime = formatDateTimeString(new Date());
            const blob = new Blob([response.data], {
                type: 'octet/stream',
            });
            saveAs(blob, `${dateTime}_neosec_${params.activeOrgParam}_alerts.zip`);

            if (totalAlertCount > CSV_EXPORT_ROW_LIMIT) {
                infoMessage(`Exported a max. of ${CSV_EXPORT_ROW_LIMIT} rows`);
            } else {
                infoMessage(`Exported ${totalAlertCount} rows`);
            }
        } catch (error) {
            errorMessage(extractErrorMessage(error, 'Failed to export CSV file'));
        }
    };

    const onSubmit = async () => {
        const validationMessage = validateName(alertFilterName);
        setInputValidationMessage(validationMessage || '');
        setInputValidationFlag(!!validationMessage);
        if (validationMessage) {
            return;
        }
        if (alertFilters) {
            setAlertFilterSetBusy(true);

            const alertFiltersToSave = { ...alertFilters };

            for (const key in alertFiltersToSave) {
                // @ts-ignore
                delete alertFiltersToSave[key].modified;
            }

            let newFilterAlertSet: IAlertFilterSet = {
                name: alertFilterName,
                filter_model: JSON.stringify(alertFiltersToSave),
                ordered_index:
                    alertFilterSet?.ordered_index === undefined
                        ? (alertFilterSetList?.length as number) - 1
                        : alertFilterSet?.ordered_index,
            };

            try {
                if (!alertFilterSet?.id || alertFilterSet?.id === 'All') {
                    // create
                    await createAlertFilterSet(params.activeOrgParam, newFilterAlertSet as IAlertFilterSet);
                    const afsl = await fetchAlertFilterSetList(params.activeOrgParam);
                    setAlertFilterSetList(afsl);
                    onSelectFilter(afsl[afsl.length - 1].id as string);
                    setAlertFilterSetBusy(false);
                } else {
                    // update
                    newFilterAlertSet.id = alertFilterSet?.id;
                    await updateAlertFilterSet(params.activeOrgParam, newFilterAlertSet);
                    const afsl = await fetchAlertFilterSetList(params.activeOrgParam);
                    setAlertFilterSetList(() => {
                        setUnSavedModalFlag(false);
                        setAlertFilterSetBusy(false);
                        return afsl;
                    });
                }
            } catch (error: any) {
                const errorText = error?.response?.data?.detail || 'Unexpected error while creating/updating filter';
                setAlertFilterSetBusy(false);
                errorMessage(errorText);
            }
        }

        onCloseDrawer();
    };

    const onRemoveAlertFilterSet = (alertFilterSetID: IAlertFilterSet['id']) => {
        setAlertFilterSetBusy(true);
        httpDelete(`organizations/${params.activeOrgParam}/alert_filter_set/${alertFilterSetID}`)
            .then(() => fetchAlertFilterSetList(params.activeOrgParam))
            .then((afsl) => {
                if (alertFilterSetID === alertFilterSet?.id) {
                    history.push(`/${params.activeOrgParam}/alerts/All`);
                }

                setAlertFilterSetList(() => {
                    setAlertFilterSetBusy(false);
                    return afsl;
                });
            })
            .catch((error) => {
                const errorText = error?.response?.data?.detail || 'Unexpected error while deleting filter';
                setAlertFilterSetBusy(false);
                errorMessage(errorText);
            });
    };

    const onReorderAlertFilterSet = (index: number, prevIndex: number) => {
        const selectedAlertFilterSet = alertFilterSetList?.find((afs) => afs.ordered_index === prevIndex);

        if (!selectedAlertFilterSet) {
            return;
        }

        setAlertFilterSetBusy(true);

        return updateAlertFilterSet(params.activeOrgParam, {
            id: selectedAlertFilterSet.id,
            ordered_index: index,
        })
            .then(() => fetchAlertFilterSetList(params.activeOrgParam))
            .then((afsl) => {
                setAlertFilterSetList(() => {
                    setAlertFilterSetBusy(false);
                    return afsl;
                });
            })
            .catch((error) => {
                console.error(error);
                setAlertFilterSetBusy(false);
            });
    };

    const onSelectFilter = (key: string) => {
        changeSelectedFilterTag(key);
    };

    const changeSelectedFilterTag = (key: string) => {
        onCloseDrawer();
        history.push(`/${params.activeOrgParam}/alerts/${key}`);
    };

    const inputHandler = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value as string;
        const validationMessage = validateName(newValue);
        setInputValidationMessage(validationMessage || '');
        setInputValidationFlag(!!validationMessage);
        if (validationMessage === alertFilterSetNameValidationMessage.long) {
            return;
        }
        setAlertFilterName(newValue);
    };

    const validateName = (name: string): string | null => {
        if (name.length === 0) {
            return alertFilterSetNameValidationMessage.required;
        } else if (name.length > CHARACTER_LIMIT) {
            return alertFilterSetNameValidationMessage.long;
        }
        return null;
    };

    const Footer = () => {
        return (
            <Space className="footer">
                <div className="footer-switcher-container"></div>
                <div className="footer-buttons-container">
                    <Button className="footer-button" onClick={onCloseDrawer}>
                        Cancel
                    </Button>
                    <Button className="footer-button" type="primary" onClick={onSubmit}>
                        Save
                    </Button>
                </div>
            </Space>
        );
    };

    const SaveButton = ({ disabled, showDrawer }: ISaveButton) => {
        return <UiButton onClick={showDrawer} disabled={disabled} text="Save Filter" type="primary" />;
    };

    const onFilterChange = (filtersModel: any) => {
        let modified = false;
        const currentFilterModel = JSON.parse(alertFilterSet?.filter_model || '{}');
        if (Object.keys(filtersModel).length < Object.keys(currentFilterModel).length) {
            modified = true;
        }

        if (checkValidEndpointChange(filtersModel)) return;

        if (alertFilterSet?.id !== 'All') {
            for (const key in filtersModel) {
                // @ts-ignore
                if (JSON.stringify(currentFilterModel[key]) !== JSON.stringify(filtersModel[key])) {
                    filtersModel[key].modified = true;
                    modified = true;
                }
            }
        }

        setUnSavedModalFlag(modified);
        setAlertFilters(filtersModel);
    };

    return alertDataCallback && alertFilterSet && filterMetadata ? (
        <div className="alerts-container container">
            <div className="alerts-tabs">
                <AlertFilterList
                    selectedFilter={alertFilterSet.id as string}
                    alertFilterTags={alertFilterSetList as IAlertFilterSet[]}
                    onSelectFilter={onSelectFilter}
                    onRemoveAlertFilter={onRemoveAlertFilterSet}
                    onReorder={onReorderAlertFilterSet}
                    modified={unSavedModalFlag}
                    busy={alertFilterSetBusy}
                />
                {alertFilterSet.id === 'All' ? (
                    <div className="alerts-tabs-action">
                        {alertFilterSetList && alertFilterSetList.length > 10 ? (
                            <UiTooltip title={'Limit of 10 alert filters'}>
                                <span>
                                    <SaveButton
                                        showDrawer={showDrawer}
                                        disabled={
                                            alertFilterSetBusy ||
                                            Object.keys(alertFilters || {}).length === 0 ||
                                            (alertFilterSetList && alertFilterSetList.length > 10)
                                        }
                                    />
                                </span>
                            </UiTooltip>
                        ) : (
                            <SaveButton
                                showDrawer={showDrawer}
                                disabled={alertFilterSetBusy || Object.keys(alertFilters || {}).length === 0}
                            />
                        )}
                        <ExportCsvButton onClick={onCsvExport} disabled={totalAlertCount === 0} />
                    </div>
                ) : (
                    <div className="alerts-tabs-action">
                        <UiButton
                            onClick={showDrawer}
                            disabled={alertFilterSetBusy}
                            text="Save Filter"
                            type="primary"
                        />
                        <ExportCsvButton onClick={onCsvExport} disabled={totalAlertCount === 0} />
                    </div>
                )}
            </div>
            <div className="alert-table" ref={tableWrapper}>
                <UiAgGridSSRM
                    columns={getColumnsDefs(filterMetadata)}
                    options={gridOptions}
                    attributes={{
                        ensureDomOrder: true,
                        enableCellTextSelection: true,
                    }}
                    getData={alertDataCallback}
                    masterDetail={true}
                    getDetailData={() => {}}
                    customDetailComponent="tableDetailCelRenderer"
                    dataMappingFunction={alertToTableRow(params.activeOrgParam, () =>
                        setAlertDataCallback(() =>
                            getAlertDataCallback(
                                params.activeOrgParam,
                                PAGE_SIZE_LIMIT,
                                alertFilters,
                                setTotalAlertCount
                            )
                        )
                    )}
                    filterModel={alertFilters}
                    onFilterChange={onFilterChange}
                    onGridReady={onGridReady}
                />
                <div className="drawer-container">
                    <Drawer
                        title={alertFilterSet.id === 'All' ? 'Save Filters' : 'Update Filters'}
                        headerStyle={{ color: 'red' }}
                        width={'30%'}
                        placement="right"
                        closable={true}
                        className={'cardinality-drawer'}
                        onClose={onCloseDrawer}
                        open={visible}
                        getContainer={false}
                        keyboard
                        maskStyle={{ backgroundColor: 'transparent' }}
                        style={{ position: 'absolute' }}
                        zIndex={1000}
                        footer={<Footer />}
                    >
                        {alertFilterSet.id === 'All' ? (
                            <p className="drawer-body-head">Save these filters as a new tab</p>
                        ) : (
                            <p className="drawer-body-head">Update these filters as a new tab</p>
                        )}
                        {alertFilterSet.id === 'All' ? (
                            <AlertFilterForm
                                visible={visible}
                                columns={getColumnsDefs(filterMetadata)}
                                currentFilters={alertFilters || {}}
                                inputHandler={inputHandler}
                                alertFilterName={alertFilterName}
                                inputValidationMessage={inputValidationMessage}
                                inputValidationFlag={inputValidationFlag}
                            />
                        ) : (
                            <AlertFilterForm
                                visible={visible}
                                columns={getColumnsDefs(filterMetadata)}
                                currentFilters={alertFilters || {}}
                                inputHandler={inputHandler}
                                alertFilterName={alertFilterName}
                                inputValidationMessage={inputValidationMessage}
                                inputValidationFlag={inputValidationFlag}
                            />
                        )}
                    </Drawer>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

import {
    IEndpointApiTypeCount,
    IEndpointApiTypeCountPieFormat,
    IEndpointApiTypeCountResponse,
    IServiceRiskChangeResponse,
    IServiceSensitiveResponse,
} from 'api/discoveryApi';

const formatStats = (stats: Record<string, { label: string; count: number }[]>): Record<string, any>[] =>
    stats && Object.keys(stats).length === 0
        ? []
        : Object.keys(stats).reduce((accGraphData: Record<string, any>[], serviceName) => {
              const serviceStatsFormatted = stats[serviceName].reduce(
                  (acc: Record<string, number>, { label, count }: { label: string; count: number }) => {
                      acc[label] = (acc[label] || 0) + count;
                      return acc;
                  },
                  {} as Record<string, number>
              );
              accGraphData.push({ name: serviceName, ...serviceStatsFormatted });
              return accGraphData;
          }, []);

export const transformResponseSensitive = ({ labels, stats }: IServiceSensitiveResponse) => ({
    labels: labels.sort(),
    stats: formatStats(stats),
});

const getHighestRiskChange = (response: IServiceRiskChangeResponse): number => {
    const highestNegativeRiskChange = Math.abs(
        response.negative?.reduce(
            (highest, service) => (service.risk_change < highest ? service.risk_change : highest),
            0
        )
    );
    const highestPositiveRiskChange = response.positive?.reduce(
        (highest, service) => (service.risk_change > highest ? service.risk_change : highest),
        0
    );

    return highestNegativeRiskChange > highestPositiveRiskChange
        ? highestNegativeRiskChange
        : highestPositiveRiskChange;
};

export const transformResponseRiskChange = (response: IServiceRiskChangeResponse) => {
    return {
        negativeServiceData:
            response.negative?.map(({ service_name, risk_change }) => ({
                name: service_name,
                risk_change: risk_change,
            })) || [],
        positiveServiceData:
            response.positive?.map(({ service_name, risk_change }) => ({
                name: service_name,
                risk_change: risk_change,
            })) || [],
        highestRiskChange: getHighestRiskChange(response),
        total: response.total,
    };
};

const formatDataPie = (data?: IEndpointApiTypeCount[]) =>
    (data || []).reduce((acc: { name: string; value: number }[], current: IEndpointApiTypeCount) => {
        acc.push({ name: current.label, value: current.count });
        return acc;
    }, []);

export const transformResponseEndpointApiTypeCount = (response: IEndpointApiTypeCountResponse | any) => {
    const dataFormatted = Object.keys(response).reduce(
        (acc: IEndpointApiTypeCountPieFormat, endpointStatus: string) => ({
            ...acc,
            [endpointStatus.toUpperCase()]: formatDataPie(response[endpointStatus]),
        }),
        {} as IEndpointApiTypeCountPieFormat
    );
    return dataFormatted;
};

export const labelColors = [
    '#565B75',
    '#638AEE',
    '#B99CF8',
    '#2D57C2',
    '#816DF9',
    '#F3A2D2',
    '#F8C655',
    '#49CC90',
    '#2F80ED',
    '#A4B2C3',
    '#8B520E',
    '#000000',
    '#FE8787',
    '#E2FA51',
    '#D11AA9',
    '#099053',
    '#FFA52B',
    '#17B0E0',
    '#E22828',
    '#33FA9F',
    '#565B75',
    '#EB7C1A',
    '#FF00D6',
    '#43CEC6',
    '#828282',
    '#76C67E',
    '#9341AF',
];

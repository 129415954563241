import React, { useMemo, useState } from 'react';
import { IComment, useDeleteAlertCommentMutation } from '../../api/alertApi';
import { dateStringFormat, TimeDisplayResEnum, timeStringFormat } from '../../general/utils';
import { UiIcon } from '../icon/UiIcon';
import { CommentDeleteConfirmationModal } from './CommentDeleteConfirmationModal';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';

import './CommentCard.scss';

interface ICommentCardProps {
    comment: IComment;
    alertId: string;
}

export const CommentCard = ({ comment, alertId }: ICommentCardProps) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteComment] = useDeleteAlertCommentMutation();
    const { data: user } = useGetUserSessionTokenPayloadQuery();

    const timestamp = useMemo(
        () =>
            `${dateStringFormat(comment.created_at)}, ${timeStringFormat(comment.created_at, TimeDisplayResEnum.SEC)}`,
        [comment.created_at]
    );

    return (
        <>
            <div className="CommentCard">
                <div className="comment-card-block">
                    <div className="comment-card-header">
                        <UiIcon name="avatar" />
                        <span className="comment-card-email">{comment.created_by}</span>
                        <span>|</span>
                        <span className="comment-card-date">{timestamp}</span>
                    </div>
                    <div className="comment-card-content pre-wrap">
                        <span>{comment.content}</span>
                    </div>
                </div>
                {user?.email === comment.created_by && (
                    <div className="comment-card-delete">
                        <UiIcon
                            onClick={() => {
                                setOpenDelete(true);
                            }}
                            name="deleteComment"
                        />
                    </div>
                )}
            </div>
            {openDelete && (
                <CommentDeleteConfirmationModal
                    date={timestamp}
                    onAccept={() => {
                        deleteComment({ alertId, commentId: comment.id })
                            .unwrap()
                            .then(() => {
                                setOpenDelete(false);
                            });
                    }}
                    onClose={() => {
                        setOpenDelete(false);
                    }}
                />
            )}
        </>
    );
};

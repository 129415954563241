import { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ICellRendererParams } from '@ag-grid-community/core';

import {
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { authConfigTableCols } from './authConfigTableCols';
import { DeleteAuthConfigConfirmModal } from '../DeleteAuthConfigConfirmModal';
import { IAuthConfig, useGetAuthConfigsQuery } from 'api/apiTestingApi';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { API_TESTING_PATH } from '../../ApitTestingRoutes';
import { renderAppsOrAuthCtaButton } from 'features/apiTesting/AppsOrAuthConfigs';
import { UiButton } from 'sharedComponents/button/Button';
import { selectApitSlice } from 'api/slices/apitSlice';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';

import './AuthConfigList.scss';

const gridOptions = {
    rowHeight: 70,
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    components: {
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
    },
};

export const AuthConfigList = () => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [authConfigToDelete, setAuthConfigToDelete] = useState<IAuthConfig>();
    const { data: authConfigs } = useGetAuthConfigsQuery();
    const authConfigBaseUrl = `/${currentTenantKey}/${API_TESTING_PATH}/auth-configs`;

    const newAuthConfigButtonProps = {
        type: 'primary',
        text: 'New Auth Configuration',
        onClick: () => history.push(`${authConfigBaseUrl}/add`),
    };

    const tableMenuItems = (params: ICellRendererParams) => {
        return {
            menuItems: [
                {
                    label: `Edit`,
                    icon: <UiIcon name="pencil" />,
                    onClick: () => {
                        history.push(`${authConfigBaseUrl}/${params?.data?.id}/edit`);
                    },
                },
                {
                    label: 'Clone',
                    icon: <UiIcon name="copy" />,
                    onClick: async () => {
                        try {
                            history.push(`${authConfigBaseUrl}/${params?.data?.id}/clone`);
                        } catch (error) {
                            errorMessage(extractErrorMessage(error));
                        }
                    },
                },
                {
                    label: 'Delete',
                    icon: <UiIcon name="trash" />,
                    onClick: () => {
                        setAuthConfigToDelete(params.data);
                    },
                },
            ],
        };
    };

    const cachedTableMenuItems = useCallback(tableMenuItems, []);
    const cachedColumnsDefs = useCallback(authConfigTableCols, []);

    const columnDefs = useMemo(
        () => cachedColumnsDefs(cachedTableMenuItems, authConfigBaseUrl),
        [cachedTableMenuItems, cachedColumnsDefs]
    );

    return (
        <div className="AuthConfigList">
            {
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={authConfigs?.items || []}
                    showRowCount={false}
                    columns={columnDefs}
                />
            }
            {authConfigToDelete && (
                <DeleteAuthConfigConfirmModal
                    onClose={() => setAuthConfigToDelete(undefined)}
                    authConfig={authConfigToDelete}
                />
            )}
            {renderAppsOrAuthCtaButton(<UiButton {...newAuthConfigButtonProps} />)}
        </div>
    );
};

import React from 'react';
import { Tag } from 'antd';

import { IFilterState } from '../../ui-ag-grid/UiAgGridCSRM';
import { UiTooltip } from '../../UiTooltip/UiTooltip';

import './FilterTag.scss';

interface FilterTagProps {
    filterTag: IFilterState;
    index: number;
    resetFilter?: Function;
}

export const FilterTag = (props: FilterTagProps) => {
    const { filterTag, index, resetFilter } = props;
    const activeValues = filterTag.activeValues;
    let tagDisplayTitle = '';
    if (filterTag.filterType === 'set' && activeValues) {
        const activeValCount = activeValues?.length;
        switch (activeValCount) {
            case 0: {
                tagDisplayTitle = 'None';
                break;
            }
            case 1: {
                const length = activeValues[0]?.length;
                if (length > 24) {
                    tagDisplayTitle =
                        activeValues[0].substring(0, 12) + '... ' + activeValues[0].substring(length - 12);
                } else {
                    tagDisplayTitle = activeValues[0];
                }
                break;
            }
            default: {
                tagDisplayTitle = activeValCount + ' Selected';
            }
        }
    }

    return (
        <Tag
            closable={!!resetFilter}
            className={`filter-tag ${filterTag.modified ? 'dotted' : ''}`}
            key={filterTag.field + index}
            onClose={() => resetFilter?.(filterTag.field)}
        >
            {tagDisplayTitle ? (
                <UiTooltip key={index} title={filterTag.colName.toUpperCase() + ' : ' + tagDisplayTitle}>
                    <span>{filterTag.colName.toUpperCase() + ' : ' + tagDisplayTitle}</span>
                </UiTooltip>
            ) : (
                <UiTooltip
                    key={index}
                    title={
                        <span>
                            {filterTag.colName.toUpperCase()} : {filterTag.tooltipDisplay}
                        </span>
                    }
                    disableCopyButton
                >
                    <span className="title-tag">{filterTag.colName}</span>: {filterTag.tagDisplay}
                </UiTooltip>
            )}
        </Tag>
    );
};

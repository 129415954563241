import { Select } from 'antd';

import { useGetServiceLabelsQuery } from 'api/discoveryApi';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';

import 'features/dashboards/DiscoveryDashboard/DiscoveryDashboardControls/ServiceLabelMultiSelect/ServiceLabelMultiSelect.scss';

const { Option } = Select;

export type IServiceLabelMultiSelectValue = string;

export interface IServiceLabelMultiSelectProps {
    value: IServiceLabelMultiSelectValue[];
    onChange: (selectedServiceLabels: IServiceLabelMultiSelectValue[]) => void;
    label?: string;
    width?: number;
    maxTagCount?: number;
    maxTagTextLength?: number;
}

export const ServiceLabelMultiSelect = (props: IServiceLabelMultiSelectProps) => {
    const { data: serviceLabelList } = useGetServiceLabelsQuery();

    return (
        <div className="ServiceLabelMultiSelect">
            <div className="slms-label">{props.label}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                value={props.value}
                maxTagCount={props.maxTagCount}
                maxTagTextLength={props.maxTagTextLength}
                onChange={props.onChange}
            >
                {(serviceLabelList || []).map((serviceLabel) => (
                    <Option key={serviceLabel} value={serviceLabel}>
                        {serviceLabel}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    );
};

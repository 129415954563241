import { httpGet } from './http-service';

export interface IConfigRes {
    default_interval: string;
    total: number;
    timeframe: {
        end_timestamp: number; // moment
        start_timestamp: number; // moment
    };
}

// @deprecated
// TODO: switch all usages to RTK-Q config query
export const getOrganizationConfig = async (activeOrg: string) => {
    const response = await httpGet(`organizations/${activeOrg}/config`);
    return response.data;
};

import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ICellRendererParams } from '@ag-grid-community/core';

import {
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { UiAgGridCSRM } from 'sharedComponents/ui-ag-grid/UiAgGridCSRM';
import { CustomTooltip } from 'sharedComponents/ui-ag-grid/customToolTip';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import {
    IServiceMappingItem,
    useDeleteServiceMappingItemMutation,
    useServiceMappingListQuery,
    useUpdateServiceMappingItemMutation,
} from 'api/ServiceMappingApi';
import { getServiceMappingRulesTableCols } from './getServiceMappingRulesTableCols';
import { ServiceMappingRuleAddEdit } from './ServiceMappingRuleAddEdit/ServiceMappingRuleAddEdit';
import { CellRendererCustomMappingRule } from './CellRendererCustomMappingRule/CellRendererCustomMappingRule';

import './ServiceMappingRuleList.scss';
import { SettingsSectionHeader } from 'features/settings/components/SettingsSectionHeader/SettingsSectionHeader';

interface IServiceMappingRuleList {
    editMode?: boolean;
    addMode?: boolean;
}

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        customTooltip: CustomTooltip,
        cellRendererCustomMappingRule: CellRendererCustomMappingRule,
    },
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: ICellRendererParams) {
            return !params.data?.active || false;
        },
    },
};

export const ServiceMappingRuleList = ({ editMode, addMode }: IServiceMappingRuleList) => {
    const history = useHistory();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { data: serviceMappingData, refetch: refetchServiceMappingList } = useServiceMappingListQuery();
    const [triggerDeleteServiceMapping] = useDeleteServiceMappingItemMutation();
    const [triggerUpdateServiceMapping] = useUpdateServiceMappingItemMutation();
    const [ruleMappingToDelete, setRuleMappingToDelete] = useState<IServiceMappingItem>();

    const tableMenuItems = (params: ICellRendererParams) => {
        if (params?.data?.pattern === 'Default Rule') {
            return { hideMenu: true };
        }
        return {
            menuItems: [
                {
                    label: `Edit`,
                    icon: <UiIcon name="pencil" />,
                    onClick: () => {
                        history.push(`/${currentTenantKey}/settings/service-mappings/${params?.data?.id}/edit`);
                    },
                },
                {
                    label: `Delete`,
                    icon: <UiIcon name="trash" />,
                    onClick: () => {
                        setRuleMappingToDelete(params?.data);
                    },
                },
            ],
        };
    };

    const onRowToggleHandler = async (e: boolean, idx: number, id: string) => {
        await triggerUpdateServiceMapping({
            serviceMappingId: id,
            body: { active: e },
        });
        await refetchServiceMappingList();
    };

    const columnsDefs = useMemo(
        () => getServiceMappingRulesTableCols(onRowToggleHandler, tableMenuItems),
        [onRowToggleHandler, tableMenuItems]
    );

    const onRowDragEnd = async (e: any) => {
        const { id, index: oldIndex } = e?.node?.data;
        const index = e.overIndex + 1;
        if (!e?.overNode || e?.overNode?.lastChild) {
            await refetchServiceMappingList();
            return;
        }
        if (index !== oldIndex) {
            await triggerUpdateServiceMapping({
                serviceMappingId: id,
                body: { index },
            });
            await refetchServiceMappingList();
        }
    };

    const onCloseModal = () => {
        history.push(`/${currentTenantKey}/settings/service-mappings`);
    };

    const handleDeleteRuleMapping = async () => {
        if (ruleMappingToDelete?.id) {
            await triggerDeleteServiceMapping({ serviceMappingId: ruleMappingToDelete?.id });
            refetchServiceMappingList();
            setRuleMappingToDelete(undefined);
        }
    };

    return (
        <div className="service-mapping-container">
            <SettingsSectionHeader
                title="Service Mapping Rules"
                urlPath="settings/service-mappings/add"
                buttonText="Add Rule"
                disableButton={(serviceMappingData?.items?.length || 0) > 20}
            />
            <div className="service-mapping-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={serviceMappingData?.items || []}
                    showRowCount={true}
                    columns={columnsDefs}
                    rowDragEndHandler={onRowDragEnd}
                    rowCountTitle={serviceMappingData?.items?.length === 1 ? 'Rule' : 'Rules'}
                />
            </div>
            {addMode && <ServiceMappingRuleAddEdit onClose={onCloseModal} />}
            {editMode && <ServiceMappingRuleAddEdit onClose={onCloseModal} />}
            {ruleMappingToDelete && (
                <UiModal
                    onCancel={() => setRuleMappingToDelete(undefined)}
                    acceptButton={{ text: 'Delete', onClick: handleDeleteRuleMapping }}
                    rejectButton={{ text: 'Cancel', onClick: () => setRuleMappingToDelete(undefined) }}
                    title={`Delete Rule Mapping?`}
                    icon="trash2"
                >{`Delete Service Mapping Rule '${
                    ruleMappingToDelete?.operation_value || ruleMappingToDelete?.pattern
                }'?`}</UiModal>
            )}
        </div>
    );
};

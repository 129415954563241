import { Select } from 'antd';

import { useEntity } from 'services/data/hooks';
import { EntityName } from 'services/data/entityMap';
import UiMultiSelect from 'sharedComponents/UiMultiSelect/UiMultiSelect';

import './AlertNameMultiSelect.scss';

const { Option } = Select;

interface IAlertNameMultiSelectProps {
    label?: string;
    value: string[];
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertNameMultiSelect = (props: IAlertNameMultiSelectProps) => {
    const [alertNameList] = useEntity<{ items: {}[] }>(EntityName.AlertName);

    return alertNameList ? (
        <div className="alert-multi-select">
            <div className="ams-label">{props.label || 'Alert Name'}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                onChange={(event: any) => props.onChange(event)}
            >
                {alertNameList.items.map((alertName: any) => (
                    <Option key={alertName.id} value={alertName.alert_name}>
                        {alertName.alert_name}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    ) : null;
};

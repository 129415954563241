import { icons } from 'assets/icons';
import { Select } from 'antd';
import React, { useState } from 'react';
import { UiTitle } from 'sharedComponents/UiTitle/UiTitle';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

export interface IOperatorsDropdown {
    item: any;
    index: number;
    onOperatorChanged: Function;
}

const numbersOperators = [
    { label: '=', value: 'Eq' },
    { label: '!=', value: 'Ne' },
    { label: '>', value: 'Gt' },
    { label: '>=', value: 'Ge' },
    { label: '<', value: 'Lt' },
    { label: '<=', value: 'Le' },
];

const stringOperators = [
    { label: 'Contains', value: 'Contains' },
    { label: 'Not Contains', value: 'NotContains' },
    { label: 'Is', value: 'Is' },
    { label: 'Is Not', value: 'IsNot' },
    { label: 'One Of', value: 'OneOf' },
    { label: 'Not One Of', value: 'NotOneOf' },
];

const anyOperators = [
    { label: 'Exists', value: 'Exists' },
    { label: 'Does Not Exist', value: 'NotExists' },
];

export const QueryOperatorsDropdown = ({ item, index, onOperatorChanged }: IOperatorsDropdown) => {
    const [headerIsOpen, setHeaderIsOpen] = useState<Record<string, boolean>>({
        ipOperators: false,
        textOperators: false,
        numberOperators: false,
    });

    const OptionHeader = ({ title, id }: any) => {
        return (
            <div className="custom-group" onClick={() => setHeaderIsOpen({ ...headerIsOpen, [id]: !headerIsOpen[id] })}>
                <UiTitle title={title} type="medium" />
                <UiIcon name={headerIsOpen[id] ? 'downArrowSmall' : 'upArrowSmall'} />
            </div>
        );
    };

    const optionOperatorsInitialState = [
        {
            key: 'textOperators',
            label: <OptionHeader title="Text Operators" id="textOperators" />,
            options: headerIsOpen['textOperators'] ? stringOperators : [],
        },
        {
            key: 'numberOperators',
            label: <OptionHeader title="Numbers Operators" id="numberOperators" />,
            options: headerIsOpen['numberOperators'] ? numbersOperators : [],
        },
        {
            key: 'anyOperators',
            label: <OptionHeader title="Any Operators" id="anyOperators" />,
            options: headerIsOpen['anyOperators'] ? anyOperators : [],
        },
    ];

    return (
        <>
            <Select
                options={optionOperatorsInitialState}
                placeholder="Op"
                disabled={!item.id && item.id !== 0}
                value={item.operator ? item.operator : null}
                onChange={(e: any) => onOperatorChanged(e, index)}
                style={{ width: 170 }}
                suffixIcon={icons['treeOpen']()}
            />
        </>
    );
};

import { AxiosResponse } from 'axios';

import { httpGet, httpPatch, httpPOST } from 'general/http-service';
import { tenantApi } from 'api/baseServerApi';
import { IDataSource } from 'features/settings/components/dataSources/shared/dataSourceApis';

/**
 * Each prop represent a state of a button in collector card's :
 * isDisableable: true => "disable collector" available, false => "enable collector" available, undefined => menu option is hidden
 * isDeletable: true => delete collector available, false => undelete collector available, undefined => menu option is hidden
 * isEditable: true => user can edit collector, undefined => can't edit collector
 * isUpgradable: true => user can upgrade collector, undefined => can't upgrade collector
 * isCanAssignConfig: true => user can assign config, false/undefined => assign config dropdown is disabled
 * color: the color of the "status disc indicator" adjacent to the name in collector card (stoplight system)
 **/
interface stateBtns {
    isDisableable?: boolean;
    isDeletable?: boolean;
    isEditable?: boolean;
    isCanAssignConfig?: boolean;
    isUpgradable?: boolean;
}

/**
 * Configure various aspects of collector per its 'state' (buttons, colors, etc)
 */
export const collectorStateMachine: Record<CollectorStates, stateBtns> = {
    enabled: {
        isDisableable: true,
        isEditable: true,
        isCanAssignConfig: true,
        isUpgradable: true,
    },
    disabled: {
        isDisableable: false,
        isDeletable: true,
        isEditable: true,
        isCanAssignConfig: true,
        isUpgradable: true,
    },
    pending_enabled: {},
    pending_disabled: {},
    errored: { isEditable: true, isCanAssignConfig: true, isUpgradable: true },
    pending_deleted: { isDeletable: false },
    deleted: {},
    applying_config: {},
    upgrading: {},
};

/**
 * Node Image states
 */
export enum NodeImageStatus {
    creating = 'Creating',
    ready = 'Ready',
    failed = 'Failed',
}

export interface INodeImage {
    id: string;
    timezone: string;
    status: NodeImageStatus;
    statusDatetime: string;
    collector_id: string;
}

export enum NodeTypes {
    ISO = 'iso',
    RPM = 'rpm',
}

export enum NodeConnectionMode {
    DHCP = 'dhcp',
    FIXED_IP = 'fixed_ip',
}

/**
 * CMS states
 */
export enum CollectorStates {
    enabled = 'enabled',
    disabled = 'disabled',
    pending_enabled = 'pending_enabled',
    pending_disabled = 'pending_disabled',
    errored = 'errored',
    pending_deleted = 'pending_deleted',
    deleted = 'deleted',
    applying_config = 'applying_config',
    upgrading = 'upgrading',
}

export interface ICollector {
    [index: string]: any; // needed (by TS) for indexing values of an instance (e.g.: someCollector[propNameVar] )
    action: string;
    configStatus: string;
    createdAt: string;
    createdBy: string;
    currentConfigId?: string;
    hostname: string;
    id: string;
    cmGeneratedId: string;
    installationCmd: string;
    ipAddresses: string[];
    isEnabled: boolean;
    logsCollectedCount: number;
    logsCollectedKb: number;
    name: string;
    pendingConfigId: string;
    proxy: string;
    sourceId: string;
    state: CollectorStates;
    errorMsg: string;
    tenantKey: string;
    deploymentType: NodeTypes;
    updatedAt: string;
    lastReported: string;
    updatedBy: string;
    version: string;
    installationLink: string;
    isHeartbeatActive: boolean;
    edges: {
        sources: IDataSource;
        currentPackageVersion: {
            version: string;
        };
        nodeImage?: INodeImage;
    };
}

export interface INodeProxy {
    schema: 'http' | 'https';
    url: string;
}

interface IFixedIp {
    address: string;
    netMask: string;
    defaultGateway: string;
    nameServer: string;
}

export type INewNode = Pick<ICollector, 'tenantKey' | 'name' | 'sourceId' | 'deploymentType' | 'collectorConfId'> & {
    proxy: INodeProxy;
    timezone?: string;
    fixedIp?: IFixedIp;
};

export const getCollector: (tenantKey: string, sourceId: string, collectorId: string) => Promise<ICollector> = async (
    tenantKey,
    sourceId,
    collectorId
) => {
    return httpGet(`organizations/${tenantKey}/cms/sources/${sourceId}/collectors/${collectorId}`).then(
        (res) => res.data
    );
};

export const getCollectors: (tenantKey: string, sourceId: string) => Promise<ICollector[]> = async (
    tenantKey,
    sourceId
) => {
    return httpGet(`organizations/${tenantKey}/cms/sources/${sourceId}/collectors`).then((res) => res.data);
};

export const createCollector: (node: INewNode) => Promise<boolean> = async ({
    tenantKey: tenant_key,
    name,
    sourceId,
    deploymentType,
    collectorConfId,
    proxy,
    fixedIp,
    timezone,
}) => {
    return httpPOST(`organizations/${tenant_key}/cms/sources/${sourceId}/collectors`, {
        name,
        sourceId,
        deploymentType,
        collectorConfId,
        proxy,
        fixedIp,
        timezone,
    }).then((res) => {
        return true;
    });
};

export const updateCollector: (
    tenantKey: string,
    sourceId: string,
    collectorId: string,
    fieldsToUpdate: Partial<ICollector>
) => Promise<AxiosResponse> = async (tenant_key, sourceId, collectorId, fieldsToUpdate) => {
    return httpPatch(`organizations/${tenant_key}/cms/sources/${sourceId}/collectors/${collectorId}`, {
        ...fieldsToUpdate,
    }).then((res) => {
        return res;
    });
};

export interface INodePackageResponse {
    id: string;
    version: string;
    downloadUrl: string;
    timestamp: string;
    changeLog: string;
}

const collectorApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getCollectorPackages: builder.query<INodePackageResponse[], { sourceId: string; collectorId: string }>({
            query: ({ sourceId, collectorId }) => `/cms/sources/${sourceId}/collectors/${collectorId}/packages`,
        }),
        patchCollector: builder.mutation<any, { sourceId: string; collectorId: string } & Partial<ICollector>>({
            query: ({ sourceId, collectorId, ...patch }) => ({
                url: `/cms/sources/${sourceId}/collectors/${collectorId}`,
                method: 'PATCH',
                body: patch,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useGetCollectorPackagesQuery = collectorApi.endpoints.getCollectorPackages.useQuery;
export const usePatchCollectorMutation = collectorApi.endpoints.patchCollector.useMutation;

import { Progress } from 'antd';

import './UiColumnProgress.scss';

interface IColumnProgressProps {
    score: number;
}

const MAX_PROGRESS_SCORE = 100;

export const UiColumnProgress = (props: IColumnProgressProps) => {
    const score = props.score;
    const defineColorByScore = (score: number) => {
        if (score < 10) {
            return 'var(--risk-info-color)';
        } else if (score > 9 && score < 25) {
            return 'var(--risk-low-color)';
        } else if (score > 24 && score < 70) {
            return 'var(--risk-medium-color)';
        } else if (score > 69 && score < 100) {
            return 'var(--risk-high-color)';
        } else {
            return 'var(--risk-critical-color)';
        }
    };

    return (
        <div className="ui-column-progress">
            <span className="extra-info">{score}</span>
            <Progress
                percent={score > MAX_PROGRESS_SCORE ? MAX_PROGRESS_SCORE : score}
                showInfo={false}
                strokeColor={defineColorByScore(score)}
            />
        </div>
    );
};

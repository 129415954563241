import { skipToken } from '@reduxjs/toolkit/query';

import { UiBarChart } from 'sharedComponents/UiBarChart/UiBarChart';
import { selectSensitiveBarsColors, useGetServiceSensitiveEndpointsQuery } from 'api/discoveryApi';
import Spinner from 'sharedComponents/spinner/Spinner';
import { store } from 'general/store';
import { DiscoveryDashboardWidgetProps } from '../../discoveryDashboardTypes';
import { DiscoveryDashboardEmptyState } from '../../DiscoveryDashboardEmptyState/DiscoveryDashboardEmptyState';
import { modifyWidgetRequestLimit } from '../../DiscoveryDashboard';
import sensitiveDataWidgetEmptyStateSvg from '../../sensitiveDataBarCharts/DiscoverySensitiveDataWidgetEmptyState.svg';

import './DiscoveryServicesWithSensitiveDataWidget.scss';

export const DiscoveryServicesWithSensitiveDataWidget = ({
    forceRender,
    commonRequestPayload,
    handleRedirectServiceLink,
}: DiscoveryDashboardWidgetProps) => {
    const requestPayload = modifyWidgetRequestLimit(6, commonRequestPayload);
    const { data: { labels = [], stats = [] } = {}, isLoading } = useGetServiceSensitiveEndpointsQuery(
        requestPayload || skipToken
    );
    const colorsMap = selectSensitiveBarsColors(store.getState(), requestPayload);

    if (isLoading) {
        return <Spinner show paddingBottom={50} />;
    }

    if (stats && Object.keys(stats).length === 0) {
        return (
            <DiscoveryDashboardEmptyState
                title="Services with Sensitive Data"
                widgetSvg={sensitiveDataWidgetEmptyStateSvg}
            />
        );
    }

    if (Object.keys(colorsMap).length < 1) {
        return <Spinner show paddingBottom={50} />;
    }

    return (
        <div className="DiscoveryServicesWithSensitiveDataWidget">
            <div className="dd-title-container">
                <div className="widget-title">Services with Sensitive Data</div>
            </div>
            <div className="widget-chart" key={forceRender + 'DiscoveryServicesWithSensitiveDataWidget'}>
                <UiBarChart
                    graphKeys={labels}
                    graphData={stats}
                    colorsMap={colorsMap}
                    onClick={handleRedirectServiceLink}
                    margin={{
                        top: 24,
                    }}
                    isStacked
                />
            </div>
        </div>
    );
};

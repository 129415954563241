import { IServerQueryResponse, tenantApi } from 'api/baseServerApi';
import { fetchAll } from 'api/apiUtils';
import { IAlertRulePredicate } from 'interfaces/throttle.interface';

export interface IAvailableAction {
    id: string;
    name: string;
    description: string;
}

export interface IAutomatedActionRule {
    id: string;
    status: 'enabled' | 'disabled';
    name: string;
    automated_action_id: IAvailableAction['id'];
    hit_count: number;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    predicate: IAlertRulePredicate;
}

export const automatedActionRulesApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getAutomatedActionRules: builder.query<IServerQueryResponse<IAutomatedActionRule>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IAutomatedActionRule>('automated_action_rules', 1000, baseQuery),
            providesTags: ['automatedActionRules'],
        }),
        getAvailableActions: builder.query<IServerQueryResponse<IAvailableAction>, void>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IAvailableAction>('automated_action_rules/available_actions', 1000, baseQuery),
        }),
        postAutomatedActionRule: builder.mutation<
            void,
            { body: Pick<IAutomatedActionRule, 'name' | 'automated_action_id' | 'predicate'> }
        >({
            query: ({ body }) => ({
                url: 'automated_action_rules',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['automatedActionRules'],
        }),
        patchAutomatedActionRule: builder.mutation<
            void,
            {
                id: IAutomatedActionRule['id'];
                body: Partial<Pick<IAutomatedActionRule, 'name' | 'status' | 'predicate' | 'automated_action_id'>>;
            }
        >({
            query: ({ id, body }) => ({
                url: `automated_action_rules/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['automatedActionRules'],
        }),
        deleteAutomatedActionRule: builder.mutation<void, { id: IAutomatedActionRule['id'] }>({
            query: ({ id }) => ({
                url: `automated_action_rules/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['automatedActionRules'],
        }),
    }),
});

export const useGetAutomatedActionRulesQuery = automatedActionRulesApi.endpoints.getAutomatedActionRules.useQuery;
export const useGetAvailableActionsQuery = automatedActionRulesApi.endpoints.getAvailableActions.useQuery;
export const usePostAutomatedActionRuleMutation = automatedActionRulesApi.endpoints.postAutomatedActionRule.useMutation;
export const usePatchAutomatedActionRuleMutation =
    automatedActionRulesApi.endpoints.patchAutomatedActionRule.useMutation;
export const useDeleteAutomatedActionRuleMutation =
    automatedActionRulesApi.endpoints.deleteAutomatedActionRule.useMutation;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EntityForm, EntityName } from './entityMap';
import { Cache, DataServiceSubscription, HTTPMethod, query, QueryParams, RESTQuery, subscribe } from './data-service';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';

function createFactory(entityUrl: string, method: HTTPMethod) {
    return (body: Object) => {
        let resolve: Function, reject: Function;
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });

        const newQuery = new RESTQuery(entityUrl, method, {}, body);

        const subscr = subscribe(
            (cache) => {
                const result = cache.get(entityUrl)?.get(JSON.stringify({}));
                if (result) {
                    subscr.unsubscribe();
                    resolve(result);
                }
            },
            (error) => {
                const relevantError = error[JSON.stringify(newQuery)];
                if (relevantError && JSON.stringify(relevantError) !== JSON.stringify(error)) {
                    subscr.unsubscribe();
                    reject(relevantError?.response?.data?.detail);
                }
            }
        );

        query(newQuery);

        return promise;
    };
}

function createNext(pageSize: number, setOffset: Function) {
    return () => setOffset((prev: number) => prev + pageSize);
}

export function useEntity<T>(
    entityName: EntityName,
    params: QueryParams = {},
    id?: string | null,
    pageSize?: number,
    deps?: Array<any>
): [T, Function, Function, string, Function | null] {
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    const [subscription, setSubscription] = useState<DataServiceSubscription>();
    const [result, setResult] = useState<T>();
    const [update, setUpdate] = useState<Function>(() => {});
    const [create, setCreate] = useState<Function>(() => {});
    const [error, setError] = useState<string>('');
    const [loadNextPage, setLoadNextPage] = useState<Function | null>(() => {});
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        if ((currentTenantKey && !deps) || deps?.filter((dep) => dep === undefined).length === 0) {
            const entityListUrl = `organizations/${currentTenantKey}/${entityName}`;
            const entityUrl = entityListUrl + (id ? `/${id}` : '');

            setCreate(() => createFactory(entityListUrl, HTTPMethod.POST));
            setUpdate(() => createFactory(entityUrl, HTTPMethod.PATCH));
            setLoadNextPage(() => createNext(pageSize || 0, setOffset));

            if (id === null) {
                // console.log('ID is null, Setting Result for', entityUrl)
                setResult(EntityForm.get(entityName) ? { ...EntityForm.get(entityName) } : null);
                return () => {};
            }

            subscription?.unsubscribe();
            setSubscription(
                subscribe(
                    (cache: Cache) => {
                        const newResult = cache.get(entityUrl)?.get(JSON.stringify({ ...params, offset }))?.data;

                        if (!newResult) {
                            return;
                        }

                        if (offset > newResult.total) {
                            return setLoadNextPage(null);
                        }

                        if (JSON.stringify(newResult) !== JSON.stringify(result)) {
                            // console.log('Setting Result for', entityUrl)
                            return setResult((prev: any) => newResult || prev);
                        }
                    },
                    (error) => {
                        const relevantError = error[JSON.stringify(newQuery)];
                        if (relevantError && JSON.stringify(relevantError) !== JSON.stringify(error)) {
                            setError((prev: any) => relevantError?.response?.data?.detail || prev);
                        }
                    }
                )
            );

            const newQuery = new RESTQuery(entityUrl, HTTPMethod.GET, { ...params, offset });
            query(newQuery);

            return () => {
                // console.log('Unsubscribing for', entityUrl)
                subscription?.unsubscribe();
            };
        }
    }, [...(deps || []), currentTenantKey, offset]);

    return [result as T, update, create, error, loadNextPage];
}

import { IConfigResponse } from 'api/configApi';
import { CognitoUser } from '@aws-amplify/auth';

type UserFilter = (user: CognitoUser | any) => boolean;

const emailListEngineering = [
    'boazr@neosec.com',
    'brymland@akamai.com',
    'dannyb@neosec.com',
    'dberger@akamai.com',
    'reutl@neosec.com',
    'rlevron@akamai.com',
    'ssiddhar@akamai.com',
    'liozs@neosec.com',
    'lsteuer@akamai.com',
    'avnerv@neosec.com',
    'roknyans@akamai.com',
    'gstrauss@akamai.com',
    'sgutta@akamai.com',
    'gala@neosec.com',
    'gala+new@neosec.com',
    'tbar@akamai.com',
    'saharl@neosec.com',
    'strauss.guy@gmail.com',
    // 'liadt@neosec.com',
    // 'ltau@akamai.com',
    // 'almogc@neosec.com',
    // 'rmagori@akamai.com',
    // 'dmashiah@akamai.com',
    // 'dhirshso@akamai.com',
    // 'user1_konaqa@akamai.com',
];

const emailListQA = ['mahshar@akamai.com', 'vikrshar@akamai.com', 'apisec_user1@akamai.com', 'apisec_user2@akamai.com'];

const emailListSE = [
    'demo+rsa@neosec.com',
    'joed@neosec.com',
    'menachemp@neosec.com',
    'earkush@akamai.com',
    'chadb@neosec.com',
    'sboulter@akamai.com',
    'ychao@neosec.com',
    'jchai@akamai.com',
];

const emailListProduct = [
    'yarivs@neosec.com',
    'yshivek@akamai.com',
    'apaz@akamai.com',
    'arsenyk@neosec.com',
    'akrupnik@akamai.com',
    'kauwers@akamai.com',
    'mharezla@akamai.com',
];

export const UserFilters: { [id: string]: UserFilter } = {
    IS_NEOSEC: (user: CognitoUser | any): boolean => {
        return user?.['cognito:groups'][0].toLowerCase().indexOf('_neosec') >= 0;
    },
};

export function checkUser(user: CognitoUser, requirements: UserFilter): boolean {
    try {
        return requirements(user);
    } catch (e) {
        return false;
    }
}

function createdInBackoffice(creationSystem: IConfigResponse['creation_system']) {
    return creationSystem === 'manual';
}

const testingTenants = [
    'api_testing_neosec_lab',
    'api_testing_field_lab',
    'api_testing_rsa_lab',
    'api_testing_rsa_2_lab',
    'e2e_payments',
];

let isDdRumTrackingAllowed = false;

export const featureFlagMap = (tenant: string, user: CognitoUser | any, config?: IConfigResponse) => {
    const isNeosecOrDemo = !!(checkUser(user, UserFilters.IS_NEOSEC) || config?.is_demo);
    if (
        config && // with TS using !config?.is_demo will return true if no config arrived yet. Need to overcome this
        !config.is_demo &&
        process.env.REACT_APP_DD_RUM_ENABLED === 'true' &&
        !navigator.userAgent.includes('DatadogSynthetics')
    ) {
        isDdRumTrackingAllowed = true;
    } else {
        isDdRumTrackingAllowed = false;
    }

    return {
        automatedActions: !config?.is_demo,
        automatedActionsMock: !!config?.is_demo,
        collectorsMgmt: isNeosecOrDemo,
        exclusionRules: isNeosecOrDemo,
        endpointPatterns: isNeosecOrDemo,
        entityManagement: isNeosecOrDemo,
        akamaiConfigs: isNeosecOrDemo,
        accManagementUrl: config?.creation_system === 'config',
        ddRumTracking: isDdRumTrackingAllowed,
        testing: testingTenants.includes(tenant),
        detokenization: !!user?.['custom:acc_scopes']?.includes('APISEC_APP_DETOK'),
        serviceMapping: isNeosecOrDemo,
        apiTesting: !!user?.['custom:acc_scopes']?.includes('APISEC_APITEST_EDIT'),
        excelExport: [...emailListEngineering, ...emailListProduct, ...emailListSE].includes(user?.email),
    };
};

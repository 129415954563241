import { useHistory, useLocation } from 'react-router-dom';
import { History, Location } from 'history';
import { useSelector } from 'react-redux';

import './App.scss'; // don't change order of imports here!

import { signOut } from 'general/auth-service';
import { ConfirmationModal } from 'sharedComponents/shared/confirmaion-modal/ConfirmationModal';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { NavBar } from 'sharedComponents/NavBar/NavBar';
import { AppRoutes } from 'AppRoutes';

interface historyType {
    location?: Location;
    navigate?: History;
}

export const history: historyType = {
    location: undefined,
    navigate: undefined,
};

function App() {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    history.location = useLocation();
    history.navigate = useHistory();

    return (
        <div className="app">
            <NavBar showNavigation={!!currentTenantKey} signOut={signOut} />
            <div className="app-container">
                <AppRoutes />
            </div>
            <ConfirmationModal />
        </div>
    );
}

export default App;

import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { IAuthConfig, useDeleteAuthConfigMutation } from 'api/apiTestingApi';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage } from 'general/utils';

export interface IDeleteAuthConfigConfirmModalProps {
    onClose: () => void;
    authConfig: IAuthConfig;
}

export const DeleteAuthConfigConfirmModal = ({ onClose, authConfig }: IDeleteAuthConfigConfirmModalProps) => {
    const [deleteAuthConfig] = useDeleteAuthConfigMutation();

    const handleDelete = async () => {
        try {
            await deleteAuthConfig({ id: authConfig.id });
        } catch (e) {
            errorMessage(extractErrorMessage(e));
        } finally {
            onClose();
        }
    };

    return (
        <UiModal
            onCancel={onClose}
            acceptButton={{ text: 'Delete', onClick: handleDelete }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            title="Delete Auth Config"
            icon="trash2"
        >
            {`Delete Auth Config ${authConfig.name}?`}
        </UiModal>
    );
};

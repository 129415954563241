import React from 'react';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

interface ICheckboxFilters {
    setFilters: Function;
    serviceData?: any;
}

enum filterHideOptions {
    noActivity = 'noActivity',
}

const options = () => [
    {
        label: 'Show endpoints with no activity',
        value: filterHideOptions.noActivity,
    },
];

const filterHideNoActivityEndpoints = [{ name: 'calls_count', operator: 'ne', value: 0 }];

export const CheckboxFilters = ({ setFilters, serviceData }: ICheckboxFilters) => {
    const onChange = (checkedValues: CheckboxValueType[]) => {
        const noActivity = checkedValues.includes('noActivity');

        if (checkedValues.length === 0) {
            setFilters([filterHideNoActivityEndpoints]);
            return;
        }

        if (checkedValues.length === 2) {
            setFilters([]);
        }

        if (!noActivity) {
            setFilters([filterHideNoActivityEndpoints]);
        }
    };

    return <Checkbox.Group options={options()} defaultValue={[filterHideOptions.noActivity]} onChange={onChange} />;
};

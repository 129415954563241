import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { copyToClipboard } from 'general/utils';
import { infoMessage } from 'general/toast-service';
import { ICollector, NodeTypes } from '../shared/collectorApis';

import './DeployCollectorModal.scss';
import { UiModal } from 'sharedComponents/UiModal/UiModal';
import { UiIcon } from 'sharedComponents/icon/UiIcon';

export interface IDeployModalProps {
    onClose: (refreshNeeded?: boolean) => void;
    setTroubleshootOpen: (openModal: boolean) => void;
    collectorToDeploy: ICollector;
}

const ISO_PDF_CREDS = 'docs_user:xi11wk7eb0p3opfuhumx3uxav4cf1j0h';

const ISO_REQUIREMENTS = `These requirements refer to VMWare vSphere, but also apply to other hypervisors.

1. 4 Intel Xeon or AMD EPYC cores
2. 16 GB RAM
3. 500GB virtual disk (thin provisioning)
4. Internet connection through one of the vSwitches/distributed vSwitches`;

const RPM_REQUIREMENTS = `CPU: 4 cores
RAM: 16 GB
HDD: 500 GB

This RPM has been tested on RedHat Enterprise Linux version 7 or above, Oracle Cloud Linux version 7.9 or above, on x86-64 architectures.`;

export const DeployCollectorModal = ({ onClose, collectorToDeploy, setTroubleshootOpen }: IDeployModalProps) => {
    const { installationLink, deploymentType } = collectorToDeploy;

    return (
        <UiModal
            isVisible
            isFormModal
            onCancel={() => onClose(false)}
            acceptButton={{ text: 'Ok', onClick: onClose }}
            title="Deploy Node"
            width={700}
            extraFooterContent={
                <div className="troubleshoot-deploy">
                    <UiIcon name="troubleshooting" /> Having trouble?{' '}
                    <span
                        className="link"
                        onClick={() => {
                            onClose();
                            setTroubleshootOpen(true);
                        }}
                    >
                        Click here for troubleshooting
                    </span>
                </div>
            }
        >
            <div className="DeployCollectorModal">
                <div className="section">
                    <div className="subtitle">
                        {deploymentType === NodeTypes.ISO ? 'ISO Link' : 'RPM Installation Command'}
                    </div>
                    <div className="section-content install-link">
                        <Button
                            ghost
                            icon={<CopyOutlined />}
                            onClick={() => copyToClipboard(installationLink)}
                            className="copy-button"
                        />
                        <div>
                            {deploymentType === NodeTypes.ISO ? (
                                <a className="link" href={installationLink}>
                                    {installationLink}
                                </a>
                            ) : (
                                installationLink
                            )}
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="subtitle">Hardware Requirements</div>
                    <div className="section-content hardware-requirements">
                        <Button
                            ghost
                            icon={<CopyOutlined />}
                            onClick={() =>
                                copyToClipboard(deploymentType === NodeTypes.ISO ? ISO_REQUIREMENTS : RPM_REQUIREMENTS)
                            }
                            className="copy-button"
                        />
                        {deploymentType === NodeTypes.ISO ? ISO_REQUIREMENTS : RPM_REQUIREMENTS}
                    </div>
                </div>
                {deploymentType === NodeTypes.ISO && (
                    <div className="section">
                        <div className="subtitle">Instructions</div>
                        <div className="section-content deploy-instructions">
                            <a
                                href={`https://${ISO_PDF_CREDS}@artifacts.neosec.com/iso-installation-docs/neosec_iso_installation_guide_2.0.pdf`}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="link"
                            >
                                Deployment Instructions for Neosec Node ISO image
                            </a>
                            <Button
                                ghost
                                icon={<CopyOutlined />}
                                onClick={() => infoMessage('This button will copy the link URL in the future')}
                                className="copy-button"
                            />
                        </div>
                    </div>
                )}
            </div>
        </UiModal>
    );
};

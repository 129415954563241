import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import Markdown from 'react-markdown';

import {
    IApplicationGroupedFinding,
    IFinding,
    IScan,
    IScanGroupedFinding,
    useGetCallCurlQuery,
    useGetFindingCallsQuery,
} from 'api/apiTestingApi';
import { UiPagination } from 'sharedComponents/UiPagination/UiPagination';
import { ValueWithLabel } from 'features/apiTesting/shared/ValueWithLabel/ValueWithLabel';
import { FindingDetailsTimeline } from 'features/apiTesting/findings/FindingDetails/FindingDetailsTimeline/FindingDetailsTimeline';
import { IApitRouteParams } from 'features/apiTesting/ApitTestingRoutes';
import Spinner from 'sharedComponents/spinner/Spinner';
import { FindingDetailsOffendingCall } from 'features/apiTesting/findings/FindingDetails/FindingDetailsEvidence/FindingDetailsOffendingCall/FindingDetailsOffendingCall';
import { CallDetailsModal } from 'sharedComponents/CallDetailsModal/CallDetailsModal';

import './FindingDetailsEvidence.scss';

interface IFindingDetailsEvidenceProps {
    groupedFinding: IApplicationGroupedFinding | IScanGroupedFinding;
    scan: IScan;
}

const getFindings = (groupedFinding: IApplicationGroupedFinding | IScanGroupedFinding): IFinding[] => {
    return 'findings' in groupedFinding ? groupedFinding.findings : groupedFinding.last_scan_findings;
};

export const FindingDetailsEvidence = ({ groupedFinding, scan }: IFindingDetailsEvidenceProps) => {
    const { appId } = useParams<IApitRouteParams>();

    const [currentFindingIndex, setCurrentFindingIndex] = useState<number>(0);
    const currentFinding = useMemo(() => {
        return groupedFinding && getFindings(groupedFinding)[currentFindingIndex];
    }, [groupedFinding?.id, currentFindingIndex]);

    const { data: callsResponse, isFetching: isCallsLoading } = useGetFindingCallsQuery(
        currentFinding?.id
            ? {
                  appId,
                  scanId: scan.id.toString(),
                  groupedFindingId: groupedFinding.id.toString(),
                  findingId: currentFinding.id.toString(),
              }
            : skipToken
    );
    const offendingCall = useMemo(() => {
        return callsResponse?.items.find((call) => call.is_offending);
    }, [callsResponse]);
    const offendingCallIndex = useMemo(() => {
        return callsResponse?.items.findIndex((call) => call.is_offending);
    }, [callsResponse]);

    const [modalCallIndex, setModalCallIndex] = useState<number>();
    const { data: curl } = useGetCallCurlQuery(
        callsResponse?.items.length && modalCallIndex !== undefined
            ? {
                  appId,
                  groupedFindingId: groupedFinding.id.toString(),
                  scanId: scan.id.toString(),
                  findingId: currentFinding.id.toString(),
                  callId: callsResponse.items[modalCallIndex].id.toString(),
              }
            : skipToken
    );

    if (isCallsLoading) {
        return (
            <div className="finding-details-container FindingDetailsEvidence">
                <Spinner show />
            </div>
        );
    }

    if (!callsResponse || !offendingCall || !currentFinding) {
        return <></>;
    }

    return (
        <div className="FindingDetailsEvidence">
            <div className="finding-details-container-title finding-details-evidence-title">
                <span>Evidence</span>
                <span className="finding-details-evidence-title-scan-id">{`[Scan ${
                    scan ? scan.id : (groupedFinding as IApplicationGroupedFinding).last_scan.id
                }]`}</span>
                <span className="finding-details-evidence-title-pagination-controls">
                    <UiPagination
                        initialPage={currentFindingIndex + 1}
                        totalPageCount={getFindings(groupedFinding).length}
                        onPageChange={(page) => setCurrentFindingIndex(page - 1)}
                    />
                </span>
            </div>
            <div className="finding-details-container-content finding-details-evidence-content">
                <Markdown>{currentFinding.evidence_description}</Markdown>
                <div className="finding-details-evidence-parameters">
                    {Object.entries(currentFinding.evidence_parameters).map((item) => (
                        <ValueWithLabel key={item[0] + item[1]} label={item[0]} value={item[1]} />
                    ))}
                </div>
                <ValueWithLabel
                    label="offending call"
                    value={
                        <FindingDetailsOffendingCall
                            offendingCall={offendingCall}
                            offendingCallSeverity={groupedFinding.severity}
                            scanId={scan.id}
                            groupedFindingId={groupedFinding.id}
                            findingId={currentFinding.id}
                            onView={() => setModalCallIndex(offendingCallIndex)}
                        />
                    }
                />
            </div>
            <FindingDetailsTimeline
                calls={callsResponse.items}
                offendingCallSeverity={groupedFinding.severity}
                onItemClick={(index) => setModalCallIndex(index)}
            />
            {modalCallIndex !== undefined && (
                <CallDetailsModal
                    calls={callsResponse.items}
                    callIndex={modalCallIndex}
                    severity={groupedFinding.severity}
                    curl={curl?.command}
                    toggleModal={() => setModalCallIndex(undefined)}
                    onCallIndexChange={(newCallIndex) => setModalCallIndex(newCallIndex)}
                />
            )}
        </div>
    );
};

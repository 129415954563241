import { RootState } from 'general/store';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';

import { tenantApi } from 'api/baseServerApi';
import { IConfigResponse, selectConfig, useGetConfigLazyQuery } from 'api/configApi';

export const deTokApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        detokenize: builder.query<
            string,
            { tokenizedContent: string; apiKey: IConfigResponse['detok_ratelimit_apikey'] }
        >({
            query: (params) => ({
                url: `nms/detokenize`,
                method: 'POST',
                body: { message: params.tokenizedContent },
                headers: { 'X-API-Key': params.apiKey },
            }),
            transformResponse: (response: { message: string }) => {
                return response.message;
            },
        }),
    }),
});

export const wrapApiKeyLazyQuery =
    (lazyQuery: UseLazyQuery<any>): UseLazyQuery<QueryDefinition<any, BaseQueryFn, string, string>> =>
    // @ts-ignore
    () => {
        const [triggerConfig] = useGetConfigLazyQuery();
        const [trigger, queryResult, promiseInfo] = lazyQuery();

        const triggerWithApiKey = (lazyQueryParams: any) =>
            triggerConfig(undefined, true)
                .unwrap()
                .then((config) => trigger({ ...lazyQueryParams, apiKey: config.detok_ratelimit_apikey }));

        return [triggerWithApiKey, queryResult, promiseInfo];
    };

export const useDetokLazyQuery: UseLazyQuery<
    QueryDefinition<{ tokenizedContent: string }, BaseQueryFn, string, string>
> = wrapApiKeyLazyQuery(deTokApi.endpoints.detokenize.useLazyQuery);

export const createSelectDetok =
    ({ tokenizedContent }: { tokenizedContent: string }) =>
    (state: RootState) => {
        const config = selectConfig(state);
        return deTokApi.endpoints.detokenize.select({ tokenizedContent, apiKey: config!.detok_ratelimit_apikey })(state)
            ?.data;
    };
